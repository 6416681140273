// Modules
import React, { useContext } from "react"

// Components
import Alert from "./Alert"
import { toast } from "react-toastify"

// Views

// Context providers / Utils
import { deleteAlert } from "../../../../Util/AlertsMiddleware"
import AlertsContext from "./AlertsContext"

// Hooks

// Material-UI *

// Styles
import "../AlertSettings.css"

const SavedAlerts = (props) => {
    const { riskData, alerts, path, onDeleteAlert = () => null, units, currentUser, featurePermissions } = props
    const { toggleLoading } = useContext(AlertsContext)

    async function handleAlertDelete(idAlert) {
        try {
            let mPath = { ...path, alert_id: idAlert }
            let userToken = await currentUser.getIdToken()
            toggleLoading(true)
            deleteAlert(userToken, mPath).then((res) => {
                toggleLoading(false)
                toast.success("Alert deleted successfully!")
                onDeleteAlert(idAlert)
            }).catch(err => {
                toggleLoading(false)
                toast.error("Error: Error deleting the user alert. " + err)
            })
        } catch (err) {
            toggleLoading(false)
            console.log("Error deleting user alert ", err)
        }
    }

    return (
        <>
            {props.alerts ? (
                <div>
                    {Object.keys(alerts).map((mAlert) => {
                        if (mAlert !== "name") {
                            let alertData = alerts[mAlert]
                            return (
                                <Alert
                                    key={"alert-" + mAlert}
                                    riskData={riskData}
                                    alertData={alertData}
                                    idAlert={mAlert}
                                    onDelete={handleAlertDelete}
                                    path={{ ...path, alert_id: mAlert }}
                                    currentUser={currentUser}
                                    featurePermissions={featurePermissions}
                                    units={units}
                                />
                            )
                        }
                        return null
                    })}
                </div>
            ) : null}
        </>
    )
}

export default SavedAlerts
