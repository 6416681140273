import React, { useEffect, useState, useContext } from "react"
// Modules
// Components
// Views
// Context providers / Utils
import networking from "../Util/Networking"
import { AuthContext } from "../Auth/Auth"

// Hooks
// Material-UI *
// Styles

export const SettingsContext = React.createContext(null)

export const SettingsProvider = ({ children }) => {
    const { currentUser } = useContext(AuthContext)
    //TODO If general settings object change: modify to include new fields
    const [currentSettings, setCurrentSettings] = useState({
        units: "metric",
        regionalViewMapVisible: false,
    })

    const getUserSettings = () => {
        currentUser
            .getIdToken()
            .then((userToken) => {
                networking
                    .get("/api/v1/settings/general", { extraHeaders: { "User-Token": userToken } })
                    .then((res) => res.data)
                    .then((result) => {
                        setCurrentSettings({ ...currentSettings, ...result })
                    })
                    .catch((error) => {
                        console.log("FirstError")
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log("SecondError")
                console.log(error)
            })
    }

    function handleUpdateDBUserSettings(changedSettings) {
        let updatedSettings = { ...currentSettings, ...changedSettings }
        currentUser
            .getIdToken()
            .then((userToken) => {
                networking
                    .put("/api/v1/settings/general", updatedSettings, { extraHeaders: { "User-Token": userToken } })
                    .then(() => {
                        setCurrentSettings(updatedSettings)
                    })
                    .catch((error) => {
                        console.log("FirstError")
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log("SecondError")
                console.log(error)
            })
    }

    useEffect(() => {
        if (currentUser !== null) getUserSettings()
    }, [])

    return (
        <SettingsContext.Provider
            value={{
                currentSettings,
                handleUpdateDBUserSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}
