import React from "react"

const AlertInput = (props) => {
    const {
        placeholder,
        value = "",
        onChange = () => null,
        match = () => null,
        disabled,
        min = -30,
        max = 2000,
        error,
        flag,
    } = props

    function handleBlur(e) {
        const error = match(e.target.value)
        onChange(e.target.value, error, true)
    }

    function handleInputChange(e) {
        const error = match(e.target.value)
        onChange(e.target.value, error, false)
    }

    return (
        <div className="alert__content__conditions__container">
            <input
                className="alert__content__conditions__input"
                onChange={handleInputChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                value={value}
                type="number"
                min={min}
                max={max}
                disabled={disabled ? "disabled" : ""}
            />

            {flag && <div className="alert__content__conditions__input-error">{error}</div>}
        </div>
    )
}

export default AlertInput
