import React from "react"

export const companyLogosObject = [
    {
        ibm: {
            image: "/images/ibm_logo.png",
            url: "https://www.ibm.com",
        },
        meteoblue: {
            image: "/images/meteoblue_logo.png",
            url: "https://www.meteoblue.com",
        },
        openWeather: {
            image: "/images/open-weather_logo.png",
            url: "https://openweathermap.org/",
        },
        spire: {
            image: "/images/spire_logo.svg",
            url: "https://spire.com/",
        },
        clearAg: {
            image: "/images/clearag_logo.jpg",
            url: "https://www.dtn.com/agriculture/agribusiness/clearag/",
        },
        ecmwf: {
            image: "/images/ecmwf_logo.png",
            url: "https://www.ecmwf.int/",
        },
        noaa: {
            image: "/images/noaa_logo.jpg",
            url: "https://www.noaa.gov/",
        },
    },
]

export const companyLogos = (
    <div className="about-modal__company-logo-container">
        {companyLogosObject.map((item) => {
            let logoArray = []
            for (let [k, n] of Object.entries(item)) {
                if (k == "noaa") {
                    logoArray.push(
                        <a
                            className="about-modal__company-logo__noaa"
                            key={k}
                            href={n.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="about-modal__company-logo-image" src={n.image} alt={`${k} logo`} />
                        </a>
                    )
                } else if (k == "ecmwf") {
                    logoArray.push(
                        <a
                            className="about-modal__company-logo__ecmwf"
                            key={k}
                            href={n.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="about-modal__company-logo-image" src={n.image} alt={`${k} logo`} />
                        </a>
                    )
                } else if (k == "openWeather") {
                    logoArray.push(
                        <a
                            className="about-modal__company-logo__open-weather"
                            key={k}
                            href={n.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="about-modal__company-logo-image" src={n.image} alt={`${k} logo`} />
                        </a>
                    )
                } else {
                    logoArray.push(
                        <a
                            className="about-modal__company-logo"
                            key={k}
                            href={n.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="about-modal__company-logo-image" src={n.image} alt={`${k} logo`} />
                        </a>
                    )
                }
            }

            return logoArray
        })}
    </div>
)

export const customDescription = (
    <div className="about-modal__text">
        <b>ClimateAi ingests all available forecasts </b> (public, private vendors, weather stations, and any other
        ground truth data) <b>to deliver the world's most accurate forecast.</b>
    </div>
)
