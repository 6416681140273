import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={109} height={18} viewBox="0 0 109 18" fill="none" {...props}>
            <path
                d="M44.39 6.615C43.136 3.287 41.952.142 39.127.142c-2.825 0-4.008 3.145-5.26 6.473-1.561 4.143-3.601 10.645-10.41 10.645v.737c8.676 0 10.987-7.582 12.123-10.603 1.215-3.232 2.037-5.213 3.544-5.213 1.507 0 2.33 1.981 3.544 5.213C43.839 10.503 46.153 18 52.548 18c-4.866-1.415-6.599-7.242-8.159-11.385z"
                fill="#F37920"
            />
            <path
                d="M.841 17.997h22.62l.207-.369-.207-.368H.841v.737zM103.786 3.542h1.789v9.678h-1.845l.577 1.487H109V13.22h-1.792V3.542H109V2.055h-5.214v1.487zM97.294 1.768C96.847.51 96.525 0 95.644 0h-.057c-.882 0-1.202.51-1.651 1.768L89.312 14.68h1.924l1.177-3.296h6.41L100 14.68h1.925l-4.63-12.913zM95.615 9.64h-2.587l2.587-7.287 2.587 7.287h-2.587z"
                fill="#F37920"
            />
            <path d="M109 17.263H61.751V18H109v-.737z" fill="#1C9690" />
            <path
                d="M40.823 6.615C42.076 3.287 43.26.142 46.084.142s4.009 3.145 5.261 6.473c1.56 4.143 3.6 10.648 10.41 10.648l.445.456-.446.281c-8.676 0-10.986-7.585-12.123-10.606-1.214-3.232-2.037-5.213-3.544-5.213-1.506 0-2.329 1.981-3.544 5.213C41.373 10.503 39.06 18 32.665 18c4.865-1.415 6.598-7.242 8.158-11.385zM6.231 3.419c1.783 0 3.283.995 3.918 2.598l.03.078h1.8l-.057-.165c-.844-2.495-3.076-4.104-5.691-4.104C2.678 1.826 0 4.644 0 8.379c0 3.736 2.671 6.557 6.212 6.557 2.678 0 4.919-1.612 5.707-4.107l.05-.162h-1.795l-.029.081c-.583 1.577-2.118 2.599-3.917 2.599-2.634 0-4.545-2.088-4.545-4.964.003-2.831 1.955-4.964 4.548-4.964zM15.818 2.055h-1.633v12.652h6.83v-1.542h-5.197V2.056zM67.458 3.613h3.51v11.094h1.635V3.613h3.51V2.055h-8.655v1.558zM79.747 13.165v-4.13h4.715V7.498h-4.715V3.613h5.475V2.055h-7.11v12.652h7.298v-1.542h-5.663zM26.613 3.542h1.79V2.055h-5.215v1.487h1.79v9.678h-1.79v1.487h3.937l.034-.03c.42-.361.829-.784 1.215-1.256l.166-.204h-1.924V3.542h-.003z"
                fill="#1C9690"
            />
            <path
                d="M63.619 3.177C63.22 2.055 62.935 1.6 62.147 1.6c-.788 0-1.074.455-1.476 1.577l-3.748 10.34.047.056c.343.407.707.775 1.083 1.098l.13.114 1.079-3.022h5.716l1.052 2.94h1.717L63.619 3.178zm-3.808 7.032l2.307-6.506 2.308 6.506H59.81z"
                fill="#1C9690"
            />
        </svg>
    )
}

export default SvgComponent
