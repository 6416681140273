import React from "react"

function TimesIcon(props) {
    const { fill } = props
    return (
        <>
            <svg
                style={{ width: "100%", height: "100%" }}
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.875 1.00634L7.99375 0.125092L4.5 3.61884L1.00625 0.125092L0.125 1.00634L3.61875 4.50009L0.125 7.99384L1.00625 8.87509L4.5 5.38134L7.99375 8.87509L8.875 7.99384L5.38125 4.50009L8.875 1.00634Z"
                    fill={fill || "black"}
                />
            </svg>
        </>
    )
}

export default TimesIcon
