import React, { useContext } from 'react';
import { Card, Typography, Box, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Dropdown from '../Dropdown';

import RedDotsIcon from '../../../../assets/Chart/red-dots.svg';
import BlueDotsIcon from '../../../../assets/Chart/blue-dots.svg';
import RedObservedIcon from '../../../../assets/Chart/red-observed.svg';
import BlueObservedIcon from '../../../../assets/Chart/blue-observed.svg';
import RedLightenHistoricalIcon from '../../../../assets/Chart/red-lighten-historical.svg';
import RedDarkenHistoricalIcon from '../../../../assets/Chart/red-darken-historical.svg';
import BlueLightenHistoricalIcon from '../../../../assets/Chart/blue-lighten-historical.svg';
import BlueDarkenHistoricalIcon from '../../../../assets/Chart/blue-darken-historical.svg';
import RedLightenSeasonalIcon from '../../../../assets/Chart/red-lighten-seasonal.svg';
import RedDarkenSeasonalIcon from '../../../../assets/Chart/red-darken-seasonal.svg';
import BlueLightenSeasonalIcon from '../../../../assets/Chart/blue-lighten-seasonal.svg';
import BlueDarkenSeasonalIcon from '../../../../assets/Chart/blue-darken-seasonal.svg';
import GreenLightenHistoricalIcon from '../../../../assets/Chart/green-lighten-historical.svg';
import GreenDarkenHistoricalIcon from '../../../../assets/Chart/green-darken-historical.svg';
import { SettingsContext } from "../../../../Util/SettingsContext"

const useStyles = makeStyles(() => ({
  headerBlocksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerBlockContainer: {
    marginBottom: 18,
    borderBottom: '0.5px solid #A3B1BD',
    '&:last-child': {
      borderBottom: '0 !important',
    }
  },
  headerBlock: {
    border: 0,
    paddingBottom: 16,
    borderRadius: 0,
    boxShadow: 'none',
  },
  titleText: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 9,
  },
  temperatureText: {
    fontFamily: 'Roboto',
    fontSize: 12,
    marginLeft: 12,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ChartSpecs = ({ GDDPlantingDate, onGDDPlantingDateChange, type, chartRef, data, onSliderChange, sliderMinValue, sliderMaxValue, sliderDefaultValue, actionsState, disabled, onHourlyCsvDataTrigger, cumulative }) => {

  const { currentSettings } = useContext(SettingsContext)

  const {
    headerBlocksContainer,
    headerBlockContainer,
    headerBlock,
    titleText,
    temperatureText,
    infoContainer
  } = useStyles();

  let colsArr = [
    'Timeline',
    'History_min',
    'History_max',
    'Forecast_min',
    'Forecast_max',
    'Observed_min',
    'Observed_max',
  ];

  let colsHourly = [
    "Timeline",
    "History",
    "Forecast"
  ]

  if (actionsState.currentTab === 'precipitation') {
    colsArr = [
      'Timeline',
      'History',
      'Forecast',
      'Observed',
    ];
  } else if (actionsState.extraHumidityChart) {
    colsArr = [
      'Timeline',
      'History',
      'Forecast',
      'Observed',
    ];
  } else if (actionsState.solarRadiationChart) {
    colsArr = [
      'Timeline',
      'History',
      'Forecast',
      'Observed',
    ];
  } else if (actionsState.growingDegreeDaysChart) {
    colsArr = [
      'Timeline',
      'History',
      'Forecast',
      'Observed',
    ];
  } else if (actionsState.extraEvapotranspirationChart) {
    colsArr = [
      'Timeline',
      'History',
      'Forecast',
      'Observed',
    ];
  } else if (actionsState.humidity) {
    colsArr = [
      'Timeline',
      'History',
      'Forecast',
      'Observed',
    ];
  }

  return (
    <div className={headerBlocksContainer}>
      <div className="btns-container">
        <Dropdown
          onHourlyCsvDataTrigger={onHourlyCsvDataTrigger}
          chartRef={chartRef}
          cols={{ csv: colsArr, hourly: colsHourly }}
          data={data}
          actionsState={actionsState}
          disabled={disabled}
        />
      </div>

      {
        actionsState.currentTab == 'gdd' ? (
          <div className="gdd-planting-date-wrapper"
            style={{ marginBottom: '20px' }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yy"
                margin="normal"
                id="date-picker-inline"
                label="Planting Date"
                autoOk={true}
                value={GDDPlantingDate}
                onChange={onGDDPlantingDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        ) : null
      }

      {
        actionsState.currentTab == 'gdd' ? (
          <div className="slider-container">
            <Slider
              key={`slider-${sliderDefaultValue}`}
              defaultValue={sliderDefaultValue}
              aria-labelledby="discrete-slider-small-steps"
              step={1}
              marks
              min={sliderMinValue}
              max={sliderMaxValue}
              valueLabelDisplay="on"
              onChangeCommitted={onSliderChange}
              disabled={disabled}
            />
            <Typography style={{ marginBottom: '30px' }} className={titleText}>
              Temp. Threshold
            </Typography>
          </div>
        ) : null
      }

      {
        actionsState.extraPrecipitationChart ? (
          <div className="slider-container">
            <Typography className={titleText}>
              Evaporation Rate
            </Typography>
            <Slider
              defaultValue={0.3}
              aria-labelledby="discrete-slider-small-steps"
              step={0.1}
              marks
              min={sliderMinValue ?? 0.3}
              max={sliderMaxValue ?? 2}
              valueLabelDisplay="auto"
              onChange={onSliderChange}
              disabled={disabled}
            />
          </div>
        ) : null
      }

      <div className={headerBlockContainer}>
        <Typography className={titleText}>
          Observed
        </Typography>
        <Card className={headerBlock}>
          {
            ['growing-degree-days'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedObservedIcon} />
                  <Typography className={temperatureText}>
                    GDDs
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            ['temp', 'temp-soil'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedObservedIcon} />
                  <Typography className={temperatureText}>
                    Tmax
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={BlueObservedIcon} />
                  <Typography className={temperatureText}>
                    Tmin
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            ['precipitation', 'relative-humidity'].includes(type) ? (
              <Box className={infoContainer} style={{ marginBottom: 0 }}>
                <img alt="" src={BlueObservedIcon} />
                <Typography className={temperatureText}>
                  {type === 'precipitation' ?
                    (currentSettings.units === 'metric' ? 'mm' : 'in')
                    : 'T'}
                </Typography>
              </Box>
            ) : null
          }
          {
            type === 'evapotranspiration' ? (
              <Box className={infoContainer} style={{ marginBottom: 0 }}>
                <img alt="" src={RedObservedIcon} />
                <Typography className={temperatureText}>
                  T
                </Typography>
              </Box>
            ) : null
          }
          {
            type === 'solar-radiation' ? (
              <Box className={infoContainer} style={{ marginBottom: 0 }}>
                <img alt="" src={RedObservedIcon} />
                <Typography className={temperatureText}>
                  {/* T */}
                </Typography>
              </Box>
            ) : null
          }

        </Card>
      </div>
      <div className={headerBlockContainer}>
        <Typography className={titleText}>
          Forecast
        </Typography>
        <Card className={headerBlock}>
          {
            ['growing-degree-days'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedDotsIcon} />
                  <Typography className={temperatureText}>
                    GDDs
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            ['temp', 'temp-soil'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedDotsIcon} />
                  <Typography className={temperatureText}>
                    Tmax
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={BlueDotsIcon} />
                  <Typography className={temperatureText}>
                    Tmin
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            ['precipitation', 'relative-humidity'].includes(type) ? (
              <Box className={infoContainer} style={{ marginBottom: 0 }}>
                <img alt="" src={BlueDotsIcon} />
                <Typography className={temperatureText}>
                  {type == 'precipitation' ?
                    (currentSettings.units === 'metric' ? 'mm' : 'in')
                    : 'T'}
                </Typography>
              </Box>
            ) : null
          }
          {
            type === 'evapotranspiration' ? (
              <Box className={infoContainer} style={{ marginBottom: 0 }}>
                <img alt="" src={RedDotsIcon} />
                <Typography className={temperatureText}>
                  T
                </Typography>
              </Box>
            ) : null
          }
          {
            type === 'solar-radiation' ? (
              <Box className={infoContainer} style={{ marginBottom: 0 }}>
                <img alt="" src={RedDotsIcon} />
                <Typography className={temperatureText}>
                  {/* T */}
                </Typography>
              </Box>
            ) : null
          }
        </Card>
      </div>
      <div className={headerBlockContainer} style={{ display: ['temp-soil', 'evapotranspiration'].includes(type) ? 'none' : 'initial' }}>
        <Typography className={titleText}>
          Confidence Bands
        </Typography>
        <Card className={headerBlock}>
          {
            ['growing-degree-days'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedLightenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={RedDarkenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            type === 'temp' ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedLightenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={RedDarkenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginTop: 14 }}>
                  <img alt="" src={BlueLightenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={BlueDarkenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            ['precipitation', 'relative-humidity'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={BlueLightenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={BlueDarkenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            type === 'solar-radiation' ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedLightenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={RedDarkenSeasonalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
        </Card>
      </div>
      <div className={headerBlockContainer}>
        <Typography className={titleText}>
          {cumulative || ['growing-degree-days'].includes(type) ? '' : 'Historical'}
        </Typography>
        <Card className={headerBlock}>
          {
            ['temp', 'temp-soil'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedLightenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={RedDarkenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginTop: 14 }}>
                  <img alt="" src={BlueLightenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={BlueDarkenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            ['precipitation', 'relative-humidity'].includes(type) ? (
              cumulative ? '' : <>
                <Box className={infoContainer}>
                  <img alt="" src={BlueLightenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={BlueDarkenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            type === 'evapotranspiration' ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={GreenLightenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={GreenDarkenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
          {
            ['solar-radiation'].includes(type) ? (
              <>
                <Box className={infoContainer}>
                  <img alt="" src={RedLightenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    90%
                  </Typography>
                </Box>
                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                  <img alt="" src={RedDarkenHistoricalIcon} />
                  <Typography className={temperatureText}>
                    50%
                  </Typography>
                </Box>
              </>
            ) : null
          }
        </Card>
      </div>
    </div >

  );
};

export default ChartSpecs;
