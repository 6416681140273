import * as React from "react"

function SvgComponent(props) {
    const { fill } = props
    return (
        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.1608 1.35325C13.8308 0.885752 13.2808 0.583252 12.6667 0.583252L2.58333 0.592419C1.575 0.592419 0.75 1.40825 0.75 2.41659V11.5833C0.75 12.5916 1.575 13.4074 2.58333 13.4074L12.6667 13.4166C13.2808 13.4166 13.8308 13.1141 14.1608 12.6466L18.1667 6.99992L14.1608 1.35325Z"
                fill={fill || "#828282"}
            />
        </svg>
    )
}

export default SvgComponent
