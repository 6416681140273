import React from "react"

import "./DecisionModal.css"

const DecisionModal = (props) => {
    const { title, text, onDecision, onCancel, disabled = false, errorBool = false } = props

    return (
        <div className="decision-modal">
            <div className="decision-modal__container">
                <div className="decision-modal__title">{title}</div>
                <div className="decision-modal__text">{text}</div>
                <div className="decision-modal__button-container">
                    {!errorBool && (
                        <button className="decision-modal__decision-button" disabled={disabled} onClick={onDecision}>
                            Confirm
                        </button>
                    )}
                    {(errorBool || !disabled) && (
                        <button className="decision-modal__cancel-button" onClick={onCancel}>
                            Cancel
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DecisionModal
