import React from "react";
import { SingleRowBarChart } from "./SingleRowBarChart.d3";
import './SingleRowBarChart.css'

/**
 * Sample Invokation
 * ```javascript
 * <SingleRowBarChartComponent
 *    disableUpdate={false}
 *    hide={false}
 *    resizeEventListenerId='single-row-bar-chart'
 *    tooltip={d=>d.name}
 *    animate={false}
 *    data={{
 *        values:[
 *            {id : 1, value : 20, color : 'red', name : 'First'},
 *            {id : 2, value : 80, color : 'blue', name : 'Second'}
 *        ]
 *    }}
 *></SingleRowBarChartComponent>
 * ```
 * @typedef {SingleRowBarChartComponent} Props
 * @prop {boolean} disableUpdate - **Component Prop** - Parameter to specify not to update component when react tries to update it
 * @prop {boolean} hide - **Component Prop** - Parameter to specify whether chart should be hidden or not
 * @prop {string} resizeEventListenerId - Sets resize event listener id. It's preferred to pass it externally, in order to not  register too many event handlers for the same event when redrawing happens (Which happens a lot)
 * @prop {chartData} data - Chart data item
 * @prop {boolean} animate - Set whether chart should have animation transitions
 * @prop {function} tooltip - Function, which generates tip content. It receives three arguments: 
 * **EVENT** - current event .
 * 
 * **{name, value}** 
 * 
 * **name** - is hovered item's name  .
 * 
 * **value** - is hovered item's value
 * 
 * **state** - is current chart state.
 *
 * @extends {Component<Props>}
 */
export class SingleRowBarChartComponent extends React.Component {

    // Handle component mounting event
    componentDidMount() {
        this.createDiagram = this.createDiagram.bind(this);
        this.createDiagram();
    }

    // Handle component update event
    componentDidUpdate(prevProps, prevState) {
        if (this.props.disableUpdate) {
            return;
        }
        this.createDiagram();
    }

    // Render chart
    render() {
        return (
            <div style={{ display: this.props.hide ? "none" : "inline-block" }}
                className="d3-single-row-bar-chart-container d3-chart-container">
                <div ref={(node) => (this.node = node)} ></div>
            </div>
        );
    }

    // Reusable, create diagram function
    createDiagram() {
        // Retrive node dom element
        const node = this.node;

        // If there is no data, exit from component
        if (!this.props.data) {
            return;
        }

        // Retrieve props
        const props = this.props;

        // Create chart reference

        if (!this.chart) {
            /* eslint-disable */
            this.chart = new SingleRowBarChart();
        }

        // Pass chart some parameters and (re)render it
        this.chart.container(node)
            .data(props.data)
            .resizeEventListenerId(props.resizeEventListenerId)
            .svgHeight(40)
            .barHeight(30)
            .borderRadius(5)

        if (props.tooltip !== undefined) {
            this.chart.tooltip(props.tooltip)
        }

        if (props.animate !== undefined) {
            this.chart.animate(props.animate)
        }

        this.chart.render()

    }
}
