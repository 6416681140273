import React from "react"

import "./EditControls.css"

import CheckIcon from "../Icons/CheckIcon"
import CancelIcon from "../Icons/CancelIcon"

import { Popover } from "../Popover"

function EditControls(props) {
    const { onClose = () => null, onAccept = () => null } = props

    return (
        <div className="table-item-editable__actions-container">
            <Popover text="Save">
                <button className="table-item-editable__actions__button-check" onClick={onAccept}>
                    <div className="table-item-editable__actions__icon">
                        <CheckIcon fill="#1F9992" />
                    </div>
                </button>
            </Popover>
            <Popover text="Cancel">
                <button className="table-item-editable__actions__button-cancel" onClick={onClose}>
                    <div className="table-item-editable__actions__iconl">
                        <CancelIcon />
                    </div>
                </button>
            </Popover>
        </div>
    )
}

export default EditControls
