// Import React
import React, { useContext, useEffect, useState, useMemo } from "react"
// Import Components
import { PlantingChartWrapperComponent } from "./components/index"
import { RiskFactorsComponent } from "./components/index"
import { FieldPropertiesComponent } from "./components/index"
import { FieldProperties } from "../../components"
import DateFnsUtils from "@date-io/date-fns"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import FlipMove from "react-flip-move"
import { AuthContext } from "../../Auth/Auth"
import { ToastContainer, toast } from "react-toastify"
import clsx from "clsx"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { addDays } from "../../helpers/chartHelpers"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import Popover from "../../ui/Popover/Popover"

// Import Libraries
import { makeStyles } from "@material-ui/styles"
import { Grid, Card, CardContent } from "@material-ui/core"
import { useParams } from "react-router-dom"

// Import Others
import networking from "../../Util/Networking"

// Import styles
import "./style.css"
import { Fragment } from "react"

// Use styles for  grids
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(4),
    },
}))

// Use styles
const useCardStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
        borderRadius: "12px",
        marginTop: "0px",
    },
}))

// Define planting tool components
const PlantingTool = () => {
    // Create style classes
    const classes = useStyles()
    const cardStyleClasses = useCardStyles()

    // Const values
    const _MS_PER_DAY = 1000 * 60 * 60 * 24
    const _MIN_WINDOW_DAYS = 14

    // Get field id from params
    let { id } = useParams()

    // Define API data states
    const [plantingData, setPlantingData] = useState({})
    const [polygonData, setPolygonData] = useState({})
    const [fieldData, setFieldData] = useState({})
    const [settingsChanged, setSettingsChanged] = useState(false)
    const [initialData, setInitialData] = useState({})


    // Load current user and settings
    const { currentUser } = useContext(AuthContext)

    // Define data status variables
    const [loadErrorOccured, setLoadErrorFlag] = React.useState(false)
    const [dataLoadStatus, setDataLoadFlag] = React.useState(false)
    const [plantingWindowStatus, setPlantingWindowStatus] = React.useState(false)
    // Define filter valuables
    const [reorderAllowed, setHoverReorderFlag] = React.useState(true)
    const [dateValues, setDateValues] = useState({
        from: new Date(new Date().toDateString()),
        to: new Date(new Date().toDateString()),
        differenceInDays: 0,
    })

    useEffect(() => {
        setDateValues({ ...dateValues, differenceInDays: dateValues.to - dateValues.from })
    }, [dateValues.to, dateValues.from])

    // Set from date and display settings button
    function setDateValue(date, prop) {
        setDateValues({ ...dateValues, [prop]: new Date(new Date(date.toDateString())) })
        setSettingsChanged(true)
    }

    // Function that checks whether disable the date or not
    function shouldDisable2WeekRule(date) {
        let diffDays = Math.ceil((new Date(date) - new Date(dateValues.from)) / _MS_PER_DAY)
        return diffDays < _MIN_WINDOW_DAYS
    }

    // Function that checks that the date is in the future
    function shouldDisablePastDate(date) {
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        return new Date(date) < today
    }

    // Handle weight changes from child component
    function handleWeightChange(item, newValue) {
        plantingData[item.type][item.subType].weight = +newValue
        setPlantingData(Object.assign({}, plantingData))
    }

    // Adjust passted data for timezone difference for consistent results
    function adjustDateForTimezonesOffset(date) {
        var d = new Date(date)
        d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000)
        let result = d.toISOString().split("Z")[0]
        return result
    }

    // Save date fitlers and  changed weights
    function saveChanges() {
        if (Math.ceil(dateValues.differenceInDays / _MS_PER_DAY) < _MIN_WINDOW_DAYS) {
            toast.error("The minimum window for planting period is 14 days")
            return
        }

        // Flat existing and initial data
        const flatInitialData = mapToFlatPlantingData(initialData.chart_data)
        const flatTransformedData = mapToFlatPlantingData(plantingData)

        // Filter out unchanged weight data
        const changedWeightsData = flatTransformedData.filter((appDataItem, i) => {
            const initDataItem = flatInitialData[i]
            if (initDataItem.weight !== appDataItem.weight) return true
            return false
        })

        // Map to Server weights data
        const mappedData = changedWeightsData.map((d) => {
            return {
                weight: d.weight,
                alert: d.subType,
                risk: d.type,
            }
        })

        // Map to final server data
        const saveResult = {
            weights: mappedData,
            planting_window_filter: {
                from: adjustDateForTimezonesOffset(dateValues.from),
                to: adjustDateForTimezonesOffset(dateValues.to),
            },
        }

        // Save result
        currentUser.getIdToken().then((userToken) => {
            // Load fields data
            networking
                .post(`/api/v1/alertsettings/${id}`, saveResult, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    toast.info(res.data.Message)
                })
                .catch((err) => {
                    toast.error(`Could not save changes: ${err}`)
                })
        })

    }

    // Load data
    useEffect(() => {
        setDataLoadFlag(false)
        setLoadErrorFlag(false)
        setInitialData({})
        setPlantingData({})

        currentUser.getIdToken().then((userToken) => {
            // Load polygon data
            networking
                .get(`/api/v1/maps/ndvimap?uuid=${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setPolygonData(res.data)
                })
                .catch((err) => {
                    toast.warn("Could not load polygon data.")
                })

            // Load fields data
            networking
                .get(`/api/v1/fields/${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setFieldData(res.data.data)
                })
                .catch((err) => {
                    toast.error("Could not load field data")
                })

            // Load alerts data
            networking
                .get(`/api/v1/planting_tool/settings/${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setPlantingWindowStatus(true)
                    setDateValues({
                        ...dateValues,
                        to: new Date(new Date(res.data.to).toDateString()),
                        from: new Date(new Date(res.data.from).toDateString()),
                    })
                })
                .catch((err) => {
                    toast.error("Could not load planting window filter data.")
                    setPlantingWindowStatus(true)
                })

            networking
                .get(`/api/v1/alertsettings/${id}`, {
                    extraHeaders: { "User-Token": userToken },
                    timeout: 60000,
                })
                .then((res) => {
                    setDataLoadFlag(true)
                    if (res.data.chart_data) {
                        setInitialData(JSON.parse(JSON.stringify(res.data)))
                        setPlantingData(res.data.chart_data)
                    } else {
                        setDataLoadFlag("empty")
                    }
                })
                .catch((err) => {
                    toast.error("Could not load data.")
                    setLoadErrorFlag(true)
                    setDataLoadFlag(true)
                })
        })
    }, [currentUser, id])

    // Define state, when chart update will be disabled (For performance improvements)
    const [riskUpdateDisabled, setRiskUpdateDisableFlag] = useState()

    // Define current x tip position (It'll get set, when hovering top chart)
    const [staticTipXPosition, setStaticTipXPosition] = useState()

    // Map planting data to chart data
    function mapToChartData(flatPlantingData, fromDate, toDate) {
        // eslint-disable-line react-hooks/exhaustive-deps

        // Define colors which will be used to color lines and areas
        const colors = ["#F3B52F", "#F4713D", "#663F59", "#6A6E93", "#4C88B2", "#01A6C4", "#04D8D7", "#73F3E4"]
            .concat([
                "#1f77b4",
                "#ff7f0e",
                "#2ca02c",
                "#d62728",
                "#9467bd",
                "#8c564b",
                "#e377c2",
                "#7f7f7f",
                "#bcbd22",
                "#17becf",
            ])
            .concat(["#D34A7C"])
            .reverse()

        // Predefine some colors for some risks
        const predefinedColors = {
            risk_drought: "#2E476B",
            risk_heat_stress: "#E55526",
            risk_high_soil_temperature: "#E55526",
            risk_cold: "#5984BB",
            risk_low_soil_temperature: "#5984BB",
            risk_wet_soil: "#288278",
        }
        let colorIncrement = 0

        // If a lag is defined, shift the planting data to reflect that lag
        const shiftedPlantingData = flatPlantingData.map((d) =>
            Object.assign({}, d, {
                time: d.time.map((t) => addDays(t, -d.lag)),
            })
        )

        // Filter out invalid planting records
        const filteredPlantingData = shiftedPlantingData
            .filter((d) => d.time)
            .filter((d) => {
                let start = new Date(d.time[0])
                let end = new Date(d.time[d.time.length - 1])

                // Current range start fits within global start and end date
                if (start >= fromDate && start <= toDate) return true

                // Current range end, fits within global start and end date
                if (end >= fromDate && end <= toDate) return true

                // Current range is within global start and end date
                if (start >= fromDate && end <= toDate) return true

                // Global range is within current range
                if (start <= fromDate && end >= toDate) return true

                // This means we are outside selected time range, and filtering those options out
                return false
            })

        // Calculate sum of weights
        const weigthSum = filteredPlantingData.map((d) => d.weight).reduce((a, b) => a + b, 0)

        // Convert planting data to actual chart component supported data
        const result = filteredPlantingData.map((d) =>
            Object.assign(d, {
                color: predefinedColors[d.type] || colors[colorIncrement++ % colors.length],
                points: wrapPoints(d),
                value: d.weight !== undefined ? d.weight * 100 : 100 / filteredPlantingData.length,
                chartName: `${d.title || d.type.split("_").map(capitalizeFirstLetter).join(" ")} <b>${Math.round((d.weight / weigthSum) * 100 * 10) / 10
                    }&nbsp;%</b> `,
                name: d.title || d.type.split("_").map(capitalizeFirstLetter).join(" "),
                description: d.description_short,
                description_long: d.description_long,
            })
        )
            .map(d => {
                d.visible = d.points
                    .filter(d => d.x >= fromDate && d.x <= toDate)
                    .some(d => d.y);

                d.visible = d.visible && (d.weight !== undefined ? d.weight : true);
                return d;
            })


        // Define points date key objects (For fast access, it'll improve performance)
        result.forEach((r) => {
            r.pointsObj = {}
            r.time.forEach((p, i) => {
                r.pointsObj[p] = r.points[i]
            })
        })
        return result
    }

    // Just a simple function to convert first letter to capital
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    // Reusable function to wrap up points for chart supported format
    const wrapPoints = ({ time, values }) => {
        return time.map((d, i) => {
            return {
                x: new Date(d),
                y: values[i] * 100,
            }
        })
    }

    function mapToFlatPlantingData(plantingData) {
        if (!plantingData) return []
        return Object.keys(plantingData)
            .map((key) => [plantingData[key], key])
            .map((arrKeyObj) => [Object.keys(arrKeyObj[0]), arrKeyObj[0], arrKeyObj[1]])
            .map((arr) => arr[0].map((k) => Object.assign({}, arr[1][k], { type: arr[2], subType: k })))
            .flat()
            .map((d) => Object.assign({ id: d.type + "_" + d.subType }, d))
    }

    // Function to weigh up top chart from bottom charts
    function weighUp(riskFactorsData) {
        // Retrieve and sort ascending uniq dates from risk data
        const uniqTimes = Array.from(new Set(riskFactorsData.map((d) => d.time).flat())).sort((a, b) =>
            +new Date(a) > +new Date(b) ? 1 : -1
        )

        // Create new points for top chart
        const points = uniqTimes.map((d) => ({ x: new Date(d) }))

        // Map Attach point values to each point
        uniqTimes.forEach((t, i) => {
            // Filter out missing points from risks data
            const filteredValues = riskFactorsData.filter((d) => {
                return d.pointsObj[t]
            })

            // Retrieve all weighted values from corresponding bottom chart points
            const values = filteredValues.map((d) => d.weight)

            // Sum up weights
            const weightSum = values.reduce((a, b) => a + b, 0)

            // Calcualte weighted risks
            const weightedProbabilities = filteredValues.map((d) => (d.weight / weightSum) * d.pointsObj[t].y)

            // Sum up weighted values
            let probSum = weightedProbabilities.reduce((a, b) => a + b, 0)

            // if sum of weights is 0, then probability is 0 too
            if (weightSum == 0) {
                probSum = 0
            }

            // Calculate probabilities (point value for top chart)
            const prob = 100 - probSum

            // Round probability value
            points[i].y = Math.round(prob)

            // Attach corresponding risk values to top chart point (It'll be used in top charts tooltip)
            points[i].riskValues = filteredValues
                .map((r) => {
                    return {
                        id: r.id,
                        color: r.color,
                        y: r.pointsObj[t].y,
                        name: r.name,
                    }
                })
                .sort((a, b) => (a.y < b.y ? 1 : -1)) // Sort it in way, bigger values are always on top

            // Add top charts risk value as well
            points[i].riskValues.unshift({
                color: "#2E81BB",
                y: prob,
                name: "Expected Yield",
            })
        })
        return points
    }

    // Convert planting data to flat array and extend some props
    const flatPlantingData = useMemo(() => {
        const result = mapToFlatPlantingData(plantingData)
        return result
    }, [plantingData])

    // Convert flat planting data to chart compatible structure
    const mappedData = useMemo(() => {
        const result = mapToChartData(flatPlantingData, dateValues.from, dateValues.to)
        return result
    }, [flatPlantingData, dateValues.from, dateValues.to, mapToChartData])

    // Save as mapped data as risk factors data
    const riskFactorsData = useMemo(() => {
        return mappedData
    }, [mappedData])

    // Save initial sort order
    const chartInitialSortOrder = useMemo(() => {
        const chartsSortOrderInitial = {}
        riskFactorsData.forEach((d, i) => (chartsSortOrderInitial[d.id] = i))
        return chartsSortOrderInitial
    }, [riskFactorsData])

    // Define sort order calculxr
    const [chartsSortOrder, setChartsSortOrder] = useState(chartInitialSortOrder)

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState()

    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)")

    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // Render layout of planting page
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="planting-tool">
                <div>
                    {loadErrorOccured ? (
                        <Alert style={{ marginBottom: "30px" }} severity="error">
                            Data load error occurred...
                        </Alert>
                    ) : (
                        ""
                    )}
                </div>
                <Grid className="grid-container" container spacing={3}>
                    <Grid
                        className="left-subgrid-container"
                        container
                        item
                        lg={9}
                        spacing={3}
                        style={{ paddingLeft: 0 }}
                    >
                        <Grid style={{ width: "100%" }} className="chart" item lg={12}>
                            <div className="keyboard-date-picker">
                                <Card
                                    className={clsx(cardStyleClasses.root)}
                                    style={{
                                        marginBottom: "20px",
                                        background: "linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%)",
                                        overflow: "visible",
                                    }}
                                >
                                    <CardContent className="planting-tool__date-container">
                                        <Popover text="Change date range." className="plain-popover planting-tool_date-option">
                                            <KeyboardDatePicker
                                                disabled={plantingWindowStatus ? false : true}
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline-from"
                                                label="From"
                                                value={dateValues.from}
                                                onChange={(dt) => setDateValue(dt, "from")}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                shouldDisableDate={(dt) => shouldDisablePastDate(dt)}
                                            />
                                        </Popover>
                                        <Popover text="Change date range." className="plain-popover planting-tool_date-option">
                                            <KeyboardDatePicker
                                                disabled={plantingWindowStatus ? false : true}
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline-to"
                                                label="To"
                                                value={dateValues.to}
                                                onChange={(dt) => setDateValue(dt, "to")}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                shouldDisableDate={(dt) => shouldDisable2WeekRule(dt)}
                                            />
                                        </Popover>

                                        {!tabletMedia && (
                                            <div style={{ margin: "5px", marginLeft: "20px" }}>
                                                <Popover text="This allows the individual risk graphs to order from higher probability to lower probability with the highest probability at the top.">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={reorderAllowed}
                                                                onChange={(event) =>
                                                                    setHoverReorderFlag(event.target.checked)
                                                                }
                                                                name="reorderAllowed"
                                                            />
                                                        }
                                                        label="Allow hover reorder"
                                                    />
                                                </Popover>
                                            </div>
                                        )}
                                        <div style={{ margin: "5px", textAlign: "center" }}>
                                            <Button
                                                onClick={saveChanges}
                                                className="cl-button"
                                                style={{
                                                    marginTop: -33,
                                                    fontSize: 12,
                                                    display: settingsChanged ? "initial" : "none",
                                                }}
                                                variant="outlined"
                                                color="primary"
                                                disabled={dateValues.differenceInDays < _MIN_WINDOW_DAYS * _MS_PER_DAY}
                                            >
                                                save changes
                                            </Button>
                                            {dateValues.differenceInDays < _MIN_WINDOW_DAYS * _MS_PER_DAY &&
                                                settingsChanged && (
                                                    <div className="planting-tool__error-message">
                                                        Date range must be bigger than {_MIN_WINDOW_DAYS} days
                                                    </div>
                                                )}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <PlantingChartWrapperComponent
                                dataLoadStatus={dataLoadStatus}
                                isMainChart={true}
                                loadErrorOccured={loadErrorOccured}
                                description={"Expected Yield vs Date"}
                                disableUpdate={riskUpdateDisabled}
                                tipOffsetY={60}
                                xRightOffset={0.1}
                                xDateMin={dateValues.from}
                                xDateMax={dateValues.to}
                                titleLabelFontSize={18}
                                titleLabelOffsetX={-40}
                                titleLabelOffsetY={-5}
                                onChartMouseLeave={(d) => {
                                    setRiskUpdateDisableFlag(false)
                                    setStaticTipXPosition(null)
                                }}
                                tooltip={(EVENT, { key, values, colors, points }, state) => {
                                    setRiskUpdateDisableFlag(true)
                                    setStaticTipXPosition(key)
                                    const idIndexes = {}
                                    points[0].riskValues.forEach((d, i) => {
                                        idIndexes[d.id] = i
                                    })
                                    if (reorderAllowed) {
                                        setChartsSortOrder(idIndexes)
                                    }
                                    return `<table cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                                      <tr><td colspan=3 style="font-weight:bold;text-align:center">${key.toLocaleString(
                                        undefined,
                                        {
                                            day: "numeric",
                                            month: "short",
                                        }
                                    )}</td></tr>
                                     ${points[0].riskValues
                                            .filter((d, i) => !i)
                                            .map((value, i) => {
                                                return ` <tr><td style="font-size:13px;color:${value.color}">${value.name
                                                    }</td><td><div style="font-size:11px;position:relative;top:-3px;margin-left:5px;margin-right:8px;display:inline-block;width:20px;height:3px;background-color:${value.color
                                                    };margin-top:-10px;border-radius:5px;"></div>${Math.round(value.y * 10) / 10
                                                    } %</td></tr>`
                                            })
                                            .join("")}
                       
                          
                                      <tr><td  colspan=3 style="font-size:12px;text-align:center" >Risks</td></tr>
                                       ${points[0].riskValues
                                            .filter((d, i) => i)
                                            .map((value, i) => {
                                                return ` <tr><td style="font-size:13px;color:${value.color}">${value.name
                                                    }</td><td><div style="font-size:11px;position:relative;top:-3px;margin-left:5px;margin-right:8px;display:inline-block;width:20px;height:3px;background-color:${value.color
                                                    };margin-top:-10px;border-radius:5px;"></div>${Math.round(value.y * 10) / 10
                                                    } %</td></tr>`
                                            })
                                            .join("")}
                                    </table>`
                                }}
                                labelY="Percentage probability of yield"
                                resizeEventListenerId="expected-sorghum"
                                svgHeight={300}
                                data={{
                                    color: "#2E81BB",
                                    points: weighUp(riskFactorsData),
                                }}
                                title=""
                            />
                        </Grid>
                        {console.log(tabletMedia)}
                        {tabletMedia && (
                            <Grid item style={{ width: "100%", display: "inline-block" }}>
                                <RiskFactorsComponent
                                    onSaveChangesClick={saveChanges}
                                    dataLoadStatus={dataLoadStatus}
                                    handleWeightChange={handleWeightChange}
                                    disableUpdate={riskUpdateDisabled}
                                    riskFactorsData={riskFactorsData}
                                    title="Risk Factors"
                                />
                            </Grid>
                        )}

                        <FlipMove style={{ width: "100%" }}>
                            {riskFactorsData
                                .slice()
                                .filter(d => {
                                    return d.visible;
                                })
                                .sort((a, b) => {
                                    if (chartsSortOrder[a.id] === undefined) return 1
                                    if (chartsSortOrder[b.id] === undefined) return 1
                                    return chartsSortOrder[a.id] > chartsSortOrder[b.id] ? 1 : -1
                                })
                                .map((rf) => {
                                    return (
                                        <Grid className="planting-tool__risk-details-item" key={rf.id} item>
                                            <PlantingChartWrapperComponent
                                                hideExport={true}
                                                description={rf.description_long}
                                                xDateMin={dateValues.from}
                                                xDateMax={dateValues.to}
                                                titleLabelFontSize={10}
                                                titleLabelOffsetX={-45}
                                                titleLabelOffsetY={-3}
                                                staticTipXPosition={staticTipXPosition}
                                                resizeEventListenerId={rf.id}
                                                data={rf}
                                                title={rf.name}
                                            />
                                        </Grid>
                                    )
                                })}
                        </FlipMove>
                    </Grid>
                    {!tabletMedia && (
                        <Grid
                            className="right-subgrid-container"
                            style={{ alignContent: "flex-start" }}
                            container
                            lg={3}
                            spacing={3}
                            item
                        >
                            <Grid className="chart" item lg={12}>
                                <FieldProperties />
                                <RiskFactorsComponent
                                    onSaveChangesClick={saveChanges}
                                    dataLoadStatus={dataLoadStatus}
                                    handleWeightChange={handleWeightChange}
                                    disableUpdate={riskUpdateDisabled}
                                    riskFactorsData={riskFactorsData.slice()}
                                    title="Risk Factors"
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <ToastContainer />
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default PlantingTool
