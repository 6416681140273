import React, { useEffect, useState, useContext } from "react"
// Modules
// Components
// Views
// Context providers / Utils
import networking from "../Util/Networking"
import { AuthContext } from "../Auth/Auth"

// Hooks
// Material-UI *
// Styles

export const DemoContext = React.createContext(null)

export const DemoProvider = ({ children }) => {
    const { currentUser } = useContext(AuthContext)
    //TODO If general settings object change: modify to include new fields
    const [currentSettings, setCurrentSettings] = useState({
        units: "metric",
    })
    const [mode, setCurrentMode] = useState(false)
    const [limit, setLimit] = useState(100)

    function updateDemoMode(mode){
        setCurrentMode(mode)
        if(mode){
            setLimit(0)
        }
    }

    function increaseLimit(lim){
        setLimit(lim+limit)
    }

    return (
        <DemoContext.Provider
            value={{
                mode,
                updateDemoMode,
                increaseLimit,
                limit
            }}
        >
            {children}
        </DemoContext.Provider>
    )
}
