import * as React from "react"

function UploadFileIcon(props) {
    return (
        <svg width={12} height={10} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.313 6.188a.312.312 0 01.312.312v1.563a.625.625 0 00.625.625h7.5a.625.625 0 00.625-.625V6.5a.313.313 0 11.625 0v1.563a1.25 1.25 0 01-1.25 1.25h-7.5A1.25 1.25 0 011 8.063V6.5a.312.312 0 01.313-.312z"
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.5}
            />
            <path
                d="M5.779 7.409a.313.313 0 00.442 0l1.875-1.875a.313.313 0 00-.442-.443L6.312 6.433V.938a.313.313 0 00-.625 0v5.495l-1.34-1.342a.313.313 0 00-.443.443l1.875 1.875z"
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.5}
            />
        </svg>
    )
}

export default UploadFileIcon
