import React from "react"
import "./Copyright.css"

const Copyright = () => {
    return (
        <div className="copyright">
            <a className="copyright-text" href="https://www.climate.ai" target="_blank" rel="noopener noreferrer">
                &#169; Climate AI
            </a>{" "}
            {new Date().getFullYear()}
            {", All Rights Reserved"}
        </div>
    )
}

export default Copyright
