import React from "react"

import "./WeatherVariables.css"
import { Popover } from "../../../ui/Popover"

const TomorrowTemperatureField = (props) => {
    const { minTemp, maxTemp } = props

    return (
        <div className="weather_variables__temp_container">
            <div className="max_temp_value">{maxTemp}°</div>
            <div className="min_temp_value">{minTemp}°</div>
        </div>
    )
}

export default TomorrowTemperatureField
