import React from "react"

import "./NewPasswordModal.css"

const NewPasswordModal = (props) => {
    const { onCancel, onConfirm } = props

    return (
        <div className="new-password-modal">
            <form className="new-password-modal__container">
                <div className="new-password-modal__title">Set New Password</div>
                <input className="new-password-modal__input" placeholder="New Password" />
                <input className="new-password-modal__input" placeholder="Confirm New Password" />

                <div className="new-password-modal__button-container">
                    <button onClick={onCancel} className="new-password-modal__cancel-button">
                        Cancel
                    </button>
                    <button onClick={onConfirm} className="new-password-modal__accept-button">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    )
}

export default NewPasswordModal
