import * as React from "react"

function ShareLinkIcon(props) {
    return (
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
            <circle cx={9} cy={9} r={9} fill="#1C9690" />
            <path
                d="M4.95 9c0-.855.695-1.55 1.55-1.55h2V6.5h-2a2.5 2.5 0 000 5h2v-.95h-2c-.855 0-1.55-.695-1.55-1.55zM7 9.5h4v-1H7v1zm4.5-3h-2v.95h2c.855 0 1.55.695 1.55 1.55 0 .855-.695 1.55-1.55 1.55h-2v.95h2a2.5 2.5 0 000-5z"
                fill="#fff"
            />
        </svg>
    )
}

export default ShareLinkIcon
