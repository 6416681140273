import React, { useContext } from "react"

import CropTable from "../CropTable"
import MobileHeader from "../../ui/Header/MobileHeader"
import { UserProfile } from "../."
import { UIAlertContext } from "../../ui/UIAlert/UIAlert"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import LogoIcon from "../../ui/Icons/LogoIcon"
import "./Dashboard.css"

export default function Dashboard() {
    const { aboutModal } = useContext(UIAlertContext)

    const tabletMedia = useMediaQuery("(max-width: 1100px)")

    return (
        <main className="dashboard">
            {!tabletMedia && (
                <div className="dashboard__sidebar">
                    <div className="dashboard__logo">
                        <a href="/dashboard">
                            <LogoIcon />
                        </a>
                    </div>

                    <div
                        onClick={() => {
                            aboutModal()
                        }}
                        className="dashboard__about"
                    >
                        About
                    </div>
                </div>
            )}

            <div className="dashboard__table">
                <header className="dashboard__header">
                    {tabletMedia && (
                        <div className="dashboard__logo">
                            <a href="/dashboard">
                                <LogoIcon />
                            </a>
                        </div>
                    )}
                    <UserProfile />
                </header>
                <div className="dashboard__crop-table">
                    <CropTable />
                </div>
            </div>
            {tabletMedia && (
                <div
                    onClick={() => {
                        aboutModal()
                    }}
                    className="dashboard__about"
                >
                    About
                </div>
            )}
        </main>
    )
}
