import React, { useRef, useState, useContext, useEffect } from "react"
// Modules
import { Link as RouterLink } from "react-router-dom"

// Components
import ArrowRight from "../../ui/Icons/ArrowRight"
import ExitApp from "../../ui/Icons/ExitApp"

// Views
// Context providers / Utils
import { AuthContext } from "../../Auth/Auth"
import app from "../../Util/Fire"

// Hooks

// Material-UI *

// Styles
import "./UserProfile.css"

function UserProfile() {
    const [open, toggle] = useState(false)
    const [userInitial, setUserInitial] = useState("")
    const [iconColor, setIconColor] = useState("")

    const {
        currentUser: { displayName, email },
    } = useContext(AuthContext)

    function handleLogoutButton(e) {
        e.preventDefault()
        app.auth().signOut()
    }

    // Init function
    useEffect(() => {
        setIconData()
    }, [])

    const profileDropdownRef = useRef(null)
    const profileRef = useRef(null)

    useEffect(() => {
        function handleDocumentClick(e) {
            if (!profileRef.current.contains(e.target) && !profileDropdownRef.current.contains(e.target)) {
                toggle(false)
            }
        }

        document.addEventListener("click", handleDocumentClick)
        return () => document.removeEventListener("click", handleDocumentClick)
    }, [])

    // Aux functions
    function setIconData() {
        setUserInitial(displayName ? displayName[0] : email[0])
        const colors = ["orange", "green"]
        setIconColor(colors[Math.round(Math.random())])
    }

    return (
        <div className="user-profile">
            <div
                className="user-profile__visible"
                ref={profileRef}
                onClick={() => toggle(!open)}
            >
                <div className={"user-profile__icon " + iconColor}>
                    <div>{userInitial}</div>
                </div>
                <ArrowRight
                    className={open ? "user-profile__deploy-icon--rotate" : "user-profile__deploy-icon"}
                />
            </div>

            <div className="user-profile__dropdown">
                <div
                    ref={profileDropdownRef}
                    className={open ? "user-profile__dropdown-menu--open" : "user-profile__dropdown-menu"}
                >
                    <div className="user-profile__dropdown-navigation-container">
                        <div className="user-profile__dropdown__user">{displayName || email.split("@")[0]}</div>
                        <RouterLink to="/profile-settings" className="user-profile__dropdown-option">
                            Profile Settings
                        </RouterLink>

                        <RouterLink to="/alerts-settings-individual" className="user-profile__dropdown-option">
                            Alerts Settings
                        </RouterLink>

                        <RouterLink to="/table-settings" className="user-profile__dropdown-option">
                            Table Settings
                        </RouterLink>

                        <div
                            className="user-profile__dropdown-option"
                            onClick={() => window.open("https://climate.ai", "_blank")}
                        >
                            Contact Us
                        </div>
                    </div>
                    <div onClick={handleLogoutButton} className="user-profile__logout-container">
                        <div className="user-profile__logout-text">Log Out</div>

                        <ExitApp fill="#505050" width="9px" height="9px" className="user-profile__logout-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile
