import React, { useContext, useEffect, useState } from "react"

// Modules
import { Link } from "react-router-dom"

// Components
import Table from "../../ui/Table/Table"
import TableLabels from "../../ui/Table/TableLabels"
import { Popover } from "../../ui/Popover"

import EditIcon from "../../ui/Icons/EditIcon"
import TrashIcon from "../../ui/Icons/TrashIcon"
import CheckIcon from "../../ui/Icons/CheckIcon"
import WarningIcon from "../../ui/Icons/WarningIcon"
import AlertIcon from "../../ui/Icons/AlertIcon"
import ArrowRight from "../../ui/Icons/ArrowRight"

import { processPolygonData } from "../../helpers/chartHelpers"
import TomorrowTemperatureField from "./WeatherVariables/TomorrowTemperatureField"
import { ToastContainer, toast } from "react-toastify"

// Views
// Context providers / Utils
import {
    createNewReport,
    getTableData,
    getPolygonData,
    addTableRow,
    updateTableRow,
    deleteTableRow,
    uploadFile,
    getFileUrl,
} from "../../services/table.service"
import { addLabelToFields, getAllLabels, removeLabel, removeLabelFromField } from "../../services/label.service"
import networking from "../../Util/Networking"
import { convertTemperatureValueOnly, convertWaterLengthValueOnly } from "../../Util/UnitConversion"
import { SettingsContext } from "../../Util/SettingsContext"

// Hooks
// Material-UI *
import useMediaQuery from "@material-ui/core/useMediaQuery"
// Styles
import "./CropTable.css"

// Table fields
import {
    alertField,
    checkField,
    nameField,
    cropField,
    varietyField,
    regionField,
    labelField,
    shapeFileField,
    actionField,
    tomorrowTempField,
    tomorrowPrecipField,
    yieldField,
    yieldVsPlanField,
} from "./tableFields"

// Table meta fields
import {
    plantingDateField,
    plantStageField,
    yieldForecastField,
    notesField,
    temporaryYieldsForHazeraFields,
} from "./tableMetaFields"
import Checkbox from "../../ui/Checkbox/Checkbox"

import { AuthContext } from "../../Auth/Auth"
import { DataContext } from "../../Util/Data"
import { DemoContext } from "../../Util/DemoContext"
import { CompareArrowsOutlined } from "@material-ui/icons"

function CropTable() {
    // field data
    const {
        state: { fields },
        dispatch,
    } = useContext(DataContext)

    const [loading, setLoading] = useState(false)
    const [renderedWeatherVariables, setRenderedWeatherVariables] = useState(false)
    const [selectedMapColumnVariable, setSelectedMapColumnVariable] = useState("temp")
    const [tableMedia, setTableMedia] = useState(null)

    const mediaBreakpoint = useMediaQuery("(min-width: 1100px)")

    useEffect(() => {
        setTableMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    const { currentUser, permissions, featurePermissions } = useContext(AuthContext)
    const { mode, increaseLimit, limit } = useContext(DemoContext)
    const {
        currentSettings: { units, regionalViewMapVisible },
        handleUpdateDBUserSettings,
    } = useContext(SettingsContext)
    const hiddenFileInput = React.useRef(null)

    // TODO make this come from backend
    const [labels, setLabels] = useState([])

    // Define polygon data state
    const [fieldsPolygonData, setFieldsPolygonData] = useState({})

    // get table data
    useEffect(() => {
        if (!fields || fields.length === 0) {
            setLoading(true)
            getTableData()
                .then((tableData) => {
                    if (tableData.length > limit) {
                        tableData = tableData.slice(0, limit)
                    }
                    dispatch({ type: "GET_FIELDS", payload: { fields: tableData } })
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                })
        }

        getAllLabels()
            .then((labelsData) => {
                setLabels(labelsData)
            })
            .catch((err) => {
                console.log(err)
            })

        getPolygonData().then((data) => {
            setFieldsPolygonData(Object.assign({}, fieldsPolygonData, data))
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        getTableData()
            .then((tableData) => {
                if (tableData.length > limit) {
                    tableData = tableData.slice(0, limit)
                }
                //sleep(2000)
                dispatch({ type: "GET_FIELDS", payload: { fields: tableData } })
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }, [limit])

    const onChangeFile = function (event) {
        event.stopPropagation()
        event.preventDefault()
        var file = event.target.files[0]
        console.log(file)
        if (file.name) {
            increaseLimit(limit + 2)
        }
        //this.setState({file}); /// if you want to upload latter
    }

    useEffect(() => {
        if (fields.length > 0 && !renderedWeatherVariables && tableMedia) {
            fetchWeatherVariablesFields()
        }
    }, [fields, units, tableMedia])

    // Define fields metadata, polygons data and selected fields data linked object
    const [fieldsMetadataAndPolygonsData, setFieldsMetadataAndPolygonsData] = useState({
        fields: [],
        polygons: {},
        selectedFields: [],
    })

    // Listen for fields and fieldsPolygonData, changes and save it to linked state object
    useEffect(() => {
        setFieldsMetadataAndPolygonsData(
            Object.assign({}, fieldsMetadataAndPolygonsData, {
                fields: fields,
                polygons: fieldsPolygonData,
            })
        )
    }, [fields, fieldsPolygonData])

    const _CheckFieldComponent = React.memo((props) => {
        const {
            rowOptions: { currentPath, isEditable, toggleContent, handleCheckChange, checked, checkedStatus },
        } = props
        return (
            <>
                <Checkbox checked={checked} status={checkedStatus} onChange={handleCheckChange} />
                <button className="crop-table__toggler-button" onClick={toggleContent}>
                    {!isEditable && !currentPath && <ArrowRight className="crop-table__toggler-icon" />}
                    {!isEditable && currentPath && <ArrowRight className="crop-table__toggler-icon--open" />}
                </button>
            </>
        )
    })

    const _checkField = {
        ...checkField,
        component: _CheckFieldComponent,
    }

    const cropTableMetaFields = [plantingDateField, plantStageField, yieldForecastField, notesField]

    const initialCropTableFields = [
        // Alert Field
        {
            ...alertField,
            render(value = {}, rowData) {
                if (value > 0) {
                    return (
                        <Popover text={value + " alerts triggered"}>
                            <div className="crop-table__alert-icon">
                                <AlertIcon alerts={value} />
                            </div>
                        </Popover>
                    )
                } else {
                    return <div></div>
                }
            },
        },
        {
            ..._checkField,
        },
        // Name Field
        {
            ...nameField,
            groupFields: [_checkField, nameField],
            render(value, rowData) {
                return (
                    <Popover text={"Go to " + rowData.name}>
                        <Link to={"/" + permissions["default"] + "/" + rowData.uuid}>{value}</Link>
                    </Popover>
                )
            },
        },
        // Crop Field
        { ...cropField, groupFields: [_checkField, cropField] },
        // Nice variety field
        { ...varietyField, groupFields: [_checkField, varietyField] },
        // Region Field
        {
            ...regionField,
            // Region Fields config when grouped
            groupFields: [
                // Alert Field (render changes)
                {
                    ...alertField,
                    render(_, rowData) {
                        return <AlertIcon />
                    },
                },
                _checkField,
                // Region Field (render changes)
                {
                    ...regionField,
                    displayName: "",
                    toggler: true,
                    draggable: false,
                    style: { flex: 4 },
                    headerStyle: { flex: 4 },
                    render(value, rowData) {
                        return (
                            <>
                                <strong>Region:</strong>
                                {value}
                            </>
                        )
                    },
                },
                // Region extra fields
                /*avgPlantDateField,
                medianStageField,
                avgYieldField,*/
                // Region view button
                {
                    propName: "report-button",
                    sortable: false,
                    displayName: "",
                    style: { flex: 2, display: "flex", justifyContent: "center", alignItems: "center" },
                    render(_, rowData) {
                        return (
                            /*
                            NOT IMPLEMENTED YET
                            <SimpleButton onClick={() => history.push("/regionAlerts/" + rowData.region)}>
                                View Report
                            </SimpleButton>*/
                            <></>
                        )
                    },
                },
            ],
        },
        {
            ...labelField,
            render(value = [], rowData, { removeLabelFromField }) {
                return (
                    <TableLabels
                        labels={value}
                        onRemoveLabelFromField={(label) => removeLabelFromField(label, rowData)}
                    />
                )
            },
        },
        // weatherFileField,
        {
            ...shapeFileField,
            // storageRef: app.storage().ref("Shape"),
            render(value, rowData) {
                return (
                    <>
                        <button
                            className="crop-table__icon-button"
                            onClick={async () => {
                                if (value) {
                                    const downloadURL = await getFileUrl("Shape", rowData.shapefile)
                                    window.open(downloadURL, "_blank")
                                }
                            }}
                        >
                            <Popover text="Download">
                                {value && value.length ? (
                                    <div className="crop-table__check-icon">
                                        <CheckIcon />
                                    </div>
                                ) : (
                                    <div className="crop-table__warning-icon">
                                        <WarningIcon />
                                    </div>
                                )}
                            </Popover>
                        </button>
                    </>
                )
            },
        },
    ]

    if (featurePermissions?.field_tools?.edit || featurePermissions?.field_tools?.delete) {
        initialCropTableFields.push({
            ...actionField,
            render(_, rowData, { toggleEdit, toggleDelete }) {
                return (
                    <>
                        {featurePermissions?.field_tools?.edit && (
                            <Popover text="Edit field">
                                <button className="crop-table__icon-button" onClick={() => toggleEdit()}>
                                    <div className="crop-table__edit-icon">
                                        <EditIcon />
                                    </div>
                                </button>
                            </Popover>
                        )}
                        {featurePermissions?.field_tools?.delete && (
                            <Popover text="Delete field">
                                <button className="crop-table__icon-button" onClick={() => toggleDelete()}>
                                    <TrashIcon className="crop-table__trash-icon" />
                                </button>
                            </Popover>
                        )}
                    </>
                )
            },
        })
    }

    const [cropTableFields, setCropTableFields] = useState(initialCropTableFields)

    useEffect(() => {
        if (fields.length > 0) {
            highlightColumn()
        }
    }, [selectedMapColumnVariable])

    // Function to handle table record selection changes
    function handleSelectionChange(selectedFields) {
        // Check if all corresponding field polygons are loaded
        let foundAll = selectedFields.every((field) => fieldsPolygonData[field.uuid])

        // If some polygons not found
        if (!foundAll && Object.keys(fieldsPolygonData).length) {
            // Retrieve ID's of fields, whose polygons we don't have
            const fieldPolygonsToRetrieve = selectedFields
                .filter((field) => !fieldsPolygonData[field.uuid])
                .map((field) => field.uuid)

            // Gett and save all polygon items which not found
            getPolygonData(fieldPolygonsToRetrieve).then((data) => {
                setFieldsMetadataAndPolygonsData(
                    Object.assign({}, fieldsMetadataAndPolygonsData, { selectedFields: selectedFields })
                )
                setFieldsPolygonData(Object.assign({}, fieldsPolygonData, data))
            })
        } else {
            // If all polygons found, update state with selected fields
            setFieldsMetadataAndPolygonsData(
                Object.assign({}, fieldsMetadataAndPolygonsData, { selectedFields: selectedFields })
            )
        }
    }

    async function handleRowAdd(rowData) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.add) {
            try {
                setLoading(true)

                // upload file to firebase
                if (rowData.shapefile instanceof File) rowData.shapefile = await uploadFile("Shape", rowData.shapefile)

                await addTableRow(rowData)
                let _data = await getTableData()
                if (_data.length > limit) {
                    _data = _data.slice(0, limit)
                } // re - poblate table
                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })
                setLoading(false)
                toast.success("Row added successfully!")
            } catch (e) {
                setLoading(false)
                toast.error(e.toString())
            }
        } else {
            toast.warning("Your account does not have permission to do this action.")
        }
    }

    async function handleRowUpdate(rowData, past) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.edit) {
            try {
                setLoading(true)

                // upload file to firebase
                if (rowData.shapefile instanceof File) rowData.shapefile = await uploadFile("Shape", rowData.shapefile)

                await updateTableRow(past.uuid, { ...past, ...rowData, labels: undefined })
                let _data = await getTableData()
                if (_data.length > limit) {
                    _data = _data.slice(0, limit)
                } //

                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })
                setLoading(false)
                toast.success("Row updated successfully!")
            } catch (e) {
                console.log(e)
                setLoading(false)
                toast.error(e.toString())
            }
        } else {
            toast.warning("Your account does not have permission to do this action.")
        }
    }

    async function handleRowDelete(rowData) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.delete) {
            try {
                setLoading(true)
                await deleteTableRow(rowData.uuid)
                let _data = await getTableData()
                if (_data.length > limit) {
                    _data = _data.slice(0, limit)
                } //
                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })
                setLoading(false)
                toast.success("Row deleted successfully!")
            } catch (e) {
                setLoading(false)
                toast.error(e.toString())
            }
        } else {
            toast.warning("Your account does not have permission to do this action.")
        }
    }

    async function handleNewReport(reportType, fields, emailList) {
        try {
            setLoading(true)
            await createNewReport(reportType, fields, emailList)
            setLoading(false)
            toast.success("Your report will be sent shortly!")
        } catch (e) {
            setLoading(false)
            toast.error(e.toString())
        }
    }

    async function handleDataSourceChange(fieldId, selectedObj) {
        if (featurePermissions?.datasources?.edit === false) {
            return
        }

        try {
            let userToken = await currentUser.getIdToken()
            return networking.put("/api/v1/fields/datasource/" + fieldId, selectedObj, {
                extraHeaders: { "User-Token": userToken },
            })
        } catch (err) {
            console.log("Error getting User token = ", err)
        }
    }

    function highlightColumn() {
        let propNames = {
            temp: "weatherVar_tomorrowTemp",
            precipitation: "weatherVar_tomorrowPrecip",
        }

        //Checks which column is selected, and modifies the style to bold it, resets the rest
        setCropTableFields(
            cropTableFields.map((el) => {
                if (el.propName === propNames[selectedMapColumnVariable]) {
                    return {
                        ...el,
                        style: { ...el.style, fontWeight: "bold" },
                        headerStyle: { ...el.headerStyle, fontWeight: "bold" },
                    }
                }
                return {
                    ...el,
                    style: { ...el.style, fontWeight: "normal" },
                    iconheaderStyle: { ...el.headerStyle, fontWeight: "normal" },
                }
            })
        )
    }

    function renderWeatherVariables(userSelectedWeatherVariables) {
        /*
            Sets the weather variables that the user has selected as true in
            their settings
        */
        if (Object.keys(userSelectedWeatherVariables).length === 0) {
            return
        }

        setRenderedWeatherVariables(true)

        let fieldsToAdd = []
        Object.keys(userSelectedWeatherVariables).forEach(function (key) {
            if (!userSelectedWeatherVariables[key]) {
                return
            }

            let fieldToAdd = null
            switch (key) {
                case "t2m":
                    // Temperature Variable
                    fieldToAdd = {
                        ...tomorrowTempField(units === "metric" ? "C" : "F"),
                        render(value, rowData) {
                            let data = rowData?.weather_variables?.t2m?.data
                            let minTemp = convertTemperatureValueOnly("metric", units, data?.t2m_min)
                            let maxTemp = convertTemperatureValueOnly("metric", units, data?.t2m_max)
                            // convertTemperatureValueOnly
                            return (
                                <Popover text={"Tomorrow's temperature"} className={"full-width"}>
                                    <TomorrowTemperatureField minTemp={minTemp} maxTemp={maxTemp} />
                                </Popover>
                            )
                        },
                    }
                    break
                case "tp":
                    // Precipitation Variable
                    fieldToAdd = {
                        ...tomorrowPrecipField(units === "metric" ? "mm" : "in"),
                        render(value, rowData) {
                            let data = rowData?.weather_variables?.tp?.data?.tp_sum
                            return (
                                <Popover text={"Tomorrow's precipitation"}>
                                    {convertWaterLengthValueOnly("metric", units, data)}
                                </Popover>
                            )
                        },
                    }
                    break
                case "yield":
                    // Yield Variable
                    let arrayLength = Object.values(temporaryYieldsForHazeraFields).length
                    let yieldFieldToAdd = {
                        ...yieldField,
                        render(value, rowData) {
                            let textToShow =
                                temporaryYieldsForHazeraFields[rowData.name]?.yield ||
                                Object.values(temporaryYieldsForHazeraFields)[rowData.name.length % arrayLength].yield
                            return <>{textToShow}</>
                        },
                    }

                    let yieldVsPlanFieldToAdd = {
                        ...yieldVsPlanField,
                        render(value, rowData) {
                            let className = ""
                            let val =
                                temporaryYieldsForHazeraFields[rowData.name]?.vsPlan ||
                                Object.values(temporaryYieldsForHazeraFields)[rowData.name.length % arrayLength].vsPlan

                            if (val > 0) {
                                className = "green-pct"
                            } else if (val < 0) {
                                className = "red-pct"
                            }

                            return <div className={className}>{val + "%"}</div>
                        },
                    }
                    fieldsToAdd.push(yieldFieldToAdd)
                    fieldsToAdd.push(yieldVsPlanFieldToAdd)
                    break
                default:
                    break
            }
            if (fieldToAdd) fieldsToAdd.push(fieldToAdd)
            let remainingWeatherVariables = userSelectedWeatherVariables
        })

        let newCropTableFields = [...cropTableFields]
        newCropTableFields.splice(6, 0, ...fieldsToAdd)
        setCropTableFields(newCropTableFields)
    }

    // async function handleNewLabel(labelName) {
    //     setLabels([...labels, labelName])
    // }

    async function handleDeleteLabel(label) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        removeLabel(label)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        setLabels(labels.filter((label) => label.name !== labelName))

        const _fields = fields.map((field) => {
            if (field.labels) {
                const _field = { ...field, labels: field.labels.filter((label) => label.name !== labelName) }
                return _field
            } else return field
        })

        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    function handleLabelFields(label, selectedFields) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        addLabelToFields(label, selectedFields)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        // label exists?
        if (!labels.find((label) => label.name === labelName)) setLabels([...labels, label])

        // insert all fields by uuid into an object
        const hash = {}
        for (let selected of selectedFields) hash[selected.uuid] = selected

        const _fields = fields.map((field) => {
            if (hash[field.uuid]) {
                // delete hash[item.uuid]
                const _field = { ...field }
                if (_field.labels && !_field.labels.find((label) => label.name === labelName))
                    _field.labels = [..._field.labels, label]
                else if (!_field.labels) _field.labels = [label]

                return _field
            } else return field
        })

        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    async function handleRemoveLabelFromField(label, field) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        removeLabelFromField(label, field)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        const _fields = fields.map((item) => {
            if (item.uuid === field.uuid) {
                return { ...item, labels: item?.labels?.filter((label) => label.name !== labelName) }
            } else return item
        })
        // setCropTableFields(_cropTableFields)
        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    async function fetchWeatherVariablesFields() {
        try {
            let userToken = await currentUser.getIdToken()
            networking
                .get("/api/v1/tablesettings/columns", {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    renderWeatherVariables(res.data)
                })
        } catch (err) {
            console.log("Error getting user alerts ", err)
        }
    }

    return (
        <>
            <div className="crop-table">
                <div className="crop-table__container">
                    <Table
                        title="Field List"
                        // data
                        data={fields}
                        fields={cropTableFields}
                        metaFields={cropTableMetaFields}
                        // Pass processed polygon data (Needed for the map display)
                        fieldsPolygonData={processPolygonData(fieldsMetadataAndPolygonsData, units)}
                        // handlers
                        loading={loading}
                        onSelectedMapColumnVariable={setSelectedMapColumnVariable}
                        onSelectionChanged={handleSelectionChange} // Handling table row selection changes
                        onNewReport={handleNewReport}
                        onRowAdd={handleRowAdd}
                        onRowUpdate={handleRowUpdate}
                        onRowDelete={handleRowDelete}
                        onDataSourceChange={handleDataSourceChange}
                        // label functionallity
                        labels={labels}
                        // onNewLabel={handleNewLabel}
                        onDeleteLabel={handleDeleteLabel}
                        onLabelFields={handleLabelFields}
                        onRemoveLabelFromField={handleRemoveLabelFromField}
                        // permissions
                        reportTypes={featurePermissions["report_generation"]}
                        showAddButton={featurePermissions?.field_tools?.add}
                        showDataSourceDropdown={featurePermissions?.datasource?.edit}
                        regionalViewMapVisibleSetting={regionalViewMapVisible}
                        onUserSettingsChange={handleUpdateDBUserSettings}
                        units={units}
                    />
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default React.memo(CropTable)
