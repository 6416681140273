import React, { useCallback, useMemo, useRef, useState, useEffect, useContext, memo } from 'react';
import { Box, Card, CardContent, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { LineAreaChartComponent } from '../../../../components/Charts/LineAreaChart/LineAreaChart.component';
import { ToastContainer, toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SettingsContext } from "../../../../Util/SettingsContext"

import {
  getHistoricalTemp,
  getExtraHistoricalTemp,
  getForecastArr,
  getExtraForecastArr,
  getForecastTemp,
  getExtraForecastTemp,
  getClim,
  getExtraClim,
  trimmData
} from './helper';
import { processUnitSystem, getUnit, assembleCumulativeData, assembleAreaData, assembleLineData, mergeHistoricalAndForecastData, getForecastConfidenceData, addMonths, validateData, duplicateMonthlyHistoricalDataForFutureSixMonths, convertToShadedRangesFormat } from '../../../../helpers/chartHelpers';
import clsx from 'clsx';
import ChartSpecs from '../ChartSpecs';
import networking from '../../../../Util/Networking';
import { AuthContext } from '../../../../Auth/Auth';
import { isEmptyObject } from '../../../../Util/General';


// Create custom theme for switch slider
const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#ccc"
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#fafafa"
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "gray",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 1,
          backgroundColor: "#41A3A4"
        }
      }
    }
  }
});


const PrecipitationChart = ({ actionsState }) => {
  const chartRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const { currentSettings } = useContext(SettingsContext)
  const { id } = useParams();

  // Prepare initial data
  const weatherVariable = 'precipitation';

  const [data, setData] = useState({
    'ds_hist': {
      time: [],
      'tp_sum': [],
    },
    'ds_fc': {
      time: [],
      'tp_sum': [],
    },
    'ds_clim': {
      time: [],
      'tp_sum': [],
    },
    pending: true,
  });

  const [cumulative, setCumulative] = useState(false);
  const [cumulativeData, setCumulativeData] = useState(false);

  // Prepare initial data
  const [monthlyData, setMonthlyData] = useState({
    'ds_hist': {
      time: [],
      'tp_sum': [],
    },
    'ds_fc': {
      time: [],
      'tp_sum': [],
    },
    'ds_clim': {
      time: [],
      'tp_sum': [],
    },
    pending: true,
  });

  const initialHourlyData = {
    'ds_hist': {
      time: [],
      t2m: [],
    },
    'ds_fc': {
      time: [],
      t2m: [],
    }
  };

  // Alerts Data
  const [alertsData, setAlertsData] = useState({
    tp_sum: {},
  })

  const [hourlyData, setHourlyData] = useState(initialHourlyData);

  const [evaporationData, setEvaporationData] = useState({
    'ds_hist': {
      time: [],
      'e_sum': [],
    },
    'ds_fc': {
      time: [],
      'e_sum': [],
    },
    'ds_clim': {
      time: [],
      'e_sum': [],
    },
    pending: true,
    coefficient: 0.3,
    error: false,
  });

  // Load data
  useEffect(() => {
    if (!actionsState.extraPrecipitationChart) {
      setData((prevData) => ({
        ...prevData,
        pending: true,
      }));

      setEvaporationData({
        'ds_hist': {
          time: [],
          'e_sum': [],
        },
        'ds_fc': {
          time: [],
          'e_sum': [],
        },
        'ds_clim': {
          time: [],
          'e_sum': [],
        },
        pending: true,
        coefficient: 0.3,
        error: false,
      });
      currentUser
        .getIdToken()
        .then((userToken) => {

          networking.get(`/api/v1/weather/${weatherVariable}/daily/${id}`, {
            extraHeaders: { 'User-Token': userToken },
          })
            .then((res) => {
              setData({
                ...res.data,
                pending: false,
              });
            })
            .catch(() => {
              setData((prevData) => ({
                ...prevData,
                pending: false,
              }));
              toast.error('Error occurred with server. Please, try later.');
            });

          networking
            .get(`/api/v1/weather/${weatherVariable}/monthly/${id}`, {
              extraHeaders: { 'User-Token': userToken },
            })
            .then((res) => {
              setMonthlyData({
                ...res.data,
                pending: false,
              });
            })
            .catch(() => {
              setMonthlyData((prevData) => ({
                ...prevData,
                pending: false,
              }));
              toast.warn('Could not load monthly data.');
            });

          networking
            .get(`/api/v1/weather/cumulative/${weatherVariable}/monthly/${id}`, {
              extraHeaders: { 'User-Token': userToken },
            })
            .then((res) => {
              console.log('cumulative Data', res.data)
              setCumulativeData(res.data);
            })
            .catch(() => {
              toast.warn('Could not process cumulative data.');
            });

          networking
            .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
              extraHeaders: { 'User-Token': userToken },
            })
            .then((res) => {
              if (isEmptyObject(res.data)) {
                toast.success(`There are no alerts for ${weatherVariable}`);
              }
              setAlertsData(res.data)
            })
            .catch(() => {
              toast.warn(
                `Alerts not displayed on dashboard due to internet 
                connectivity issues. All other functions working.`);
            });

        });
    } else {
      setEvaporationData((prevData) => ({
        ...prevData,
        pending: true,
      }));
      setData((prevData) => ({
        ...prevData,
        pending: true,
      }));

      currentUser
        .getIdToken()
        .then(async (userToken) => {
          await networking.get(`/api/v1/weather/${weatherVariable}/daily/${id}`, {
            extraHeaders: { 'User-Token': userToken },
          })
            .then((res) => {
              setData({
                ...res.data,
                pending: false,
              });
            })
            .catch(() => {
              setData((prevData) => ({
                ...prevData,
                pending: false,
              }));
              toast.error('Error occurred with server. Please, try later.');
            });
          return userToken;
        })
        .then(async (userToken) => {

          await networking.get(`/api/v1/weather/evaporation/daily/${id}`, {
            extraHeaders: { 'User-Token': userToken },
          })
            .then((res) => {
              setEvaporationData((prevState) => ({
                ...prevState,
                ...res.data,
                pending: false,
              }));
            })
            .catch(() => {
              setEvaporationData((prevData) => ({
                ...prevData,
                pending: false,
              }));
              toast.error('Error occurred with server. Please, try later.');
            });
        });
    }
  }, [actionsState.extraPrecipitationChart, id, currentUser]);

  // Prepare historical and forecast data
  const historicalTemp = useMemo(() => getHistoricalTemp(data['ds_hist']), [data]);
  const forecastArr = useMemo(() => getForecastArr(data['ds_fc']), [data]);
  const forecastTemp = useMemo(() => getForecastTemp(data['ds_fc'], historicalTemp[historicalTemp.length - 1], forecastArr), [data, forecastArr, historicalTemp]);
  const { climLighten, climDarken } = useMemo(() => getClim(data['ds_clim']), [data]);

  // Prepare extra historical and forecast data
  const extraHistoricalTemp = useMemo(() => {
    try {
      return getExtraHistoricalTemp(historicalTemp, evaporationData['ds_hist'], evaporationData.coefficient);
    } catch (e) {
      actionsState.extraPrecipitationChart && toast.error('Problem ocurred processsing information');
      return [];
    }
  }, [evaporationData, historicalTemp, actionsState.extraPrecipitationChart]);
  const extraForecastArr = useMemo(() => {
    try {
      return getExtraForecastArr(evaporationData['ds_fc']);
    } catch (e) {
      return [];
    }
  }, [evaporationData]);
  const extraForecastTemp = useMemo(() => {
    try {
      return getExtraForecastTemp(
        forecastTemp,
        evaporationData['ds_fc'],
        extraForecastArr,
        extraHistoricalTemp[extraHistoricalTemp.length - 1],
        evaporationData.coefficient,
      );
    } catch (e) {
      return [];
    }
  }, [evaporationData, extraForecastArr, forecastTemp, extraHistoricalTemp]);




  const forecastConfidence75 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalTemp[historicalTemp.length - 1], data['ds_fc']['tp_sum'], '0.75')
  }, [data, historicalTemp]);
  const forecastConfidence95 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalTemp[historicalTemp.length - 1], data['ds_fc']['tp_sum'], '0.95')
  }, [data, historicalTemp]);

  const extraForecastConfidence75 = useMemo(() => {
    return getForecastConfidenceData(evaporationData['ds_fc'], extraHistoricalTemp[extraHistoricalTemp.length - 1], evaporationData['ds_fc']['e_sum'], '0.75', false)
  }, [evaporationData, extraHistoricalTemp]);
  const extraForecastConfidence95 = useMemo(() => {
    const extraEvapResult = getForecastConfidenceData(evaporationData['ds_fc'], extraHistoricalTemp[extraHistoricalTemp.length - 1], evaporationData['ds_fc']['e_sum'], '0.95', false);
    return extraEvapResult;
  }, [evaporationData, extraHistoricalTemp]);

  const { extraClimLighten, extraClimDarken } = useMemo(() => {
    try {
      return getExtraClim({ climLighten, climDarken }, evaporationData['ds_clim'], evaporationData.coefficient);
    } catch (e) {
      return { extraClimLighten: [], extraClimDarken: [] };
    }
  }, [evaporationData, climLighten, climDarken]);

  // Look at the changes for historical and forecast data and display warning messages if invalid
  useMemo(() => {
    if (actionsState.extraPrecipitationChart) {
      validateData({ diffToAlert: 15, historic: extraClimLighten, forecast: extraForecastTemp, accessorKey: 'y', message: "Forecast Anomaly Detected" });
    } else {
      validateData({ diffToAlert: 15, historic: climLighten, forecast: forecastTemp, accessorKey: 'y', message: "Forecast Anomaly Detected" });
    }
  }, [extraForecastTemp, extraClimLighten, forecastTemp, climLighten, actionsState.extraPrecipitationChart])

  // Prepare CSV Data
  const histCsvData = data['ds_hist'].time.map((item, index) => {
    return [
      data['ds_hist']['tp_sum'][index],
    ];
  });
  const forcCsvData = data['ds_fc'].time.map((item, index) => {
    return [
      forecastArr[index],
    ];
  });
  const climArr = [].concat.apply([], Object.values(data['ds_clim']['tp_sum']));
  const climCsvData = data['ds_clim'].time.map((item, index) => {
    return [
      item,
      climArr[index],
    ];
  });

  // Combine CSV data, which will be used for export
  const combinedCsvData = (clim, forecast, historical) => {
    const csvArr = [];
    let j = 0;
    for (let i = 0; i <= clim.length; i++) {
      if (historical[i]) {
        csvArr.push([
          ...clim[i],
          [''],
          ...historical[i],
        ]);
      } else if (clim[i] && forecast[j]) {
        csvArr.push([
          ...clim[i],
          ...forecast[j],
          [''],
        ]);
        j += 1;
      } else if (clim[i]) {
        csvArr.push([
          ...clim[i],
          [''],
        ]);
      }
    }
    return csvArr;
  };

  // Change slider handler
  const handleChangeSlider = useCallback((e, value) => {
    setEvaporationData((prevState) => ({
      ...prevState,
      coefficient: value,
    }));
  }, []);
  // Use styles
  const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
      borderRadius: '20px',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Card className={clsx(classes.root)}>
        <CardContent>
          <div className="chart-container-element">
            <div style={{
              position: 'absolute',
              marginTop: '50px',
              marginLeft: '103px',
              border: '1px solid #41A3A4',
              borderRadius: 8,
              padding: '5px',
              paddingTop: '8px',
              paddingBottom: '8px',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 4px 10px rgb(56 78 99 / 40%)',
              display: actionsState.isMonthly && !data.pending ? 'initial' : 'none'
            }}>
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cumulative}
                      onChange={d => { setCumulative(!cumulative) }}
                      name="cumulative_check"
                    />
                  }
                  label="Cumulative"
                />
              </ThemeProvider>
            </div>

            <Box style={{ display: data.pending ? 'flex' : 'none' }}
              className="chart-preload-container">
              <CircularProgress />
            </Box>
            <LineAreaChartComponent

              // Pass height externally
              svgHeight={Math.max(window.innerHeight - 300, 600)}

              // Title text
              title={actionsState.extraPrecipitationChart ? 'Water Budget' : (cumulative && actionsState.isMonthly ? "Cumulative Precipitation" : "Precipitation")}

              // Set title hover text
              titleHover={!actionsState.extraPrecipitationChart ? (actionsState.isMonthly ? `This graph shows the monthly observed and forecasted ${weatherVariable}.` : `This graph shows the daily observed and forecasted ${weatherVariable} accumulation.`) : 'This graph shows the daily observed and forecasted evaporation rate.'}

              // Y label text
              labelY={actionsState.extraPrecipitationChart ? `Water Budget [${getUnit({ system: currentSettings.units }).precipUnit}]` : `Precipitation [${getUnit({ system: currentSettings.units }).precipUnit}]`}

              // Add top margin
              marginTop={35}

              // Convert received data to shaded ranges format
              shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}

              // Pass unique resize event key
              resizeEventListenerId={`${weatherVariable}-chart`}

              // Pass y bottom offset proportion values
              yBottomOffset={actionsState.extraPrecipitationChart || (cumulative && actionsState.isMonthly) ? 0.3 : 0.1}

              // Pass y top offset proportion values
              yTopOffset={cumulative && actionsState.isMonthly ? 1 : 0.1}

              // Pass whether chart should be zero basis or not (default it is)
              zeroBasis={actionsState.extraPrecipitationChart ? false : true}

              // Center Ticks
              centerTicks={(actionsState.isMonthly && !actionsState.extraPrecipitationChart) ? true : false}

              // Provide custom date max axis extent for monthly view charts
              xDateMax={actionsState.isMonthly && !actionsState.extraPrecipitationChart ? addMonths(new Date(), 7) : null}

              // Provide custom date min axis extent for monthly view charts
              xDateMin={actionsState.isMonthly && !actionsState.extraPrecipitationChart ? addMonths(new Date(), -7) : null}

              // How x ticks will be formatted in chart
              xTickFormat={(actionsState.isMonthly && !actionsState.extraPrecipitationChart) ? (d, i, arr) => {
                // Remove last, overflowing tick item
                if (i === arr.length - 1) return '';
                return d.toLocaleString(undefined, { month: "short" })
              } :
                (d, i, arr) => {
                  if (i < arr.length - 1 && i !== 0) return d.getDate();
                  if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate();
                  return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                }}

              // Give chart tips count tip
              xTicksCount={(actionsState.isMonthly && !actionsState.extraPrecipitationChart) ? 12 : 30}

              // Hide chart if data is pending
              hide={data.pending}

              // Tooltip content on line points mouse over
              tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:20px" rowspan="${values.length}"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${(cumulative && actionsState.isMonthly) ? key.toLocaleString(undefined, {
                  month: "short"
                }) : key.toLocaleString(undefined, {
                  day: "numeric",
                  month: "short"
                })}</div></td> 
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[0].dashed ? 'dashed' : 'solid'} ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0] * 10) / 10} ${getUnit({ system: currentSettings.units }).precipUnit}</td>
                 </tr>
                 ${values.filter((d, i) => i > 0).map((value, i) => {
                  return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[i + 1].dashed ? 'dashed' : 'solid'} ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${Math.round(value) * 10}/10 ${getUnit({ system: currentSettings.units }).precipUnit}</td></tr>`
                }).join('')}
             </table>`}
              }

              // Chart data content
              data={!actionsState.extraPrecipitationChart ? [
                {
                  type: 'area',
                  points: (!actionsState.isMonthly ? trimmData(climLighten) : (cumulative ? [] : duplicateMonthlyHistoricalDataForFutureSixMonths(climLighten)))
                    .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'precip' })),
                  color: '#C0E1EB',
                  opacity: 0.6
                },
                {
                  type: 'area',
                  points: (!actionsState.isMonthly ? trimmData(climDarken) : (cumulative ? [] : duplicateMonthlyHistoricalDataForFutureSixMonths(climDarken)))
                    .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'precip' })),
                  color: '#A0CBE0',
                  opacity: 0.6
                },
                // Confidence Bands

                {
                  type: 'area',
                  points: (cumulative && actionsState.isMonthly ?
                    []
                    // assembleCumulativeData({
                    //   forecastData: cumulativeData.ds_fc,
                    //   confidenceLevel: 0.05,
                    //   variable: 'tp_sum'
                    // })
                    : assembleAreaData({
                      areaData: forecastConfidence95,
                      isMonthly: actionsState.isMonthly,
                      isCumulative: cumulative,
                      cumulativeType: 'sum_per_month',
                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                        return {
                          x: +new Date(d),
                          key: +new Date(d),
                          y1: monthlyData.ds_fc.tp_sum['0.95'][i],
                          y0: monthlyData.ds_fc.tp_sum['0.05'][i],
                          max: monthlyData.ds_fc.tp_sum['0.95'][i],
                          min: monthlyData.ds_fc.tp_sum['0.05'][i]
                        }
                      })
                    }))
                    .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'precip' })),
                  color: '#237CB5',
                  'opacity': 0.4,
                },
                {
                  type: 'area',
                  points: (cumulative && actionsState.isMonthly ?
                    assembleCumulativeData({
                      forecastData: cumulativeData.ds_fc,
                      confidenceLevel: 0.25,
                      variable: 'tp_sum'
                    })
                    : assembleAreaData({
                      areaData: forecastConfidence75,
                      isMonthly: actionsState.isMonthly,
                      isCumulative: cumulative,
                      cumulativeType: 'sum_per_month',
                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                        return {
                          x: +new Date(d),
                          key: +new Date(d),
                          y1: monthlyData.ds_fc.tp_sum['0.75'][i],
                          y0: monthlyData.ds_fc.tp_sum['0.25'][i],
                          max: monthlyData.ds_fc.tp_sum['0.75'][i],
                          min: monthlyData.ds_fc.tp_sum['0.25'][i]
                        }
                      })
                    }))
                    .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'precip' })),
                  color: '#237CB5',
                  'opacity': 0.4,
                },
                {
                  type: 'line',
                  points:
                    (cumulative && actionsState.isMonthly ?
                      assembleCumulativeData({
                        forecastData: cumulativeData.ds_fc,
                        confidenceLevel: 0.5,
                        climCumulativeData: assembleLineData({
                          isMonthly: actionsState.isMonthly,
                          historical: historicalTemp,
                          isCumulative: cumulative,
                          cumulativeType: 'sum_per_month',
                          forecast: forecastTemp,
                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                            return {
                              x: new Date(d),
                              y: monthlyData.ds_fc.tp_sum['0.5'][i]
                            }
                          })
                        }),
                        variable: 'tp_sum'
                      }) :
                      assembleLineData({
                        isMonthly: actionsState.isMonthly,
                        historical: historicalTemp,
                        isCumulative: cumulative,
                        cumulativeType: 'sum_per_month',
                        forecast: forecastTemp,
                        seasonal: monthlyData.ds_fc.time.map((d, i) => {
                          return {
                            x: new Date(d),
                            y: monthlyData.ds_fc.tp_sum['0.5'][i]
                          }
                        })
                      }))
                      .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'precip' })),
                  color: '#237CB5',
                  'stroke-width': 2
                }] :
                [
                  {
                    type: 'area',
                    points: trimmData(duplicateMonthlyHistoricalDataForFutureSixMonths(extraClimLighten)),
                    color: '#C0E1EB',
                    opacity: 0.6
                  },
                  {
                    type: 'area',
                    points: trimmData(duplicateMonthlyHistoricalDataForFutureSixMonths(extraClimDarken)),
                    color: '#A0CBE0',
                    opacity: 0.6
                  },
                  // Confidence Bands
                  {
                    type: 'area',
                    points: trimmData(extraForecastConfidence95),
                    color: '#237CB5',
                    'opacity': 0.4,
                  },
                  {
                    type: 'area',
                    points: trimmData(extraForecastConfidence75),
                    color: '#237CB5',
                    'opacity': 0.4,
                  },

                  {
                    type: 'line',
                    points: trimmData(extraHistoricalTemp.concat(extraForecastTemp.map(d => Object.assign(d, { dashed: true })))),
                    color: '#237CB5',
                    'stroke-width': 2
                  }
                ]}
            ></LineAreaChartComponent>
          </div>
          <div className="chart-specs-container">
            <ChartSpecs
              type={weatherVariable}
              chartRef={chartRef}
              cumulative={cumulative}
              data={{
                csv: combinedCsvData(climCsvData, forcCsvData, histCsvData),
                hourlyCsv: !actionsState.extraPrecipitationChart ?
                  (mergeHistoricalAndForecastData({
                    forecast: hourlyData.ds_fc,
                    historical: hourlyData.ds_hist,
                    prop: 'tp'
                  })) :
                  (mergeHistoricalAndForecastData({
                    forecast: hourlyData.ds_fc,
                    historical: hourlyData.ds_hist,
                    prop: 'e'
                  }))
              }}
              onHourlyCsvDataTrigger={() => {
                if (actionsState.extraPrecipitationChart) {
                  return new Promise((resolve, reject) => {
                    currentUser
                      .getIdToken()
                      .then((userToken) => {
                        networking
                          .get(`/api/v1/weather/evaporation/hourly/${id}`, {
                            extraHeaders: { 'User-Token': userToken },
                          })
                          .then((res) => {
                            setHourlyData({
                              ...res.data,
                            });
                            resolve(res.data)
                          })
                          .catch(() => {
                            reject();
                          });
                      })
                  })
                } else {
                  return new Promise((resolve, reject) => {
                    currentUser
                      .getIdToken()
                      .then((userToken) => {
                        networking
                          .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                            extraHeaders: { 'User-Token': userToken },
                          })
                          .then((res) => {
                            setHourlyData({
                              ...res.data,
                            });
                            resolve(res.data)
                          })
                          .catch(() => {
                            reject();
                          });
                      })
                  })
                }

              }}
              actionsState={actionsState}
              onSliderChange={handleChangeSlider}
              disabled={!evaporationData.pending && !extraHistoricalTemp.length}
            />
          </div>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default memo(PrecipitationChart);
