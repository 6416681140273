import React, { forwardRef } from "react"
import { makeStyles } from "@material-ui/styles"
import { NavLink as RouterLink, Link } from "react-router-dom"
import { Button } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import "./NavbarOption.css"
const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: "none",
        padding: "0",
        margin: "0",
        letterSpacing: "0",
        fontWeight: "400",
        fontSize: "11px",
        fontFamily: "Roboto",
        marginBottom: "4px",
        marginLeft: "18%",
        color: "rgba(29, 41, 52, 0.5)",
    },
    buttonMedia: {
        textTransform: "none",
        padding: "20px 0",
        margin: "0",
        letterSpacing: "0",
        fontWeight: "400",
        fontSize: "22px",
        fontFamily: "Roboto",
        marginLeft: "20px",
        color: "rgba(29, 41, 52, 0.5)",
    },
    active: {
        fontWeight: "700",
        color: "rgba(29, 41, 52)",
    },
    activeMedia: {
        fontWeight: "800",
        fontSize: "22px",
        color: "#1D2934",
    },
    settingsButton: {
        padding: "2px 0 6px 20px",
        margin: "0",

        fontFamily: "Roboto",
        textTransform: "none",
        letterSpacing: "0",
        fontWeight: "600",
        fontSize: "11px",
        color: "rgba(29, 41, 52, 0.5)",
    },
    settingsButtonMedia: {
        padding: "20px 0",
        margin: "0",

        fontFamily: "Roboto",
        textTransform: "none",
        letterSpacing: "0",
        fontWeight: "600",
        fontSize: "22px",
        color: "rgba(29, 41, 52, 0.5)",
    },
    settingsActive: {
        fontWeight: "700",
        color: "rgba(29, 41, 52)",
    },
    settingsActiveMedia: {
        fontWeight: "800",
        color: "rgba(29, 41, 52)",
    },
    media: {
        fontSize: "22px",
    },
}))

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
))

function NavbarOption(props) {
    const { pages, fieldId, isDashboard, optionsTitle, optionDesc, ...rest } = props
    const classes = useStyles()
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)")
    return (
        <>
            {pages.length > 0 && (
                <div className="navbar-option__menu">
                    {isDashboard && <div className="navbar-option__menu__tool">{optionsTitle}</div>}
                    {pages.map((page, i) => (
                        <Button
                            key={i}
                            activeClassName={
                                mediaBreakpoint
                                    ? isDashboard
                                        ? classes.activeMedia
                                        : classes.settingsActiveMedia
                                    : isDashboard
                                    ? classes.active
                                    : classes.settingsActive
                            }
                            className={
                                mediaBreakpoint
                                    ? isDashboard
                                        ? classes.buttonMedia
                                        : classes.settingsButtonMedia
                                    : isDashboard
                                    ? classes.button
                                    : classes.settingsButton
                            }
                            // onClick={CustomRouterLink}
                            component={CustomRouterLink}
                            to={page.href + fieldId}
                        >
                            {page.title} {optionDesc}
                        </Button>
                    ))}
                </div>
            )}
        </>
    )
}

export default NavbarOption
