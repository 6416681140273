import React, { useState, useContext } from "react"
// Modules

// Components
import AlertSelect from "./AlertSelect"
import AlertInput from "./AlertInput"
import AlertDateInput from "./AlertDateInput"
import AlertActiveToggle from "./AlertActiveToggle"
import { toast } from "react-toastify"

// Views

// Context providers / Utils
import validations from "./ValidateAlertInput"
import AlertsContext from "./AlertsContext"
import {
    getUnitsNotation,
    cleanAlertData,
    cleanStackedAlertData,
    isAlertDataValid,
    cleanValidations,
} from "../../../../Util/Alerts"
import { RISKS } from "./RisksList"

// Hooks

// Material-UI *

// Styles
import "./Alert.css"

const NewAlert = (props) => {
    const {
        riskData,
        path,
        onSave = () => null,
        onCancel,
        units,
        currentUser,
        featurePermissions,
        toggleNewAlert = () => {},
    } = props

    const { onAlertAdd, onStackedAlertAdd, toggleLoading } = useContext(AlertsContext)

    const cleanState = !riskData.stacked
        ? {
              active: true,
              probability: "",
              title: "",
              start_date: { day: 1, month: 1 },
              end_date: { day: 31, month: 12 },
              errors: {},
              flags: {},
          }
        : {
              active: true,
              title: "",
              start_date: { day: 1, month: 1 },
              end_date: { day: 31, month: 12 },
              risks: {},
              errors: {},
              flags: {},
          }

    const [state, setState] = useState(cleanState)
    const [isGDD, setIsGDD] = useState(riskData.isGDD)
    const [isStacked, setIsStacked] = useState(riskData.stacked)

    const cropStages = ["Custom"] //["Planting", "Emergence", "Canopy C.", "Senescence", "Harvest", "Custom"]

    function handleInputChange(propName, risk) {
        if (isStacked && risk) {
            return (value, error, flag) => {
                setState({
                    ...state,
                    risks: { ...state.risks, [risk]: { ...state.risks[risk], [propName]: value } },
                    errors: { ...state.errors, [risk]: { ...state.errors[risk], [propName]: error } },
                    flags: { ...state.flags, [risk]: { ...state.flags[risk], [propName]: flag } },
                })
            }
        }

        return (value, error, flag) => {
            setState({
                ...state,
                [propName]: value,
                errors: { ...state.errors, [propName]: error },
                flags: { ...state.flags, [propName]: flag },
            })
        }
    }

    function handleAlertTitleBlur(e) {
        let onChange = handleInputChange("title")
        const error = validations.title()(e.target.value)
        onChange(e.target.value, error, true)
    }

    function handleAlertTitleChange(e) {
        let onChange = handleInputChange("title")
        const error = validations.title()(e.target.value)
        onChange(e.target.value, error, false)
    }

    function saveAlert() {
        toggleLoading(true)
        let alertData
        let addAlertFunction
        if (isStacked) {
            alertData = cleanStackedAlertData(state, RISKS, units)
            addAlertFunction = onStackedAlertAdd
        } else {
            alertData = cleanAlertData(state, riskData.units, units, isGDD)
            addAlertFunction = onAlertAdd
        }

        addAlertFunction(alertData)
            .then((res) => {
                setState(cleanState)
                let alertId = res.data.alert_id
                toggleLoading(false)
                toggleNewAlert(false)
                toast.success(`${state?.title} has been created`)
                onSave(alertId, alertData)
            })
            .catch((err) => {
                console.log("Error setting user alert ", err)
                toggleLoading(false)
                toast.error(`Error saving ${state?.title}, please try again later`)
            })
    }

    function checkStackedAlertData() {
        const errors = {}
        const flags = {}
        let dataHasErrors = false

        let vals = Object.assign({}, validations)

        let general_vals = ["title", "recurrent_days"]
        general_vals.map((val) => {
            let validationFunction = vals[val]()
            let err = validationFunction(state[val])
            errors[val] = err
            if (err !== null) {
                flags[val] = true
                dataHasErrors = true
            }
            delete vals[val]
        })

        riskData.risks.map((risk) => {
            let individualRiskData = RISKS[risk]
            let data = state.risks[risk]
            let riskVals = cleanValidations(individualRiskData.isGDD, Object.assign({}, vals))

            const { errors: riskErrors, flags: riskFlags, dataHasErrors: riskDataHasErrors } = isAlertDataValid(
                riskVals,
                data,
                individualRiskData.units,
                units
            )
            dataHasErrors = dataHasErrors || riskDataHasErrors
            errors[risk] = riskErrors
            flags[risk] = riskFlags
        })

        setState({ ...state, errors, flags })
        if (dataHasErrors) {
            console.log("Data has errors = ", errors)
            return false
        }
        saveAlert()
        return true
    }

    function checkAlertData(isGDD) {
        let vals = cleanValidations(isGDD, Object.assign({}, validations))

        const { errors, flags, dataHasErrors } = isAlertDataValid(vals, state, riskData.units, units)

        setState({ ...state, errors, flags })
        if (dataHasErrors) {
            console.log("Data has errors = ", errors)
            return false
        }
        saveAlert()
        return true
    }

    function onCancelNewAlert() {
        setState(cleanState)
        onCancel()
    }

    return (
        <div className="alert__content">
            <div className="alert__content__header">
                <div className="alert__content__title-dot">&middot;</div>
                <input
                    className="alert__content__title-input"
                    type="text"
                    value={state.title}
                    placeholder={"Alert name"}
                    onChange={handleAlertTitleChange}
                    onBlur={handleAlertTitleBlur}
                />
                {state.flags.title && <div className="alert__content__title-error">{state.errors.title}</div>}
                <AlertActiveToggle
                    path={path}
                    isActive={state.active}
                    currentUser={currentUser}
                    featurePermissions={featurePermissions}
                />{" "}
            </div>

            <div className="alert__content__conditions">
                {isStacked ? (
                    riskData.risks.map((risk, index) => {
                        let individualRiskData = RISKS[risk]
                        return (
                            <div key={risk + index}>
                                <div className="alert__content__conditions__options-s-margin">
                                    <div>
                                        <strong> {index > 0 ? "And " : ""} If: &nbsp;</strong> Predicted{" "}
                                        {individualRiskData.modifier} {individualRiskData.variable} is{" "}
                                        {individualRiskData.conditional}
                                    </div>
                                    {!individualRiskData.isGDD && (
                                        <AlertInput
                                            placeholder={30}
                                            value={
                                                state.risks[risk]?.threshold === null
                                                    ? ""
                                                    : state.risks[risk]?.threshold
                                            }
                                            onChange={handleInputChange("threshold", risk)}
                                            match={validations.threshold(individualRiskData.units, units)}
                                            error={state.errors[risk]?.threshold}
                                            flag={state.flags[risk]?.threshold}
                                            min={-30}
                                            max={2000}
                                        />
                                    )}
                                    {individualRiskData.isGDD && (
                                        <AlertInput
                                            placeholder={300}
                                            value={state.risks[risk]?.gddSum === null ? "" : state.risks[risk]?.gddSum}
                                            onChange={handleInputChange("gddSum", risk)}
                                            match={validations.gddSum(units)}
                                            error={state.errors[risk]?.gddSum}
                                            flag={state.flags[risk]?.gddSum}
                                            min={100}
                                            max={10000}
                                        />
                                    )}
                                    <div>{getUnitsNotation(individualRiskData.units, units)}</div>
                                </div>

                                <div className="alert__content__conditions__options-m-margin">
                                    <strong>With: &nbsp;</strong> Probability of at least
                                    <AlertInput
                                        placeholder={80}
                                        value={state.risks[risk]?.probability}
                                        onChange={handleInputChange("probability", risk)}
                                        match={validations.probability()}
                                        error={state.errors[risk]?.probability}
                                        flag={state.flags[risk]?.probability}
                                        min={1}
                                        max={100}
                                    />
                                    %
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <>
                        <div className="alert__content__conditions__options-s-margin">
                            <div>
                                <strong>If: &nbsp;</strong> Predicted {riskData.modifier} {riskData.variable} is{" "}
                                {riskData.conditional}
                            </div>
                            {!riskData.isGDD && (
                                <AlertInput
                                    placeholder={30}
                                    value={state.threshold === null ? "" : state.threshold}
                                    onChange={handleInputChange("threshold")}
                                    match={validations.threshold(riskData.units, units)}
                                    error={state.errors.threshold}
                                    flag={state.flags.threshold}
                                    min={-30}
                                    max={2000}
                                />
                            )}
                            {riskData.isGDD && (
                                <AlertInput
                                    placeholder={300}
                                    value={state.gddSum === null ? "" : state.gddSum}
                                    onChange={handleInputChange("gddSum")}
                                    match={validations.gddSum(units)}
                                    error={state.errors.gddSum}
                                    flag={state.flags.gddSum}
                                    min={100}
                                    max={10000}
                                />
                            )}
                            <div>{getUnitsNotation(riskData.units, units)}</div>
                        </div>

                        <div className="alert__content__conditions__options-m-margin">
                            <strong>With: &nbsp;</strong> Probability of at least
                            <AlertInput
                                placeholder={80}
                                value={state.probability}
                                onChange={handleInputChange("probability")}
                                match={validations.probability()}
                                error={state.errors.probability}
                                flag={state.flags.probability}
                                min={1}
                                max={100}
                            />
                            %
                        </div>

                        {riskData.isGDD && (
                            <>
                                <div className="alert__content__conditions__options-m-margin">
                                    <strong>And: &nbsp;</strong> {riskData.variable} Base is:
                                    <AlertInput
                                        placeholder={10}
                                        value={state.gddBase === null ? "" : state.gddBase}
                                        onChange={handleInputChange("gddBase")}
                                        match={validations.gddBase(riskData.units, units)}
                                        error={state.errors.gddBase}
                                        flag={state.flags.gddBase}
                                        min={-30}
                                        max={2000}
                                    />
                                    <div>{getUnitsNotation(riskData.units, units)}</div>
                                </div>
                                <div className="alert__content__conditions__options-m-margin">
                                    <strong>And: &nbsp;</strong> Total Plant Life Cycle:
                                    <AlertInput
                                        placeholder={90}
                                        value={state.gddLifeCycle === null ? "" : state.gddLifeCycle}
                                        onChange={handleInputChange("gddLifeCycle")}
                                        match={validations.gddLifeCycle()}
                                        error={state.errors.gddLifeCycle}
                                        flag={state.flags.gddLifeCycle}
                                        min={0}
                                        max={360}
                                    />
                                    <div>days</div>
                                </div>
                            </>
                        )}
                    </>
                )}

                {!riskData.isGDD && (
                    <div className="alert__content__conditions__options-s-margin">
                        <strong>And: &nbsp;</strong> {riskData.aggregated ? "Aggregated" : "Recurrent"} for
                        <AlertInput
                            placeholder={3}
                            value={state.recurrent_days === null ? "" : state.recurrent_days}
                            onChange={handleInputChange("recurrent_days")}
                            match={validations.recurrent_days()}
                            error={state.errors.recurrent_days}
                            flag={state.flags.recurrent_days}
                            min={1}
                            max={60}
                        />{" "}
                        consecutive days
                    </div>
                )}

                <div className="alert__content__conditions__options-s-margin">
                    <strong>During: &nbsp;</strong> <AlertSelect options={cropStages} selectedIndex={1} />
                    Crop Stage
                </div>

                <div className="alert__content__conditions__options-m-margin">
                    <strong>Time Frame: &nbsp;</strong>
                    <AlertDateInput value={state?.start_date} onChange={handleInputChange("start_date")} />
                    to
                    <AlertDateInput value={state?.end_date} onChange={handleInputChange("end_date")} />
                </div>
            </div>
            <div className="alert__content__button-container">
                <button className="alert__content__cancel-button" onClick={onCancelNewAlert}>
                    Cancel
                </button>
                <button
                    className="alert__content__save-button"
                    onClick={() => {
                        !isStacked ? checkAlertData(isGDD) : checkStackedAlertData()
                    }}
                >
                    Save
                </button>
            </div>
        </div>
    )
}

export default NewAlert
