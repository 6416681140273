import * as React from "react"

// mdi format list bullet

function SvgComponent(props) {
    return (
        <svg width={13} height={10} viewBox="0 0 13 10" fill="none" {...props}>
            <path
                d="M1.667 4c-.554 0-1 .447-1 1 0 .553.446 1 1 1 .553 0 1-.447 1-1 0-.553-.447-1-1-1zm0-4c-.554 0-1 .447-1 1 0 .553.446 1 1 1 .553 0 1-.447 1-1 0-.553-.447-1-1-1zm0 8c-.554 0-1 .453-1 1 0 .547.453 1 1 1 .546 0 1-.453 1-1 0-.547-.447-1-1-1zm2 1.667H13V8.333H3.666v1.334zm0-4H13V4.333H3.666v1.334zm0-5.334v1.334H13V.333H3.666z"
                fill="#1D2934"
            />
        </svg>
    )
}

export default SvgComponent
