import React, { useState, useRef } from 'react';
import Dropdown from 'rc-dropdown';
import downloadSVG from 'export-svg-with-styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router';

import { monthNames } from '../TempChart/helper';

import DownloadIcon from '../../../../assets/Chart/download.svg';
import ArrowDownBlue from '../../../../assets/Chart/arrow-down-blue.svg';
import DownloadIconWhite from '../../../../assets/Chart/download-white.svg';
import ArrowDownWhite from '../../../../assets/Chart/arrow-down-white.svg';
import { toast } from 'react-toastify';

const CustomCheckbox = withStyles({
  root: {
    color: '#1A2935',
    '&$checked': {
      color: '#325588',
    },
  },
  checked: {},
})((props) => (
  <Checkbox
    color="default"
    inputProps={{
      id: props.label,
    }}
    style={{ width: 10, height: 10, padding: 0 }}
    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 10 }} />}
    checkedIcon={<CheckBoxIcon style={{ fontSize: 10 }} />}
    {...props}
  />
));

const DropdownComponent = ({ cols, data, actionsState, disabled, onHourlyCsvDataTrigger }) => {
  const { id } = useParams();
  const linkRef = useRef(null);
  const hourlyCsvLinkRef = useRef(null);
  const [state, setState] = useState({
    exportToPng: false,
    exportToCsv: false,
    exportAsHourlyCsv: false,
    exportAsHincastCsv: false,
    visible: false,
    loadingHourly: false
  });

  const chart = document.getElementsByClassName('svg-chart-container')[0];
  let width, height;

  if (chart) {
    const rect = chart.getBoundingClientRect();
    width = rect.width;
    height = rect.height;
  }

  const handleSubmitExport = () => {
    if (state.exportToPng) {
      downloadSVG({
        width: width * 3,
        height: height * 3,
        svg: chart,
        filename: `${new Date().getDate() + monthNames[new Date().getMonth()]}_${currentTab.split(/\s/).join('_')}.png`,
      });
    }

    if (state.exportToCsv) {
      linkRef.current.link.click();
    }

    // Prepare initial state
    let initialState = { visible: false };

    // If some boxes are checked, process state accordingly
    if (state.exportToCsv || state.exportToPng || state.exportAsHincastCsv || state.exportAsHourlyCsv) {
      initialState.exportToPng = false;
      initialState.exportToCsv = false;
      initialState.exportAsHincastCsv = false;
      if (state.exportAsHourlyCsv) {
        initialState.visible = true;
        initialState.loadingHourly = true
      }
    }

    // Save state
    setState({
      ...state,
      ...initialState
    });


    // If export as hourly data was checked
    if (state.exportAsHourlyCsv) {

      // Get hourly csv data promise 
      onHourlyCsvDataTrigger()
        .then((data) => {
          // If promise was successfully fulfilled, close dropdown and download data
          setState({
            ...state,
            visible: false,
            loadingHourly: false,
            exportAsHourlyCsv: false,
            exportToPng: false,
            exportToCsv: false,
            exportAsHincastCsv: false
          });
          hourlyCsvLinkRef.current.link.click();
        })
        .catch((data) => {
          // If promise was rejected, display warning and close dropdown
          toast.warning('Hourly data load error occured');
          setState({
            ...state,
            visible: false,
            loadingHourly: false,
            exportAsHourlyCsv: false,
            exportToPng: false,
            exportToCsv: false,
            exportAsHincastCsv: false
          });
        })
    }
  };

  let currentTab = actionsState.currentTab;

  if (actionsState.extraPrecipitationChart) {
    currentTab = 'Water Budget';
  } else if (actionsState.extraHumidityChart) {
    currentTab = 'Relative Humidity';
  } else if (actionsState.solarRadiationChart) {
    currentTab = "Solar Radiation"
  } else if (actionsState.growingDegreeDaysChart) {
    currentTab = "GDD"
  } else if (actionsState.currentTab === 'minmax') {
    currentTab = 'Min & Max Temp';
  } else if (actionsState.currentTab === 'precipitation') {
    currentTab = 'Precipitation';
  } else if (actionsState.currentTab === 'soil') {
    currentTab = 'Soil Temperature';
  } else if (actionsState.extraEvapotranspirationChart) {
    currentTab = 'Evapotranspiration';
  } else if (actionsState.humidityChart) {
    currentTab = 'Humidity';
  }

  function onVisibleChange(visible) {
    setState({
      ...state,
      visible: visible
    })
  }

  return (
    <Dropdown
      trigger="click"
      onVisibleChange={onVisibleChange}
      visible={state.visible}
      overlay={(
        <div className="content-dropdown">
          <p style={{ paddingLeft: 0, marginBottom: 10 }} className="dropdown-item-text">Export as </p>
          <div className="dropdown-item">
            <CustomCheckbox
              checked={state.exportToPng}
              label="exportAsPng"
              onChange={() => {
                setState({
                  ...state,
                  exportToPng: !state.exportToPng,
                })
              }}
              disableRipple
            />
            <label style={{ cursor: 'pointer' }} className="dropdown-item-text" htmlFor="exportAsPng"> Export as <span className="dropdown-item-text-bold">Png</span></label>
          </div>
          <div className="dropdown-item">
            <CustomCheckbox
              checked={state.exportToCsv}
              label="exportAsCsv"
              onChange={() => {
                setState({
                  ...state,
                  exportToCsv: !state.exportToCsv,
                })
              }}
              disableRipple
            />
            <label style={{ cursor: 'pointer' }} className="dropdown-item-text" htmlFor="exportAsCsv"> Export as <span className="dropdown-item-text-bold">CSV</span></label>
          </div>
          { data.hourlyCsv ? <div className="dropdown-item">
            <CustomCheckbox
              checked={state.exportAsHourlyCsv}
              label="exportAsHourlyCsv"
              onChange={() => {
                setState({
                  ...state,
                  exportAsHourlyCsv: !state.exportAsHourlyCsv,
                })
              }}
              disableRipple
            />
            <label style={{ cursor: 'pointer' }} className="dropdown-item-text" htmlFor="exportAsHourlyCsv">  Export as <span className="dropdown-item-text-bold">Hourly CSV {state.loadingHourly ? '(Loading...)' : ''}</span></label>
          </div> : ''
          }

          {/* <div className="dropdown-item">
            <CustomCheckbox
              checked={state.exportAsHincastCsv}
              label="exportAsHincastCsv"
              onChange={() => {
                setState({
                  ...state,
                  exportAsHincastCsv: !state.exportAsHincastCsv,
                })
              }}
              disableRipple
            />
            <label style={{ cursor: 'pointer' }} className="dropdown-item-text" htmlFor="exportAsHincastCsv"> Export <span className="dropdown-item-text-bold">hincast</span> data as CSV</label>
          </div> */}
          <CSVLink
            ref={linkRef}
            data={data.csv}
            headers={cols.csv}
            className="hidden-link"
            filename={`${new Date().getDate() + monthNames[new Date().getMonth()]}_${currentTab.split(/\s/).join('_')}.csv`}
          />
          {
            data.hourlyCsv ? <CSVLink
              ref={hourlyCsvLinkRef}
              data={data.hourlyCsv}
              headers={cols.hourly}
              className="hidden-link"
              filename={`hourly_${new Date().getDate() + monthNames[new Date().getMonth()]}_${currentTab.split(/\s/).join('_')}.csv`}
            /> : ''
          }

          <button
            className="export-now"
            onClick={handleSubmitExport}
          >
            Export now
          </button>
        </div>
      )}
      animation="slide-up"
    >
      <button
        className="button-dropdown"
        style={{
          backgroundColor: state.visible ? '#1C9690' : '',
          color: state.visible ? '#fff' : '#1C9690'
        }}
      // onClick={() => {
      //   setState({
      //     ...state,
      //     visible: !state.visible
      //   })
      // }}
      >
        <div className="buttonDropdownLeftSide">
          {
            !state.visible ? (
              <img src={DownloadIcon} alt="" />
            ) : (
                <img src={DownloadIconWhite} alt="" />
              )
          }
          Export
        </div>
        {
          !state.visible ? (
            <img src={ArrowDownBlue} alt="" />
          ) : (
              <img src={ArrowDownWhite} alt="" />
            )
        }
      </button>
    </Dropdown>
  );
};

export default DropdownComponent;
