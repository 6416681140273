import React from "react"

import "./EditControls.css"

import TrashIcon from "../Icons/TrashIcon"
import CancelIcon from "../Icons/CancelIcon"

import { Popover } from "../Popover"

function DeleteControls(props) {
    const { onClose = () => null, onDelete = () => null } = props

    return (
        <div className="table-item-editable__actions-container">
            <Popover text="Delete">
                <button className="table-item-editable__actions__button-delete" onClick={onDelete}>
                    <div className="table-item-editable__actions__icon">
                        <TrashIcon fill="#FF3D3D" />
                    </div>
                </button>
            </Popover>
            <Popover text="Cancel">
                <button className="table-item-editable__actions__button-cancel" onClick={onClose}>
                    <div className="table-item-editable__actions__icon">
                        <CancelIcon />
                    </div>
                </button>
            </Popover>
        </div>
    )
}

export default DeleteControls
