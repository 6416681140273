import React, { useState } from "react"
import { Box, Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Alert from "@material-ui/lab/Alert"
import Dropdown from "../../../Weather/components/Dropdown/index"
import LoaderWithMessages from "../../../../components/LoaderWithMessages"
import "../../style.css"
import * as d3 from "d3"

const PlantingChartWrapperComponent = ({
    titleLabelFontSize,
    titleLabelOffsetX,
    titleLabelOffsetY,
    description,
    hideExport,
    dataLoadStatus,
    loadErrorOccured,
    xDateMin,
    xDateMax,
    xRightOffset,
    tipOffsetY,
    staticTipXPosition,
    title,
    data,
    color,
    xTicksCount,
    labelY,
    svgHeight,
    resizeEventListenerId,
    tooltip,
    disableUpdate,
    onChartMouseLeave,
    isMainChart = false,
}) => {
    // Use styles
    const useStyles = makeStyles((theme) => ({
        root: {
            boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
            borderRadius: "12px",
            background: "linear-gradient(131.47deg, #F8FBFF 7.84%, #EFF2F6 93.19%)",
        },
    }))
    const classes = useStyles()

    const loadingMessages = [
        "Generating extended forecast...",
        "Analyzing probability of extreme weather events...",
        "Determining impact on key crop stages...",
        "Weighing risk-yield tradeoffs... ",
        "Calculating optimal time to plant/sow...",
        "Finalizing results...",
    ]

    const formatMillisecond = d3.timeFormat(".%L"),
        formatSecond = d3.timeFormat(":%S"),
        formatMinute = d3.timeFormat("%I:%M"),
        formatHour = d3.timeFormat("%I %p"),
        formatDay = d3.timeFormat("%a %d"),
        formatWeek = d3.timeFormat("%a %d"),
        formatMonth = d3.timeFormat("%b"),
        formatYear = d3.timeFormat("%Y")

    return (
        <>
            <Card className={[clsx(classes.root), "planting-tool__chart"]}>
                <CardContent>
                    <div
                        className="planting-chart-title-container"
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <div>{title}</div>

                        <div className="btns-container" style={{ display: hideExport ? "none" : "initial" }}>
                            <Dropdown
                                // onHourlyCsvDataTrigger={onHourlyCsvDataTrigger}
                                cols={{ csv: ["Timeline", "Expected Yield %"] }}
                                // chartRef={chartRef}
                                // cols={{ csv: colsArr, hourly: colsHourly }}
                                data={{ csv: data.points.map((d) => [d.x.toISOString(), d.y]) }}
                                actionsState={{ currentTab: "Planting" }}
                                // disabled={disabled}
                            />
                        </div>
                    </div>
                    {!dataLoadStatus && isMainChart && (
                        <Box className="chart-preload-container with-message">
                            <LoaderWithMessages messages={loadingMessages} />
                        </Box>
                    )}
                    {!loadErrorOccured && dataLoadStatus && dataLoadStatus !== "empty" && data.points.length === 0 ? (
                        <Alert style={{ marginTop: "30px" }} severity="info">
                            Update filters to see chart
                        </Alert>
                    ) : (
                        ""
                    )}
                    {dataLoadStatus === "empty" ? (
                        <Alert style={{ marginTop: "30px" }} severity="info">
                            Alerts not found for this field...
                        </Alert>
                    ) : (
                        ""
                    )}
                    <div style={{ display: data.points.length ? "flex" : "none" }}>
                        <LineAreaChartComponent
                            xTickFormat={(date) => {
                                return (d3.timeSecond(date) < date
                                    ? formatMillisecond
                                    : d3.timeMinute(date) < date
                                    ? formatSecond
                                    : d3.timeHour(date) < date
                                    ? formatMinute
                                    : d3.timeDay(date) < date
                                    ? formatHour
                                    : d3.timeMonth(date) < date
                                    ? d3.timeWeek(date) < date
                                        ? formatDay
                                        : formatWeek
                                    : d3.timeYear(date) < date
                                    ? formatMonth
                                    : formatYear)(date)
                            }}
                            xDateMax={xDateMax}
                            xDateMin={xDateMin}
                            title={description}
                            tipOffsetY={tipOffsetY}
                            titleLabelOffsetY={titleLabelOffsetY}
                            titleLabelOffsetX={titleLabelOffsetX}
                            titleLabelFontSize={titleLabelFontSize}
                            marginLeft={65}
                            labelYOffsetX={-10}
                            staticTipXPosition={staticTipXPosition}
                            disableUpdate={disableUpdate}
                            onChartMouseLeave={onChartMouseLeave}
                            svgHeight={svgHeight || 200}
                            yTicksCount={5}
                            yValueMax={100}
                            labelYFontSize={11}
                            yTickFormat={(d) => d + "%"}
                            resizeEventListenerId={resizeEventListenerId || "resize-event-listener-id"}
                            gridView={false}
                            tooltip={
                                tooltip ||
                                ((EVENT, { key, values, colors }, state) => {
                                    return `<table cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                        <tr><td style="font-weight:bold;font-size:14px" rowspan="${
                            values.length
                        }"><div style="text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(
                                        undefined,
                                        {
                                            day: "numeric",
                                            month: "short",
                                        }
                                    )}</div></td>
                            <td><div style="font-size:11px;position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${
                                colors[0]
                            };margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0] * 10) / 10} %</td>
                        </tr>
                                 ${values
                                     .filter((d, i) => i > 0)
                                     .map((value, i) => {
                                         return ` <tr><td><div style="font-size:11px;position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${
                                             colors[i + 1]
                                         };margin-top:-10px;border-radius:5px;"></div>${
                                             Math.round(value * 10) / 10
                                         } %</td></tr>`
                                     })
                                     .join("")}
                    </table>`
                                })
                            }
                            xLeftOffset={0.1}
                            xRightOffset={xRightOffset || 0.05}
                            xTicksCount={xTicksCount || 5}
                            labelY={labelY || "Percentage probability of risk"}
                            data={[
                                {
                                    type: "line",
                                    "stroke-width": 2,
                                    stroke: data.color || "#1C877E",
                                    points: data.points,
                                },
                            ]}
                        ></LineAreaChartComponent>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default PlantingChartWrapperComponent
