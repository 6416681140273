import React, { useCallback, useContext, useState } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Copyright from "../ui/Copyright/Copyright"
import app from "../Util/Fire"
import { AuthContext } from "../Auth/Auth"
import { Link as RouterLink } from "react-router-dom"
import { Redirect, withRouter } from "react-router"

import "./SignInSideBar.css"
import { UIAlertContext } from "../ui/UIAlert/UIAlert"

import firebase from "firebase"

const SignInSideBar = ({ history }) => {
    // TODO: Set default to user's check
    const [rememberUser, setRememberUser] = useState(!!localStorage.getItem("@flag/remember-me"))

    const { confirmationModal } = useContext(UIAlertContext)

    const { currentUser } = useContext(AuthContext)

    const handleLogin = useCallback(
        async (event) => {
            event.preventDefault()
            const { email, password } = event.target.elements
            try {
                console.log(rememberUser)
                await app
                    .auth()
                    .setPersistence(
                        rememberUser ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
                    )
                await app.auth().signInWithEmailAndPassword(email.value, password.value)
            } catch (error) {
                // alert(error)
                confirmationModal(null, error.message)
            }
        },
        [history, rememberUser]
    )

    if (currentUser) {
        return <Redirect to="/dashboard" />
    }

    function handleRememberUser(e) {
        console.log(e.target.checked)
        if (e.target.checked) localStorage.setItem("@flag/remember-me", "true")
        else localStorage.removeItem("@flag/remember-me")

        setRememberUser(e.target.checked)
    }

    return (
        <div className="sign-in-side">
            <CssBaseline />
            <main className="sign-in-side__main">
                <div className="sign-in-side__grid">
                    <div className="sign-in-side__grid-item__image" />
                    <div className="sign-in-side__grid-item__content">
                        <img alt="Climate AI logo" className="sign-in-side__logo" src="/Climate-AI-Color-Logo.png" />

                        <div className="sign-in-side__login-text">LOG IN</div>

                        <form className="sign-in-side__form" noValidate onSubmit={handleLogin}>
                            <input
                                className="sign-in-side__text-input"
                                placeholder="Email Address"
                                type="email"
                                id="email"
                                name="email"
                            />
                            <input
                                className="sign-in-side__text-input"
                                name="password"
                                placeholder="Password"
                                type="password"
                                id="password"
                            />
                            <div className="sign-in-side__checkbox-container">
                                {/* <span className={checkmark}></span>
                                <input
                                    type="checkbox"
                                    className="sign-in-side__checkbox"
                                    value="remember"
                                    onClick={changeCheckmark}
                                /> */}

                                <span className="sign-in-side__checkbox">
                                    <input
                                        type="checkbox"
                                        checked={rememberUser}
                                        onChange={handleRememberUser}
                                        className="sign-in-side__checkbox-input"
                                    />
                                    <label className="sign-in-side__checkbox-label">
                                        {rememberUser && (
                                            <svg
                                                width="90%"
                                                height="90%"
                                                viewBox="0 0 8 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.74982 4.73743L1.01232 2.99993L0.420654 3.58743L2.74982 5.9166L7.74982 0.916601L7.16232 0.329102L2.74982 4.73743Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        )}
                                    </label>
                                </span>

                                {/* <Checkbox /> */}

                                <div className="sign-in-side__checkbox-text">Remember me</div>
                            </div>

                            <button type="submit" className="sign-in-side__log-button">
                                Login
                            </button>

                            <div className="sign-in-side__divider-container">
                                <div className="sign-in-side__divider-line"></div>
                                <div className="sign-in-side__divider-text">or</div>
                                <div className="sign-in-side__divider-line"></div>
                            </div>

                            {/* TODO: Replace when there is google auth */}
                            {/* <img src="/images/google_signin.png" className="sign-in-side__google-sign-in" /> */}

                            <RouterLink className="sign-in-side__sign-up" to="/signup">
                                Don't have an account? <b>Sign Up</b>
                            </RouterLink>

                            <RouterLink
                                className="sign-in-side__restore-password"
                                style={{ textDecoration: "none", color: "white" }}
                                to="/restore-password"
                            >
                                Did you forget your password?
                            </RouterLink>
                        </form>
                        <div className="sign-in-side__copyright">
                            <Copyright />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default withRouter(SignInSideBar)
