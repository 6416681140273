import {
    select,
    selection,
    min,
    max,
    color,
    hcl,
    create,
    scaleLinear,
} from "d3";

import d3Tip from '../../../components/Charts/D3Tip/d3-tip.js'


/**
* ```javascript
* {
*    id : 1, 
*    value : 20, 
*    color : 'red', 
*    name : 'First'
* }
* ```
* @typedef {object} SingleRowBarChartDataPoint - Chart area data point
* @property {number | string } id - Unique identifier for each rectangle
* @property {number} value - Percent  number value , make sure that it sums up to 100
* @property {string} color - HTML compliant color value (hex, rgba, named colors etc)
* @property {string} name - Label of the chart - not used currently
*/



/**
* ```javascript
* {
*  values:[
*      {id : 1, value : 20, color : 'red', name : 'First'},
*      {id : 2, value : 80, color : 'blue', name : 'Second'}
*  ]
* }
* ```
* @typedef {object} ChartData - Chart data item
* @property {Array<SingleRowBarChartDataPoint>} values - Chart , inner rectangle data
 */

/**
 *  Single Row Har Chart Javascript Component. for initialization use   
 * 
 *`const chart = new SingleRowBarChart()`
 *
 * @export
 * @class SingleRowBarChart
 */
export class SingleRowBarChart {
    /**
    * Returns the current state of single row bar chart component   
    * 
    *`const {svgHeight} = chart.getState()`
    * @return {state}
    * @memberof SingleRowBarChart
     */
    getState() {
        return this.state;
    }

    /**
     * Extends the current chart state 
     *
     *`chart.setState({svgHeight:400})`
     * 
     * @param {Object} subState - State subObject, which will extend chart state
     * @return {state} 
     * @memberof SingleRowBarChart
     */
    setState(d) {
        return Object.assign(this.state, d)
    };

    /**
    * Sets resize event listener. It's preferred to pass it externally, in order to not  register too many event handlers for the same event when redrawing happens (Which happens a lot)
    * 
    *`chart.resizeEventListenerId('weight-risk-chart-bar')`
    *
    * @param {string | number} resizeEventListenerId - id, which will be used to minimize event handling function binding
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
    resizeEventListenerId(resizeEventListenerId) {
        this.setState({
            resizeEventListenerId
        });
        return this;
    }

    /**
    * Sets the default tooltip body content
    * 
    *```javascript
    * chart.tooltip((EVENT, { name, value }, state)=>{
    *    return  `<div>
    *                 Hovered item name : ${name} </br>
    *                 Hovered item value : ${value} </br>
    *                 Current Event : ${EVENT} </br>
    *                 Current App State : ${state}
    *             </div>`
    * })
    * ``` 
    *
    * @param {function} tooltip - Function, which generates tip content. It receives three arguments: 
    * **EVENT** - current event .
    * 
    * **{name, value}** 
    * 
    * **name** - is hovered item's name  .
    * 
    * **value** - is hovered item's value
    * 
    * **state** - is current chart state.
    * 
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
    tooltip(tooltip) {
        this.setState({
            tooltip
        });
        return this;
    }

    /**
    * Sets svg (actual chart content) height in pixels
    * 
    *`chart.svgHeight(400)`
    *
    * @param {number} svgHeight - Bar eight number value in pixels
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
    svgHeight(svgHeight) {
        this.setState({
            svgHeight
        });
        return this;
    }

    /**
    * Sets bar chart height
    * 
    *`chart.barHeight(400)`
    *
    * @param {number} barHeight - Height number value in pixels
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
    barHeight(barHeight) {
        this.setState({
            barHeight
        });
        return this;
    }

    /**
    * Sets global border radius for bar rectangle
    * 
    *`chart.borderRadius(400)`
    *
    * @param {number} borderRadius - Border radius value for whole rectangle bar
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
    borderRadius(borderRadius) {
        this.setState({
            borderRadius
        });
        return this;
    }

    /**
    * Sets flag  which animates rect transitions
    * 
    *`chart.animate(false)`
    *
    * @param {boolean} animate - Border radius value for whole rectangle bar
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
   animate(animate) {
        this.setState({
            animate
        });
        return this;
    }

    /**
    * Sets data for the chart. It takes data in the following format
    * ```javascript
    * {"values": [ {
    *         id : Unique identifier,
    *         value: Percent Value,
    *         color: Rectangle color,
    *         name: Label
    *  }]
    * ```
    * id should be unique across all bar rectangles, one bar rect may override another
    * 
    * 
    * Sample Usage
    *```javascript
    *chart.data({
    *  values:[
    *      {id : 1, value : 20, color : 'red', name : 'First'},
    *      {id : 2, value : 80, color : 'blue', name : 'Second'}
    *  ]
    *})
    *```
    * @param {ChartData} data - Array of line and area data items
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
    data(data) {
        this.setState({
            data
        });
        return this;
    }

    /**
     * Sets container for the graph. Takes raw DOM element or CSS selector as an input
     * 
     *`chart.container('div.chart-container')`  
     *
     * @param {string|DomElement} container - CSS selector string or dom element object, in which SVG graph will be drawn
     * @return {chartInstance} chart
     * @memberof SingleRowBarChart
     */
    container(container) {
        this.setState({
            container
        });
        return this;
    }

    /**
    * (Re)renders visualization
    * 
    *`chart.render()`
    *
    * @return {chartInstance} chart
    * @memberof SingleRowBarChart
    */
    render() {
        this.main();
        return this;
    };

    constructor() {
        this.addPrototypeFuncs();
        // Exposed variables
        var attrs = {
            id: 'ID' + Math.floor(Math.random() * 1000000), // Id for event handlings
            svgWidth: 400,
            svgHeight: 400,
            marginTop: 5,
            marginBottom: 5,
            marginRight: 5,
            marginLeft: 5,
            container: 'body',
            defaultTextFill: '#2C3E50',
            defaultFont: 'Helvetica,sans-serif',
            resizeEventListenerId: 'ID' + Math.floor(Math.random() * 1000000),
            barHeight: 10,
            scale: scaleLinear().domain([0, 100]).range([0, 500 - 200]),
            duration: 500,
            delay: 500,
            animate: true,
            textWidth: 0,
            tooltip: (EVENT, d) => d.name,
            displayTypeInformation: true,
            data: {
                "values": [
                    {
                        "id": 'id' + 1,
                        "value": 25,
                        "color": "#30938B",
                        "name": "Soil Moisture: <b>25</b>"
                    },
                    {
                        "id": 'id' + 2,
                        "value": 25,
                        "color": "#ED6B31",
                        "name": "Soil Temperature: <b>25</b>"
                    },
                    {
                        "id": 'id' + 3,
                        "value": 25,
                        "color": "#6C97C7",
                        "name": "Freeze Risk: <b>25</b>"
                    },
                    {
                        "id": 'id' + 4,
                        "value": 25,
                        "color": "#3C5A7E",
                        "name": "Drought Risk: <b>25</b>"
                    }
                ]
            }
        };

        // Save state
        this.state = attrs;
    }

    //Main chart object
    main() {
        const that = this;
        const attrs = this.getState();
        const { tooltip } = attrs;

        // Calculate container width
        const width = attrs.container.getBoundingClientRect().width || 300;
        attrs.svgWidth = width;
        attrs.scale.range([0, width])

        //Add svg
        const svg = select(attrs.container)
            .patternify({
                tag: 'svg',
                selector: 'svg-chart-container'
            })
            .style('font-family', attrs.defaultFont)
            .attr('width', attrs.svgWidth)
            .attr('height', attrs.svgHeight)

        // If tooltip content is defined
        if (tooltip) {
            // Create tip instance
            const tip = d3Tip('bar-chart')
                .offset([-10, 0])
                .attr('class', 'd3-tip')
                .html(tooltip)

            // Call tip on SVG
            svg.call(tip)

            this.setState({ tip });
        }

        // Create rounded corners mask
        const defs = svg.patternify({ tag: 'defs', selector: 'defs-element' })
        const clipPath = defs.patternify({ tag: 'clipPath', selector: 'clippath-element' })
            .attr('id', 'round-corner')
        clipPath.patternify({ tag: 'rect', selector: 'clip-rect' })
            .attr('x', 0)
            .attr('y', 0)
            .attr('rx', attrs.borderRadius || 0)
            .attr('ry', attrs.borderRadius || 0)
            .attr('width', attrs.scale.range()[1])
            .attr('height', attrs.barHeight)

        // Set d3 container
        this.setState({ d3Container: select(attrs.container) });

        //Drawing containers
        var container = svg;

        //Calculated properties
        var calc = {};
        calc.id = 'ID' + Math.floor(Math.random() * 1000000); // id for event handlings

        //Assign each data x
        calc.types = Array.from(new Set(attrs.data.values.map(d => d.type)));

        // Assign x coordinates to each bar
        attrs.data.values.filter(v => v).forEach((v, j, arr) => {
            if (j === 0) {
                v.x = 0;
            } else {
                v.x = arr[j - 1].x + attrs.scale(arr[j - 1].value)
            }
        })

        //Add container g element
        var chart = container
            .patternify({ tag: 'g', selector: 'chart' })

        // Draw rectangle group wrappers
        const rectsWrapper = chart
            .patternify({ tag: 'g', selector: 'rect-wrapper' })
            .attr('transform', `translate(${attrs.textWidth})`)

        // Draw rectangles
        const rects = rectsWrapper.patternify({ tag: 'rect', selector: 'rects', data: attrs.data.values })
            .attr('height', attrs.barHeight)
            .attr('fill', d => d.color)
            .attr('clip-path', 'url(#round-corner)')
            .attr('stroke', d => 'white')
            .attr('stroke-width', 0.1)
            .on('mouseenter.d3-tip', function (event, d) {
                const { tip } = that.getState()
                select(this).attr('fill', hcl(d.color).darker(1))
                tip.show(event, d)
            })
            .on('mouseleave.d3-tip', function (event, d) {
                const { tip } = that.getState()
                select(this).attr('fill', d.color)
                tip.hide();
            })

        // If animation disabled, set x position
        if (!attrs.animate) {
            rects
                .attr('x', d => d.x)
                .attr('width', d => Math.max(attrs.scale(d.value), 0))
        }

        // Transition rectangles to their new position
        rects.transition()
            .duration(attrs.duration)
            .delay((d, i, arr) => i / arr.length * attrs.delay)
            .attr('x', d => d.x)
            .attr('width', d => Math.max(attrs.scale(d.value), 0))
            .on('end', function (d) {
                d.prevWidth = attrs.scale(d.value);
                d.prevX = d.x;
            })

        //  Calculate optimal text  x positions and set colors
        attrs.data.values.forEach((d, i, arr) => {
            d.textY = 0;
            d.isBottom = false;
            const maxString = d.name.split(' ')
                .map(d => create('div').html(d).node().innerText)
                .sort((a, b) => a.length > b.length ? -1 : 1)[0];
            d.textWidth = textWidth(maxString, '10px');
            d.textTotalWidth = textWidth(d.name, '10px')
            const barWidth = attrs.scale(d.value);
            if (d.textWidth > barWidth - 2) {
                d.isBottom = true;
                d.textY = 20;
                d.textColor = hcl(d.color).darker()
            } else {
                const isDark = hexColorIsDark(color(d.color).hex());
                d.textColor = isDark ? 'white' : attrs.defaultTextFill;
            }
        })

        // Calculate Y positions
        attrs.data.values.forEach((d, i, arr) => {
            d.isMiddle = false;
            if (d.isBottom) {
                if (arr[i - 1] && arr[i + 1] && !arr[i - 1].isBottom && !arr[i + 1].isBottom) {
                    d.isMiddle = true;
                    d.textY = attrs.barHeight;
                }
            }
        })

        // Override text positions for some cases
        attrs.data.values.forEach(d => {
            d.isCornerLine = false;
            d.left = null;
        })
        const bottomCircles = attrs.data.values.filter(d => d.isBottom && !d.isMiddle);
        const linesCount = bottomCircles.length;
        const sidesCount = Math.round(linesCount / 2);
        bottomCircles.forEach((d, i) => {
            d.isCornerLine = true;
            if (i < sidesCount) {
                d.textIndex = i;
                d.left = true;
            } else {
                d.textIndex = sidesCount - Math.abs(sidesCount - i) - 1;
                d.left = false;
            }
            d.textY = attrs.barHeight + d.textIndex * 12
        })

        const maxX = max(bottomCircles.filter(d => !d.left), d => d.x + attrs.scale(d.value) / 2);
        const minX = min(bottomCircles.filter(d => d.left), d => d.x + attrs.scale(d.value) / 2);
        const leftMaxTextWidth = max(bottomCircles.filter(d => d.left), d => d.textTotalWidth);
        bottomCircles.filter(d => !d.left).forEach(d => d.bottomTextX = maxX + 10);
        bottomCircles.filter(d => d.left).forEach(d => d.bottomTextX = minX - 10 - leftMaxTextWidth);

        // Draw foreign object 
        const innerFor = rectsWrapper.patternify({ tag: 'foreignObject', selector: 'bar-inner-text', data: [] })
            .style('overflow', 'visible')
            .attr('width', 500)
            .attr('height', 100)
            .attr('y', d => d.textY || 0)
            .attr('x', d => {
                const x = d.prevX || d.x + 2;
                return x;
            })

        // Draw foreign object divs
        const innerTexts = innerFor.patternify({
            tag: 'xhtml:div', selector: 'fo-div', data: d => [d]
        })
            .style('font-size', 9.5 + 'px')
            .style('opacity', d => d.isCornerLine ? 0 : 1)
            .style('color', d => d.textColor)
            .style('width', d => {
                if (d.textY > 0) {
                    return 500 + 'px'
                }
                return attrs.scale(d.value) + 'px'
            })
            .html(d => {
                return `
				<div style="display: table; height: ${attrs.barHeight}px; overflow: hidden;">
 					<div style="display: table-cell; vertical-align: middle;">
 					  <div>
 					    ${d.name ? d.name : ''}
 					  </div>
 					</div>
                 </div>`
            })


        // Hide texts initially
        if (attrs.animate) {
            innerTexts.style('opacity', 0)
        }

        // Transition texts to specific x coordinates
        innerFor.transition()
            .delay(attrs.delay)
            .duration(attrs.duration)
            .attr('x', d => {
                if (d.isCornerLine) {
                    return d.bottomTextX
                }
                if (d.isMiddle) {
                    return d.x + (attrs.scale(d.value) - d.textTotalWidth) / 2
                }
                return d.x + 2;
            })

        // Make texts visible again
        innerTexts.transition()
            .delay(attrs.duration + attrs.delay)
            .duration(attrs.duration)
            .style('opacity', 1)

        // Bottom Text Link Lines
        const middleRects = rectsWrapper.patternify({ tag: 'rect', selector: 'text-link-line', data: [] })
            .attr('width', 0.5)
            .attr('height', attrs.barHeight / 2 + 10)
            .attr('y', attrs.barHeight / 2)
            .attr('x', d => (d.prevX || d.x) + (d.prevWidth || attrs.scale(d.value)) / 2)

        //  Hide middle rects initially
        if (attrs.animate) {
            middleRects.style('opacity', 0)
        }

        // Position middle rects to correct position
        middleRects.transition()
            .delay(attrs.delay)
            .duration(attrs.duration)
            .style('opacity', 1)
            .attr('x', d => d.x + attrs.scale(d.value) / 2)

        // ---- BOTTOM TEXT CORNERED LINES  ---------
        const bottomCornerLines = rectsWrapper.patternify({ tag: 'rect', selector: 'bottom-cornet-line-vertical', data: [] })
            .attr('width', 0.5)
            .attr('height', d => d.textY)
            .attr('opacity', 0)
            .attr('y', attrs.barHeight / 2)
            .attr('x', d => (d.prevX || d.x) + (d.prevWidth || attrs.scale(d.value)) / 2)
        if (attrs.animate) {
            bottomCornerLines
                .attr('x', 0)
        }

        // Position bottom corner lines
        bottomCornerLines.transition()
            .duration(attrs.duration)
            .delay(attrs.delay)
            .attr('opacity', 1)
            .attr('x', d => (d.x) + (attrs.scale(d.value)) / 2)
        const bottomHorCornerLines = rectsWrapper.patternify({ tag: 'rect', selector: 'bottom-hor-line', data: [] })
            .attr('width', d => {
                const dx = d.prevX || d.x;
                const width = (d.prevWidth || attrs.scale(d.value)) / 2;
                let result = 0;
                if (d.left) {
                    result = (dx + width) - d.bottomTextX - d.textTotalWidth - 5
                } else {
                    result = d.bottomTextX - dx - 10;
                }
                return result > 0 ? result : 0;
            })
            .attr('height', 0.5)
            .attr('opacity', 0)
            .attr('y', d => d.textY + attrs.barHeight / 2)
            .attr('x', d => {
                const dx = d.prevX || d.x;
                const width = (d.prevWidth || attrs.scale(d.value)) / 2
                if (d.left) {
                    return dx + width - ((dx + width) - d.bottomTextX - d.textTotalWidth - 5);
                } else {
                    return dx + width;
                }
            })

        if (attrs.animate) {
            bottomHorCornerLines
                .attr('x', 0)
        }
        bottomHorCornerLines.transition()
            .duration(attrs.duration)
            .delay(attrs.delay)
            .attr('width', d => {
                let result = 0
                if (d.left) {
                    result = (d.x + attrs.scale(d.value) / 2) - d.bottomTextX - d.textTotalWidth - 5
                } else {
                    result = d.bottomTextX - d.x - 14;
                }
                return Math.max(result, 0)
            })
            .attr('x', d => {
                if (d.left) {
                    return d.x + attrs.scale(d.value) / 2 - ((d.x + attrs.scale(d.value) / 2) - d.bottomTextX - d.textTotalWidth - 5);
                } else {
                    return d.x + attrs.scale(d.value) / 2;
                }
            })
            .attr('opacity', 1)

        this.reRenderOnResize();

        //#########################################  UTIL FUNCS ##################################
        function textWidth(text, fontProp) {
            var tag = document.createElement("div");
            tag.style.position = "absolute";
            tag.style.left = "-99in";
            tag.style.whiteSpace = "nowrap";
            tag.style.fontSize = fontProp;
            tag.innerHTML = text;
            document.body.appendChild(tag);
            var result = tag.clientWidth;
            document.body.removeChild(tag);
            return result;
        }

        // Utility func which checks whether color is dark 
        function hexColorIsDark(color) {
            var c = color.substring(1);      // strip #
            var rgb = parseInt(c, 16);   // convert rrggbb to decimal
            var r = (rgb >> 16) & 0xff;  // extract red
            var g = (rgb >> 8) & 0xff;  // extract green
            var b = (rgb >> 0) & 0xff;  // extract blue
            var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
            if (luma < 186) {
                return true;
            }
            return false;
        }
    };

    addPrototypeFuncs() {
        //----------- PROTOTYPE FUNCTIONS  ----------------------
        selection.prototype.patternify = function (params) {
            var container = this;
            var selector = params.selector;
            var elementTag = params.tag;
            var data = params.data || [selector];

            // Pattern in action
            var selection = container.selectAll('.' + selector).data(data, (d, i) => {
                if (typeof d === 'object') {
                    if (d.id) {
                        return d.id;
                    }
                }
                return i;
            });
            selection.exit().remove();
            selection = selection.enter().append(elementTag).merge(selection);
            selection.attr('class', selector);
            return selection;
        };
    }

    // Listen resize event and resize on change
    reRenderOnResize() {
        const {
            resizeEventListenerId,
            d3Container,
            svgWidth
        } = this.getState();
        select(window).on('resize.' + resizeEventListenerId, () => {
            const { timeoutId, transationTimeoutId } = this.getState();
            if (timeoutId) clearTimeout(timeoutId);
            if (transationTimeoutId) clearTimeout(transationTimeoutId);
            const newTimeoutId = setTimeout(d => {
                const { disableResizeTransition } = this.getState();
                const containerRect = d3Container.node().getBoundingClientRect();
                const newSvgWidth = containerRect.width > 0 ? containerRect.width : svgWidth;
                this.setState({
                    svgWidth: newSvgWidth
                });
                if (disableResizeTransition) {
                    this.setState({ transition: false })
                }
                this.render();
                this.setState({});
                const newTransationTimeoutId = setTimeout(v => {
                    this.setState({ transition: true })
                }, 500)
                this.setState({ transationTimeoutId: newTransationTimeoutId })
            }, 1000)
            this.setState({ timeoutId: newTimeoutId })
        });
    }


}