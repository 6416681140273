import React, { useState, Children } from "react"

import LabelIcon from "../Icons/LabelIcon"

import "./TableLabelButton.css"

function TableLabelButton(props) {
    const { children } = props

    const [open, toggle] = useState(false)

    const child = Children.only(children)

    function handleChildClose() {
        toggle(false)
    }

    return (
        <>
            <div className="table-label-button">
                <button
                    className={"table-label-button__button" + (open ? " active" : "")}
                    onClick={() => toggle(!open)}
                >
                    <LabelIcon fill={open && "#4e4e4e"} />
                </button>
                {open && (
                    <div className="table-label-button__content">
                        {React.cloneElement(child, { __onClose__: handleChildClose })}
                    </div>
                )}
            </div>
        </>
    )
}

export default TableLabelButton
