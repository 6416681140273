import React from "react"
// Modules
import PropTypes from "prop-types"

// Components
import RiskAlert from "./RiskAlert"

// Views

// Context providers / Utils
import {
    setNewAlert,
    setNewStackedAlert,
    updateAlert,
    updateStackedAlert,
    deleteAlert,
} from "../../../../Util/AlertsMiddleware"

// Hooks

// Material-UI *

// Styles
import "../AlertSettings.css"

const AlertContainer = (props) => {
    const { riskData, icon, alertsObj = {}, path, units, currentUser, featurePermissions } = props

    async function handleNewAlert(alertData) {
        try {
            let userToken = await currentUser.getIdToken()
            return setNewAlert(userToken, path, alertData)
        } catch (err) {
            console.log("Error setting user alert ", err)
        }
    }

    async function handleNewStackedAlert(alertData) {
        try {
            let userToken = await currentUser.getIdToken()
            return setNewStackedAlert(userToken, path, alertData)
        } catch (err) {
            console.log("Error setting user alert ", err)
        }
    }

    async function handleActiveAlerChange(isAlertActive, mPath) {
        try {
            let userToken = await currentUser.getIdToken()
            return updateAlert(userToken, mPath, { active: isAlertActive })
        } catch (err) {
            console.log("Error updating user alert ", err)
        }
    }

    async function handleUpdateAlert(alertData, mPath) {
        try {
            let userToken = await currentUser.getIdToken()
            return updateAlert(userToken, mPath, alertData)
        } catch (err) {
            console.log("Error updating user alert ", err)
        }
    }

    async function handleUpdateStackedAlert(alertData, mPath) {
        try {
            let userToken = await currentUser.getIdToken()
            return updateStackedAlert(userToken, mPath, alertData)
        } catch (err) {
            console.log("Error setting user alert ", err)
        }
    }

    async function handleDeleteAlert(idAlert, mPath) {
        try {
            mPath = { ...mPath, alert_id: idAlert }
            let userToken = await currentUser.getIdToken()
            return deleteAlert(userToken, mPath)
        } catch (err) {
            console.log("Error deleting user alert ", err)
        }
    }

    return (
        <>
            <RiskAlert
                riskData={riskData}
                icon={icon}
                alertsObj={alertsObj}
                path={path}
                units={units}
                currentUser={currentUser}
                featurePermissions={featurePermissions}
                onAlertAdd={handleNewAlert}
                onStackedAlertAdd={handleNewStackedAlert}
                onAlertActiveChange={handleActiveAlerChange}
                onUpdateAlert={handleUpdateAlert}
                onUpdateStackedAlert={handleUpdateStackedAlert}
                onDeleteAlert={handleDeleteAlert}
            />
        </>
    )
}

RiskAlert.propTypes = {
    children: PropTypes.node,
}

export default AlertContainer
