import React, { useEffect, useState } from "react"

import "./UINotification.css"

function UINotification(props) {
    const { visible, text, onClose } = props

    // TODO this behaviour could become a custom hook lol

    const [className, setClassName] = useState("")
    const [active, toggle] = useState(false)

    // this is how much the notification will display
    const NOTIFICATION_ACTIVE_TIME = 2000
    // how much the animation takes to exit
    const NOTIFICATION_EXIT_ANIMATION_DURATION = 200

    useEffect(() => {
        if (visible) {
            // notification active time (duration)
            setTimeout(() => {
                // after that, close notification
                onClose()
            }, NOTIFICATION_ACTIVE_TIME)
        }
    }, [visible])

    useEffect(() => {
        console.log("visible", visible)
        if (!visible) {
            setClassName("exit")
            setTimeout(() => {
                setClassName("")
                toggle(false)
            }, NOTIFICATION_EXIT_ANIMATION_DURATION)
        } else {
            toggle(true)
        }
    }, [visible])

    if (!active) return null

    return (
        <>
            <div className="ui-notification__container">
                <div className={"ui-notification " + className}>{text}</div>
            </div>
        </>
    )
}

export default React.memo(UINotification)
