import React from "react"

function PublishIcon(props) {
    const { alerts, fill } = props

    return (
        <>
            <span className="alert-icon">
                <svg width="100%" height="100%" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.5 13.75C8.1875 13.75 8.75 13.1875 8.75 12.5H6.25C6.25 13.1875 6.80625 13.75 7.5 13.75ZM11.25 10V6.875C11.25 4.95625 10.225 3.35 8.4375 2.925V2.5C8.4375 1.98125 8.01875 1.5625 7.5 1.5625C6.98125 1.5625 6.5625 1.98125 6.5625 2.5V2.925C4.76875 3.35 3.75 4.95 3.75 6.875V10L2.5 11.25V11.875H12.5V11.25L11.25 10Z"
                        fill={fill || "#323232"}
                    />
                </svg>
                {alerts && <label className="alert-icon__indicator">{alerts}</label>}
            </span>
        </>
    )
}

export default PublishIcon
