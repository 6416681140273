import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={8} height={9} viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10 0c6.667 8.526 10 14.592 10 18.2a9.63 9.63 0 01-.761 3.75 9.79 9.79 0 01-2.168 3.18 10.015 10.015 0 01-3.244 2.124 10.177 10.177 0 01-7.654 0A10.015 10.015 0 012.93 25.13 9.789 9.789 0 01.76 21.95 9.63 9.63 0 010 18.2C0 14.592 3.333 8.526 10 0z"
                fill="#FFF"
            />
        </svg>
    )
}

export default SvgComponent
