export const RISKS = {
    risk_heat_stress: {
        title: "Heat Stress Risk",
        modifier: "max",
        variable: "temperature",
        conditional: "greater than",
        units: "C",
        isGDD: false,
    },
    risk_cold: {
        title: "Cold Risk",
        modifier: "min",
        variable: "temperature",
        conditional: "less than",
        units: "C",
        isGDD: false,
    },
    risk_insufficient_high_temp: {
        title: "Insufficient High Temperature Risk",
        modifier: "max",
        variable: "temperature",
        conditional: "less than",
        units: "C",
        isGDD: false,
    },
    risk_insufficient_low_temp: {
        title: "Insufficient Chill Temperature Risk",
        modifier: "min",
        variable: "temperature",
        conditional: "greater than",
        units: "C",
        isGDD: false,
    },
    risk_flood: {
        title: "Precipitation Risk",
        modifier: "mean",
        variable: "precipitation",
        conditional: "greater than",
        units: "mm",
        isGDD: false,
    },
    risk_cumulative_flood: {
        title: "Cumulative Precipitation Risk",
        modifier: "sum",
        variable: "precipitation",
        conditional: "greater than",
        units: "mm",
        isGDD: false,
        aggregated: true,
    },
    risk_drought: {
        title: "Drought Risk",
        modifier: "mean",
        variable: "precipitation",
        conditional: "less than",
        units: "mm",
        isGDD: false,
    },
    risk_cumulative_drought: {
        title: "Cumulative Drought Risk",
        modifier: "sum",
        variable: "precipitation",
        conditional: "less than",
        units: "mm",
        isGDD: false,
        aggregated: true,
    },
    risk_dry_soil: {
        title: "Dry Soil Risk",
        modifier: "mean",
        variable: "soil moisture",
        conditional: "less than",
        units: "mm/m^3",
        isGDD: false,
    },
    risk_wet_soil: {
        title: "Wet Soil Risk",
        modifier: "mean",
        variable: "soil moisture",
        conditional: "greater than",
        units: "mm/m^3",
        isGDD: false,
    },
    risk_low_soil_temperature: {
        title: "Low Soil Temperature Risk",
        modifier: "mean",
        variable: "soil temperature",
        conditional: "less than",
        units: "%",
        isGDD: false,
    },
    risk_high_soil_temperature: {
        title: "High Soil Temperature Risk",
        modifier: "mean",
        variable: "soil temperature",
        conditional: "greater than",
        units: "C",
        isGDD: false,
    },
    risk_low_humidity: {
        title: "Low Humidity Risk",
        modifier: "mean",
        variable: "humidity",
        conditional: "less than",
        units: "%",
        isGDD: false,
    },
    risk_high_humidity: {
        title: "High Humidity Risk",
        modifier: "mean",
        variable: "humidity",
        conditional: "greater than",
        units: "%",
        isGDD: false,
    },
    risk_growing_degree_days: {
        title: "Growing Degree Days",
        modifier: "sum",
        variable: "GDD",
        conditional: "less than",
        units: "C",
        isGDD: true,
    },
    risk_pest_degree_days: {
        title: "Pest Degree Days",
        modifier: "sum",
        variable: "PDD",
        conditional: "greater than",
        units: "C",
        isGDD: true,
    },
}

export const STACKED_RISKS = {
    risk_cold_and_damp: {
        title: "Cold & Damp",
        risks: ["risk_cold", "risk_high_humidity"],
        stacked: true,
    },
    risk_specific_temperature_range: {
        title: "Specific Temperature Range",
        risks: ["risk_insufficient_high_temp", "risk_insufficient_low_temp"],
        stacked: true,
    },
}
