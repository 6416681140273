import {
    select,
    selection,
    min,
    max,
    schemeCategory10,
    scaleTime,
    scaleLinear,
    axisBottom,
    axisLeft,
    curveMonotoneX,
    area,
    pointer,
    zoomIdentity,
    selectAll,
    zoom,
} from "d3";
//import { tip as d3Tip } from "d3-v6-tip";
import { interpolatePath } from "d3-interpolate-path";
import { lineChunked } from "d3-line-chunked";
import d3Tip from '../../../components/Charts/D3Tip/d3-tip.js'
import { timeline } from '../../../components/Charts/D3Timeline/index'


/**
* ```javascript
* {
*   start: new Date('Jan 7, 2021'),
*   end: new Date('Jan 12, 2021'),
*   label: `Alert`
* }
* ```
* @typedef {object} ShadedRangesDataItem - Single shaded ranges data item
* @property {date} start - Start date of range
* @property {date} end - End date of range
* @property {string} label - Label of range (displayed above the range rectangle), it has html support, so icons can be included
*/


/**
* ```javascript
* {
*    x: new Date('1 Jan 2021'),
*    y: 10   
* }
* ```
* @typedef {object} LineDataPoint - Chart line data point
* @property {date} x - Date value of point
* @property {number} y - number value of point
*/

/**
* ```javascript
* {
*    x: new Date('1 Jan 2021'),
*    y0:20,
*    y: 100   
* }
* ```
* @typedef {object} AreaDataPoint - Chart area data point
* @property {date} x - Date value of point
* @property {number} y0 - Min number value of point
* @property {number} y - Max number value of point
*/

/**
* ```javascript
* {
*    type:'line',
*    points: [
*      {x: new Date('1 Jan 2021'),  y: 100 },  
*      {x: new Date('31 Dec 2021'), y: 100 }
*    ]  
* }
* ```
* @typedef {object} LineData - Chart line data item
* @property {string} type - String value of type - equals to `line`
* @property {LineDataPoint[]} points - Line data point objects
*/

/**
* ```javascript
* {
*    type:'area',
*    points: [
*      {x: new Date('1 Jan 2021'),  y0:20, y: 100 },  
*      {x: new Date('31 Dec 2021'), y0:20, y: 100 }
*    ]  
* }
* ```
* @typedef {object} AreaData - Chart area data item
* @property {string} type - String value of type - equals to `area`
* @property {AreaDataPoint[]} points - Area data point objects
*/

/**
* Data passed for drawing areas and lines
* ```javascript
* [
*    {
*      type:'area',
*      points: [
*        {x: new Date('1 Jan 2021'),  y0:20, y: 100 },  
*        {x: new Date('31 Dec 2021'), y0:20, y: 100 }
*      ]  
*    },
*    {
*       type:'line',
*       points: [
*         {x: new Date('1 Jan 2021'),  y: 80 },  
*         {x: new Date('31 Dec 2021'), y: 80 }
*       ]  
*    }
* ]
* ``` 
* @typedef {Array<AreaData | LineData>} ChartData - Chart data structure
 */

/**
* Data passed for displaying shaded ranges
* ```javascript
*[{
*   start: new Date('Jan 7, 2021'),
*   end: new Date('Jan 12, 2021'),
*   label: `Alert`
*}]
* ``` 
* @typedef {Array<ShadedRangesDataItem>} ShadedRangesData - Chart data structure
*/


/**
 *  Line Area Chart Javascript Component. for initialization use   
 * 
 *`const chart = new LineAreaChart()`
 *
 * @export
 * @class LineAreaChart
 */
export class LineAreaChart {
    /**
     * Returns the current state of line area chart component   
     * 
     *`const {svgHeight} = chart.getState()`
     * @return {state}
     * @memberof LineAreaChart
     */
    getState() {
        return this.state;
    }

    /**
     * Extends the current chart state 
     *
     *`chart.setState({svgHeight:400})`
     * 
     * @param {Object} subState - State subObject, which will extend chart state
     * @return {state} 
     * @memberof LineAreaChart
     */
    setState(d) {
        return Object.assign(this.state, d)
    };

    // Internal method to for creating unique enough values for different purposes
    createId() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    // Constructs new instance and state with default values
    constructor() {
        // d3-tip css
        document.getElementsByTagName("head")[0].insertAdjacentHTML(
            "beforeend",
            `<style>.d3-tip tr{border-bottom:none}.d3-tip{font-family:Arial,Helvetica,sans-serif;line-height:1.4;padding:10px;pointer-events:none!important;color:#203d5d;box-shadow:0 4px 20px 4px rgba(0,20,60,.1),0 4px 80px -8px rgba(0,20,60,.2);background-color:#fff;border-radius:4px}.d3-tip:after{box-sizing:border-box;display:inline;font-size:10px;width:100%;line-height:1;color:#fff;position:absolute;pointer-events:none}.d3-tip.n:after{content:"▼";margin:-1px 0 0 0;top:100%;left:0;text-align:center}.d3-tip.e:after{content:"◀";margin:-4px 0 0 0;top:50%;left:-8px}.d3-tip.s:after{content:"▲";margin:0 0 1px 0;top:-8px;left:0;text-align:center}.d3-tip.w:after{content:"▶";margin:-4px 0 0 -1px;top:50%;left:100%}</style>`)

        // Define state variables
        const state = {
            id: this.createId(),
            resizeEventListenerId: this.createId(),
            svgWidth: 400,
            svgHeight: 500,
            marginTop: 35,
            marginBottom: 35,
            marginRight: 10,
            marginLeft: 55,
            container: 'body',
            duration: 500,
            defaultTextFill: '#2C3E50',
            defaultFont: 'Helvetica, Sans-Serif',
            ctx: document.createElement('canvas').getContext('2d'),
            data: null,
            groups: null,
            dimensions: null,
            gridView: true,
            scaleX: null,
            scaleY: null,
            xAxis: null,
            yAxis: null,
            firstXTick: null,
            secondXTick: null,
            lastXTick: null,
            firstYTick: null,
            lastYTick: null,
            xLeftOffset: 0.01,
            xRightOffset: 0.01,
            yTopOffset: 0.1,
            yBottomOffset: 0.9,
            xDateMax: null,
            xDateMin: null,
            yValueMax: null,
            yValueMin: null,
            lineShadows: false,
            dashedLineDasharray: '6 6',
            disableResizeTransition: true,
            centerTicks: false,
            xTicksCount: 30,
            yTicksCount: null,
            xTickFormat: null,
            yTickFormat: null,
            transition: true,
            title: "",
            titleHover: null,
            labelX: null,
            labelY: null,
            labelYFontSize: 16,
            titleLabelFontSize: 16,
            shadedRanges: null,
            minShadedRangesRectWidth: 15,
            titleLabelOffsetX: 0,
            titleLabelOffsetY: 0,
            labelYOffsetX: 0,
            zeroBasis: true,
            onChartMouseLeave: d => d,
            dropShadowId: 'drop-shadow',
            staticTipXPosition: null,
            tipOffsetY: 0,
            tooltip: (EVENT, { key, values, colors }, state) => {
                return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                     <tr><td style="font-weight:bold;font-size:20px" rowspan="${values.length}"><div style="text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(undefined, {
                    day: "numeric",
                    month: "short"
                })}</div></td> 
                         <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0] * 10) / 10}</td>
                     </tr>
                     ${values.filter((d, i) => i > 0).map((value, i) => {
                    return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${Math.round(value * 10) / 10}</td></tr>`
                }).join('')}
                    
              </table>`
            },
            colors: ['#F3B52F', '#F4713D', '#663F59', '#6A6E93', '#4C88B2', '#01A6C4', '#04D8D7', '#73F3E4'].concat(schemeCategory10).concat(['#D34A7C']),
            // This function takes care of different kind of data formatting setting
            setData: state => {

                // If we don't have crossfilter group, set normal data
                if (!state.groups) {

                    // If passed data is not array, save it as array
                    if (!Array.isArray(state.data)) {
                        return [state.data]
                    };

                    // If passed data is array, just return it
                    return state.data;
                };

                // If we have crossfilter groups in place, derive normal data from it
                const groups = state.groups;
                let result = [];

                // Attach points to crossfilter groups (usually line chart type) {points:[{key,value}]}
                result = result.concat(groups
                    .filter(d => d.crossfilterGroup)
                    .map(d => Object.assign(d, {
                        points: d.crossfilterGroup.all()
                    }))
                )

                // Assemble area chart data  in following form {points:[{key,max,min}]}
                result = result.concat(groups
                    .filter(d => d.crossfilterGroupMin &&
                        d.crossfilterGroupMax)
                    .map(d => {
                        const maxes = d.crossfilterGroupMax.all();
                        const mins = d.crossfilterGroupMin.all();
                        return Object.assign(d, {
                            points: maxes.map((d, i) => ({
                                key: d.key,
                                max: d.value,
                                min: mins[i].value
                            }))
                        })
                    })
                )

                // If directly crossfilter group was passed, treat it as line chart
                result = result.concat(groups.filter(group => {
                    return !(group.crossfilterGroup ||
                        group.crossfilterGroupMin ||
                        group.crossfilterGroupMax)
                })
                    .map(g => g.all())
                    .map(points => ({
                        points: points,
                        type: 'line',
                    }))
                )
                return result;
            }
        };

        // Save state
        this.state = state;

        // Define handful d3 enter, exit, update pattern method
        this.initializeEnterExitUpdatePattern();
    }

    /**
    * Sets title hover
    * 
    *`chart.titleHover('title hover tip message')`
    *
    * @param {string} titleHover - What text will be displayed upon hover over title
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    titleHover(titleHover) {
        this.setState({
            titleHover
        });
        return this;
    }

    /**
    * Passes shaded ranges data
    * 
    *```javascript  
    *chart.shadedRanges([{
    *   start: new Date('Jan 7, 2021'),
    *   end: new Date('Jan 12, 2021'),
    *   label: `Alert`
    *}])
    *```
    *
    * @param {ShadedRangesData} shadedRanges - Shaded ranges data items array
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    shadedRanges(shadedRanges) {
        this.setState({
            shadedRanges
        });
        return this;
    }

    /**
     * Moves title label vertically in pixels
     * 
     *`chart.titleLabelOffsetY(10)`
     *
     * @param {number} titleLabelOffsetY - How many pixels will label be moved vertically
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    titleLabelOffsetY(titleLabelOffsetY) {
        this.setState({
            titleLabelOffsetY
        });
        return this;
    }

    /**
     * Moves title label horizontally in pixels
     * 
     *`chart.titleLabelOffsetX(-10)`
     *
     * @param {number} titleLabelOffsetX -  How many pixels will label be moved horizontally
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    titleLabelOffsetX(titleLabelOffsetX) {
        this.setState({
            titleLabelOffsetX
        });
        return this;
    }

    /**
     * Set title label font size in pixels
     * 
     *`chart.titleLabelFontSize(13)`
     *
     * @param {number} titleLabelFontSize - How many pixels title label font size will be
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    titleLabelFontSize(titleLabelFontSize) {
        this.setState({
            titleLabelFontSize
        });
        return this;
    }

    /**
     * Moves y label horizontally in px
     * 
     *`chart.labelYOffsetX(10)`
     *
     * @param {number} labelYOffsetX - How many px will y axis label move, horizontally
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    labelYOffsetX(labelYOffsetX) {
        this.setState({
            labelYOffsetX
        });
        return this;
    }

    /**
    * Set whether the chart should have evenly distributed grids
    * 
    *`chart.gridView(true)`
    *
    * @param {boolean} gridView - Allow or disable gridview
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    gridView(gridView) {
        this.setState({
            gridView
        });
        return this;
    }

    /**
    * If the argument is passed, activates static tip at a passed date
    * 
    *`chart.staticTipXPosition(new Date('20 Dec, 2020'))`
    *
    * @param {date} staticTipXPosition - Position, at which static tip will be displayed
    * @return {chartInstance} chart 
    * @memberof LineAreaChart
    */
    staticTipXPosition(staticTipXPosition) {
        this.setState({
            staticTipXPosition
        });
        return this;
    }

    /**
    * Pass svg mouse leave event handler function
    * 
    *`chart.onChartMouseLeave(()=>console.log('mouse leave happened'))`
    *
    * @param {function} mouseLeaveHandler - Function, to handle mouse leave event
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    onChartMouseLeave(onChartMouseLeave) {
        this.setState({
            onChartMouseLeave
        });
        return this;
    }

    /**
     * Sets proportional RIGHT side offset margin between chart and data bounds. 
     * 
     *`chart.xRightOffset(0.1)`  // Data will take 90% of the chart space and will be left aligned
     *
     * @param {number} xRightOffset - How much x axis will offset from the right side. if 0.5 is passed, data will be shown in the first half of axis
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    xRightOffset(xRightOffset) {
        this.setState({
            xRightOffset
        });
        return this;
    }

    /**
     * Sets proportional LEFT side offset margin between chart and data bounds. 
     * 
     *`chart.xLeftOffset(0.1)`  // Data will take 90% of the chart space and will be right aligned
     *
     * @param {number} xLeftOffset - How much x axis will offset from the left side. if 0.5 is passed, data will be shown in the second half of axis
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    xLeftOffset(xLeftOffset) {
        this.setState({
            xLeftOffset
        });
        return this;
    }

    /**
     * Sets container for the graph. Takes raw DOM element or CSS selector as an input
     * 
     *`chart.container('div.chart-container')`  
     *
     * @param {string|DomElement} container - CSS selector string or dom element object, in which SVG graph will be drawn
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    container(container) {
        this.setState({
            container
        });
        return this;
    }

    /**
    * Sets maximum y value for y axis, useful, when we don't want axis to be data based.
    * For example, when we want to show 100% percent scale on y axis
    * 
    *`chart.yValueMax(100)`  
    *
    * @param {number} yValueMax - Upper bound of y axis , if skipped it will be based on the data
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    yValueMax(yValueMax) {
        this.setState({
            yValueMax
        });
        return this;
    }

    /**
    * Sets maximum date value fox x axis, useful when we want to show strict right bound on x axis;
    * 
    *`chart.xDateMax(new Date('31 Dec 2021'))`  
    *
    * @param {date} xDateMax - Right bound of x axis. If skipped, it will be determined from data (Taking right offset proportion into account as well)
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    xDateMax(xDateMax) {
        this.setState({
            xDateMax
        });
        return this;
    }

    /**
    * Sets minimum date value fox x axis, useful when we want to show strict left bound on x axis;
    * 
    *`chart.xDateMin(new Date('1 Jan 2021'))`  
    *
    * @param {date} xDateMin - Left bound of x axis. If skipped, it will be determined from data (Taking left offset proportion into account as well)
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    xDateMin(xDateMin) {
        this.setState({
            xDateMin
        });
        return this;
    }

    /**
    * Sets data for the chart. It takes data in the following format
    * ```javascript
    * [
    *   {type:'line',points:[
    *        {x:date1,y:value1},
    *        {x:date2,y:value2} ]
    *   },
    *   {type:'area',points:[
    *        {x:date1,y0:valueMin,y:valueMax},
    *        {x:date2,y0:valueMin1,y:valueMax1}]
    *   },
    * ]
    * ```
    * Where the type is either `line` or  `area`, `x` is a date and `y0,y` are number values.  
    * 
    * Data also can be a crossfilter dimension, but crossfiltering is not implemented, 
    * so, there is no point in using crossfilter format.
    * 
    * 
    * Sample Usage
    *```javascript
    *chart.data([
    *   {type:'line', points:[
    *        { x: new Date('10 Jan 2021'), y: 20 },
    *        { x: new Date('31 Dec 2021'), y: 40 } ]
    *   },
    *   {type:'area', points:[
    *        {x: new Date('10 Jan 2021'), y0: 15, y: 25},
    *        {x: new Date('31 Dec 2021'), y0: 35, y: 45}]
    *   },
    *])
    *```
    * @param {ChartData} data - Array of line and area data items
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    data(data) {
        this.setState({
            data
        });
        return this;
    }

    /**
    * Sets whether tick texts should be centered between tick lines
    * 
    *`chart.centerTicks(true)`
    *
    * @param {boolean} centerTicks - Flag, which will center axis texts between tick lines
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    centerTicks(centerTicks) {
        this.setState({
            centerTicks
        });
        return this;
    }

    /**
    * Custom y tick formatting function. Passed function will receive `y` tick number value as an argument
    * 
    *`chart.yTickFormat( d =>  d+' %')`
    *
    * @param {function} yTickFormatterFunction - Function, which formats  `y` tick value, passed as an argument
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    yTickFormat(yTickFormat) {
        this.setState({
            yTickFormat
        });
        return this;
    }


    /**
    * Custom x tick formatting function. Passed function will receive `x` tick date value as an argument
    * 
    *`chart.xTickFormat( d => d.getFullYear())`
    *
    * @param {function} xTickFormat - Function, which formats  `x` tick value, passed as an argument
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    xTickFormat(xTickFormat) {
        this.setState({
            xTickFormat
        });
        return this;
    }

    /**
    * Set xTicksCount number, which will suggest d3 engine to produce approximately same number of ticks for `y` axis
    * 
    *`chart.xTicksCount(4)`
    *
    * @param {number} xTicksCount - Preferred x axis ticks count
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    xTicksCount(xTicksCount) {
        this.setState({
            xTicksCount
        });
        return this;
    }

    /**
    * Set yTicksCount number, which will suggest d3 engine to produce approximately same number of ticks for `y` axis
    * 
    *`chart.yTicksCount(4)`
    *
    * @param {number} yTicksCount - Preferred y axis ticks count
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    yTicksCount(yTicksCount) {
        this.setState({
            yTicksCount
        });
        return this;
    }

    /**
    * Sets resize event listener. It's preferred to pass it externally, in order to not  register too many event handlers for the same event when redrawing happens (Which happens a lot)
    * 
    *`chart.resizeEventListenerId('weather-temp-chart')`
    *
    * @param {string | number} resizeEventListenerId - id, which will be used to minimize event handling function binding
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    resizeEventListenerId(resizeEventListenerId) {
        this.setState({
            resizeEventListenerId
        });
        return this;
    }

    /**
    * Sets title label for chart
    * 
    *`chart.title('Precipitation')`
    *
    * @param {string} title - Chart top text title
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    title(title) {
        this.setState({
            title
        });
        return this;
    }


    /**
    * Sets whether y axis should be zero based. Usually we want it to be, but sometimes we don't, 
    * for example if we wan't to show yearly temperature  in Canada 
    * 
    *`chart.zeroBasis(true)`
    *
    * @param {boolean} zeroBasis - Flag, which will set y axis min value to be zero based or  min value from data based
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    zeroBasis(zeroBasis) {
        this.setState({
            zeroBasis
        });
        return this;
    }

    /**
     * Sets proportional BOTTOM side offset margin between chart y axis and data bounds. 
     * 
     *`chart.yBottomOffset(0.1)`  // Data will take 90% of the chart space and will be top aligned
     *
     * @param {number} yBottomOffset - How much y axis will offset from the bottom side. if 0.5 is passed, data will be shown in the first half of y axis (Unless zero basis is set)
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    yBottomOffset(yBottomOffset) {
        this.setState({
            yBottomOffset
        });
        return this;
    }

    /**
     * Sets proportional TOP side offset margin between chart y axis and data bounds. 
     * 
     *`chart.yTopOffset(0.1)`  // Data will take 90% of the chart space and will be bottom aligned
     *
     * @param {number} yTopOffset - How much y axis will offset from the top side. if 0.5 is passed, data will be shown in the second half of y axis 
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    yTopOffset(yTopOffset) {
        this.setState({
            yTopOffset
        });
        return this;
    }

    /**
     * Sets chart x axis label value
     * 
     *`chart.labelX('Date')` 
     *
     * @param {string} labelX - Text value of x Label
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    labelX(labelX) {
        this.setState({
            labelX
        });
        return this;
    }


    /**
    * Sets chart y axis label value
    * 
    *`chart.labelY('Temp in C')` 
    *
    * @param {string} labelY - Text value of y axis Label
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    labelY(labelY) {
        this.setState({
            labelY
        });
        return this;
    }

    /**
    * Sets the font size of y axis label
    * 
    *`chart.labelYFontSize(16)` 
    *
    * @param {string} labelYFontSize - Pixel font size of y axis label
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    labelYFontSize(labelYFontSize) {
        this.setState({
            labelYFontSize
        });
        return this;
    }

    /**
    * Sets the y offset value of static tip 
    * 
    *`chart.tipOffsetY(-10)` // Static tip will get displayed 10px above previous location
    *
    * @param {string} tipOffsetY - Pixel value which will move static tip vertically
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    tipOffsetY(tipOffsetY) {
        this.setState({
            tipOffsetY
        });
        return this;
    }

    /**
    * Sets the default tooltip body content
    * 
    *```javascript
    * chart.tooltip((EVENT, { key, values, colors }, state)=>{
    *    return  `<div>
    *                 Passed Values Length: ${values.length} <br/>
    *                 Hovered date: ${key} <br/>
    *                 Corresponding Colors : ${colors} <br/>
    *                 Current Event : ${EVENT} </br>
    *                 Current App State : ${state}
    *             </div>`
    * })
    * ``` 
    *
    * @param {function} tooltip - Function, which generates tip content. It receives three arguments: 
    * **EVENT** - current event .
    * 
    * **{key, values, colors}** 
    * 
    * **key** - is hovered date  .
    * 
    * **values** - are corresponding line points . 
    * 
    * **colors** - are corresponding point colors . 
    * 
    * **state** - is current chart state.
    * 
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    tooltip(tooltip) {
        this.setState({
            tooltip
        });
        return this;
    }

    /**
    * Sets svg (actual chart content) height in pixels
    * 
    *`chart.svgHeight(400)`
    *
    * @param {number} svgHeight - Height number value in pixels
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    svgHeight(svgHeight) {
        this.setState({
            svgHeight
        });
        return this;
    }

    /**
    * Sets svg (actual chart content) width in pixels. Usually gets overriden if width can be extracted from container
    * 
    *`chart.svgWidth(400)`
    *
    * @param {number} svgWidth - Width number value in pixels
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    svgWidth(svgWidth) {
        this.setState({
            svgWidth
        });
        return this;
    }

    /**
    * Sets left margin for chart in pixels. Usually it is used to make space for y axis
    * 
    *`chart.marginLeft(400)`
    *
    * @param {number} marginLeft - Set margin left value for chart content in pixels
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    marginLeft(marginLeft) {
        this.setState({
            marginLeft
        });
        return this;
    }

    /**
     * Sets top margin for chart in pixels. Usually it is used to make space for shaded ranges
     * 
     *`chart.marginTop(400)`
     *
     * @param {number} marginTop - Set margin top value for chart content in pixels
     * @return {chartInstance} chart
     * @memberof LineAreaChart
     */
    marginTop(marginTop) {
        this.setState({
            marginTop
        });
        return this;
    }

    /**
    * Returns SVG dom element for chart
    * 
    *`const svgNode = chart.getSvgRef()`
    * @return {DOMElement}
    * @memberof LineAreaChart
    */
    getSvgRef() {
        const { svg } = this.getState();
        return svg.node();
    }

    // Expose dimension setting
    dimension(dimensions) {
        if (Array.isArray(dimensions)) {
            this.setState({
                dimensions
            });
        } else if (dimensions) {
            this.setState({
                dimensions: [dimensions]
            });
        }
        return this;
    }

    // Expose crossfilter group setting
    group(groups) {
        if (Array.isArray(groups)) {
            this.setState({
                groups
            });
        } else if (groups) {
            this.setState({
                groups: [groups]
            });
        } else {
            this.setState({ groups: null });
        }
        return this;
    }

    // Define enter exit update pattern shorthand
    initializeEnterExitUpdatePattern() {
        selection.prototype.pattr = function (attribute, value, defaultProperty) {
            const container = this;
            container.attr(attribute, function (d, i, arr) {
                if (defaultProperty && d[defaultProperty] !== undefined) return d[defaultProperty];
                if (d[attribute] !== undefined) return d[attribute];
                if (typeof value === 'function') return value(d, i, arr);
                return value;
            })
            return this;
        }
        selection.prototype.patternify = function (params) {
            const container = this;
            const selector = params.selector;
            const elementTag = params.tag;
            const data = params.data || [selector];

            // Pattern in action
            let selection = container.selectAll('.' + selector).data(data, (d, i) => {
                if (typeof d === 'object' && d.id) return d.id;
                return i;
            });
            selection.exit().remove();
            selection = selection.enter().append(elementTag).merge(selection);
            selection.attr('class', selector);
            return selection;
        };
    }

    /**
    * (Re)renders visualization
    * 
    *`chart.render()`
    *
    * @return {chartInstance} chart
    * @memberof LineAreaChart
    */
    render() {

        // Define how data will be set
        this.setDataProp();

        // Define containers and set SVG width based on container size
        this.setDynamicContainer();

        // Calculate some properties
        this.calculateProperties();

        // Create chart scales
        this.createScales();

        // Draw SVG and its wrappers
        this.drawSvgAndWrappers();

        // Create drop shadows
        this.createShadowsAndGradients();

        // Invoke reusable chart redraw method
        this.redraw();

        // Attach interactions (tooltip, hover line)
        this.attachInteractionElements();

        // Attach zooming behavior to chart
        this.attachZooming();

        // listen for resize event and reRender accordingly
        this.reRenderOnResize();

        // Allow chaining
        return this;
    }

    // Reusable redraw method
    redraw() {
        // Create chart axises
        this.createAxises();

        // Draw horizontal and vertical axises
        this.drawAxises();

        // Draw area shapes
        this.drawAreas();

        // Draw range
        this.drawShadedRanges();

        // Draw line shapes
        this.drawLines();
    }

    // Create chart scales
    createScales() {
        const {
            data,
            calc,
            xLeftOffset,
            xRightOffset,
            yBottomOffset,
            yTopOffset,
            xDateMax,
            xDateMin,
            yValueMax,
            yValueMin,
            zeroBasis
        } = this.getState();
        const {
            chartWidth,
            chartHeight,
            dateMin,
            dateMax
        } = calc;

        // Retrieve min, max dates and differences
        const diffX = dateMax - dateMin;

        // Calculate x domain min and max values (from where x axis ranges)
        let domainXMin = new Date(dateMin - diffX * xLeftOffset);
        let domainXMax = new Date(+dateMax + diffX * xRightOffset);

        if (xDateMax) domainXMax = xDateMax;
        if (xDateMin) domainXMin = xDateMin;

        // Create x scale
        const scaleX = scaleTime()
            .domain([domainXMin, domainXMax])
            .range([0, chartWidth]);
        const zoomedX = scaleX;

        // Retrieve min, max values and differences
        const valueMax = max(data, gr => max(gr.points, d => {
            if (d.value !== undefined) return d.value;
            if (d.max !== undefined) return d.max;
            if (d.min !== undefined) return d.min;
        }));
        const valueMin = min(data, gr => min(gr.points, d => {
            if (d.value !== undefined) return d.value;
            if (d.min !== undefined) return d.min;
            if (d.max !== undefined) return d.max;
        }));
        const diffY = valueMax - valueMin;

        // Calculate domain min and max values
        let domainYMin = zeroBasis ? 0 : valueMin - diffY * yBottomOffset;
        let domainYMax = valueMax + diffY * yTopOffset;

        if (yValueMax) domainYMax = yValueMax;
        if (yValueMin) domainYMin = yValueMin;

        // Create Y sca;e
        const scaleY = scaleLinear()
            .domain([domainYMax, domainYMin])
            .range([0, chartHeight]);
        const zoomedY = scaleY;

        // Save scales into state   
        this.setState({
            scaleX,
            scaleY,
            zoomedY,
            zoomedX
        });
    }

    // Create chart axises
    createAxises() {
        const {
            calc,
            xTicksCount,
            yTicksCount,
            xTickFormat,
            yTickFormat,
            zoomedX,
            zoomedY,
            gridView
        } = this.getState();

        // Retrieve chart width and height
        const { chartWidth, chartHeight } = calc;

        // Retrieve allticks
        const xTicks = zoomedX.ticks(xTicksCount);

        // Get first and last x ticks
        const firstXTick = xTicks[0];
        const secondXTick = xTicks[1];
        const lastXTick = xTicks[xTicks.length - 1];

        // Retrieve all y ticks
        const yTicks = zoomedY.ticks(yTicksCount);

        // Get first and last y tick
        const firstYTick = yTicks[0];
        const lastYTick = yTicks[yTicks.length - 1];

        // Create x axis
        const xAxis = axisBottom(zoomedX)
            .ticks(xTicksCount)
            .tickFormat(xTickFormat)
            .tickSize(-chartHeight)

        // Create y axis
        const yAxis = axisLeft(zoomedY)
            .ticks(yTicksCount)
            .tickFormat(yTickFormat)
            .tickSize(-chartWidth)

        // If gridXView  enabled, correct grid line sizes
        if (gridView) {
            xAxis.tickSize(-(zoomedY(lastYTick) - zoomedY(firstYTick)))
            yAxis.tickSize(-(zoomedX(lastXTick) - zoomedX(firstXTick)));
        }
        // Save axises and tick values into state
        this.setState({
            xAxis, yAxis, firstXTick, secondXTick, lastXTick, firstYTick, lastYTick
        });
    }

    // Draw axises
    drawAxises() {
        const {
            xAxis,
            yAxis,
            chart,
            firstXTick,
            secondXTick,
            lastYTick,
            calc,
            labelX,
            labelY,
            labelYFontSize,
            transition,
            svg,
            marginLeft,
            title,
            centerTicks,
            zoomedX,
            zoomedY,
            duration,
            data,
            gridView,
            labelYOffsetX,
            tip,
            titleLabelFontSize,
            titleLabelOffsetX,
            titleLabelOffsetY,
            titleHover,
            tooltip
        } = this.getState();
        const {
            chartHeight,
            chartWidth
        } = calc;

        // Draw title
        const titleLabel = svg.patternify({ tag: 'text', selector: 'title-label' })
            .text(title)
            .attr('transform', `translate(${20 + marginLeft + titleLabelOffsetX},${20 + titleLabelOffsetY})`)
            .attr('fill', '#66708a')
            .attr('font-size', titleLabelFontSize)
            .attr('font-weight', 'bold')

        if (titleHover) {
            // Draw title
            svg.patternify({ tag: 'foreignObject', selector: 'title-label-icon' })
                .attr('width', 25)
                .attr('height', 25)
                .attr('x', 10 + 20 + marginLeft + titleLabelOffsetX + titleLabel.node().getBoundingClientRect().width)
                .on('mouseenter.infotip', event => {
                    if (tooltip) {
                        tip
                            .html(d => `<div style="width:200px">${titleHover}</div>`)
                            .show(event, titleHover)
                    }

                })
                .on('mouseleave.infotip', d => {
                    if (tooltip) {
                        tip.hide()
                    }
                })
                .attr('cursor', 'pointer')
                .patternify({ tag: 'xhtml:div', selector: 'title-label0icon-fo' })
                .attr('pointer-events', 'none')
                .html(`<svg class="MuiSvgIcon-root jss144" focusable="false" width=24 height=24 viewBox="0 0 24 24" aria-hidden="true" tabindex="-1" title="InfoOutlined" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="InfoOutlined"> <circle fill="white" pointer-events="all"  r=12 cx=12 cy=12 ></circle> <path pointer-events="none" fill='#87ceeb'd="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>`)
        }

        // Draw x Label
        chart.patternify({ tag: 'text', selector: 'label-x' })
            .text(labelX)
            .attr('transform', `translate(${chartWidth / 2 - this.getTextWidth(labelX, { fontSize: 16 }) / 2},${chartHeight + 50})`)
            .attr('fill', '#66708a')
            .attr('font-size', 16)

        // Draw y label
        chart.patternify({ tag: 'text', selector: 'label-y' })
            .attr('transform', `translate(${-30 + labelYOffsetX},${chartHeight / 2}) rotate(-90)`)
            .text(labelY)
            .attr('fill', '#66708a')
            .attr('font-size', labelYFontSize)
            .attr('text-anchor', 'middle')

        // Draw axis wrapper group
        const axisWrapper = chart
            .patternify({
                tag: 'g',
                selector: 'axis-wrapper'
            })

        // Draw x axis wrapper group
        const xAxisWrapper = axisWrapper
            .patternify({
                tag: 'g',
                selector: 'x-axis-wrapper'
            })
            .attr('transform', `translate(0,${chartHeight})`)

        // Draw y axis wrapper
        const yAxisWrapper = axisWrapper
            .patternify({
                tag: 'g',
                selector: 'y-axis-wrapper'
            })

        if (data.every(d => d.points.length === 0)) return;

        if (transition) {
            // Draw and transition x axis
            xAxisWrapper
                .transition()
                .duration(duration)
                .call(xAxis)

            // Transition and draw y axis
            yAxisWrapper
                .transition()
                .duration(duration)
                .call(yAxis);
        } else {
            xAxisWrapper.call(xAxis);
            yAxisWrapper.call(yAxis);
        }

        // // Remove domain lines
        if (gridView) {
            axisWrapper.selectAll('.domain').remove();
        }

        yAxisWrapper.selectAll('.domain').remove();

        // Make all tick lines dashed and change color as well
        axisWrapper.selectAll('.tick line')
            .attr('stroke-dasharray', '5 5')
            .attr('stroke', '#DADBDD')


        axisWrapper.selectAll('.domain')
            .attr('stroke-dasharray', '5 5')
            .attr('stroke', '#DADBDD')
        // Change color of all axis texts
        axisWrapper.selectAll('text').attr('fill', '#66708a');

        //Change position of all texts
        const xAxisTexts = xAxisWrapper.selectAll('text')
            .transition()
            .duration(0)
            .attr('y', d => {
                //centerTicks
                return (zoomedY(lastYTick) || chartHeight) - chartHeight + 15
            })

        // If grid view enabled, move axis pieces to their respective positions
        if (gridView) {
            xAxisTexts.attr('x', d => {
                if (centerTicks) {
                    return (zoomedX(secondXTick) - zoomedX(firstXTick)) / 2;
                } else {
                    return 0
                }
            })

            // Move grid lines to first tick value
            xAxisWrapper.selectAll('line')
                .attr('transform', `translate(0,${(zoomedY(lastYTick) || chartHeight) - chartHeight})`);

            // Change position of all texts
            yAxisWrapper.selectAll('text')
                .transition()
                .duration(0)
                .attr('x', (zoomedX(firstXTick) || 0) - 10);

            // Move grid lines to first tick value
            yAxisWrapper.selectAll('line').attr('transform', `translate(${(zoomedX(firstXTick) || 0)})`);
        }
    }

    // Draw line shapes
    drawLines() {
        const { data, calc, duration, chart, lineShadows, zoomedX, zoomedY, dropShadowId, colors, dashedLineDasharray, firstXTick, lastXTick, gridView } = this.getState();
        const { chartWidth } = calc;

        // Filter lines
        let filteredLinesData = data.filter(d => d.type === 'line');

        // Filter points
        if (gridView) {
            filteredLinesData = filteredLinesData.map(l => Object.assign({}, l, {
                points: l.points.filter(p => p.key >= firstXTick && p.key <= lastXTick)
                    .map(p => Object.assign(p, { line: l })),
            }))
        } else {
            filteredLinesData = filteredLinesData.map(l => Object.assign({}, l, {
                points: l.points.filter(p => p.key >= zoomedX.invert(0) && p.key <= zoomedX.invert(chartWidth))
                    .map(p => Object.assign(p, { line: l })),
            }))
        }

        // Break func if points length is zero    
        if (filteredLinesData.every(d => d.points.length === 0)) return;

        // Define color access function
        const getColor = (d, i) => {
            if (d[0] && d[0].line && (d[0].line.color || d[0].line.stroke || d[0].line.fill)) return d[0].line.color || d[0].line.stroke || d[0].line.fill;
            return colors[colors.length - (i % colors.length) - 1]
        }

        // Define chundked line instructions
        const chunkedLine = lineChunked()
            .curve(curveMonotoneX)
            .x(d => zoomedX(d.key))
            .y(d => zoomedY(d.value))
            .chunkDefinitions({
                line: {
                    styles: {
                        stroke: getColor,
                        'stroke-width': d => (d[0] && d[0].line && d[0].line['stroke-width']) || 4,
                    },
                },
                'dashed': {
                    styles: {
                        'stroke-dasharray': dashedLineDasharray,
                        'stroke-width': d => (d[0] && d[0].line && d[0].line['stroke-width']) || 4,
                        'stroke-linejoin': 'round',
                        'stroke-linecap': 'round',
                    }
                }
            })
            .chunk(function (d) { return d.dashed ? 'dashed' : 'line'; });

        // Create line wrapper group
        const linesWrapper = chart.patternify({
            tag: 'g', selector: 'lines-wrapper'
        })
        // Define each lines wrapper
        const eachLinesWrapper = linesWrapper.patternify({ tag: 'g', selector: 'each-lines-wrapper', data: filteredLinesData.map(d => d.points) })

        // Create line paths 
        const paths = eachLinesWrapper
            .transition()
            .duration(duration)
            .call(chunkedLine)

        //  add line shadows if set
        if (lineShadows) {
            paths.style('filter', `url(#${dropShadowId})`);
        }
    }

    // Draw area shapes 
    drawAreas() {
        const { chart, gridView, duration, zoomedX, zoomedY, calc, data, colors, transition, firstXTick, lastXTick } = this.getState();
        const { chartWidth } = calc;

        // Filter areas
        let filteredAreasData = data.filter(d => d.type === 'area');

        // Filter points
        if (gridView) {
            filteredAreasData = filteredAreasData.map(l => Object.assign({}, l, {
                points: l.points.filter(p => p.key >= firstXTick && p.key <= lastXTick)
            }))
        } else {
            filteredAreasData = filteredAreasData.map(l => Object.assign({}, l, {
                points: l.points.filter(p => p.key >= zoomedX.invert(0) && p.key <= zoomedX.invert(chartWidth))
            }))
        }

        // Create areas wrapper group
        const areasWrapper = chart.patternify({
            tag: 'g',
            selector: 'lines-wrapper'
        });

        // Retrieve neccessary amounts of colors and reverse them
        const colorsUsed = colors.filter((d, i) => i < filteredAreasData.length).reverse();

        // Create ara path calculation function
        const areaPath = area()
            .curve(curveMonotoneX)
            .x(d => zoomedX(d.key))
            .y0(d => zoomedY(d.min))
            .y1(d => zoomedY(d.max))

        // Create area paths
        const areas = areasWrapper.patternify({
            tag: 'path',
            selector: "areas",
            data: filteredAreasData
        })
            .pattr('stroke', 'none')
            .pattr('stroke-width', 1)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .pattr('opacity', 1)
            .pattr('fill-opacity', 1)
            .pattr('fill', (d, i) => colorsUsed[i % colorsUsed.length], 'color')

        if (transition) {
            // Transition area to its shape
            areas.transition()
                .duration(duration)
                .attrTween('d', function (d) {
                    var previous = select(this).attr('d');
                    var current = areaPath(d.points);
                    return interpolatePath(previous, current);
                });
        } else {
            areas.attr('d', d => areaPath(d.points));
        }
    }

    // Draw shaded ranges
    drawShadedRanges() {
        const { chart, minShadedRangesRectWidth, innerWrapper, duration, tip, transition, shadedRanges, firstYTick, lastYTick, calc, zoomedY, zoomedX, firstXTick, lastXTick } = this.getState();
        // If we don;t have shaded data, or chart data is invalid, break func
        if (!shadedRanges || !shadedRanges.length || isNaN(+zoomedX.domain()[0])) return;

        // Filter out some out ranges 
        const filteredShadedRangesData = shadedRanges.filter(shadedRange => {
            // If date ranges overlap does not overlap, hide them
            if (shadedRange.start <= firstXTick && shadedRange.end >= lastXTick) return true;
            if (shadedRange.start >= firstXTick && shadedRange.end <= lastXTick) return true;
            if (shadedRange.start >= firstXTick && shadedRange.start < lastXTick) return true;
            if (shadedRange.end > firstXTick && shadedRange.end <= lastXTick) return true;
            return false;
        })

        // If all ranges were filtered return
        if (filteredShadedRangesData.length === 0) {
            selectAll('.ranges-wrapper').remove();
            return;
        }

        // Calculate extents
        const minDate = min(filteredShadedRangesData, d => d.start);
        const maxDate = max(filteredShadedRangesData, d => d.end);

        // Define timeline instance
        const timelineInstance = timeline()
            .size([zoomedX(maxDate) - zoomedX(minDate), 35])
            .bandStart(function (d) {
                const width = Math.min(zoomedX(lastXTick), zoomedX(d.end)) - zoomedX(d.start);
                if (width < minShadedRangesRectWidth) {
                    return zoomedX.invert(zoomedX(d.start) - (minShadedRangesRectWidth - width) / 2)
                }
                return d.start;
            })
            .bandEnd(function (d) {
                const width = Math.min(zoomedX(lastXTick), zoomedX(d.end)) - zoomedX(d.start);
                if (width < minShadedRangesRectWidth) {
                    return zoomedX.invert(zoomedX(d.end) + (minShadedRangesRectWidth - width) / 2)
                }
                return d.end;
            })

        // Retrieve transformted data
        const timelineShadedRangesData = timelineInstance(filteredShadedRangesData);

        // Create shaded ranges
        const shadedRangesWrapper = chart.patternify({
            tag: 'g',
            selector: 'ranges-wrapper'
        });

        // Create shaded rectangles
        let shadedRangeRects = shadedRangesWrapper.patternify({ tag: 'rect', selector: 'shaded-rect-ranges', data: timelineShadedRangesData })
            .attr('fill', '#0003bf')
            .attr('opacity', 0.05)

        // Transform shaded range rects to transitions
        if (transition) {
            shadedRangeRects = shadedRangeRects
                .transition()
                .duration(duration)
        }

        // Position shaded range rects to their positions
        shadedRangeRects
            .attr('x', d => {
                const x = zoomedX(d.originalStart);
                const width = Math.min(zoomedX(lastXTick), zoomedX(d.originalEnd)) - zoomedX(d.originalStart);
                if (width < minShadedRangesRectWidth) {
                    return x - (minShadedRangesRectWidth - width) / 2;
                }
                return x;
            })
            .attr('y', zoomedY(firstYTick))
            .attr('width', d => {
                const width = Math.min(zoomedX(lastXTick), zoomedX(d.originalEnd)) - zoomedX(d.originalStart);
                if (width < minShadedRangesRectWidth) return minShadedRangesRectWidth;
                return width;
            })
            .attr('height', zoomedY(lastYTick) - zoomedY(firstYTick))

        // Create shaded label rectangles
        let shadedRangeLabelRects = shadedRangesWrapper.patternify({ tag: 'rect', selector: 'shaded-label-rect-ranges', data: timelineShadedRangesData })
            .attr('fill', '#D0DAE5')
            .attr('opacity', 1)
            .on('mouseenter.shaded-hover', function (event, d) {
                tip
                    .html((event, d) => `<div style="width:250px">${d.label}<br/><br/>
                    <b>Start</b> : ${d.originalStart.toLocaleString(undefined, {
                        weekday: "short",
                        month: "short",
                        day: "numeric"
                    })}
                    <br/>
                    <b>End</b>: ${d.originalEnd.toLocaleString(undefined, {
                        weekday: "short",
                        month: "short",
                        day: "numeric"
                    })}
                    <br/><br/>
                    ${d.metadata}
                    </div>`)
                    .direction('n')
                    .offset([-10, 0])
                    .show(event, d)

                select(this).attr('fill', 'gray')

                // Make shaded range texts white
                chart.selectAll('.shaded-range-labels')
                    .filter(sh => sh === d)
                    .selectAll('.range-label-div')
                    .style('color', '#fafafa');
                chart.selectAll('.shaded-range-labels')
                    .filter(sh => sh === d)
                    .selectAll('.range-label-div path')
                    .attr('fill', '#fafafa');
            })
            .on('mouseleave.shaded-hover', function (event, d) {
                select(this).attr('fill', '#D0DAE5');

                // Restore shaded range texts default value
                chart.selectAll('.shaded-range-labels')
                    .selectAll('.range-label-div')
                    .style('color', '#5D6772');
                chart.selectAll('.shaded-range-labels')
                    .selectAll('.range-label-div path')
                    .attr('fill', '#5D6772');
                tip.hide();
            })

        // Transform shaded range label rects to transitions
        if (transition) {
            shadedRangeLabelRects = shadedRangeLabelRects
                .transition()
                .duration(duration)
        }

        // Position shaded range label rects to their positions
        shadedRangeLabelRects
            .attr('x', d => {
                const x = zoomedX(d.originalStart);
                const width = Math.min(zoomedX(lastXTick), zoomedX(d.originalEnd)) - zoomedX(d.originalStart);
                if (width < minShadedRangesRectWidth) {
                    return x - (minShadedRangesRectWidth - width) / 2;
                }
                return x;
            })
            .attr('y', d => zoomedY(firstYTick) - 35 + d.y)
            .attr('width', d => {
                const width = Math.min(zoomedX(lastXTick), zoomedX(d.originalEnd)) - zoomedX(d.originalStart);
                if (width < minShadedRangesRectWidth) return minShadedRangesRectWidth;
                return width;
            })
            .attr('height', d => d.dy)
            .attr('stroke', 'white')

        // Add shaded range labels
        let shadedRangeLabels = shadedRangesWrapper.patternify({ tag: 'foreignObject', selector: 'shaded-range-labels', data: timelineShadedRangesData })

        // Add custom label content
        shadedRangeLabels.patternify({ tag: 'xhtml:div', selector: 'text-content', data: d => [d] })
            .html(d => `<div class="range-label-div" style="display:${timelineShadedRangesData[0].dy === 35 ? 'block' : 'none'};font-size:12px;color:#5D6772;border-radius:3px;padding:3px;text-overflow: ellipsis;white-space: nowrap;overflow:hidden">
             ${d.label}
            </div>`)

        // If transition is enabled, then
        if (transition) {
            shadedRangeLabels = shadedRangeLabels.transition().duration(duration);
        }

        // Move labels to the updated position (And hide them in case, they are too small)
        shadedRangeLabels
            .attr('pointer-events', 'none')
            .attr('x', d => zoomedX(d.originalStart))
            .attr('y', zoomedY(firstYTick) - 30)
            .attr('width', d => {
                let width = d.end - d.start;
                if (width < 40) return 0;
                return width;
            })
            .attr('height', 21)

        // Reposition drag handler
        innerWrapper.selectAll('.drag-handler-rect').attr('y', zoomedY(firstYTick))
    }

    // Add interaction to chart
    attachInteractionElements() {
        const { svg, chart, calc, data, colors, staticTipXPosition, onChartMouseLeave } = this.getState();
        const { chartHeight } = calc;
        const that = this;

        // Create hover line wrapper element
        const hoverLineWrapper = chart.patternify({
            tag: 'g',
            selector: 'vertical-line-wrapper'
        })
            .attr('opacity', 0)

        // Create hover rectangle shape
        hoverLineWrapper.patternify({ tag: 'rect', selector: 'hover-rect' })
            .attr('width', 1)
            .attr('height', chartHeight)
            .attr('fill', 'url(#gradient)');

        // Create points' white outline
        hoverLineWrapper.patternify({
            tag: 'circle',
            selector: 'points-outer',
            data: data.filter(d => d.type === 'line')
        })
            .attr('cx', 0)
            .attr('cy', 10)
            .attr('r', 7)
            .attr('fill', 'white')

        // Create points inner circle
        hoverLineWrapper.patternify({
            tag: 'circle',
            selector: 'points-inner',
            data: data.filter(d => d.type === 'line')
        })
            .attr('cx', 0)
            .attr('cy', 10)
            .pattr('r', 5)
            .pattr('fill', (d, i) => colors[colors.length - (i % colors.length) - 1], 'color')

        // Create circle, from which tip will be fired
        hoverLineWrapper.patternify({
            tag: 'circle',
            selector: 'circle-tip',
            data: ['tip']
        })
            .attr('cx', 0)
            .attr('cy', 40)
            .attr('r', 0)
            .pattr('fill', (d, i) => colors[colors.length - (i % colors.length) - 1], 'color')

        // Listen and handle svg events
        svg
            .on('mousemove', function (event, d) {
                const { tipOffsetY, marginLeft, data, zoomedX, prevIndex, zoomedY, tip, tooltip, calc, prevTipPosX } = that.getState();
                const { chartWidth } = calc;

                if (event.srcElement.tagName === 'circle') {
                    // Hide hover line
                    hoverLineWrapper.attr('opacity', 0)
                    return;
                }

                // Disable svg tip on label rect eange hover
                if ([...event.srcElement.classList].includes("shaded-label-rect-ranges")) {
                    // Hide hover line
                    hoverLineWrapper.attr('opacity', 0)
                    return;
                }

                // Get actual x position (taking margin into account)
                const actualX = pointer(event)[0] - marginLeft;

                // Get value from position
                const v = zoomedX.invert(actualX)

                // Retrieve lines
                const lines = data.filter(d => d.type === 'line');

                // If lines not found, don't display hover line
                if (!lines.length) return;

                // Get nearest date value and index from data 
                const { value, index } = that.nearest(lines[0]?.points.map(d => d.invisible ? new Date('1900') : d.key), v, prevIndex || 0) || {};

                // If destructured value is not defined, return
                if (value === undefined) return;

                // Get all related points
                const points = data.filter(d => d.type === 'line').map(d => d.points[index]);

                // Get all related y values
                const yValues = points.map(p => p?.value).filter(d => d !== null && d !== undefined);

                // If y values not found, then break action
                if (!yValues.length) return;

                // Calculate tip position
                const tipPositionX = zoomedX(new Date(value));

                if (tipPositionX < 0 || tipPositionX > chartWidth) return;

                // Position hover points to respective y coordinates
                hoverLineWrapper.selectAll('.points-inner')
                    .attr('cy', (d, i) => zoomedY(yValues[i]));
                hoverLineWrapper.selectAll('.points-outer')
                    .attr('cy', (d, i) => zoomedY(yValues[i]));

                // Move hoverline to its position   
                hoverLineWrapper
                    .attr('opacity', 1)
                    .attr('transform', `translate(${tipPositionX})`);

                // Retrieve corresponding colors
                const lineColors = data.filter(d => d.type === 'line')
                    .map((d, i) => (d.color || d.fill || d.stroke) || colors[colors.length - (i % colors.length) - 1]);

                if (tipPositionX !== null && tipPositionX !== prevTipPosX) {
                    // Display eastside or westside tooptip, depending on current position
                    tip
                        .direction(tipPositionX < chartWidth / 2 ? 'e' : 'w')
                        .offset([tipOffsetY || 0, tipPositionX < chartWidth / 2 ? 15 : -15])
                        .html((EVENT, d) => tooltip(EVENT, d, this.getState))
                        .show(event, {
                            key: new Date(value),
                            values: yValues,
                            colors: lineColors,
                            lines: lines,
                            points: points
                        }, hoverLineWrapper.select('.circle-tip').node());

                    that.setState({ prevTipPosX: tipPositionX })
                }

                // Save date index     
                that.setState({ prevIndex: index })
            })
            .on('mouseleave', function (d) {
                const { tip, tooltip } = that.getState();

                // Hide tip
                if (tooltip) { tip.hide(); }

                // Hide hover line
                hoverLineWrapper.attr('opacity', 0)

                // Empty tip position x
                that.setState({ prevTipPosX: null })

                // Fire chart mouse leave event
                onChartMouseLeave(d)
            })

        // Get scales and chart params from state
        const { zoomedX, zoomedY, tip, tipTimeout, tooltip } = this.getState();
        const { chartWidth } = calc;

        // Hide previous state and also clear prev state timeout
        if (tooltip) { tip.hide(); }
        clearTimeout(tipTimeout)

        // If static tip is supplied and it's not outside bounds
        if (staticTipXPosition !== null && zoomedX(staticTipXPosition) < chartWidth) {

            // Calculate tip position
            const tipActualXPosition = zoomedX(staticTipXPosition)

            // Make hover line visible and translate to relevant position
            hoverLineWrapper.attr('opacity', 1)
                .attr('transform', `translate(${tipActualXPosition})`);

            // Get relevant data values
            const lines = data.filter(d => d.type === 'line');
            const lineP = lines[0].points.filter(d => +d.key === +staticTipXPosition)[0];
            const index = lines[0].points.indexOf(lineP);

            // Get all related points
            const points = data.filter(d => d.type === 'line').map(d => d.points[index]);

            // Get all related y values
            const yValues = points.map(p => p?.value).filter(d => d !== null && d !== undefined);
            if (yValues.length > 0) {
                // Position hover points to respective y coordinates
                hoverLineWrapper.selectAll('.points-inner')
                    .attr('cy', (d, i) => zoomedY(yValues[i]));
                hoverLineWrapper.selectAll('.points-outer')
                    .attr('cy', (d, i) => zoomedY(yValues[i]));

                // Show time after timeout ends (Needed because of existing reordering functionality)
                const tipTimeout = setTimeout(d => {
                    tip
                        .direction(tipActualXPosition < chartWidth / 2 ? 'e' : 'w')
                        .offset([0, tipActualXPosition < chartWidth / 2 ? 15 : -15])
                        .html((EVENT, d) => d.values.map(d => Math.round(d * 10) / 10 + ' %').join('<br>'))
                        .show({}, {
                            key: staticTipXPosition,
                            values: yValues,
                        }, hoverLineWrapper.select('.circle-tip').node());
                }, 700)

                this.setState({ tipTimeout })
            } else {
                // Hide points
                hoverLineWrapper.selectAll('.points-inner')
                    .attr('cy', 10000);

                hoverLineWrapper.selectAll('.points-outer')
                    .attr('cy', 10000);
            }
        }
        // Save previous index into state
        this.setState({ prevIndex: 0, hoverLineWrapper })
    }

    // Make it possible to zoom using mouse wheel
    attachZooming() {
        // Get svg from state
        const { svgWidth, svgHeight, savedZoom, innerWrapper, scaleX, calc } = this.getState();
        const { dateMin } = calc;

        // Define and attach zoom event and handlers
        const zoomBehavior = zoom()
            .scaleExtent([1, 1])
            .translateExtent([
                [scaleX(dateMin), 0],
                [svgWidth, svgHeight],
            ])
            .on('start', (event) => this.zoomStarted(event))
            .on("zoom", (event) => this.zoomed(event))
            .on('end', (event) => this.zoomEnded(event));

        if (savedZoom) {
            innerWrapper
                .transition()
                .delay(300)
                .duration(0)
                .call(zoomBehavior.transform, zoomIdentity);
        }

        // Call zoom behavior over g group element
        innerWrapper.call(zoomBehavior);

        // Disable annoying double click zooming
        innerWrapper.on("dblclick.zoom", null);

        // Save zoom into state
        this.setState({ savedZoom: zoomBehavior });
    }

    // Handle zoom start event
    zoomStarted(event) {
        this.setState({ duration: 0 })
        // Get state items
        const { tip, hoverLineWrapper } = this.getState();

        // If source event IS defined (Usually artificial redraw , hide tip)
        if (event.sourceEvent != null) {
            // Hide tip
            tip.hide();

            // Hide hover line
            hoverLineWrapper.attr('opacity', 0)
        }

        // Disable transition
        this.setState({ transition: false });
    }

    // Handle zoom end event
    zoomEnded(event) {
        this.setState({ duration: 500 })
        // Enable transition again
        this.setState({ transition: true })
    }

    // Handle zoom event
    zoomed(event) {
        const {
            svg,
            scaleX,
            // scaleY,
        } = this.getState();

        // Get transform object
        const transform = event.transform;

        // Hide overlay line
        svg.select(".overlay-line-g").style("display", "none");

        // Rescale x and y scale based on zoom event
        const zoomedX = transform.rescaleX(scaleX);

        // Incase we will need y zooming in future
        // const zoomedY = transform.rescaleY(scaleY);

        // Save scales into state
        this.setState({
            transform,
            zoomedX,
            //zoomedY, Don't save zoomed y into state 
        });

        // Redraw based on change state
        this.redraw()
    }


    // Calculate what size will text take when drew
    getTextWidth(text, {
        fontSize = 14,
        fontWeight = 400
    } = {}) {
        const { defaultFont, ctx } = this.getState();
        // If canvas context is not defined, return placeholder text
        if (!ctx) return 100;
        ctx.font = `${fontWeight || ''} ${fontSize}px ${defaultFont} `
        const measurement = ctx.measureText(text);
        return measurement.width;
    }

    // Find nearest index and value
    nearest(arr, target, prevIndex) {
        // Check if search is valid
        if (!(arr) || arr.length === 0)
            return null;
        if (arr.length === 1)
            return { value: arr[0], index: 0 };

        // first check if it's much different from old index for faster access
        if (arr.length > 80) {
            const start = Math.max(prevIndex - 40, 1);
            const end = Math.min(prevIndex + 40, arr.length);
            for (let i = start; i < end; i++) {
                if (arr[i] > target) {
                    let p = arr[i - 1];
                    let c = arr[i]
                    const result = Math.abs(p - target) < Math.abs(c - target) ? { value: p, index: i - 1 } : { value: c, index: i };
                    return result;
                }
            }
        }

        // Loop over all array items and find nearest value
        for (let i = 1; i < arr.length; i++) {
            // As soon as a number bigger than target is found, return the previous or current
            // number depending on which has smaller difference to the target.
            if (arr[i] > target) {
                let p = arr[i - 1];
                let c = arr[i]
                const result = Math.abs(p - target) < Math.abs(c - target) ? { value: p, index: i - 1 } : { value: c, index: i };
                return result;
            }
        }

        // No number in array is bigger so return the last.
        return { value: arr[arr.length - 1], index: arr.length - 1 };
    }

    // Create shadows for lines and gradient for hover lines
    createShadowsAndGradients() {
        const { svg, dropShadowId } = this.getState();

        // Initialize shadow properties
        const color = 'black';
        const opacity = 0.2;
        const filterX = -70;
        const filterY = -70;
        const filterWidth = 400;
        const filterHeight = 400;
        const feOffsetDx = 10;
        const feOffsetDy = 10;
        const feOffsetX = -20;
        const feOffsetY = -20;
        const feGaussianBlurStdDeviation = 3.1;

        // Add Gradients
        var defs = svg.patternify({
            tag: 'defs',
            selector: 'defs-element'
        });


        // Add linear gradient
        const gradients = defs
            .patternify({
                tag: 'linearGradient',
                selector: 'gradients',
                data: ['#B450EE']
            })
            .attr('id', 'gradient')
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '0%')
            .attr('y2', '100%')

        // Add color stops for each hover line gradient color    
        gradients
            .patternify({
                tag: 'stop',
                selector: 'gradient-stop-top',
                data: (d) => [d]
            })
            .attr('stop-color', 'white')
            .attr('offset', '0%');
        gradients
            .patternify({
                tag: 'stop',
                selector: 'gradient-stop-middle',
                data: (d) => [d]
            })
            .attr('stop-color', '#475A8B')
            .attr('offset', '50%');
        gradients
            .patternify({
                tag: 'stop',
                selector: 'gradient-stop-bottom',
                data: (d) => [d]
            })
            .attr('stop-color', 'white')
            .attr('offset', '100%');

        // Add Shadows
        var filter = defs
            .patternify({
                tag: 'filter',
                selector: 'shadow-filter-element'
            })
            .attr('id', dropShadowId)
            .attr('y', `${filterY}%`)
            .attr('x', `${filterX}%`)
            .attr('height', `${filterHeight}%`)
            .attr('width', `${filterWidth}%`);
        filter
            .patternify({
                tag: 'feGaussianBlur',
                selector: 'feGaussianBlur-element'
            })
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', feGaussianBlurStdDeviation)
            .attr('result', 'blur');
        filter
            .patternify({
                tag: 'feOffset',
                selector: 'feOffset-element'
            })
            .attr('in', 'blur')
            .attr('result', 'offsetBlur')
            .attr('dx', feOffsetDx)
            .attr('dy', feOffsetDy)
            .attr('x', feOffsetX)
            .attr('y', feOffsetY);
        filter
            .patternify({
                tag: 'feFlood',
                selector: 'feFlood-element'
            })
            .attr('in', 'offsetBlur')
            .attr('flood-color', color)
            .attr('flood-opacity', opacity)
            .attr('result', 'offsetColor');

        filter
            .patternify({
                tag: 'feComposite',
                selector: 'feComposite-element'
            })
            .attr('in', 'offsetColor')
            .attr('in2', 'offsetBlur')
            .attr('operator', 'in')
            .attr('result', 'offsetBlur');
        var feMerge = filter.patternify({
            tag: 'feMerge',
            selector: 'feMerge-element'
        });
        feMerge.patternify({
            tag: 'feMergeNode',
            selector: 'feMergeNode-blur'
        }).attr('in', 'offsetBlur');
        feMerge.patternify({
            tag: 'feMergeNode',
            selector: 'feMergeNode-graphic'
        }).attr('in', 'SourceGraphic');
    }

    // Listen resize event and resize on change
    reRenderOnResize() {
        const {
            resizeEventListenerId,
            d3Container,
            svgWidth
        } = this.getState();
        select(window).on('resize.' + resizeEventListenerId, () => {
            const { timeoutId, transationTimeoutId } = this.getState();
            if (timeoutId) clearTimeout(timeoutId);
            if (transationTimeoutId) clearTimeout(transationTimeoutId);
            const newTimeoutId = setTimeout(d => {
                const { disableResizeTransition } = this.getState();
                const containerRect = d3Container.node().getBoundingClientRect();
                const newSvgWidth = containerRect.width > 0 ? containerRect.width : svgWidth;
                this.setState({
                    svgWidth: newSvgWidth
                });
                if (disableResizeTransition) {
                    this.setState({ transition: false })
                }
                this.render();
                this.setState({});
                const newTransationTimeoutId = setTimeout(v => {
                    this.setState({ transition: true })
                }, 500)
                this.setState({ transationTimeoutId: newTransationTimeoutId })
            }, 1)
            this.setState({ timeoutId: newTimeoutId })
        });
    }

    // Draw SVG and g wrapper
    drawSvgAndWrappers() {
        const {
            d3Container,
            svgWidth,
            svgHeight,
            defaultFont,
            calc,
            tooltip,
            resizeEventListenerId
        } = this.getState();
        const {
            chartLeftMargin,
            chartTopMargin
        } = calc;


        // Draw SVG
        const svg = d3Container
            .patternify({
                tag: 'svg',
                selector: 'svg-chart-container'
            })
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .attr('font-family', defaultFont)
            .style('background-color', '#FFFFFF')
        // .style('overflow', 'visible')

        // If tooltip content is defined
        if (tooltip) {
            // Create tip instance
            const tip = d3Tip(resizeEventListenerId)
                .direction('e')
                .offset([0, 15])
                .attr('class', 'd3-tip')
                .html((EVENT, d) => tooltip(EVENT, d, this.getState));

            // Call tip on SVG
            svg.call(tip)

            this.setState({ tip });
        }

        // Add wraper group element
        const innerWrapper = svg
            .patternify({
                tag: 'g',
                selector: 'inner-wrapper'
            })
            .attr('transform', 'translate(' + chartLeftMargin + ',' + chartTopMargin + ')');

        innerWrapper.selectAll('.drag-handler-rect').remove();

        // Add background rect , which will receive and handle zoom events
        innerWrapper
            .patternify({ tag: "rect", selector: "drag-handler-rect" })
            .attr("width", svgWidth)
            .attr("height", svgHeight)
            .attr("fill", "none")
            .attr("pointer-events", "all");

        // Add container g element
        const chart = innerWrapper
            .patternify({
                tag: 'g',
                selector: 'chart'
            })

        this.setState({
            chart,
            innerWrapper,
            svg,
        });
    }

    // Calculate some properties
    calculateProperties() {
        const { data } = this.getState();
        const {
            marginTop,
            marginLeft,
            marginRight,
            marginBottom,
            svgWidth,
            svgHeight
        } = this.getState();

        // Calculated properties
        const calc = {
            id: this.createId(), // id for event handlings,
            chartTopMargin: marginTop,
            chartLeftMargin: marginLeft,
            chartWidth: svgWidth - marginRight - marginLeft,
            chartHeight: svgHeight - marginBottom - marginTop,
            dateMin: min(data, gr => min(gr.points, d => d.key)),
            dateMax: max(data, gr => max(gr.points, d => d.key)),
        };

        this.setState({
            calc
        })
    }

    // Set dynamic width for chart
    setDynamicContainer() {
        const {
            container,
            svgWidth
        } = this.getState();

        // Drawing containers
        const d3Container = select(container);
        const containerRect = d3Container.node().getBoundingClientRect();
        let newSvgWidth = containerRect?.width > 0 ? containerRect.width : svgWidth;
        this.setState({
            d3Container,
            svgWidth: newSvgWidth
        });
    }

    // Get current date from state
    getData() {
        const state = this.getState();
        const {
            setData
        } = state;
        return setData(state);
    }

    // Set data property
    setDataProp() {
        const data = this.getData();

        // Support additional properties for convenience
        data.forEach(d => {
            d.points.forEach(p => {
                if (p.x !== undefined) p.key = p.x;
                if (p.y !== undefined) {
                    if (d.type === 'area') p.max = p.y;
                    if (d.type === 'line') p.value = p.y;
                }
                if (p.y0 !== undefined) p.min = p.y0;
            })
        })
        this.setState({
            data
        })
    }
}