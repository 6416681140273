import React from "react"
import { Popover } from "../Popover"

import "./EmailItem.css"
import PlusIconAlt from "../Icons/PlusIconAlt"

function EmailItem(props) {
    //
    const { email, name, img, onDelete = () => null } = props

    return (
        <>
            <Popover text={email}>
                <div className="email-item">
                    <img className="email-item__image" src="/images/user_placeholder.png" alt="" />
                    <div className="email-item__info">
                        <h5 className="email-item__name">{name}</h5>
                        <p className="email-item__email">
                            {email.substring(0, email.length < 10 ? email.length : 10)}...
                        </p>
                    </div>
                    <button className="email-item__delete" onClick={onDelete}>
                        <PlusIconAlt width={10} height={10} />
                    </button>
                </div>
            </Popover>
        </>
    )
}

export default EmailItem
