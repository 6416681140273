import React, { useState, useCallback } from "react"

import WeatherVariableTabs from "../WeatherVariableTabs"
import TempChart from "../TempChart"
import PrecipitationChart from "../PrecipitationChart"
import SoilTempChart from "../SoilTempChart"
import RelativeHumidityChart from "../RelativeHumidityChart"
import EvapotranspirationChart from "../EvapotranspirationChart"
import SolarRadiationChart from "../SolarRadiationChart"
import GrowingDegreeDaysChart from "../GrowingDegreeDaysChart"

const TabView = (props) => {
    const { allowedWeatherVariables } = props

    const [actionsState, setActionsState] = useState({
        isMonthly: false,
        currentTab: "temperature_t2m",
    })

    const handleChangeAction = useCallback((state) => {
        setActionsState(state)
    }, [])

    const renderComponent = useCallback(() => {
        switch (actionsState.currentTab) {
            case "temperature_t2m":
                return <TempChart actionsState={actionsState} />
            case "precipitation":
                return <PrecipitationChart actionsState={actionsState} />
            case "soil_temperature":
                return <SoilTempChart actionsState={actionsState} />
            case "relative_humidity":
                return <RelativeHumidityChart actionsState={actionsState} />
            case "water_budget":
                return <PrecipitationChart actionsState={{ ...actionsState, extraPrecipitationChart: true }} />
            case "evapotranspiration":
                return <EvapotranspirationChart actionsState={actionsState} />
            case "solar_radiation":
                return <SolarRadiationChart actionsState={actionsState} />
            case "gdd":
                return <GrowingDegreeDaysChart actionsState={actionsState} />
            default:
        }
    }, [actionsState])

    return (
        <>
            <WeatherVariableTabs
                allowedWeatherVariables={allowedWeatherVariables}
                initialState={actionsState}
                onStateChange={handleChangeAction}
            />
            {renderComponent()}
        </>
    )
}

export default TabView
