import React from "react"

import TrashIcon from "../Icons/TrashIcon"

import "./LabelItem.css"

// TODO
// import { BlockPicker } from "react-color"

function LabelItem(props) {
    const {
        name,
        color,
        // onColorChange = () => null,
        onDeleteLabel = () => null,
        onSelectLabel = () => null,
    } = props
    return (
        <>
            <div key={`label-item__${name}`} className="label-item">
                {/* <BlockPicker /> */}
                <button className="label-item__label" onClick={() => onSelectLabel({ name, color })}>
                    <div className="label-item__label__color-dot" style={{ backgroundColor: color }} />
                    <div className="label-item__label__name">{name}</div>
                </button>
                <button className="label-item__delete" onClick={() => onDeleteLabel({ name, color })}>
                    <TrashIcon className="" />
                </button>
            </div>
        </>
    )
}

export default LabelItem
