import * as React from "react"

function ShareIcon(props) {
    return (
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
            <circle cx={9} cy={9} r={9} fill="#1C9690" />
            <path
                d="M13 12c.55 0 .995-.45.995-1L14 6a1 1 0 00-1-1H5c-.555 0-1 .445-1 1v5a1 1 0 001 1H3v1h12v-1h-2zm-3.5-1.765V9.14c-1.39 0-2.305.425-3 1.36.28-1.335 1.055-2.665 3-2.935V6.5l2 1.865-2 1.87z"
                fill="#fff"
            />
        </svg>
    )
}

export default ShareIcon
