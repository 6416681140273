import React, { useEffect, useState } from "react"
import app from "../Util/Fire.js"
import networking from "../Util/Networking"

import LoadingScreen from "../components/LoadingScreen"

export const AuthContext = React.createContext(null)

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [pending, setPending] = useState(true)
    const [permissions, setPermissions] = useState(null)
    const [featurePermissions, setFeaturePermissions] = useState(null)

    // detect if this is first login in browser
    function setUserPermissions(user) {
        if (user) {
            app.auth()
                .currentUser.getIdToken()
                .then((token) => {
					networking
						.get("/api/v1/settings/permissions", { extraHeaders: { "User-Token": token } })
						.then((res) => res.data)
						.then((result) => {
							setPermissions(result["views"])
							setCurrentUser(user)
							setFeaturePermissions(result["features"])
							setPending(false)
						})
						.catch((error) => {
							console.log("There was a problem obtaining permissions")
						})
                })
        }
    }

    useEffect(() => {
        app.auth().onAuthStateChanged((user) => {
            if (user != null) {
                setPending(true)
                // We might want to add more variables or more information.
                let pendo_object = {
                    visitor_id: user.email,
                    account_id: user.email
                }
                window['wrapper_pendo'](pendo_object)
				setUserPermissions(user)          
            } else {
                setCurrentUser(null)
                setPending(false)
            }
        })
    }, [])

    if (pending) {
        return <LoadingScreen />
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                permissions,
                featurePermissions,
                setUserPermissions,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
