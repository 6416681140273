import React from "react"
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import Copyright from "../ui/Copyright/Copyright"
import "./SignUpConfirmation.css"

const SignUpConfirmation = () => {
    return (
        <div className="sign-up-confirmation">
            <div className="sign-up-confirmation__container">
                <img
                    alt="climate ai color logo"
                    className="sign-up-confirmation__logo"
                    src="/Climate-AI-Color-Logo.png"
                />

                <div className="sign-up-confirmation__content">
                    <div className="sign-up-confirmation__title-container">
                        <div className="sign-up-confirmation__title">Thank you for contacting us.</div>
                        <div className="sign-up-confirmation__title">We have received your message</div>
                    </div>

                    <div className="sign-up-confirmation__text">
                        One of our team members will get in touch with you shortly.
                    </div>

                    <RouterLink to="/">
                        <button className="sign-up-confirmation__button">Continue</button>
                    </RouterLink>
                </div>
            </div>

            <div className="sign-up__copyright-confirmation">
                <Copyright />
            </div>
        </div>
    )
}

export default withRouter(SignUpConfirmation)
