import React from "react"

import "./SimpleModal.css"

function ModalFormTable(props) {
    const { children, title, className } = props
    return (
        <>
            <div className="simple-modal">
                <div className="simple-modal__content">
                    <h1 className="simple-modal__content__title">{title}</h1>
                    {children}
                </div>
            </div>
        </>
    )
}

export default ModalFormTable
