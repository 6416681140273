import React from "react"

import TrashIcon from "../Icons/TrashIcon"

import "./TableLabelItem.css"

function TableLabelItem(props) {
    const { label, onDelete = () => null } = props
    const { name, color } = label
    return (
        <>
            <div className="table-label-item" style={{ backgroundColor: color }}>
                <div className="table-label-item__text">{name}</div>
                <button className="table-item-labels__item__delete" onClick={() => onDelete(label)}>
                    <TrashIcon className="" fill="#fff" />
                </button>
            </div>
        </>
    )
}

export default TableLabelItem
