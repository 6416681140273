import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Navbar from "../../ui/Navbar/Navbar"
import SettingsTopbar from "../../ui/Navbar/SettingsTopbar"
import MobileHeader from "../../ui/Header/MobileHeader"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import "./SettingsLayout.css"

function SettingsLayout(props) {
    const { children, name } = props

    const [settingsMedia, setMedia] = useState(null)

    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)")

    useEffect(() => {
        setMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    return (
        <>
            {!settingsMedia && (
                <div className="settings-layout">
                    <div className="settings-layout__navbar">
                        <Navbar navbarContext="settings" />
                    </div>

                    <div className="settings-layout__content">
                        <div className="settings-layout__header">
                            <SettingsTopbar settingsName={name} />
                        </div>
                        {children}
                    </div>
                </div>
            )}
            {settingsMedia && (
                <div className="settings-layout">
                    <MobileHeader navbarContext="settings" />
                    {children}
                </div>
            )}
        </>
    )
}

SettingsLayout.propTypes = {
    children: PropTypes.node,
}

export default SettingsLayout
