// Import React
import React, { useContext, useEffect, useState } from "react"

// Import
import Navbar from "../../../../ui/Navbar/Navbar"
import MobileHeader from "../../../../ui/Header/MobileHeader"

// Import from Libraries
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SettingsIcon from "@material-ui/icons/Settings"
import CloudIcon from "@material-ui/icons/Cloud"
import PublicIcon from "@material-ui/icons/Public"
import { AuthContext } from "../../../../Auth/Auth"


const Sidebar = (props) => {
    const { view, className, fieldName, open, variant, onClose } = props

    const { permissions } = useContext(AuthContext)
    const [pages, setPages] = useState([])
    const [lock, setLock] = useState(true)
    const p = {
        general: {
            title: "General",
            href: "/general/",
            icon: <SettingsIcon />,
            type: "models",
        },
        weather: {
            title: "Weather",
            href: "/weather/",
            icon: <CloudIcon />,
            type: "models",
        },
        satellite: {
            title: "Satellite",
            href: "/satellite/",
            icon: <PublicIcon />,
            type: "models",
        },
        planting_tool: {
            title: "Planting",
            href: "/planting_tool/",
            icon: <PublicIcon />,
            type: "tools",
        },
    }
    useEffect(() => {
        if (lock) {
            let page = []
            for (let key in permissions) {
                if (permissions[key] === true && key != "default") {
                    if (key in p) page.push(p[key])
                }
            }
            setPages(page)
            setLock(false)
        }
    }, [pages, lock])
    // const pages = [
    //     {
    //         title: "General",
    //         href: "/general/" + fieldName,
    //         icon: <SettingsIcon />,
    //     },
    //     {
    //         title: "Weather",
    //         href: "/weather/" + fieldName,
    //         icon: <CloudIcon />,
    //     },
    //     /*        {
    //         title: "Soil",
    //         href: "/soil/" + fieldName,
    //         icon: <EcoIcon />,
    //     },*/
    //     {
    //         title: "Satellite",
    //         href: "/satellite/" + fieldName,
    //         icon: <PublicIcon />,
    //     },
    //     /*        {
    //         title: "Sustainability",
    //         href: "/sustainability/" + fieldName,
    //         icon: <NatureIcon />,
    //     },
    //     {
    //         title: "Long Term",
    //         href: "/longterm/" + fieldName,
    //         icon: <WatchLaterIcon />,
    //     },*/
    // ]


    const [tabletMedia, setTabletMedia] = useState()

    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)")

    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    return (
        <>
           {!mediaBreakpoint && <Navbar navbarContext="dashboard" view={view} pages={pages} permissions={permissions} />}
            {mediaBreakpoint && <MobileHeader navbarContext="dashboard" view={view} pages={pages} permissions={permissions }/>}
        </>
    )
}

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
}

export default Sidebar
