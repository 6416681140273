import React, { useContext, useEffect } from "react"

import "./Navbar.css"

import { Link as RouterLink } from "react-router-dom"

import app from "../../Util/Fire"
import NavbarFields from "./NavbarFields"
import NavbarOption from "./NavbarOption"
import { DataContext } from "../../Util/Data"
import { getTableData } from "../../services/table.service"
import { UIAlertContext } from "../UIAlert/UIAlert"
import { useParams } from "react-router-dom"

import LogoIcon from "../Icons/LogoIcon"

function Navbar(props) {
    const { view, pages, permissions, navbarContext } = props

    let { id } = useParams()

    const { aboutModal } = useContext(UIAlertContext)

    const {
        state: { fields },
        dispatch,
    } = useContext(DataContext)

    useEffect(() => {
        if (!fields || fields.length === 0) {
            getTableData()
                .then((data) => {
                    dispatch({ type: "GET_FIELDS", payload: { fields: data } })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [])

    const SETTINGS = [
        {
            href: "dashboard",
            title: "Dashboard",
        },
        {
            href: "profile-settings",
            title: "Profile Settings",
        },
        {
            href: "alerts-settings-individual",
            title: "Alerts Settings",
        },
        {
            href: "alerts-settings-stacked",
            title: "Alerts Settings Stacked",
        },
        {
            href: "table-settings",
            title: "Table Settings",
        },
    ]

    return (
        <>
            <div className="navbar">
                <div className="navbar__container">
                    <div className="navbar__header">
                        <RouterLink to="/dashboard">
                            <LogoIcon className="navbar__header-image" />
                        </RouterLink>
                    </div>

                    {navbarContext === "dashboard" && (
                        <>
                            <NavbarFields view={view} fields={fields} permissions={permissions} />
                            <NavbarOption
                                optionsTitle="Optimization Tools"
                                optionDesc="Tool"
                                isDashboard={true}
                                pages={pages.filter((d) => d.type === "tools")}
                                fieldId={id}
                            />
                            <NavbarOption
                                optionsTitle="Models"
                                optionDesc="Model"
                                isDashboard={true}
                                pages={pages.filter((d) => d.type === "models")}
                                fieldId={id}
                            />
                        </>
                    )}

                    {navbarContext === "settings" && (
                        <>
                            <NavbarOption isDashboard={false} pages={SETTINGS} fieldId="" />
                        </>
                    )}

                    <div
                        onClick={() => {
                            aboutModal()
                        }}
                        className="navbar__about"
                    >
                        About
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
