import React, { Component } from "react"
import ToggleSwitch from "../../ui/ToggleSwitch/ToggleSwitch"
import MapPinIcon from "../../ui/Icons/MapPinIcon"
import "./ToggleContainerRegionalView.css"

class ToggleContainerRegionalViewMap extends Component {
    constructor() {
        super()
    }

    componentWillUnmount() {
        if (this.props.isVisibleSetting !== this.props.showRegionalViewMap) {
            this.props.onUserSettingsChange({ regionalViewMapVisible: this.props.showRegionalViewMap })
        }
    }

    handleOnChange = () => {
        this.props.setShowRegionalViewMap(!this.props.showRegionalViewMap)
    }

    render() {
        return (
            <div
                className={`regional-map__toggle__container ${this.props.showRegionalViewMap ? "active" : ""}`}
                onClick={this.handleOnChange}
            >
                {this.props.showRegionalViewMap && <MapPinIcon className="regional-map__toggle__icon" />}
                {!this.props.showRegionalViewMap && <MapPinIcon className="regional-map__toggle__icon" />}
            </div>
        )
    }
}

export default ToggleContainerRegionalViewMap
