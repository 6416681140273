import React, { useState, useReducer, useRef, useEffect, useCallback, useMemo, useContext } from "react"

import "./Table.css"

import { Search, Clear } from "@material-ui/icons"
import PlusIcon from "../Icons/PlusIcon"
import LabelIcon from "../Icons/LabelIcon"
import _ from "lodash"

import TableContent from "./TableContent"
import TableDraggableHeaders from "./TableDraggableHeaders"
import TablePagination from "./TablePagination"

//
import TableContext from "./TableContext"

// reducer
import TableReducer from "./TableReducer"

//
import { transformData, getRowsFromGroups, groupData } from "../../helpers/table"
import TableItem from "./TableItem"
import Checkbox from "../Checkbox/Checkbox"

import { SimpleModal } from "../Modal"
import ShareReportForm from "./ShareReportForm"
import TableLabelButton from "./TableLabelButton"
import LabelSelect from "./LabelSelect"

import { Popover } from "../../ui/Popover"

import { MapboxFieldHeatmapViewerComponent } from "../../components/Charts/MapboxMaps/MapboxFieldHeatmapViewer/MapboxFieldHeatmapViewer"
import { ToggleContainerRegionalViewMap } from "../../components"
import { DemoContext } from "../../Util/DemoContext"
import ArrowRight from "../Icons/ArrowRight"
import { Link as RouterLink } from "react-router-dom"
import ExitApp from "../Icons/ExitApp"


const initialState = {
    // groupBy: [{ propName: "region", displayName: "Region" }],
    groupBy: [],
    orderBy: "",
    asc: true,
    inputValue: "",
    rows: 10,
    currentPage: 1,
    openPaths: {},
    selected: [],
    // uuids: [],
}

function Table(props) {
    //
    const {
        fields = [],
        metaFields = [],
        data = [],
        loading = false,
        onNewReport = () => null,
        onSelectionChanged = () => null,
        onRowAdd = () => null,
        onRowUpdate = () => null,
        onRowDelete = () => null,
        onDataSourceChange = () => null,
        reportTypes = [],
        labels,
        onDeleteLabel,
        onLabelFields,
        showAddButton,
        showDataSourceDropdown,
        fieldsPolygonData,
        onSelectedMapColumnVariable,
        regionalViewMapVisibleSetting,
        onUserSettingsChange,
        onRemoveLabelFromField,
        // itemComponent: ItemComponent = () => <React.Fragment />,
        // itemChildComponent: ItemChildComponent = () => <React.Fragment />,
        units,
    } = props

    const { mode,
        increaseLimit,
        limit} = useContext(DemoContext)

    // state
    const [state, dispatch] = useReducer(TableReducer, initialState)
    const { groupBy, orderBy, asc, inputValue, rows, currentPage, openPaths, selected } = state
    const [open, toggle] = useState(false)

    useMemo(() => {
        onSelectionChanged(state.selected)
    }, [state.selected])

    // console.log(openPaths)
    //
    const [showEditable, setEditable] = useState(false)
    //
    const [report, setReport] = useState("-")

    const [showRegionalViewMap, setShowRegionalView] = useState(regionalViewMapVisibleSetting)

    useEffect(() => {
        setShowRegionalView(regionalViewMapVisibleSetting)
    }, [regionalViewMapVisibleSetting])

    const inputRef = useRef(null)

    const hiddenFileInput = useRef(null);

    const onChangeFile = function(event) {
        if(open){
            toggle(!open)
        }
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        console.log(file);
        if(file.name){
            increaseLimit(limit+2)
        }

        //this.setState({file}); /// if you want to upload latter
    }

    function handleHeaderClick(propName) {
        dispatch({
            type: "SET_ORDER_BY",
            payload: { orderBy: propName },
        })
    }

    function handleRowSelect(rowData) {
        dispatch({
            type: "ADD_SELECTED",
            payload: { items: rowData.__group ? getRowsFromGroups(rowData.data) : [rowData] },
        })
    }

    function handleRowDeselect(rowData) {
        console.log("deselect", rowData)
        if (rowData.__group) {
            dispatch({
                type: "REMOVE_SELECTED_BY_PROPNAME",
                payload: { propName: rowData.__propName, value: rowData[rowData.__propName] },
            })
        } else {
            dispatch({ type: "REMOVE_SELECTED", payload: { deselected: rowData } })
        }
    }

    function handleRowOpen(rowData, path) {
        // console.log("select")
        dispatch({ type: "ADD_OPEN_PATH", payload: { path, rowData } })
    }

    function handleRowClose(_, path) {
        // console.log("deselect")
        dispatch({ type: "REMOVE_OPEN_PATH", payload: { path } })
    }

    // input handling
    const debounceInputChange = _.debounce((e) => {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: e.target.value } })
    }, 500)

    const handleSearchBarInputChange = useCallback(
        (e) => {
            e.persist()
            debounceInputChange(e)
        },
        [debounceInputChange]
    )

    function handleSearchBarButtonClick(e) {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: "" } })
        inputRef.current.value = ""
    }

    // draggable headers functionality
    function handleHeaderDrop(propData) {
        if (
            !groupBy.find(
                (_propData) =>
                    (propData && _propData && propData.propName === _propData.propName) ||
                    propData.displayName === _propData.displayName
            )
        )
            dispatch({ type: "ADD_GROUP_BY", payload: { ...propData } })
    }

    function handleHeaderClose(propData) {
        dispatch({ type: "REMOVE_GROUP_BY", payload: { ...propData } })
    }

    // pagination
    function handleRowNumberChange(_rows) {
        dispatch({ type: "ROW_NUMBER_CHANGE", payload: { rows: _rows } })
    }

    function handleCurrentPageChange(_page) {
        dispatch({ type: "CURRENT_PAGE_CHANGE", payload: { page: _page } })
    }

    function handleHeaderButtonPress() {
        setEditable(true)
    }

    function handleEditableCheck(_rowData) {
        onRowAdd(_rowData)
        setEditable(false)
    }

    function handleEditableClose() {
        setEditable(false)
    }

    let displayData = transformData(data, groupBy, orderBy, asc, inputValue, rows, currentPage, fields)

    // Define a state variable to check whether checkAll checkbox was checked once
    const [checkedOnce, setCheckedOnce] = useState(false)
    useEffect(() => {
        // Listen for data changes and if data found and checkbox is not checked at least once
        if (data.length > 0) {
            if (!checkedOnce) {
                // Set checked flag
                setCheckedOnce(true)

                // Get currently displayed data
                let currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)

                // Dispatch check_all event (which checks `check all` checkbox)
                dispatch({
                    type: "ADD_SELECTED_BY_ROWS",
                    payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
                })
            }
        }
    }, [data])

    function handleCheckAllRows(e) {
        let currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)

        if (e.target.checked) {
            dispatch({
                type: "ADD_SELECTED_BY_ROWS",
                payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
            })
        } else {
            dispatch({
                type: "REMOVE_SELECTED_BY_ROWS",
                payload: { items: currentDisplayedData, rows, currentPage, groupBy },
            })
        }
    }

    function areReportsAllowed() {
        return Object.keys(reportTypes).some(function (object) {
            return reportTypes[object]
        })
    }

    function handleSelectReport(e) {
        if (e.target.value && e.target.value.length && e.target.value !== "-") setReport(e.target.value)
    }

    function handleReportCancel() {
        setReport("-")
    }

    function handleReportContinue(emailList) {
        onNewReport(report, selected, emailList)
        setReport("-")
    }

    // checkbox logic
    let checked = false
    let checkedStatus = "empty"

    let _currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)
    const groupedSelected = groupData(selected, groupBy)

    const _propName = (groupBy[0] && groupBy[0].propName) || "uuid"
    const hashed = {}

    for (let _currentItem of _currentDisplayedData) hashed[_currentItem[_propName]] = false
    for (let _selectItem of groupedSelected)
        if (hashed[_selectItem[_propName]] === false) hashed[_selectItem[_propName]] = true

    let c = Object.values(hashed).reduce((prev, curr) => (curr ? prev + 1 : prev), 0)

    checked = c > 0 && c <= _currentDisplayedData.length
    checkedStatus = c === 0 ? "empty" : c > 0 && c < _currentDisplayedData.length ? "half" : "full"

    // console.log(groupedSelected)
    // console.log(displayData.slice(rows * currentPage - rows, rows * currentPage))

    return (
        <>
            <TableContext.Provider
                value={{
                    onRowAdd,
                    onRowDelete,
                    onRowUpdate,
                    onDataSourceChange,
                    onRowOpen: handleRowOpen,
                    onRowClose: handleRowClose,
                    onRowSelect: handleRowSelect,
                    onRowDeselect: handleRowDeselect,
                    onRemoveLabelFromField,
                    openPaths,
                    units,
                    showDataSourceDropdown,
                }}
            >
                <div className="table">
                    <div className="table__container">
                        <div
                            style={{
                                width: "100%",
                                display: "block",
                                padding: "0px 0px",
                            }}
                        >
                            <div className={`table__mapbox__container ${!showRegionalViewMap ? "hide" : ""}`}>
                                <MapboxFieldHeatmapViewerComponent
                                    data={fieldsPolygonData.data}
                                    variables={fieldsPolygonData.variables}
                                    units={fieldsPolygonData.units}
                                    onSelectedVariableChange={(v) => onSelectedMapColumnVariable(v.value)}
                                    width="100%"
                                    height="370px"
                                ></MapboxFieldHeatmapViewerComponent>
                            </div>
                        </div>
                        <div className="table__main-header">
                            <div className="table__main-header__title">
                                <div className="table__main-header__title__controls-left">
                                    {showAddButton && (
                                        <div className="table__main-header__title__button-container">
                                            <Popover text="Add new field">
                                                <button
                                                    className="table__main-header__title__button"
                                                    onClick={handleHeaderButtonPress}
                                                >
                                                    <PlusIcon />
                                                </button>
                                            </Popover>
                                        </div>
                                    )}
                                    <div className="table__main-header__title__checkbox-container">
                                        <Checkbox
                                            onChange={handleCheckAllRows}
                                            checked={checked}
                                            status={checkedStatus}
                                        />
                                    </div>
                                </div>
                                {/*TODO add logic to control reports that appear*/}
                                {(selected && selected.length && (
                                    <div className="table__main-header__title__controls-right">
                                        <div className="table__main-header__title__controls-right__label-button">
                                            <TableLabelButton>
                                                <LabelSelect
                                                    labels={labels}
                                                    // onNewLabel={onNewLabel}
                                                    onDeleteLabel={onDeleteLabel}
                                                    onSelectLabel={(label) => onLabelFields(label, selected)}
                                                />
                                            </TableLabelButton>
                                        </div>
                                        {areReportsAllowed() && (
                                            <select
                                                value={report}
                                                className="table__main-header__title__select"
                                                onChange={handleSelectReport}
                                            >
                                                <option value="-" defaultChecked disabled>
                                                    Choose report to generate
                                                </option>
                                                {Object.entries(reportTypes)
                                                    .filter((data) => data[1])
                                                    .map((data) => {
                                                        let enabled = data[1] ? "" : "disabled"
                                                        let reportName = data[0]
                                                        const nameCapitalized =
                                                            reportName.charAt(0).toUpperCase() + reportName.slice(1)
                                                        return (
                                                            <option
                                                                key={reportName}
                                                                value={reportName}
                                                                disabled={enabled}
                                                            >
                                                                {nameCapitalized}
                                                            </option>
                                                        )
                                                    })}
                                            </select>
                                        )}
                                    </div>
                                )) ||
                                null}
                            </div>
                            {/* TODO search bar */}
                            <div style={{display:"flex"}}>
                                <div className="alert__content__button-container">
                                    <input id="myInput"
                                           type="file"
                                           ref={hiddenFileInput}
                                           style={{display: 'none'}}
                                           onChange={onChangeFile.bind(this)}
                                           accept={".zip"}
                                    />
                                    {/*<button className="alert__content__save-button" onClick={()=>{hiddenFileInput.current.click();}}>*/}
                                    {/*    Bulk Upload*/}
                                    {/*</button>*/}
                                    <button className="alert__content__save-button" onClick={() => toggle(!open)}>
                                        Bulk Upload
                                    </button>
                                    <div className="user-profile__dropdown" style={{right:"110px", top:"25px"}}>
                                        <div className={open ? "user-profile__dropdown-menu--open" : "user-profile__dropdown-menu"}>
                                            <div className="user-profile__dropdown-navigation-container-edgar">
                                                <div
                                                    className="user-profile__dropdown-option"
                                                    onClick={()=>{hiddenFileInput.current.click();}}
                                                >
                                                    Folder Upload
                                                </div>
                                                <div
                                                    className="user-profile__dropdown-option"
                                                >
                                                    File Upload
                                                </div>
                                                <div
                                                    className="user-profile__dropdown-option"
                                                >
                                                    FieldView
                                                </div>
                                                <div
                                                    className="user-profile__dropdown-option"
                                                >
                                                    JohnDeere
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="table__main-header__search-bar">
                                    {/* TODO material icon */}
                                    <Search style={{ fontSize: 18 }} />
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        className="table__main-header__search-bar__input"
                                        placeholder="Search"
                                        onChange={(e) => {
                                            e.persist()
                                            handleSearchBarInputChange(e)
                                        }}
                                    />
                                    {/* Erase button */}
                                    <button
                                        className="table__main-header__search-bar__button"
                                        onClick={handleSearchBarButtonClick}
                                    >
                                        <Clear style={{ fontSize: 18 }} />
                                    </button>
                                </div>
                            </div>
                            <ToggleContainerRegionalViewMap
                                showRegionalViewMap={showRegionalViewMap}
                                setShowRegionalViewMap={setShowRegionalView}
                                onUserSettingsChange={onUserSettingsChange}
                                isVisibleSetting={regionalViewMapVisibleSetting}
                            />
                        </div>
                        {loading && <div className="table__loader" />}
                        <TableDraggableHeaders
                            groupBy={groupBy}
                            onDrop={handleHeaderDrop}
                            onHeaderClose={handleHeaderClose}
                        />
                        <div className="table__content-container">
                            <table className="table__content root">
                                {showEditable ? (
                                    <tbody className="table__content__new-item">
                                    <TableItem
                                        fields={fields}
                                        metaFields={metaFields}
                                        onClose={handleEditableClose}
                                        onAccept={handleEditableCheck}
                                        formOnly
                                    />
                                    </tbody>
                                ) : null}
                                <TableContent
                                    displayData={displayData}
                                    fields={fields}
                                    metaFields={metaFields}
                                    rows={rows}
                                    currentPage={currentPage}
                                    orderBy={orderBy}
                                    groupBy={groupBy}
                                    asc={asc}
                                    onHeaderClick={handleHeaderClick}
                                    selected={groupedSelected}
                                    root
                                />
                            </table>
                        </div>
                    </div>
                    <div className="table__pagination">
                        <TablePagination
                            rowList={[5, 10, 20]}
                            rows={rows}
                            currentPage={currentPage}
                            dataLength={displayData.length}
                            onRowNumberChange={handleRowNumberChange}
                            onCurrentPageChange={handleCurrentPageChange}
                        />
                    </div>
                </div>
                {report && report !== "-" && (
                    <SimpleModal title="We’re getting your report ready!">
                        <ShareReportForm onCancel={handleReportCancel} onContinue={handleReportContinue} />
                    </SimpleModal>
                )}
            </TableContext.Provider>
        </>
    )
}

export default React.memo(Table)
