import React from "react"

import "./Checkbox.css"

function Checkbox(props) {
    const { checked, status, onChange = () => null } = props

    return (
        <>
            <span className="checkbox">
                <input type="checkbox" checked={checked} onChange={onChange} className="checkbox__input" />
                <label className="checkbox__label">
                    {(status === "empty" && null) ||
                        (status === "half" && (
                            <svg
                                width="90%"
                                height="90%"
                                viewBox="0 0 8 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 1H8" stroke="#F7FAFE" />
                            </svg>
                        )) ||
                        (status === "full" && (
                            <svg
                                width="90%"
                                height="90%"
                                viewBox="0 0 8 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.74982 4.73743L1.01232 2.99993L0.420654 3.58743L2.74982 5.9166L7.74982 0.916601L7.16232 0.329102L2.74982 4.73743Z"
                                    fill="white"
                                />
                            </svg>
                        ))}
                </label>
            </span>
        </>
    )
}

export default React.memo(Checkbox)
