import React, { useMemo, useRef, useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { LineAreaChartComponent } from '../../../../components/Charts/LineAreaChart/LineAreaChart.component';
import { ToastContainer, toast } from 'react-toastify';

import {
  getHistoricalTemp,
  getForecastArr,
  getForecastTemp,
  getClim,
  trimmData
} from './helper';

import { convertToShadedRangesFormat, mergeHistoricalAndForecastData, getForecastConfidenceData, addMonths, validateData } from '../../../../helpers/chartHelpers';

import clsx from 'clsx';
import ChartSpecs from '../ChartSpecs';
import networking from '../../../../Util/Networking';
import { AuthContext } from '../../../../Auth/Auth';
import { isEmptyObject } from '../../../../Util/General';

const PrecipitationChart = ({ actionsState }) => {
  const chartRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const { id } = useParams();

  // Prepare initial data
  const weatherVariable = 'evaporation'
  const alertWeatherVariable = 'evapotranspiration'

  const [data, setData] = useState({
    'ds_hist': {
      time: [],
      'e_sum': [],
    },
    'ds_fc': {
      time: [],
      'e_sum': [],
    },
    'ds_clim': {
      time: [],
      'e_sum': [],
    },
    pending: true,
  });

  const [hourlyData, setHourlyData] = useState({
    'ds_hist': {
      time: [],
      t2m: [],
    },
    'ds_fc': {
      time: [],
      t2m: [],
    }
  })

  // Alerts Data
  const [alertsData, setAlertsData] = useState({
    e_sum: {},
  })

  // Load data
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      pending: true,
    }));
    currentUser
      .getIdToken()
      .then((userToken) => {
        networking.get(`/api/v1/weather/${weatherVariable}/daily/${id}`, {
          extraHeaders: { 'User-Token': userToken },
        })
          .then((res) => {
            setData({
              ...res.data,
              pending: false,
            });
          })
          .catch(() => {
            setData((prevData) => ({
              ...prevData,
              pending: false,
            }));
            toast.error('Error occurred with server. Please, try later.');
          });

        networking
          .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
            extraHeaders: { 'User-Token': userToken },
          })
          .then((res) => {
            if (isEmptyObject(res.data)) {
              toast.success(`There are no alerts for ${alertWeatherVariable}`);
            }
            setAlertsData(res.data)
          })
          .catch(() => {
            toast.warn(
              `Alerts not displayed on dashboard due to internet 
              connectivity issues. All other functions working.`);
            });
      });
  }, [currentUser, id]);

  // Prepare historical data
  const historicalTemp = useMemo(() => {
    try {
      return getHistoricalTemp(data['ds_hist']);
    } catch (e) {
      toast.error('Problem ocurred processsing information');
      return [];
    }
  }, [data]);

  // Prepare forecast data
  const forecastArr = useMemo(() => {
    try {
      return getForecastArr(data['ds_fc']);
    } catch (e) {
      return [];
    }
  }, [data]);
  const forecastTemp = useMemo(() => {
    try {
      return getForecastTemp(data['ds_fc'], forecastArr);
    } catch (e) {
      return [];
    }
  }, [data, forecastArr]);

  // Prepare Confidence Data
  const forecastConfidence75 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalTemp[historicalTemp.length - 1], data['ds_fc']['e_sum'], '0.75')
  }, [data, historicalTemp]);
  const forecastConfidence95 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalTemp[historicalTemp.length - 1], data['ds_fc']['e_sum'], '0.95')
  }, [data, historicalTemp]);


  // Prepare clim data
  const { climLighten, climDarken } = useMemo(() => {
    try {
      return getClim(data['ds_clim']);
    } catch (e) {
      return [];
    }
  }, [data]);

  // Look at the changes for historical and forecast data and display warning messages if invalid
  useMemo(() => {
    validateData({ diffToAlert: 15, historic: climLighten, forecast: forecastTemp, accessorKey: 'y', message: "Forecast Anomaly Detected" });
  }, [forecastTemp, climLighten])


  // Prepare csv data
  const histCsvData = data['ds_hist'].time.map((item, index) => {
    return [
      data['ds_hist']['e_sum'][index],
    ];
  });
  const forcCsvData = data['ds_fc'].time.map((item, index) => {
    return [
      forecastArr[index],
    ];
  });

  // Prepare array data
  const climArr = [].concat.apply([], Object.values(data['ds_clim']['e_sum']));

  // Assemble clim csv data
  const climCsvData = data['ds_clim'].time.map((item, index) => {
    return [
      item,
      climArr[index],
    ];
  });

  // Combine CSV data, which will be used for export
  const combinedCsvData = (clim, forecast, historical) => {
    const csvArr = [];
    let j = 0;
    for (let i = 0; i <= clim.length; i++) {
      if (historical[i]) {
        csvArr.push([
          ...clim[i],
          [''],
          [''],
          ...historical[i],
        ]);
      } else if (clim[i] && forecast[j]) {
        csvArr.push([
          ...clim[i],
          ...forecast[j],
          [''],
          [''],
        ]);
        j += 1;
      } else if (clim[i]) {
        csvArr.push([
          ...clim[i],
          [''],
          [''],
        ]);
      }
    }
    return csvArr;
  };

  // Use styles
  const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
      borderRadius: '20px',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Card className={clsx(classes.root)}>
        <CardContent>
          <div className="chart-container-element">

            <Box style={{ display: data.pending ? 'flex' : 'none' }}
              className="chart-preload-container">
              <CircularProgress />
            </Box>

            <LineAreaChartComponent

              // Pass height externally
              svgHeight={Math.max(window.innerHeight - 300, 600)}

              // Title text
              title="Evapotranspiration"

              // Set title hover text
              titleHover='This graph shows the daily observed and forecasted evapotranspiration.'

              // Y label text
              labelY="Evapotranspiration [mm]"

              // Convert received data to shaded ranges format
              shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData), `<svg width="16" height="16" viewBox="0 0 286 286" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M209.817 125.063L150.57 30.7183C149.71 29.5118 148.575 28.5282 147.258 27.8496C145.941 27.171 144.481 26.8169 143 26.8169C141.519 26.8169 140.059 27.171 138.742 27.8496C137.425 28.5282 136.29 29.5118 135.43 30.7183L75.9151 125.509C67.6469 138.846 63.0412 154.128 62.5625 169.813C62.5625 191.146 71.0371 211.605 86.1221 226.69C101.207 241.775 121.667 250.25 143 250.25C164.333 250.25 184.793 241.775 199.878 226.69C214.963 211.605 223.438 191.146 223.438 169.813C222.924 153.956 218.225 138.517 209.817 125.063ZM143 232.375C126.414 232.354 110.513 225.756 98.7851 214.027C87.057 202.299 80.4588 186.399 80.4375 169.813C80.9126 157.314 84.6668 145.161 91.3234 134.572L99.6799 121.255L189.716 211.292C183.869 217.917 176.68 223.223 168.626 226.86C160.572 230.496 151.837 232.379 143 232.384V232.375Z" fill="gray"/> </svg>`)}

              // Pass unique resize event key
              resizeEventListenerId="evapotranspiration-chart"

              // Add chart data id to filter out some update requests
              chartDataId={(actionsState.isMonthly ? 'month' : 'day') + '_evapotranspiration-chart_' + climLighten.length}

              // Center Ticks
              centerTicks={actionsState.isMonthly ? true : false}

              // Make chart to have dynamic y basis
              zeroBasis={false}

              // Bottom margin will be 0.2 times of data diff
              yBottomOffset={0.2}

              // Top margin will be 0.3 times of data diff
              yTopOffset={0.3}

              // Provide custom date max axis extent for monthly view charts
              xDateMax={actionsState.isMonthly ? addMonths(new Date(), 6) : null}

              // Provide custom date min axis extent for monthly view charts
              xDateMin={actionsState.isMonthly ? addMonths(new Date(), -7) : null}

              // How x ticks will be formatted in chart
              xTickFormat={actionsState.isMonthly ? (d, i, arr) => {
                // Remove last, overflowing tick item
                if (i === arr.length - 1) return '';
                return d.toLocaleString(undefined, { month: "short" })
              } : (d, i, arr) => {
                if (i < arr.length - 1 && i !== 0) return d.getDate();
                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate();
                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
              }}

              // Give chart tips count tip
              xTicksCount={actionsState.isMonthly ? 12 : 30}

              // Hide chart if data is pending
              hide={data.pending}

              // Tooltip content on line points mouse over
              tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:20px" rowspan="${values.length}"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(undefined, {
                  day: "numeric",
                  month: "short"
                })}</div></td> 
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[0].dashed ? 'dashed' : 'solid'} ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0])} mm</td>
                 </tr>
                 ${values.filter((d, i) => i > 0).map((value, i) => {
                  return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[i + 1].dashed ? 'dashed' : 'solid'} ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${Math.round(value)} mm</td></tr>`
                }).join('')}
             </table>`}
              }

              // Chart data content
              data={[
                {
                  type: 'area',
                  points: !actionsState.isMonthly ? trimmData(climLighten) : climLighten,
                  color: '#E2E2CA',
                  opacity: 0.6
                },
                {
                  type: 'area',
                  points: !actionsState.isMonthly ? trimmData(climDarken) : climDarken,
                  color: '#D3D292',
                  opacity: 0.6
                },
                // Confidence Bands
                {
                  type: 'area',
                  points: !actionsState.isMonthly ? trimmData(forecastConfidence95) : forecastConfidence95,
                  color: '#FF7002',
                  'opacity': 0.4,
                },
                {
                  type: 'area',
                  points: !actionsState.isMonthly ? trimmData(forecastConfidence75) : forecastConfidence75,
                  color: '#FF7002',
                  'opacity': 0.4,
                },
                {
                  type: 'line',
                  points: !actionsState.isMonthly ?
                    trimmData(historicalTemp).concat(trimmData(forecastTemp).map(d => Object.assign(d, { dashed: true }))) :
                    historicalTemp.concat(forecastTemp.map(d => Object.assign(d, { dashed: true }))),
                  color: '#FF7002',
                  'stroke-width': 2
                }
              ]}
            ></LineAreaChartComponent>
          </div>
          <div className="chart-specs-container">
            <ChartSpecs
              type="evapotranspiration"
              chartRef={chartRef}
              data={{
                csv: combinedCsvData(climCsvData, forcCsvData, histCsvData),
                hourlyCsv: mergeHistoricalAndForecastData({
                  forecast: hourlyData.ds_fc,
                  historical: hourlyData.ds_hist,
                  prop: 'e'
                })
              }}
              onHourlyCsvDataTrigger={() => {
                return new Promise((resolve, reject) => {
                  currentUser
                    .getIdToken()
                    .then((userToken) => {
                      networking
                        .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                          extraHeaders: { 'User-Token': userToken },
                        })
                        .then((res) => {
                          setHourlyData({
                            ...res.data,
                          });
                          resolve(res.data)
                        })
                        .catch(() => {
                          reject();
                        });
                    })
                })
              }}
              actionsState={actionsState}
            />
          </div>

        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default PrecipitationChart;
