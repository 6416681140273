import React, { createContext, useReducer } from "react"

export const DataContext = createContext({})

const initialState = {
    fields: [],
}

function DataReducer(state = {}, action) {
    const { type, payload } = action

    switch (type) {
        case "GET_FIELDS": {
            const { fields } = payload
            return { ...state, fields }
        }
        default: {
            return state
        }
    }
}

function DataProvider(props) {
    const { children } = props
    const [state, dispatch] = useReducer(DataReducer, initialState)

    return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>
}

export default DataProvider
