import React, { useContext, useEffect } from "react"
// Modules

// Components
import AlertContainer from "./AlertContainer"

import FloodIcon from "../../../../ui/Icons/FloodIcon"
import ThermometerIcon from "../../../../ui/Icons/ThermometerIcon"
import ThermometerUpIcon from "../../../../ui/Icons/ThermometerUpIcon"
import DrySoilIcon from "../../../../ui/Icons/DrySoilIcon"
import HeatIcon from "../../../../ui/Icons/HeatIcon"
import SunIcon from "../../../../ui/Icons/SunIcon"
import ColdIcon from "../../../../ui/Icons/ColdIcon"
import WetIcon from "../../../../ui/Icons/WetIcon"
import PestIcon from "../../../../ui/Icons/PestIcon"
import HeatSoilIcon from "../../../../ui/Icons/HeatSoilIcon"
import ColdSoilIcon from "../../../../ui/Icons/ColdSoilIcon"
import UploadFileIcon from "../../../../ui/Icons/UploadFileIcon"
import DownloadFileIcon from "../../../../ui/Icons/DownloadFileIcon"
import IconButton from "../../../../ui/Button/IconButton"

// Views

// Context providers / Utils
import { RISKS } from "./RisksList"
import { STACKED_RISKS } from "./RisksList"
import { AuthContext } from "../../../../Auth/Auth"
import { SettingsContext } from "../../../../Util/SettingsContext"
import { toast } from "react-toastify"

// Hooks

// Material-UI *

// Styles

// import "./AlertSettings.css"

function VarietyAlert(props) {
    const {
        varietyObj,
        path,
        onVarietyFileDownload = () => null,
        onVarietyFileUpload = () => null,
        isDownloadEnabled,
        isUploadEnabled,
        stackedAlertsView,
    } = props
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)
    const { currentUser, featurePermissions } = useContext(AuthContext)
    const RISK_LIST = !stackedAlertsView ? RISKS : STACKED_RISKS
    const getIcon = (riskName) => {
        switch (riskName) {
            case "risk_heat_stress":
                return <SunIcon />
            case "risk_cold":
                return <ColdIcon />
            case "risk_flood":
                return <FloodIcon />
            case "risk_cumulative_flood":
                return <FloodIcon />
            case "risk_drought":
                return <HeatIcon />
            case "risk_cumulative_drought":
                return <HeatIcon />
            case "risk_dry_soil":
                return <DrySoilIcon />
            case "risk_wet_soil":
                return <WetIcon />
            case "risk_high_soil_temperature":
                return <HeatSoilIcon />
            case "risk_low_soil_temperature":
                return <ColdSoilIcon />
            case "risk_growing_degree_days":
                return <ThermometerUpIcon />
            case "risk_low_humidity":
                return <HeatIcon />
            case "risk_high_humidity":
                return <WetIcon />
            case "risk_pest_degree_days":
                return <PestIcon />
            default:
                return <ThermometerIcon />
        }
    }

    function handleDownload() {
        onVarietyFileDownload(path.crop, path.variety)
    }

    function handleCSVUpload(e) {
        e.persist()
        const fileExt = e.target.value.split(".").pop()
        if (fileExt !== "csv") {
            toast.error("Error: Only .csv files are accepted")
            return
        }
        onVarietyFileUpload(path.crop, path.variety, e.target.files[0])
    }

    useEffect(() => {}, [])
    return (
        <div>
            <div className="variety-alert__controls">
                {isDownloadEnabled && !stackedAlertsView && (
                    <div className="variety-alert__controls__download">
                        <IconButton icon={DownloadFileIcon} onClick={handleDownload}>
                            Download Alerts
                        </IconButton>
                    </div>
                )}
                {isUploadEnabled && !stackedAlertsView && (
                    <div className="variety-alert__controls__upload" style={{ paddingLeft: 10 }}>
                        <IconButton icon={UploadFileIcon} type="file" fileTypesAllowed=".csv" onClick={handleCSVUpload}>
                            Upload Alerts
                        </IconButton>
                    </div>
                )}
            </div>

            {Object.keys(RISK_LIST).map((mRisk) => {
                let risk = !stackedAlertsView ? RISKS[mRisk] : STACKED_RISKS[mRisk]
                let alertsObj = varietyObj[mRisk]
                return (
                    <AlertContainer
                        key={"risk-" + mRisk}
                        riskData={risk}
                        icon={getIcon(mRisk)}
                        alertsObj={alertsObj}
                        path={{ ...path, risk: mRisk }}
                        units={units}
                        currentUser={currentUser}
                        featurePermissions={featurePermissions}
                    />
                )
            })}
        </div>
    )
}

export default React.memo(VarietyAlert)
