import React, { useEffect, useState, useContext } from "react"
// Modules

// Components
import AlertSelect from "./AlertSelect"
import AlertInput from "./AlertInput"
import AlertDateInput from "./AlertDateInput"
import AlertActiveToggle from "./AlertActiveToggle"
import DeleteModal from "../../../../ui/Modal/DeleteModal"
import { toast } from "react-toastify"

// Icons
import EditIcon from "../../../../ui/Icons/EditIcon"
import TrashIcon from "../../../../ui/Icons/TrashIcon"
import ArrowRight from "../../../../ui/Icons/ArrowRight"

// Views

// Context providers / Utils
import AlertsContext from "./AlertsContext"
import {
    getUnitsNotation,
    getCorrectUnitValue,
    cleanAlertData,
    cleanStackedAlertData,
    isAlertDataValid,
    cleanValidations,
} from "../../../../Util/Alerts"
import validations from "./ValidateAlertInput"
import { RISKS } from "./RisksList"

// Hooks

// Material-UI *

// Styles

const Alert = (props) => {
    const { riskData, alertData, onDelete, idAlert, path, units, currentUser, featurePermissions } = props

    const { onUpdateAlert, onUpdateStackedAlert, onDeleteAlert, toggleLoading } = useContext(AlertsContext)

    const cleanState = !alertData.stacked
        ? {
              probability: (alertData.probability * 100).toFixed(1),
              errors: {},
              flags: {},
          }
        : {
              active: true,
              risks: [],
              errors: { risks: [] },
              flags: { risks: [] },
          }

    const [isGDD, setIsGDD] = useState(riskData.isGDD)
    const [isStacked, setIsStacked] = useState(riskData.stacked)
    const [saveModalText, setSaveModalText] = useState("")
    const [isDisabled, setIsDisabled] = useState(true)
    const [dataBeforeEdit, setDataBeforeEdit] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [state, setState] = useState(cleanState)
    const [modal, setModal] = useState("")
    const cropStages = ["Custom"] // ["Planting", "Emergence", "Canopy C.", "Senescence", "Harvest", "Custom"]

    const handleOpenAlertToggle = () => {
        setIsAlertOpen(!isAlertOpen)
    }

    function handleInputChange(propName, indexOfRisk) {
        if (isStacked && indexOfRisk >= 0) {
            return (value, error, flag) => {
                let updatedRisks = [...state.risks]
                updatedRisks[indexOfRisk][propName] = value
                let updatedErrors = { ...state.errors }
                let updatedFlags = { ...state.flags }

                if ((propName !== "title", "recurrent_days")) {
                    updatedErrors.risks[indexOfRisk][propName] = error
                    updatedFlags.risks[indexOfRisk][propName] = flag
                } else {
                    updatedErrors[propName] = error
                    updatedFlags[propName] = flag
                }

                setState({
                    ...state,
                    risks: updatedRisks,
                    errors: updatedErrors,
                    flags: updatedFlags,
                })
            }
        }

        return (value, error, flag) => {
            setState({
                ...state,
                [propName]: value,
                errors: { ...state.errors, [propName]: error },
                flags: { ...state.flags, [propName]: flag },
            })
        }
    }

    function onEdit() {
        if (isStacked) {
            setDataBeforeEdit({ ...state, risks: JSON.parse(JSON.stringify(state.risks)) })
        } else {
            setDataBeforeEdit({ ...state })
        }
        setIsDisabled(false)
    }

    function onCancelEdit() {
        setState({ ...dataBeforeEdit })
        setIsDisabled(true)
    }

    function handleAlertTitleBlur(e) {
        let onChange = handleInputChange("title")
        const error = validations.title()(e.target.value)
        onChange(e.target.value, error, true)
    }

    function handleAlertTitleChange(e) {
        let onChange = handleInputChange("title")
        const error = validations.title()(e.target.value)
        onChange(e.target.value, error, false)
    }

    function checkStackedAlertData() {
        const errors = { risks: generateEmptyArray(alertData.risks.length) }
        const flags = { risks: generateEmptyArray(alertData.risks.length) }
        let dataHasErrors = false

        let vals = Object.assign({}, validations)

        let general_vals = ["title", "recurrent_days"]
        general_vals.map((val) => {
            let validationFunction = vals[val]()
            let err = validationFunction(state[val])
            errors[val] = err
            if (err !== null) {
                flags[val] = true
                dataHasErrors = true
            }
            delete vals[val]
        })

        state.risks.map((risk, index) => {
            let individualRiskData = RISKS[risk.risk]
            let data = risk
            let riskVals = cleanValidations(individualRiskData.isGDD, Object.assign({}, vals))

            const { errors: riskErrors, flags: riskFlags, dataHasErrors: riskDataHasErrors } = isAlertDataValid(
                riskVals,
                data,
                individualRiskData.units,
                units
            )
            dataHasErrors = dataHasErrors || riskDataHasErrors
            errors.risks[index] = riskErrors
            flags.risks[index] = riskFlags
        })

        setState({ ...state, errors, flags })
        if (dataHasErrors) {
            console.log("Data has errors = ", errors)
            return false
        }
        updateAlert()
        setIsDisabled(true)
        return true
    }

    function checkAlertData(isGDD) {
        let vals = cleanValidations(isGDD, Object.assign({}, validations))

        const { errors, flags, dataHasErrors } = isAlertDataValid(vals, state, riskData.units, units)

        setState({ ...state, errors, flags })
        if (dataHasErrors) {
            console.log("Data has errors = ", errors)
            return false
        }
        updateAlert()
        setIsDisabled(true)
        return true
    }

    function updateAlert() {
        toggleLoading(true)
        let alertData
        let updateAlertFunction
        if (isStacked) {
            alertData = cleanStackedAlertData(
                {
                    ...state,
                    risks: JSON.parse(JSON.stringify(state.risks)),
                    errors: { ...state.errors, risks: JSON.parse(JSON.stringify(state.errors)) },
                    flags: { ...state.flags, risks: JSON.parse(JSON.stringify(state.flags)) },
                },
                RISKS,
                units
            )
            updateAlertFunction = onUpdateStackedAlert
        } else {
            alertData = cleanAlertData(state, riskData.units, units, isGDD)
            updateAlertFunction = onUpdateAlert
        }
        updateAlertFunction(alertData, path)
            .then(() => {
                toggleLoading(false)
                toast.success(`${state?.title} has been updated`)
            })
            .catch((err) => {
                console.log("Error updating user alert ", err)
                toggleLoading(false)
                toast.error(`Error saving ${state?.title}, please try again later`)
            })
    }

    function deleteAlert() {
        setModal("")
        toggleLoading(true)
        onDeleteAlert(idAlert, path)
            .then(() => {
                onDelete(idAlert)
            })
            .catch((err) => {
                toast.error(`Error: Error deleting user alert. ${err}`)
                console.log("Error deleting user alert ", err)
            })
    }

    function generateEmptyArray(length) {
        return Array.apply(null, Array(length)).map(() => {
            return new Object()
        })
    }

    useEffect(() => {
        if (isStacked) {
            alertData.risks.map((risk, index) => {
                alertData.risks[index].probability = (alertData.risks[index].probability * 100).toFixed(1)
            })

            let newState = {
                ...alertData,
                errors: { risks: generateEmptyArray(alertData.risks.length) },
                flags: { risks: generateEmptyArray(alertData.risks.length) },
            }
            setState(newState)
        } else {
            let newState = { ...alertData }
            if (isGDD) {
                newState.gddSum = getCorrectUnitValue(riskData.units, "metric", units, alertData.threshold)
                newState.gddBase = getCorrectUnitValue(riskData.units, "metric", units, alertData.gddBase)
            } else {
                newState.threshold = getCorrectUnitValue(riskData.units, "metric", units, alertData.threshold)
            }
            setState({
                ...newState,
                probability: (alertData.probability * 100).toFixed(1),
                errors: {},
                flags: {},
            })
        }
    }, [])

    return (
        <div className="alert__content">
            <div className="alert__content__header">
                <div className="alert__content__title-dot">&middot;</div>
                {!isDisabled ? (
                    <div className="alert__content__title">
                        <input
                            className="alert__content__title-input"
                            type="text"
                            value={state?.title}
                            onChange={handleAlertTitleChange}
                            onBlur={handleAlertTitleBlur}
                            disabled={isDisabled ? "disabled" : ""}
                        />
                    </div>
                ) : (
                    <div className="alert__content__title">{state?.title}</div>
                )}
                {state.flags.title && <div className="alert__content__title-error">{state.errors.title}</div>}
                <AlertActiveToggle
                    isActive={alertData?.active}
                    path={path}
                    currentUser={currentUser}
                    featurePermissions={featurePermissions}
                />

                <ArrowRight
                    onClick={handleOpenAlertToggle}
                    className={isAlertOpen ? "alert__header__arrow-icon upside" : "alert__header__arrow-icon"}
                />
            </div>

            {isAlertOpen && (
                <div>
                    <div className="alert__content__conditions">
                        {isStacked ? (
                            state.risks.map((risk, index) => {
                                let individualRiskData = RISKS[risk.risk]
                                return (
                                    <div key={risk + index}>
                                        <div className="alert__content__conditions__options-s-margin">
                                            <div>
                                                <strong>If: &nbsp;</strong> Predicted {individualRiskData.modifier}{" "}
                                                {individualRiskData.variable} is {individualRiskData.conditional}
                                            </div>
                                            {!individualRiskData.isGDD && (
                                                <AlertInput
                                                    placeholder={30}
                                                    value={state.threshold === null ? "" : state.risks[index].threshold}
                                                    onChange={handleInputChange("threshold", index)}
                                                    match={validations.threshold(individualRiskData.units, units)}
                                                    error={state.errors.risks[index]?.threshold}
                                                    flag={state.flags.risks[index]?.threshold}
                                                    disabled={isDisabled}
                                                    min={-30}
                                                    max={2000}
                                                />
                                            )}
                                            {individualRiskData.isGDD && (
                                                <AlertInput
                                                    placeholder={300}
                                                    value={state.gddSum === null ? "" : state.risks[index].gddSum}
                                                    onChange={handleInputChange("gddSum", index)}
                                                    match={validations.gddSum(units)}
                                                    error={state.errors.risks[index]?.gddSum}
                                                    flag={state.flags.risks[index]?.gddSum}
                                                    disabled={isDisabled}
                                                    min={100}
                                                    max={10000}
                                                />
                                            )}
                                            <div>{getUnitsNotation(individualRiskData.units, units)}</div>
                                        </div>

                                        <div className="alert__content__conditions__options-m-margin">
                                            <strong>With: &nbsp;</strong> Probability of at least
                                            <AlertInput
                                                placeholder={80}
                                                value={state.risks[index].probability}
                                                onChange={handleInputChange("probability", index)}
                                                disabled={isDisabled}
                                                match={validations.probability()}
                                                error={state.errors.risks[index]?.probability}
                                                flag={state.flags.risks[index]?.probability}
                                                min={1}
                                                max={100}
                                            />
                                            %
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <>
                                <div className="alert__content__conditions__options-s-margin">
                                    <div>
                                        <strong>If: &nbsp;</strong> Predicted {riskData.modifier} {riskData.variable} is{" "}
                                        {riskData.conditional}
                                    </div>
                                    {!riskData.isGDD && (
                                        <AlertInput
                                            placeholder={30}
                                            value={state.threshold === null ? "" : state.threshold}
                                            onChange={handleInputChange("threshold")}
                                            match={validations.threshold(riskData.units, units)}
                                            error={state.errors.threshold}
                                            flag={state.flags.threshold}
                                            disabled={isDisabled}
                                            min={-30}
                                            max={2000}
                                        />
                                    )}
                                    {riskData.isGDD && (
                                        <AlertInput
                                            placeholder={300}
                                            value={state.gddSum === null ? "" : state.gddSum}
                                            onChange={handleInputChange("gddSum")}
                                            match={validations.gddSum(units)}
                                            error={state.errors.gddSum}
                                            flag={state.flags.gddSum}
                                            disabled={isDisabled}
                                            min={100}
                                            max={10000}
                                        />
                                    )}
                                    <div>{getUnitsNotation(riskData.units, units)}</div>
                                </div>

                                <div className="alert__content__conditions__options-m-margin">
                                    <strong>With: &nbsp;</strong> Probability of at least
                                    <AlertInput
                                        placeholder={80}
                                        value={state.probability}
                                        onChange={handleInputChange("probability")}
                                        disabled={isDisabled}
                                        match={validations.probability()}
                                        error={state.errors.probability}
                                        flag={state.flags.probability}
                                        min={1}
                                        max={100}
                                    />
                                    %
                                </div>

                                {riskData.isGDD && (
                                    <>
                                        <div className="alert__content__conditions__options-m-margin">
                                            <strong>And: &nbsp;</strong> {riskData.variable} Base is:
                                            <AlertInput
                                                placeholder={30}
                                                value={state.gddBase === null ? "" : state.gddBase}
                                                onChange={handleInputChange("gddBase")}
                                                match={validations.gddBase(riskData.units, units)}
                                                error={state.errors.gddBase}
                                                flag={state.flags.gddBase}
                                                disabled={isDisabled}
                                                min={-30}
                                                max={2000}
                                            />
                                            <div>{getUnitsNotation(riskData.units, units)}</div>
                                        </div>
                                        <div className="alert__content__conditions__options-m-margin">
                                            <strong>And: &nbsp;</strong> Total Plant Life Cycle:
                                            <AlertInput
                                                placeholder={90}
                                                value={state.gddLifeCycle === null ? "" : state.gddLifeCycle}
                                                onChange={handleInputChange("gddLifeCycle")}
                                                match={validations.gddLifeCycle()}
                                                error={state.errors.gddLifeCycle}
                                                flag={state.flags.gddLifeCycle}
                                                disabled={isDisabled}
                                                min={0}
                                                max={360}
                                            />
                                            <div>days </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {!riskData.isGDD && (
                            <div className="alert__content__conditions__options-s-margin">
                                <strong>And: &nbsp;</strong> {riskData.aggregated ? "Aggregated" : "Recurrent"} for
                                <AlertInput
                                    placeholder={3}
                                    value={state.recurrent_days === null ? "" : state.recurrent_days}
                                    onChange={handleInputChange("recurrent_days")}
                                    match={validations.recurrent_days()}
                                    error={state.errors.recurrent_days}
                                    flag={state.flags.recurrent_days}
                                    disabled={isDisabled}
                                    min={1}
                                    max={60}
                                />{" "}
                                consecutive days
                            </div>
                        )}

                        <div className="alert__content__conditions__options-s-margin">
                            <strong>During: &nbsp;</strong>{" "}
                            <AlertSelect options={cropStages} selectedIndex={1} disabled={isDisabled} />
                            Crop Stage
                        </div>

                        <div className="alert__content__conditions__options-m-margin">
                            <strong>Time Frame: &nbsp;</strong>
                            <AlertDateInput
                                value={state?.start_date}
                                disabled={isDisabled}
                                onChange={handleInputChange("start_date")}
                            />
                            to
                            <AlertDateInput
                                value={state?.end_date}
                                disabled={isDisabled}
                                onChange={handleInputChange("end_date")}
                            />
                        </div>
                    </div>
                    {isDisabled && (
                        <div className="alert__content__edit-button-container">
                            {featurePermissions.alerts.edit && (
                                <div className="alert__content__edit-icon" onClick={onEdit}>
                                    <EditIcon fill={"rgba(50, 50, 50, 0.5)"} />
                                </div>
                            )}

                            {featurePermissions.alerts.delete && (
                                <div
                                    className="alert__content__trash-icon"
                                    onClick={() => {
                                        setModal("deleteModal")
                                    }}
                                >
                                    <TrashIcon />
                                </div>
                            )}
                        </div>
                    )}
                    {!isDisabled && (
                        <div className="alert__content__button-container">
                            <button
                                className="alert__content__cancel-button"
                                onClick={() => {
                                    onCancelEdit()
                                }}
                            >
                                {" "}
                                Cancel{" "}
                            </button>
                            <button
                                className="alert__content__save-button"
                                onClick={() => {
                                    !isStacked ? checkAlertData(isGDD) : checkStackedAlertData()
                                }}
                            >
                                {" "}
                                Save{" "}
                            </button>
                        </div>
                    )}
                </div>
            )}

            {modal === "deleteModal" && (
                <DeleteModal
                    deleteItem={state?.title}
                    context="Alert"
                    onDelete={() => {
                        deleteAlert()
                    }}
                    onCancel={() => {
                        setModal("")
                    }}
                />
            )}
        </div>
    )
}

export default Alert
