const ranges = {
    metric: {
        forCelsius: {
            min: -30,
            max: 80,
        },
        forMM: {
            min: 0,
            max: 2000,
        },
        forCubicMeters: {
            min: 0,
            max: 2000,
        },
        forGDDSum: {
            min: 100,
            max: 10000,
        },
    },
    imperial: {
        forCelsius: {
            min: -22,
            max: 176,
        },
        forMM: {
            min: 0,
            max: 78.75,
        },
        forCubicMeters: {
            min: 0,
            max: 2.23,
        },
        forGDDSum: {
            min: 212,
            max: 18032,
        },
    },
    probability: {
        min: 0,
        max: 100,
    },
    lag: {
        min: -180,
        max: 180,
    },
    days: {
        min: 1,
        max: 60,
    },
    lifeCycleDays: {
        min: 1,
        max: 360,
    },
}

function isValueEmpty(value) {
    return value === "" || value === undefined || value === null
}

function validateTitle() {
    return function (value) {
        if (!value.replace(/\s/g, "").length || isValueEmpty(value)) {
            return "Your alert must have a title"
        }
        return null
    }
}

function validateThreshold(riskUnit, userUnits) {
    let min = -30
    let max = 100
    switch (riskUnit) {
        case "C":
            min = ranges[userUnits].forCelsius.min
            max = ranges[userUnits].forCelsius.max
            break
        case "mm":
            min = ranges[userUnits].forMM.min
            max = ranges[userUnits].forMM.max
            break
        case "mm/m^3":
            min = ranges[userUnits].forCubicMeters.min
            max = ranges[userUnits].forCubicMeters.max
            break
        case "%":
            min = ranges.probability.min
            max = ranges.probability.max
            break
        default:
            min = ranges[userUnits].forCelsius.min
            max = ranges[userUnits].forCelsius.max
            break
    }

    return function (value) {
        if (value === NaN || isValueEmpty(value)) {
            return "Variable must be a valid number"
        }
        if (value < min) {
            return "Variable must be equal or higher than " + min
        } else if (value > max) {
            return "Variable must be equal or lower than " + max
        } else {
            return null
        }
    }
}

function validateProbability() {
    return function (value) {
        if (value <= ranges.probability.min) {
            return "Probability must be higher than " + ranges.probability.min
        } else if (value > ranges.probability.max) {
            return "Probability must be equal or lower than " + ranges.probability.max
        } else {
            return null
        }
    }
}

function validateLagDays() {
    return function (value) {
        if (value === NaN || isValueEmpty(value)) {
            return "Variable must be a valid number"
        }

        if (value < ranges.lag.min) {
            return "Lag days must be equal or higher than " + ranges.lag.min
        } else if (value > ranges.lag.max) {
            return "Lag days must be equal or lower than " + ranges.lag.max
        } else {
            return null
        }
    }
}

function validateDays() {
    return function (value) {
        if (value < ranges.days.min) {
            return "Consecutive days must be equal or higher than " + ranges.days.min
        } else if (value > ranges.days.max) {
            return "Consecutive days must be equal or lower than " + ranges.days.max
        } else if (value % 1 !== 0) {
            return "Consecutive days must be a whole number"
        } else {
            return null
        }
    }
}

function validateLifeCycleDays() {
    return function (value) {
        if (value < ranges.lifeCycleDays.min) {
            return "Life cycle days must be equal or higher than " + ranges.lifeCycleDays.min
        } else if (value > ranges.lifeCycleDays.max) {
            return "Life cycle must be equal or lower than " + ranges.lifeCycleDays.max
        } else if (value % 1 !== 0) {
            return "Life cycle days must be a whole number"
        } else {
            return null
        }
    }
}

function validateGDDSum(userUnits) {
    let min = ranges[userUnits].forGDDSum.min
    let max = ranges[userUnits].forGDDSum.max

    return function (value) {
        if (value === NaN || isValueEmpty(value)) {
            return "Variable must be a valid number"
        }
        if (value < min) {
            return "Variable must be equal or higher than " + min
        } else if (value > max) {
            return "Variable must be equal or lower than " + max
        } else {
            return null
        }
    }
}

const validations = {
    title: validateTitle,
    threshold: validateThreshold,
    probability: validateProbability,
    recurrent_days: validateDays,
    gddBase: validateThreshold,
    gddSum: validateGDDSum,
    gddLifeCycle: validateLifeCycleDays,
    //lag: validateLagDays,
}
export default validations
