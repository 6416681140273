export default class EventEmiter {
    constructor() {
        this.events = {}
    }

    addListener(eventName, callback) {
        if (!eventName || !callback) throw new Error("You must provide an eventName and a callback. ")

        if (this.events[eventName] && Array.isArray(this.events[eventName])) this.events[eventName].push(callback)
        else this.events[eventName] = [callback]
    }

    removeListener(eventName, callback) {
        if (!eventName || !callback) throw new Error("You must provide an eventName and a callback. ")

        if (!this.events[eventName] || !Array.isArray(this.events[eventName])) throw new Error("Event does not exist.")

        let callbackIndex = this.events[eventName].indexOf(callback)

        if (callbackIndex > -1) this.events[eventName].splice(callbackIndex, 1)

        return 0
    }

    emit(eventName, ...args) {
        if (!eventName) throw new Error("You must provide an eventName.")

        if (this.events[eventName] || Array.isArray(this.events[eventName]))
            for (let event of this.events[eventName]) event(...args)
    }
}
