import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
            <path
                d="M8.688 7.75h-.494l-.175-.169a4.062 4.062 0 10-.438.438l.169.175v.493l3.125 3.12.931-.932L8.688 7.75zm-3.75 0a2.809 2.809 0 01-2.813-2.813 2.809 2.809 0 012.813-2.812A2.809 2.809 0 017.75 4.938 2.809 2.809 0 014.937 7.75z"
                fill="#1D2934"
            />
        </svg>
    )
}

export default SvgComponent
