import React, { useState, useContext, useRef } from "react"

// Modules

// Components
import EditControls from "./EditControls"
import DeleteControls from "./DeleteControls"
import FileInput from "../Input/FileInput"
import DataSourceDropdown from "./DataSourceDropdown"

// Views

// Context providers / Utils
import TableContext from "./TableContext"
import { UIAlertContext } from "../UIAlert/UIAlert"
import { isValidValue } from "../../helpers/table"

// Hooks

// Material-UI *

// Styles
import "./TableItem.css"

function TableItem(props) {
    //
    const {
        rowData = {},
        fields = [],
        metaFields = [],
        children,
        formOnly = false,
        onAccept = () => null,
        onClose = () => null,
        previousPath = "",
        path = "",
        checked,
        checkedStatus,
        // toggle = () => null,
        // open,
    } = props

    // data state
    const formData = {}
    for (let field of fields) if (field.form) formData[field.propName] = rowData[field.propName] || ""
    for (let metaField of metaFields)
        if (metaField.form) formData[metaField.propName] = rowData[metaField.propName] || ""

    const [state, setState] = useState({ ...formData })

    // const [open, toggle] = useState(true)
    // const [open, toggle] = useState(formOnly)
    const [isEditable, setIsEditable] = useState(formOnly)
    const [isDeletable, setIsDeletable] = useState(false)

    const {
        onRowUpdate,
        onRowDelete,
        onRowOpen,
        onRowClose,
        onRowSelect,
        onRowDeselect,
        onRemoveLabelFromField,
        openPaths,
        units,
        onDataSourceChange,
        showDataSourceDropdown,
    } = useContext(TableContext)

    const [open, toggle] = useState(formOnly)

    // Test
    const { confirmationModal } = useContext(UIAlertContext)

    // test, will change variable and function names later
    let currentPath = openPaths[path]
    function addCurrentPath(_value) {
        const rowValue = rowData.__group ? true : rowData
        if (_value) {
            onRowOpen(rowValue, path, previousPath)
        } else {
            onRowClose(rowValue, path, previousPath)
        }
    }

    // file uploader reference
    const fileUploaderRef = useRef(null)

    // handle changes inside input
    function handleFormInputChange(propName, value) {
        setState({ ...state, [propName]: value })
    }

    function handleItemClick() {
        addCurrentPath(!currentPath)
    }

    function handleItemEditPress() {
        setIsEditable(true)
        setIsDeletable(false)
        toggle(true)
    }

    function handleItemDeletePress() {
        if (!formOnly) {
            setIsDeletable(true)
            setIsEditable(false)
            toggle(false)
        }
    }

    // function handleRowDelete() {}

    async function handleRowDelete(rowData) {
        const value = await confirmationModal(
            "Are you sure you want to delete this field?",
            "This action can not be undone",
            ["Delete Field", "Cancel"]
        )

        if (!value) {
            setIsDeletable(false)
            return
        }

        onRowDelete(rowData)
    }

    async function handleRowUpdate() {
        // validation occours here uwu

        for (let field of fields)
            if (field.required && formOnly && !isValidValue(field.type, state[field.propName])) {
                // swal({
                //     text: `${field.displayName} is required.`,
                //     buttons: { cancel: "Close" },
                //     icon: "warning",
                // })
                await confirmationModal(null, `${field.displayName} is required.`)
                return
            }

        if (formOnly) {
            const value = await confirmationModal("New Field", "Make sure all the information is OK.", [
                "Yes, create new field.",
                "Cancel",
            ])

            console.log(value)

            if (!value) {
                return
            } else {
                onAccept({ ...state }, rowData)
            }
        } else {
            onRowUpdate({ ...state }, rowData)
        }

        setIsEditable(false)
        toggle(false)
    }

    function handleBothClose() {
        toggle(false)
        setState({ ...formData })
        if (!formOnly) {
            setIsEditable(false)
            setIsDeletable(false)
        } else {
            onClose()
        }
    }

    function handleCheckChange(e) {
        if (e.target.checked) onRowSelect(rowData)
        else onRowDeselect(rowData)
    }

    // set focus on first form item
    let focusIndex = -1

    // pass row config options for any available field
    const rowOptions = {
        toggleEdit: handleItemEditPress,
        toggleDelete: handleItemDeletePress,
        // toggle content functionallity
        toggleContent: handleItemClick,
        removeLabelFromField: onRemoveLabelFromField,
        isEditable,
        currentPath,
        // check functionallity
        handleCheckChange,
        checked,
        checkedStatus,
    }

    // DELETE - This is a test

    const dataSource = ["Option1", "Option2", "Option3"]

    return (
        <>
            {/* <tr className={`table-item`}> */}
            {/* {checkedStatus}
            <input
                type="checkbox"
                checked={checked}
                className="table-item__checkbox-test"
                onChange={handleCheckChange}
            /> */}
            {/* <div className="table-item__checkbox-text">
                <Checkbox checked={checked} status={checkedStatus} onChange={handleCheckChange} />
            </div> */}

            <tr className={`table-item ${open ? "active" : ""} ${isEditable ? "on-edit" : ""}`}>
                {fields &&
                    fields.map((field, index) => {
                        // individual field
                        const {
                            render,
                            component: Component,
                            headerStyle,
                            style,
                            className = "",
                            // toggler = false,
                        } = field

                        // find first form item
                        if (field.form && focusIndex < 0) focusIndex = index
                        return (
                            <td
                                key={`${rowData.uuid}_${field.propName}_${index}`}
                                className={`${className} ${
                                    !field.editField && isEditable && !field.form ? "blur" : ""
                                }`}
                                style={{
                                    ...(style || headerStyle),
                                    justifyContent:
                                        field.form && isEditable
                                            ? "flex-start"
                                            : style || headerStyle
                                            ? (style || headerStyle).justifyContent
                                            : "initial",
                                }}
                                // {...{ onClick: !isEditable && !isDeletable && toggler ? handleItemClick : undefined }}
                            >
                                {/* {!isEditable && toggler && !currentPath && <ArrowRightOutlined />} 
                                {!isEditable && toggler && currentPath && <ArrowDropDownOutlined />} */}
                                {field.deleteField && isDeletable ? (
                                    <DeleteControls
                                        onClose={handleBothClose}
                                        onDelete={() => handleRowDelete(rowData)}
                                    />
                                ) : field.editField && isEditable ? (
                                    <EditControls onClose={handleBothClose} onAccept={handleRowUpdate} />
                                ) : field.form && field.type === "string" && isEditable ? (
                                    <div className="table-item__field-input">
                                        <input
                                            type="text"
                                            autoFocus={index === focusIndex}
                                            placeholder={field.displayName}
                                            value={state[field.propName]}
                                            onChange={(e) => handleFormInputChange(field.propName, e.target.value)}
                                        />
                                    </div>
                                ) : field.form && field.type === "date" && isEditable ? (
                                    <div>
                                        <input
                                            type="date"
                                            value={
                                                (state[field.propName] ? new Date(state[field.propName]) : new Date())
                                                    .toISOString()
                                                    .split("T")[0]
                                            }
                                            onChange={(e) => handleFormInputChange(field.propName, e.target.value)}
                                            max={new Date().toISOString().split("T")[0]}
                                        />
                                    </div>
                                ) : field.form && field.type === "file" && isEditable ? (
                                    <div
                                        className="table-item__field-input"
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <FileInput
                                            type="file"
                                            multiple={false}
                                            onChange={(e) => handleFormInputChange(field.propName, e.target.files[0])}
                                            accept={".kml,.geojson,.zip"}
                                        />
                                    </div>
                                ) : !field.form && isEditable ? null : (
                                    (render && render(rowData[field.propName], rowData, rowOptions)) ||
                                    (Component && (
                                        <Component
                                            fieldData={rowData[field.propName]}
                                            rowData={rowData}
                                            rowOptions={rowOptions}
                                            propName={field.propName}
                                        />
                                    )) ||
                                    rowData[field.propName]
                                )}
                            </td>
                        )
                    })}
            </tr>
            {/* Table Meta Item */}
            {(currentPath || open) &&
                ((children && (
                    <tr className="nested-table__container">
                        <td colSpan={fields.length} className="nested-table">
                            <table className="table__content">
                                <tbody>{children}</tbody>
                            </table>
                        </td>
                    </tr>
                )) || (
                    <tr>
                        <td className={`table-item-meta-data ${isEditable ? "edit" : ""}`}>
                            {!isEditable && (
                                <DataSourceDropdown
                                    datasourcesData={rowData.datasources}
                                    fieldId={rowData.uuid}
                                    units={units}
                                    onConfirmChange={onDataSourceChange}
                                    showDataSourceDropdown={showDataSourceDropdown}
                                />
                            )}
                            {metaFields.map((metaField, index) => {
                                return (
                                    <div
                                        className="table-item-meta-data__field"
                                        key={`${rowData.uuid}_${metaField.propName}_editable_${index}`}
                                    >
                                        <strong>{metaField.displayName}: </strong>
                                        {isEditable && metaField.form && metaField.type === "string" ? (
                                            <input
                                                type="text"
                                                placeholder={metaField.displayName}
                                                value={state[metaField.propName]}
                                                onChange={(e) =>
                                                    handleFormInputChange(metaField.propName, e.target.value)
                                                }
                                            />
                                        ) : isEditable && metaField.form && metaField.type === "date" ? (
                                            <input
                                                type="date"
                                                value={
                                                    (state[metaField.propName]
                                                        ? new Date(state[metaField.propName])
                                                        : new Date()
                                                    )
                                                        .toISOString()
                                                        .split("T")[0]
                                                }
                                                onChange={(e) =>
                                                    handleFormInputChange(metaField.propName, e.target.value)
                                                }
                                                max={new Date().toISOString().split("T")[0]}
                                            />
                                        ) : (
                                            rowData[metaField.propName] || "N/A"
                                        )}
                                    </div>
                                )
                            })}
                        </td>
                    </tr>
                ))}
        </>
    )
}

export default React.memo(TableItem)
