import React, { useCallback, useMemo, useRef, useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { LineAreaChartComponent } from '../../../../components/Charts/LineAreaChart/LineAreaChart.component';
import { toast, ToastContainer } from 'react-toastify';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {
  getHistoricalTemp,
  getForecastArr,
  getForecastTemp,
  getClim,
  trimmData,
} from './helper';
import { convertToShadedRangesFormat, assembleAreaData, assembleLineData, mergeHistoricalAndForecastData, getForecastConfidenceData, addMonths, validateData } from '../../../../helpers/chartHelpers';

import clsx from 'clsx';
import ChartSpecs from '../ChartSpecs';
import networking from '../../../../Util/Networking';
import { AuthContext } from '../../../../Auth/Auth';


// Create custom theme for switch slider
const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#ccc"
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#fafafa"
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "gray",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 1,
          backgroundColor: "#41A3A4"
        }
      }
    }
  }
});



const GrowingDegreeDaysChart = ({ actionsState }) => {
  const chartRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const { id } = useParams();

  // Define threshold value
  const initialThreshold = -10;
  const [threshold, setThreshold] = useState(initialThreshold);

  const [GDDPlantingDate, setGDDPlantingDate] = React.useState(addMonths(new Date(), -7));

  // Define cumulative switch state
  const [cumulative, setCumulative] = useState(false);

  // Define slider extent values
  const [tempExtent, setTempExtent] = useState([0, 100])

  // Define GDD specific transformed data
  const [dataGDD, setDataGDD] = useState({
    'ds_hist': {
      time: [],
      't2m_avg': [],
    },
    'ds_fc': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    'ds_clim': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    pending: true,
  });
  const [hourlyDataGDD, setHourlyDataGDD] = useState({
    'ds_hist': {
      time: [],
      't2m_avg': [],
    },
    'ds_fc': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    'ds_clim': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    pending: true,
  });
  // Prepare initial data
  const [monthlyDataGDD, setMonthlyDataGDD] = useState({
    'ds_hist': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    'ds_fc': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    'ds_clim': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    pending: true,
  });


  // Prepare initial data
  const [data, setData] = useState({
    'ds_hist': {
      time: [],
      't2m_avg': [],
    },
    'ds_fc': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    'ds_clim': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    pending: true,
  });

  const [hourlyData, setHourlyData] = useState({
    'ds_hist': {
      time: [],
      t2m_avg: [],
      gdd: [],
    },
    'ds_fc': {
      time: [],
      t2m_avg: [],
      gdd: [],
    }
  })

  // Prepare initial data
  const [monthlyData, setMonthlyData] = useState({
    'ds_hist': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    'ds_fc': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    'ds_clim': {
      time: [],
      't2m_avg': [],
      gdd: [],
    },
    pending: true,
  });

  // Alerts Data
  const [alertsData, setAlertsData] = useState({
    t2m_max: {},
    t2m_min: {},
  })


  // Transform temp data to GDD (By adding gdd property to relevant object)
  const transformTempToGDD = (tempData, threshold) => {
    // If passed data item, has array of average values
    const isArray = Array.isArray(tempData.t2m_avg);
    if (isArray) {
      // Calculate GDD from it
      tempData.gdd = tempData.t2m_avg.map((d, i) => Math.max(d - threshold, 0))
    } else {
      // If not, calculate GDD for each confidence levels
      const keys = Object.keys(tempData.t2m_avg);
      tempData.gdd = {};
      keys.forEach(confidenceLevel => {
        tempData.gdd[confidenceLevel] = tempData.t2m_avg[confidenceLevel].map((d, i) => Math.max(d - threshold, 0));
      })
    }
    return tempData;
  }

  // Extract data properties and calculate gdd for each oone
  const transformToGdd = (data, threshold) => {
    const result = {};
    ["ds_hist", "ds_fc", "ds_clim"]
      .filter(k => data[k])
      .forEach(k => {
        result[k] = transformTempToGDD(data[k], threshold);
      })
    return result;
  }

  // Simple function to get extent (min,max) of array of numbers
  const getExtent = (arr) => {
    const min = Math.min(...arr);
    const max = Math.max(...arr);
    return [min, max];
  }

  // Update array of min, max with the new ones
  const updateExtent = (oldExtent, newExtent) => {
    if (oldExtent[0] > newExtent[0]) {
      oldExtent[0] = newExtent[0]
    }
    if (oldExtent[1] < newExtent[1]) {
      oldExtent[1] = newExtent[1]
    }
  }

  // Extract temperature extents from all data
  const extractTempExtent = (dataGdd, hourlyDataGDD, monthlyDataGDD) => {
    let extent = [200, -200];
    [dataGdd, hourlyDataGDD, monthlyDataGDD].forEach(data => {
      ["ds_hist", "ds_fc", "ds_clim"]
        .filter(k => data[k])
        .forEach(k => {
          const tempData = data[k];
          if (Array.isArray(tempData.t2m_avg)) {
            const newExtent = getExtent(tempData.t2m_avg);
            updateExtent(extent, newExtent)
          } else {
            const keys = Object.keys(tempData.t2m_avg);
            keys.forEach(confidenceLevel => {
              const newExtent = getExtent(tempData.t2m_avg[confidenceLevel]);
              updateExtent(extent, newExtent)
            })
          }
        })
    })
    return extent;
  }

  // Listhen for threshold,monthlydata, and data
  useMemo((d) => {
    // Calculate GDD's and update extents
    setDataGDD(transformToGdd(data, threshold));
    setHourlyDataGDD(transformToGdd(hourlyData, threshold));
    setMonthlyDataGDD(transformToGdd(monthlyData, threshold));
    const tempExtentNew = extractTempExtent(dataGDD, hourlyDataGDD, monthlyDataGDD);
    if (tempExtentNew[1] > tempExtentNew[0]) {
      tempExtentNew[0] = Math.floor(tempExtentNew[0])
      tempExtentNew[1] = Math.ceil(tempExtentNew[1])
      setTempExtent(tempExtentNew);
    }
  }, [threshold, monthlyData, data])

  useMemo(() => {
    if (threshold < tempExtent[0] || threshold > tempExtent[1]) {
      setThreshold((tempExtent[1] + tempExtent[0]) / 2);
    }
  }, [tempExtent])

  // Adds t2m_avg property, calculated from (t2m_max, t2m_min props)
  const addAvgToTemp = (data) => {
    if (data.t2m_max) {
      const isArray = Array.isArray(data.t2m_max);
      if (isArray) {
        data.t2m_avg = data.t2m_max.map((d, i) => (d + data.t2m_min[i]) / 2);
      } else {
        const keys = Object.keys(data.t2m_max);
        data.t2m_avg = {};
        keys.forEach(confidenceLevel => {
          data.t2m_avg[confidenceLevel] = data.t2m_max[confidenceLevel].map((d, i) => (d + data.t2m_min[confidenceLevel][i]) / 2);
        })
      }
    } else {
      const result = {};
      const keys = Object.keys(data);
      keys.forEach(k => {
        result[k] = addAvgToTemp(data[k]);
      })
      return result;
    }
    return data;
  }

  // Load data
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      pending: true,
    }));
    currentUser
      .getIdToken()
      .then((userToken) => {
        const weatherVariable = 'temperature';
        networking
          .get(`/api/v1/weather/${weatherVariable}/daily/${id}`, {
            extraHeaders: { 'User-Token': userToken },
          })
          .then((res) => {
            const data = res.data;
            const transformedData = addAvgToTemp(data);
            setData({
              ...transformedData,
              pending: false,
            });
          })
          .catch((e) => {
            setData((prevData) => ({
              ...prevData,
              pending: false,
            }));
            toast.error('Error occurred with server. Please, try later.');
          });

        networking
          .get(`/api/v1/weather/${weatherVariable}/monthly/${id}`, {
            extraHeaders: { 'User-Token': userToken },
          })
          .then((res) => {
            const data = res.data;
            const transformedData = addAvgToTemp(data);
            setMonthlyData({
              ...transformedData,
              pending: false,
            });
          })
          .catch(() => {
            setMonthlyData((prevData) => ({
              ...prevData,
              pending: false,
            }));
            toast.warn('Could not load monthly data.');
          });

        networking
          .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
            extraHeaders: { 'User-Token': userToken },
          })
          .then((res) => {
            setAlertsData(res.data)
          })
          .catch(() => {
            toast.warn(
              `Alerts not displayed on dashboard due to internet 
              connectivity issues. All other functions working.`);
          });
      });
  }, [currentUser, id]);

  // Prepare historical data
  const historicalTemp = useMemo(() => getHistoricalTemp(dataGDD['ds_hist']), [dataGDD]);

  // Prepare forecast data
  const forecastArr = useMemo(() => getForecastArr(dataGDD['ds_fc']), [dataGDD]);
  const forecastTemp = useMemo(() => {
    return getForecastTemp(dataGDD['ds_fc'], forecastArr);
  }, [dataGDD, forecastArr]);

  // Prepare areas data
  const { climLighten, climDarken } = useMemo(() => getClim(dataGDD['ds_clim']), [dataGDD]);

  // Prepare Confidence Data
  const forecastConfidence75 = useMemo(() => {
    return getForecastConfidenceData(dataGDD['ds_fc'], historicalTemp[historicalTemp.length - 1], dataGDD['ds_fc']['gdd'], '0.75')
  }, [dataGDD, historicalTemp]);
  const forecastConfidence95 = useMemo(() => {
    return getForecastConfidenceData(dataGDD['ds_fc'], historicalTemp[historicalTemp.length - 1], dataGDD['ds_fc']['gdd'], '0.95')
  }, [dataGDD, historicalTemp]);

  // Look at the changes for historical and forecast data and display warning messages if invalid
  useMemo(() => {
    validateData({ diffToAlert: 25, historic: climLighten, forecast: forecastTemp, accessorKey: 'y', message: "Forecast Anomaly Detected" });
  }, [forecastTemp, climLighten])

  // Prepare csv data
  const histCsvData = dataGDD['ds_hist'].time.map((item, index) => {
    return [
      item,
      dataGDD['ds_hist']['gdd'][index],
    ];
  });
  const forcCsvData = dataGDD['ds_fc'].time.map((item, index) => {
    return [
      item,
      forecastArr[index],
    ];
  });
  const climArr = [].concat.apply([], Object.values(dataGDD['ds_clim']['gdd']));
  const climCsvData = dataGDD['ds_clim'].time.map((item, index) => {
    return [
      item,
      climArr[index],
    ];
  });
  const combinedCsvData = (clim, forecast, historical) => {
    const csvArr = [];
    let j = 0;
    for (let i = 0; i <= clim.length; i++) {
      if (historical[i]) {
        csvArr.push([
          ...clim[i],
          [''],
          historical[i][1],
        ]);
      } else if (clim[i] && forecast[j]) {
        csvArr.push([
          ...clim[i],
          forecast[j][1],
          [''],
        ]);
        j += 1;
      } else if (clim[i]) {
        csvArr.push([
          ...clim[i],
          [''],
        ]);
      }
    }
    return csvArr;
  };

  // Change slider handler
  const handleChangeSlider = useCallback((e, value) => {
    setThreshold(value)
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
      borderRadius: '20px',
    },
  }));


  // Define line data basis to accompany forecast accumulative GDD data
  const lineData = useMemo(d => {
    return assembleLineData({
      isMonthly: actionsState.isMonthly,
      historical: historicalTemp.filter(d => d.x > GDDPlantingDate),
      isCumulative: cumulative,
      cumulativeType: 'sum_per_month',
      forecast: forecastTemp.filter(d => d.x > GDDPlantingDate),
      seasonal: monthlyData.ds_fc.time.map((d, i) => {
        return {
          x: new Date(d),
          y: monthlyData.ds_fc.gdd['0.5'][i]
        }
      })
        .filter(d => d.x > GDDPlantingDate)
    })

  }, [actionsState.isMonthly, historicalTemp, cumulative, forecastTemp, monthlyData, GDDPlantingDate])

  // Calculate base value from lineData to use with forecast areas
  const lineDataBaseValue = useMemo(() => {
    return historicalTemp.filter(d => d.x > GDDPlantingDate);
  }, [lineData, GDDPlantingDate])

  const classes = useStyles();

  return (
    <>
      <Card className={clsx(classes.root)}>
        <CardContent>

          <div className="chart-container-element">

            <div style={{
              position: 'absolute',
              marginTop: '50px',
              marginLeft: '103px',
              border: '1px solid #41A3A4',
              borderRadius: 8,
              padding: '5px',
              paddingTop: '8px',
              paddingBottom: '8px',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 4px 10px rgb(56 78 99 / 40%)',
              display: actionsState.isMonthly && !data.pending ? 'initial' : 'none'
            }}>
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cumulative}
                      onChange={d => { setCumulative(!cumulative) }}
                      name="cumulative_check"
                    />
                  }
                  label="Cumulative"
                />
              </ThemeProvider>
            </div>

            <Box style={{ display: data.pending ? 'flex' : 'none' }}
              className="chart-preload-container">
              <CircularProgress />
            </Box>

            <LineAreaChartComponent

              // Pass height externally
              svgHeight={Math.max(window.innerHeight - 300, 600)}

              // Title text
              title="Growing Degree Days"

              // Set title hover text
              titleHover={actionsState.isMonthly ? "This graphs shows the monthly observed and forecasted solar radiation" : "This graph shows the daily observed and forecasted solar radiation."}

              // Y label text
              labelY="Accumulated GDD"

              // Add chart data id to filter out some update requests
              chartDataId={(actionsState.isMonthly ? 'month' : 'day') + '_growing-degree-days_' + climLighten.length}

              // Pass unique resize event key
              resizeEventListenerId="growing-degree-days-chart"

              // Convert received data to shaded ranges format
              shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData), `<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path transform="translate(0,5)" fill="#4B545F" d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/> </svg>`)}

              // Center Ticks
              centerTicks={actionsState.isMonthly ? true : false}

              // Make chart to have zero y basis
              zeroBasis={true}

              // Bottom margin will be 0.2 times of data diff
              yBottomOffset={0.2}

              // Top margin will be 0.3 times of data diff
              yTopOffset={0.3}

              // Provide custom date max axis extent for monthly view charts
              xDateMax={actionsState.isMonthly ? addMonths(new Date(), 7) : null}

              // Provide custom date min axis extent for monthly view charts
              xDateMin={actionsState.isMonthly ? addMonths(new Date(), -7) : null}

              // How x ticks will be formatted in chart
              xTickFormat={actionsState.isMonthly ? (d, i, arr) => {
                // Remove last, overflowing tick item
                if (i === arr.length - 1) return '';
                return d.toLocaleString(undefined, { month: "short" })
              } : (d, i, arr) => {
                if (i < arr.length - 1 && i !== 0) return d.getDate();
                if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate();
                return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
              }}

              // Give chart tips count tip
              xTicksCount={actionsState.isMonthly ? 12 : 30}

              // Hide chart if data is pending
              hide={data.pending}

              // Tooltip content on line points mouse over
              tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
     <tr><td style="font-weight:bold;font-size:20px" rowspan="${values.length}"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(undefined, {
                  day: "numeric",
                  month: "short"
                })}</div></td> 
         <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[0].dashed ? 'dashed' : 'solid'} ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0])} GDDs</td>
     </tr>
     ${values.filter((d, i) => i > 0).map((value, i) => {
                  return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[i + 1].dashed ? 'dashed' : 'solid'} ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${Math.round(value)} GDDs </td></tr>`
                }).join('')}
 </table>`}
              }

              // Chart data content
              data={[
                // {
                //   type: 'area',
                //   points: !actionsState.isMonthly ? trimmData(climLighten) : climLighten,
                //   color: '#F8D6C5',
                //   opacity: 0.6
                // },
                // {
                //   type: 'area',
                //   points: !actionsState.isMonthly ? trimmData(climDarken) : climDarken,
                //   color: '#FDBE9D',
                //   opacity: 0.6
                // },
                // Confidence Bands
                {
                  type: 'area',
                  points: assembleAreaData({
                    lineDataBaseValue: lineDataBaseValue,
                    areaData: forecastConfidence95.filter(d => d.x > GDDPlantingDate),
                    isMonthly: actionsState.isMonthly,
                    isCumulative: cumulative,
                    cumulativeType: 'cumulative_sum_per_month',
                    //   climatology: duplicateMonthlyHistoricalDataForFutureSixMonths(climMaxLighten),
                    seasonal: monthlyData.ds_fc.time.map((d, i) => {
                      return {
                        x: +new Date(d),
                        key: +new Date(d),
                        y1: monthlyData.ds_fc.gdd['0.95'][i],
                        y0: monthlyData.ds_fc.gdd['0.05'][i],
                        max: monthlyData.ds_fc.gdd['0.95'][i],
                        min: monthlyData.ds_fc.gdd['0.05'][i]
                      }
                    }).filter(d => d.x > GDDPlantingDate),
                  }),
                  color: actionsState.isMonthly ? '#FFBB97' : '#FF7100',
                  'opacity': actionsState.isMonthly ? 1 : 0.4,
                },
                {
                  type: 'area',
                  points: assembleAreaData({
                    lineDataBaseValue: lineDataBaseValue,
                    areaData: forecastConfidence75.filter(d => d.x > GDDPlantingDate),
                    isMonthly: actionsState.isMonthly,
                    isCumulative: cumulative,
                    cumulativeType: 'cumulative_sum_per_month',
                    //climatology: climMaxDarken,
                    seasonal: monthlyData.ds_fc.time.map((d, i) => {
                      return {
                        x: +new Date(d),
                        key: +new Date(d),
                        y1: monthlyData.ds_fc.gdd['0.75'][i],
                        y0: monthlyData.ds_fc.gdd['0.25'][i],
                        max: monthlyData.ds_fc.gdd['0.75'][i],
                        min: monthlyData.ds_fc.gdd['0.25'][i]
                      }
                    }).filter(d => d.x > GDDPlantingDate),
                  }),
                  color: actionsState.isMonthly ? '#FB9769' : '#FF7100',
                  'opacity': actionsState.isMonthly ? 1 : 0.4,
                },

                {
                  type: 'line',
                  points: assembleLineData({
                    isMonthly: actionsState.isMonthly,
                    historical: historicalTemp.filter(d => d.x > GDDPlantingDate),
                    isCumulative: cumulative,
                    cumulativeType: 'cumulative_sum_per_month',
                    forecast: forecastTemp.filter(d => d.x > GDDPlantingDate),
                    seasonal: monthlyData.ds_fc.time.map((d, i) => {
                      return {
                        x: new Date(d),
                        y: monthlyData.ds_fc.gdd['0.5'][i]
                      }
                    }).filter(d => d.x > GDDPlantingDate),
                  }),
                  color: '#FF7100',
                  'stroke-width': 2,
                }
              ]}
            ></LineAreaChartComponent>
          </div>
          <div className="chart-specs-container">
            <ChartSpecs
              type="growing-degree-days"
              chartRef={chartRef}
              data={{
                csv: combinedCsvData(climCsvData, forcCsvData, histCsvData),
                // hourlyCsv: mergeHistoricalAndForecastData({
                //   forecast: hourlyData.ds_fc,
                //   historical: hourlyData.ds_hist,
                //   prop: 'ssrd'
                // })
              }}
              onHourlyCsvDataTrigger={() => {
                return new Promise((resolve, reject) => {
                  currentUser
                    .getIdToken()
                    .then((userToken) => {
                      networking
                        .get(`/api/v1/weather/solar_radiation/hourly/${id}`, { // Needs change
                          extraHeaders: { 'User-Token': userToken },
                        })
                        .then((res) => {
                          setHourlyData({
                            ...res.data,
                          });
                          resolve(res.data)
                        })
                        .catch(() => {
                          reject();
                        });
                    })
                })
              }}
              sliderMinValue={tempExtent[0]}
              sliderMaxValue={tempExtent[1]}
              sliderDefaultValue={threshold}
              GDDPlantingDate={GDDPlantingDate}
              onGDDPlantingDateChange={d => {
                setGDDPlantingDate(d)
              }}
              onSliderChange={handleChangeSlider}
              actionsState={actionsState}
            />
          </div>

        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default GrowingDegreeDaysChart;
