import * as React from "react"

function UploadIcon(props) {
    const { fill, size } = props
    return (
        <div>
            <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
                <path
                    d="M14.512 7.53A5.617 5.617 0 009 3a5.622 5.622 0 00-4.987 3.03A4.496 4.496 0 000 10.5C0 12.982 2.018 15 4.5 15h9.75c2.07 0 3.75-1.68 3.75-3.75a3.732 3.732 0 00-3.488-3.72zM10.5 9.75v3h-3v-3H5.25L9 6l3.75 3.75H10.5z"
                    fill={fill || "#1C9690"}
                    size={size || 18}
                />
            </svg>
        </div>
    )
}

export default UploadIcon
