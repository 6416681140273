// Import React
import React, { useContext } from "react"
// Import Components
import { TabView} from "./components"
import { AuthContext } from "../../Auth/Auth"
import "./Weather.css"

const Weather = () => {
    const { featurePermissions } = useContext(AuthContext)
    
    return (
        <div className="weather-model">
            <div>
                <TabView allowedWeatherVariables={featurePermissions["weather_meta"]} />
            </div>
        </div>
    )
}

export default Weather
