import * as React from "react"

function TrashIcon(props) {
    const { fill, size } = props

    return (
        <div>
            <svg width={size} height={size} viewBox="0 0 17 17" fill="none" {...props}>
                <path
                    d="M4.25 13.458c0 .78.638 1.417 1.417 1.417h5.666a1.42 1.42 0 001.417-1.417v-8.5h-8.5v8.5zm9.208-10.625H10.98l-.708-.708H6.729l-.708.708H3.54V4.25h9.917V2.833z"
                    fill={fill || "#323232"}
                    opacity={0.5}
                    size={size || "17"}
                />
            </svg>
        </div>
    )
}

export default TrashIcon
