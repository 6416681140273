import React, { useState, useEffect, useRef, useContext } from "react"
// Modules
// Components
import ToggleSwitch from "../../../../ui/ToggleSwitch/ToggleSwitch"
// Views
// Context providers / Utils
import AlertsContext from "./AlertsContext"

// Hooks
// Material-UI *
// Styles

const AlertActiveToggle = (props) => {
    const { isActive, path = null, featurePermissions } = props
    const [isAlertActive, setIsAlertActive] = useState(isActive)
    const { onAlertActiveChange } = useContext(AlertsContext)

    function handleActiveChange(value) {
        console.log("Receiving value = ", value)
        if (!path || !featurePermissions.alerts.edit) {
            return
        }
        setIsAlertActive(value)
        onAlertActiveChange(value, path)
    }

    return (
        <>
            <ToggleSwitch
                isOn={isAlertActive}
                isAllowed={featurePermissions.alerts.edit}
                onChange={handleActiveChange}
                onText={"Disable alert"}
                offText={"Enable alert"}
            />
        </>
    )
}

export default AlertActiveToggle
