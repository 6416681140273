import React from "react"

function PlusIcon(props) {
    const { fill, color } = props

    return (
        <>
            <div>
                <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d)">
                        <circle cx="11" cy="11" r="7" fill="#1C9690" />
                    </g>
                    <path
                        d="M14.913 11.6522H11.6521V14.9131C11.6521 15.2718 11.3587 15.5652 11 15.5652C10.6413 15.5652 10.3478 15.2718 10.3478 14.9131V11.6522H7.08693C6.72823 11.6522 6.43475 11.3587 6.43475 11C6.43475 10.6413 6.72823 10.3479 7.08693 10.3479H10.3478V7.08699C10.3478 6.72829 10.6413 6.43481 11 6.43481C11.3587 6.43481 11.6521 6.72829 11.6521 7.08699V10.3479H14.913C15.2717 10.3479 15.5652 10.6413 15.5652 11C15.5652 11.3587 15.2717 11.6522 14.913 11.6522Z"
                        fill="white"
                    />
                    <defs>
                        <filter
                            id="filter0_d"
                            x="0"
                            y="0"
                            width="22"
                            height="22"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </>
    )
}

export default PlusIcon
