import React, { useState, useEffect } from "react"

import TimesIcon from "../Icons/TimesIcon"

import "./UIModal.css"

function UIModal(props) {
    const { text, description, buttons, onClose, onContinue, visible, image, customDescription, lowerImage } = props

    // TODO we can make a custom hook out of this behaviour
    const [active, toggle] = useState(visible)
    const [className, setClassName] = useState("")

    // how much the animation takes to exit
    const MODAL_EXIT_ANIMATION_DURATION = 300

    // delay active to handle exit animation
    useEffect(() => {
        if (!visible) {
            setClassName("exit")
            setTimeout(() => {
                setClassName("")
                toggle(false)
            }, MODAL_EXIT_ANIMATION_DURATION)
        } else {
            toggle(true)
        }
    }, [visible])

    if (!active) return null

    return (
        <>
            <div className={"ui-modal__container " + className}>
                <div className="ui-modal__background" />
                <div className="ui-modal">
                    {image}
                    <button className="ui-modal__close-button" onClick={onClose}>
                        <TimesIcon />
                    </button>
                    {text && <h3 className="ui-modal__title">{text}</h3>}
                    {description && <p className="ui-modal__description">{description}</p>}
                    {customDescription}
                    {lowerImage}
                    <div className="ui-modal__buttons">
                        {(buttons && (
                            <>
                                <button className="ui-modal__buttons__principal" onClick={onContinue}>
                                    {(buttons && buttons[0]) || "Continue"}
                                </button>
                                <button className="ui-modal__buttons__secondary" onClick={onClose}>
                                    {(buttons && buttons[1]) || "Cancel"}
                                </button>
                            </>
                        )) || (
                            <button className="ui-modal__buttons__principal" onClick={onClose}>
                                Ok
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(UIModal)
