import React, { useMemo, useState, useRef, useEffect, useContext } from 'react';
import { Box, Card, CardContent, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { LineAreaChartComponent } from '../../../../components/Charts/LineAreaChart/LineAreaChart.component';
import { ToastContainer, toast } from 'react-toastify';

import { AuthContext } from '../../../../Auth/Auth';
import { SettingsContext } from "../../../../Util/SettingsContext"
import networking from '../../../../Util/Networking';
import './styles.css';
import { isEmptyObject } from '../../../../Util/General';

import {
  getHistoricalMinTemp,
  getHistoricalMaxTemp,
  getForecastMinTemp,
  getForecastMaxTemp,
  getClimMin,
  getClimMax,
  getForecastMinArr,
  getForecastMaxArr,
} from './helper';
import { processUnitSystem, trimSeasonalDateClimate, getUnit, trimmData, assembleAreaData, assembleLineData, getForecastConfidenceData, mergeHistoricalAndForecastData, addMonths, validateData, convertToShadedRangesFormat } from '../../../../helpers/chartHelpers';
import clsx from 'clsx';
import ChartSpecs from '../ChartSpecs';
import { makeStyles } from '@material-ui/styles';

const TempChart = ({ actionsState }) => {
  let chartRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const { currentSettings } = useContext(SettingsContext)
  const { id } = useParams();

  const [fieldId, setFieldId] = useState()

  // Prepare initial data
  const weatherVariable = 'temperature'
  const [data, setData] = useState({
    'ds_hist': {
      time: [],
      't2m_min': [],
      't2m_max': [],
    },
    'ds_fc': {
      time: [],
      't2m_min': [],
      't2m_max': [],
    },
    'ds_clim': {
      time: [],
      't2m_min': [],
      't2m_max': [],
    },
    pending: true,
  });

  // Prepare initial data
  const [monthlyData, setMonthlyData] = useState({
    'ds_hist': {
      time: [],
      't2m_min': [],
      't2m_max': [],
    },
    'ds_fc': {
      time: [],
      't2m_min': [],
      't2m_max': [],
    },
    'ds_clim': {
      time: [],
      't2m_min': [],
      't2m_max': [],
    },
    pending: true,
  });

  // Alerts Data
  const [alertsData, setAlertsData] = useState({
    t2m_max: {},
    t2m_min: {}
  })

  const [hourlyData, setHourlyData] = useState({
    'ds_hist': {
      time: [],
      t2m: [],
    },
    'ds_fc': {
      time: [],
      t2m: [],
    }
  })


  // Load data
  useEffect(() => {
    if (fieldId != id) {

      // Set data status pending
      setData({
        ...data,
        pending: true
      })
      setMonthlyData({
        ...monthlyData,
        pending: true,
      });

      // Load data
      currentUser
        .getIdToken()
        .then((userToken) => {
          networking
            .get(`/api/v1/weather/${weatherVariable}/daily/${id}`, {
              extraHeaders: { 'User-Token': userToken },
            })
            .then((res) => {
              setData({
                ...res.data,
                pending: false,
              });
            })
            .catch(() => {
              setData((prevData) => ({
                ...prevData,
                pending: false,
              }));
              toast.error('Error occurred with server. Please, try later.');
            });

          networking
            .get(`/api/v1/weather/${weatherVariable}/monthly/${id}`, {
              extraHeaders: { 'User-Token': userToken },
            })
            .then((res) => {
              setMonthlyData({
                ...res.data,
                pending: false,
              });
            })
            .catch(() => {
              setMonthlyData((prevData) => ({
                ...prevData,
                pending: false,
              }));
              toast.warn('Could not load monthly data.');
            });

          networking
            .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
              extraHeaders: { 'User-Token': userToken },
            })
            .then((res) => {
              if (isEmptyObject(res.data)) {
                toast.success(`There are no alerts for ${weatherVariable}`);
              }
              setAlertsData(res.data)
            })
            .catch(() => {
              toast.warn(
                `Alerts not displayed on dashboard due to internet 
                connectivity issues. All other functions working.`);
            });

        });
    }

    setFieldId(id);

  }, [actionsState.isMonthly, actionsState.currentTab, currentUser, id]);

  // Prepare min  temp data
  const historicalMinTemp = useMemo(() => getHistoricalMinTemp(data['ds_hist']), [data]);
  const historicalMaxTemp = useMemo(() => getHistoricalMaxTemp(data['ds_hist']), [data]);
  const forecastMinArr = useMemo(() => getForecastMinArr(data['ds_fc']), [data]);
  const forecastMaxArr = useMemo(() => getForecastMaxArr(data['ds_fc']), [data]);

  // Prepare max temp data
  const forecastMinTemp = useMemo(() => getForecastMinTemp(data['ds_fc'], historicalMinTemp[historicalMinTemp.length - 1], forecastMinArr), [data, forecastMinArr, historicalMinTemp]);
  const forecastMaxTemp = useMemo(() => getForecastMaxTemp(data['ds_fc'], historicalMaxTemp[historicalMaxTemp.length - 1], forecastMaxArr), [data, forecastMaxArr, historicalMaxTemp]);
  const { climMinLighten, climMinDarken } = useMemo(() => getClimMin(data['ds_clim']), [data]);
  const { climMaxLighten, climMaxDarken } = useMemo(() => getClimMax(data['ds_clim']), [data]);

  // Look at the changes for historical and forecast data and display warning messages if invalid
  useMemo(() => {
    validateData({ diffToAlert: 15, historic: climMinLighten, forecast: forecastMinTemp, message: "Tmin Forecast Anomaly Detected" });
    validateData({ diffToAlert: 15, historic: climMaxLighten, forecast: forecastMaxTemp, accessorKey: 'y', message: "TMax Forecast Anomaly Detected" });
  }, [forecastMinTemp, forecastMaxTemp, climMinLighten, climMaxLighten])

  // Prepare Confidence Data
  const forecastMinConfidence75 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalMinTemp[historicalMinTemp.length - 1], data['ds_fc']['t2m_min'], '0.75', historicalMinTemp)
  }, [data, historicalMinTemp]);
  const forecastMinConfidence95 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalMinTemp[historicalMinTemp.length - 1], data['ds_fc']['t2m_min'], '0.95', historicalMinTemp)
  }, [data, historicalMinTemp]);
  const forecastMaxConfidence75 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalMaxTemp[historicalMaxTemp.length - 1], data['ds_fc']['t2m_max'], '0.75', historicalMaxTemp)
  }, [data, historicalMaxTemp]);
  const forecastMaxConfidence95 = useMemo(() => {
    return getForecastConfidenceData(data['ds_fc'], historicalMaxTemp[historicalMaxTemp.length - 1], data['ds_fc']['t2m_max'], '0.95', historicalMaxTemp)
  }, [data, historicalMaxTemp]);

  // Prepare historical data
  const histCsvData = data['ds_hist'].time.map((item, index) => {
    return [
      data['ds_hist']['t2m_min'][index],
      data['ds_hist']['t2m_max'][index],
    ];
  });

  // Prepare forecast csv data
  const forcCsvData = data['ds_fc'].time.map((item, index) => {
    return [
      forecastMinArr[index],
      forecastMaxArr[index],
    ];
  });

  // Prepare clim max and min data
  const climMaxArr = [].concat.apply([], Object.values(data['ds_clim']['t2m_max']));
  const climMinArr = [].concat.apply([], Object.values(data['ds_clim']['t2m_min']));

  // Assemble clim csv data
  const climCsvData = data['ds_clim'].time.map((item, index) => {
    return [
      item,
      climMinArr[index],
      climMaxArr[index],
    ];
  });

  // Combine CSV data, which will be used for export
  const combinedCsvData = (clim, forecast, historical) => {
    const csvArr = [];
    let j = 0;
    for (let i = 0; i <= clim.length; i++) {
      if (historical[i]) {
        csvArr.push([
          ...clim[i],
          [''],
          [''],
          ...historical[i],
        ]);
      } else if (clim[i] && forecast[j]) {
        csvArr.push([
          ...clim[i],
          ...forecast[j],
          [''],
          [''],
        ]);
        j += 1;
      } else if (clim[i]) {
        csvArr.push([
          ...clim[i],
          [''],
          [''],
        ]);
      }
    }
    return csvArr;
  };

  // Use styles
  const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
      borderRadius: '20px',
    },
  }));
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)}>
      <CardContent >
        <div className="chart-container-element">
          <Box style={{ display: data.pending ? 'flex' : 'none' }}
            className="chart-preload-container">
            <CircularProgress />
          </Box>
          <LineAreaChartComponent

            svgHeight={Math.max(window.innerHeight - 300, 600)}

            // Title text
            title="Minimum and Maximum Temperature"

            // Set title hover text
            titleHover={actionsState.isMonthly ? `This graph shows the monthly observed and forecasted ${weatherVariable}.` : `This graph shows the daily observed and forecasted minimum and maximum ${weatherVariable}.`}

            // Y label text
            labelY={`Temperature in ${getUnit({ system: currentSettings.units }).tempUnit}`}

            // Add top margin
            marginTop={55}

            // Title label offset
            titleLabelOffsetY={0}

            // Pass unique resize event key
            resizeEventListenerId={`${weatherVariable}-chart`}

            // Add chart data id to filter out some update requests
            chartDataId={(actionsState.isMonthly ? 'month' : 'day') + '_temp-chart_' + climMaxLighten.length}

            // Center Ticks
            centerTicks={actionsState.isMonthly ? true : false}

            // Make chart to have dynamic y basis
            zeroBasis={false}

            // Convert received data to shaded ranges format
            shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}

            // Bottom margin will be 0.3 times of data diff
            yBottomOffset={0.3}

            // Top margin will be 0.3 times of data diff
            yTopOffset={0.3}

            // Provide custom date max axis extent for monthly view charts
            xDateMax={actionsState.isMonthly ? addMonths(new Date(), 7) : null}

            // Provide custom date min axis extent for monthly view charts
            xDateMin={actionsState.isMonthly ? addMonths(new Date(), -7) : null}

            // How x ticks will be formatted in chart
            xTickFormat={actionsState.isMonthly ? (d, i, arr) => {
              // Remove last, overflowing tick item
              if (i === arr.length - 1) return '';
              return d.toLocaleString(undefined, { month: "short" })
            } : (d, i, arr) => {
              if (i < arr.length - 1 && i !== 0) return d.getDate();
              if (i === 0) return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate();
              return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
            }}

            // Give chart tips count tip
            xTicksCount={actionsState.isMonthly ? 12 : 30}

            // Hide chart if data is pending
            hide={data.pending}

            // Tooltip content on line points mouse over
            tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
              return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:20px" rowspan="${values.length}"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(undefined, {
                day: "numeric",
                month: "short"
              })}</div></td> 
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[0].dashed ? 'dashed' : 'solid'} ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0])} ${getUnit({ system: currentSettings.units }).tempUnit}</td>
                 </tr>
                 ${values.filter((d, i) => i > 0).map((value, i) => {
                return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${points[i + 1].dashed ? 'dashed' : 'solid'} ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${Math.round(value)} ${getUnit({ system: currentSettings.units }).tempUnit}</td></tr>`
              }).join('')}
             </table>`}
            }

            // Chart data content
            data={[
              {
                type: 'area',
                points: (!actionsState.isMonthly ? trimmData(climMaxLighten) : trimSeasonalDateClimate(climMaxLighten))
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: '#F8D6C5',
                opacity: 0.6
              },
              {
                type: 'area',
                points: (!actionsState.isMonthly ? trimmData(climMaxDarken) : trimSeasonalDateClimate(climMaxDarken))
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: '#FDBE9D',
                opacity: 0.6
              },
              {
                type: 'area',
                points: (!actionsState.isMonthly ? trimmData(climMinLighten) : trimSeasonalDateClimate(climMinLighten))
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: '#DBEBF5',
                opacity: 0.6
              },
              {
                type: 'area',
                points: (!actionsState.isMonthly ? trimmData(climMinDarken) : trimSeasonalDateClimate(climMinDarken))
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: '#C6E2F1',
                opacity: 0.6
              },
              // Confidence Bands
              {
                type: 'area',
                color: actionsState.isMonthly ? '#9BC5E3' : '#0089C6',
                'opacity': actionsState.isMonthly ? 1 : 0.4,
                points: assembleAreaData({
                  areaData: forecastMinConfidence95,
                  isMonthly: actionsState.isMonthly,
                  //climatology: duplicateMonthlyHistoricalDataForFutureSixMonths(climMinLighten),
                  seasonal: monthlyData.ds_fc.time.map((d, i) => {
                    return {
                      x: +new Date(d),
                      y: monthlyData.ds_fc.t2m_min['0.95'][i],
                      y0: monthlyData.ds_fc.t2m_min['0.05'][i],
                    }
                  })
                })
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
              },
              {
                type: 'area',
                color: actionsState.isMonthly ? '#5AA0D0' : '#0089C6',
                points: assembleAreaData({
                  areaData: forecastMinConfidence75,
                  isMonthly: actionsState.isMonthly,
                  // climatology: climMinDarken,
                  seasonal: monthlyData.ds_fc.time.map((d, i) => {
                    return {
                      x: +new Date(d),
                      y: monthlyData.ds_fc.t2m_min['0.75'][i],
                      y0: monthlyData.ds_fc.t2m_min['0.25'][i],
                    }
                  })
                })
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' }))
                ,
                'opacity': actionsState.isMonthly ? 1 : 0.4,
              },
              {
                type: 'area',
                points: assembleAreaData({
                  areaData: forecastMaxConfidence95,
                  isMonthly: actionsState.isMonthly,
                  //   climatology: duplicateMonthlyHistoricalDataForFutureSixMonths(climMaxLighten),
                  seasonal: monthlyData.ds_fc.time.map((d, i) => {
                    return {
                      x: +new Date(d),
                      y: monthlyData.ds_fc.t2m_max['0.95'][i],
                      y0: monthlyData.ds_fc.t2m_max['0.05'][i],
                    }
                  })
                })
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: actionsState.isMonthly ? '#FFBB97' : '#FF7100',
                'opacity': actionsState.isMonthly ? 1 : 0.4,
              },
              {
                type: 'area',
                points: assembleAreaData({
                  areaData: forecastMaxConfidence75,
                  isMonthly: actionsState.isMonthly,
                  //climatology: climMaxDarken,
                  seasonal: monthlyData.ds_fc.time.map((d, i) => {
                    return {
                      x: +new Date(d),
                      y: monthlyData.ds_fc.t2m_max['0.75'][i],
                      y0: monthlyData.ds_fc.t2m_max['0.25'][i],
                    }
                  })
                }).map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: actionsState.isMonthly ? '#FB9769' : '#FF7100',
                'opacity': actionsState.isMonthly ? 1 : 0.4,
              },
              {
                type: 'line',
                points: assembleLineData({
                  isMonthly: actionsState.isMonthly,
                  historical: historicalMaxTemp,
                  forecast: forecastMaxTemp,
                  seasonal: monthlyData.ds_fc.time.map((d, i) => {
                    return {
                      x: new Date(d),
                      y: monthlyData.ds_fc.t2m_max['0.5'][i]
                    }
                  })
                })
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: '#FF7100',
                'stroke-width': 2,
              },
              {
                type: 'line',
                points: assembleLineData({
                  isMonthly: actionsState.isMonthly,
                  historical: historicalMinTemp,
                  forecast: forecastMinTemp,
                  seasonal: monthlyData.ds_fc.time.map((d, i) => {
                    return {
                      x: new Date(d),
                      y: monthlyData.ds_fc.t2m_min['0.5'][i]
                    }
                  })
                })
                  .map(d => processUnitSystem(d, { system: currentSettings.units, type: 'temp' })),
                color: '#0089C6',
                'stroke-width': 2
              }
            ]}
          ></LineAreaChartComponent>
        </div>
        <div className="chart-specs-container">
          <ChartSpecs
            type="temp"
            chartRef={chartRef}
            data={{
              csv: combinedCsvData(climCsvData, forcCsvData, histCsvData),
              hourlyCsv: mergeHistoricalAndForecastData({
                forecast: hourlyData.ds_fc,
                historical: hourlyData.ds_hist
              })
            }}
            onHourlyCsvDataTrigger={() => {
              return new Promise((resolve, reject) => {
                currentUser
                  .getIdToken()
                  .then((userToken) => {
                    networking
                      .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                        extraHeaders: { 'User-Token': userToken },
                      })
                      .then((res) => {
                        setHourlyData({
                          ...res.data,
                        });
                        resolve(res.data)
                      })
                      .catch(() => {
                        reject();
                      });


                  })
              })
            }}
            actionsState={actionsState}
          />
        </div>
      </CardContent>
      <ToastContainer />
    </Card >
  );
};

export default TempChart;
