import React, { useState, useEffect } from "react"
import { Card, Typography, Box } from "@material-ui/core"

const ActionBlock = ({ chartIcon = { active: null, default: null }, currentTab, title, current, select }) => {
    const [isCurrent, setCurrent] = useState(false)

    function handleSelect() {
        select(currentTab)
    }

    useEffect(() => {
        if (current === currentTab) {
            setCurrent(true)
        } else {
            setCurrent(false)
        }
    }, [current, currentTab])

    return (
        <div
            className="headerBlockContainer headerBlockContainer-hover chart-action__header-block-option"
            style={{
                boxShadow: isCurrent ? "0px 4px 10px #1C9690" : "",
                backgroundColor: isCurrent ? "#1C9690" : "",
            }}
            onClick={handleSelect}
        >
            <Card className="headerBlock">
                <Box className="infoContainer">
                    {/* TODO: are 'alt's useful in this context ? */}
                    {/* TODO: icons seem to take more to load ?? Type Coercion?? */}
                    {isCurrent ? (
                        <img className="chart-action__icon" src={chartIcon.active} alt={`${title} selected icon`} />
                    ) : (
                        <img className="chart-action__icon" src={chartIcon.default} alt={`${title} default icon`} />
                    )}
                </Box>
                <Box className="infoContainer">
                    <Typography
                        className="titleText"
                        style={{
                            color: isCurrent ? "#fff" : "",
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
            </Card>
        </div>
    )
}

export default ActionBlock
