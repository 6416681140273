import React from "react"

import "./ColorPicker.css"

function ColorPicker(props) {
    // const [open, toggle] = useState(false)

    const { colors = ["#2187C1", "#5CC30B", "#0645AD", "#FAA80A", "#F3833F"], onColorSelect = () => null } = props
    return (
        <>
            <div className="color-picker">
                {colors.map((color) => {
                    return (
                        <button
                            key={`color-picker-item__${color}`}
                            className="color-picker__item"
                            style={{ backgroundColor: color }}
                            onClick={() => onColorSelect(color)}
                        />
                    )
                })}
            </div>
        </>
    )
}

export default ColorPicker
