import React, { useState, useEffect } from "react"
import { Box, Card, CardContent, CircularProgress, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { SingleRowBarChartComponent } from "../../../..//components/Charts/SingleRowBarChart/SingleRowBarChart.component"
import "./RiskFactors.css"
import Button from "@material-ui/core/Button"
import { Popover } from "../../../../ui/Popover"

const RiskFactorsComponent = ({
    onSaveChangesClick,
    dataLoadStatus,
    handleWeightChange,
    title,
    riskFactorsData,
    disableUpdate,
}) => {
    // Use styles
    const useStyles = makeStyles((theme) => ({
        root: {
            boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
            borderRadius: "20px",
            background: "linear-gradient(131.47deg, #F8FBFF 7.84%, #EFF2F6 93.19%)",
        },
    }))

    // Settings visibility state handling
    const [settingsVisible, setSettingsVisibility] = useState(true)
    const [showAll, setShowAll] = useState(false)

    // Calculate sum of weights
    const weightSum = riskFactorsData.map((d) => d.weight).reduce((a, b) => a + b, 0)
    const classes = useStyles()

    // Set settings visibility once advanced settings is clicked
    function onSettingsClick() {
        setSettingsVisibility(false)
        setShowAll(true)
    }

    useEffect(() => { })

    return (
        <>
            <Card className={clsx(classes.root)}>
                <CardContent>
                    <div
                        style={{
                            padding: "10px",
                            paddingTop: 0,
                        }}
                    >
                        <h2 className="planting-tool__risk-factors__title">{title}</h2>
                        <div className="planting-tool__risk-factors__text">Relative Importance</div>
                        <Box
                            style={{ display: dataLoadStatus || dataLoadStatus === undefined ? "none" : "flex" }}
                            className="chart-preload-container"
                        >
                            <CircularProgress />
                        </Box>
                        <div
                            style={{
                                width: 100 + "%",
                                marginTop: 20 + "px",
                                display: riskFactorsData.length ? "flex" : "none",
                            }}
                        >
                            <SingleRowBarChartComponent
                                disableUpdate={disableUpdate}
                                data={{
                                    values: riskFactorsData.map((d) => {
                                        return {
                                            id: d.id,
                                            value: d.value / weightSum,
                                            color: d.color,
                                            name: d.chartName,
                                        }
                                    }),
                                }}
                            ></SingleRowBarChartComponent>
                        </div>
                        <div style={{ textAlign: "end" }}>
                            {riskFactorsData.length > 3 && (
                                <div
                                    onClick={() => {
                                        setShowAll(!showAll)
                                    }}
                                    style={{
                                        display: settingsVisible && dataLoadStatus ? "initial" : "none",
                                    }}
                                    className="risk-advanced-settings"
                                >
                                    Show {showAll ? "less" : "more"}
                                </div>
                            )}
                        </div>

                        <div className="risk-factors__risks-container">
                            {riskFactorsData
                                .map((d, index) => {
                                    if (!showAll && index > 2) {
                                        return
                                    }

                                    if (d.weight === 0 && settingsVisible) return;

                                    return (
                                        <div className="risk-factors__risks" key={d.id}>
                                            <div
                                                className="risk risk-factors__color-block"
                                                style={{
                                                    backgroundColor: d.color,
                                                }}
                                            ></div>
                                            <div>
                                                <div className="risk-factors__risk-name">{d.name}</div>
                                                <div className="risk-factors__description">{d.description}</div>
                                                <div
                                                    style={{
                                                        display: !settingsVisible ? "flex" : "none",
                                                        alignItems: "baseline",
                                                    }}
                                                >
                                                    <div style={{ marginLeft: "23px", fontSize: 12 }}>
                                                        Weight:&nbsp;&nbsp;&nbsp;
                                                </div>
                                                    <TextField
                                                        id="standard-basic"
                                                        inputProps={{ step: 0.05, min: 0 }}
                                                        style={{ width: 60 + "px" }}
                                                        type="number"
                                                        value={d.weight}
                                                        onChange={(event) => handleWeightChange(d, event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            <div className="risk-factors__settings-container">
                                <div className="separator"></div>
                                <div
                                    onClick={onSettingsClick}
                                    style={{
                                        display: settingsVisible && dataLoadStatus ? "initial" : "none",
                                    }}
                                    className="risk-advanced-settings"
                                >
                                    <Popover
                                        className="plain-popover"
                                        text="Change the probabilistic weight of each risk factor."
                                    >
                                        Advanced settings
                                    </Popover>
                                </div>
                                <div className="risk-advanced-settings__buttons-container">
                                    <Button
                                        className="cl-button"
                                        onClick={() => {
                                            setSettingsVisibility(true)
                                            setShowAll(false)
                                            onSaveChangesClick()
                                        }}
                                        style={{
                                            fontSize: 12,
                                            display: settingsVisible ? "none" : "initial",
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        save changes
                                    </Button>
                                    <Button
                                        className="cancel-button"
                                        onClick={() => {
                                            setSettingsVisibility(true)
                                            setShowAll(false)
                                        }}
                                        style={{
                                            fontSize: 12,
                                            display: settingsVisible ? "none" : "initial",
                                        }}
                                        variant="outlined"
                                        color="red"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ marginTop: '25px', width: '100%', height: '0.5px', backgroundColor: '#CBCED2' }} className="custom-hr"></div>
                        <div style={{ marginTop: '20px', display: settingsVisible && dataLoadStatus ? 'initial' : 'none' }} onClick={onSettingsClick} className="risk-advanced-settings">Advanced settings</div>
                        <Button className="cl-button"
                            onClick={onSaveChangesClick}
                            style={{
                                marginTop:15,
                                fontSize: 12,
                                display: settingsVisible ? 'none' : 'initial'
                            }} variant="outlined" color="primary">save changes</Button> */}
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default RiskFactorsComponent
