import React from "react"

import ArrowRight from "../../../../ui/Icons/ArrowRight"

// TODO: Any difference in performance for children importing styles?
// import "./AlertSettings.css"

const CropTab = (props) => {
    const { name } = props

    return (
        <div id={name} className="alert-settings__alerts__crop-menu">
            <div className="alert-settings__alerts__crop-menu-title">{name} Crop</div>

            <ArrowRight className="alert-settings__alert__crop-menu-icon" />
        </div>
    )
}

export default CropTab
