/**
 * initial state
 *
 * groupBy: [],
 * orderBy: "",
 * asc: true,
 * inputValue: "",
 * rows: 10,
 * currentPage: 1,
 */

function TableReducer(state = {}, action) {
    //
    const { type, payload } = action
    //
    switch (type) {
        //
        case "INPUT_CHANGE": {
            const { inputValue } = payload
            return { ...state, inputValue, currentPage: 1 }
        }
        //
        case "ADD_GROUP_BY": {
            const { propName, displayName } = payload
            const _openPaths = { ...state.openPaths }
            const _groupBy = [...state.groupBy, { propName, displayName }]

            for (let path in _openPaths) {
                if (_openPaths[path] && typeof _openPaths[path] === "object") {
                    const splittedPath = path.split("/")
                    splittedPath.splice(splittedPath.length - 1, 0, _openPaths[path][propName])

                    const previousSplittedPath = [...splittedPath].splice(0, splittedPath.length - 1)

                    let newPath = splittedPath.join("/")
                    let prevPath = previousSplittedPath.join("/")

                    _openPaths[newPath] = { ..._openPaths[path] }
                    _openPaths[prevPath] = true

                    delete _openPaths[path]
                }
            }

            return { ...state, groupBy: _groupBy, openPaths: _openPaths, currentPage: 1 }
        }
        //
        case "REMOVE_GROUP_BY": {
            const { propName, displayName } = payload
            // const _openPaths = { ...state.openPaths }
            // get the removed group position
            // let groupRemovedIndex = -1
            const _groupBy = [...state.groupBy].filter((_propData, index) => {
                if (_propData.propName !== propName && _propData.displayName !== displayName) {
                    // console.log(index)
                    // groupRemovedIndex = index
                    return true
                }
            })
            return { ...state, groupBy: _groupBy, openPaths: [] }
        }
        //
        case "SET_ORDER_BY": {
            const { orderBy } = payload

            if (state.orderBy !== orderBy) {
                return { ...state, orderBy: orderBy, asc: true }
            } else if (state.orderBy === orderBy && state.asc) {
                return { ...state, orderBy: orderBy, asc: false }
            } else if (state.orderBy === orderBy && !state.asc) {
                return { ...state, orderBy: "", asc: true }
            }

            break
        }
        //
        case "ROW_NUMBER_CHANGE": {
            const { rows } = payload
            return { ...state, rows }
        }
        //
        case "CURRENT_PAGE_CHANGE": {
            const { page } = payload
            return { ...state, currentPage: page }
        }
        case "ADD_OPEN_PATH": {
            const { path, rowData } = payload
            return { ...state, openPaths: { ...state.openPaths, [path]: rowData || null } }
        }
        case "REMOVE_OPEN_PATH": {
            const { path } = payload
            const _openPaths = { ...state.openPaths }
            delete _openPaths[path]
            return { ...state, openPaths: _openPaths }
        }
        case "ADD_SELECTED": {
            const { items = [] } = payload
            const { selected } = state

            const _selected = [...selected].concat(items)

            return { ...state, selected: _selected }
        }
        case "REMOVE_SELECTED": {
            const { deselected } = payload
            const { selected } = state
            const _selected = selected.filter((item) => {
                return item.uuid !== deselected.uuid
            })

            console.log(_selected)

            return { ...state, selected: _selected }
        }
        case "REMOVE_SELECTED_BY_PROPNAME": {
            const { propName, value } = payload
            const { selected } = state

            const _selected = selected.filter((item) => {
                return item[propName] !== value
            })

            console.log(_selected)

            return { ...state, selected: _selected }
        }
        case "ADD_SELECTED_BY_ROWS": {
            const { items, data, groupBy } = payload
            const { selected } = state

            const propName = (groupBy[0] && groupBy[0].propName) || "uuid"

            const hash = {}
            for (let item of items) hash[item[propName]] = item

            const _data = data.filter((item) => hash[item[propName]])
            const _selected = selected.filter((item) => !hash[item[propName]]).concat(_data)

            return { ...state, selected: _selected }
        }
        case "REMOVE_SELECTED_BY_ROWS": {
            const { items, groupBy } = payload
            const { selected } = state

            const propName = (groupBy[0] && groupBy[0].propName) || "uuid"

            const hash = {}
            for (let item of items) hash[item[propName]] = true

            const _selected = selected.filter((item) => !hash[item[propName]])
            console.log(_selected)

            return { ...state, selected: _selected }
        }
        default: {
            return state
        }
    }
}

export default TableReducer
