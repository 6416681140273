const TEXT_HEADERS_EQUIVALENCE = {
    "Risk Category (not editable)": "risk_category",
    "Alert Title / Subtitle (Column is editable)": "title",
    "Modifier (not editable)": "modifier",
    Threshold: "threshold",
    "Probability (%)": "probability",
    "Req Recurrence (Consecutive Days)": "recurrent_days",
    "Start Date (month + day)": "start_date",
    "End Date (month + day)": "end_date",
    "Active Alert": "active",
    "Lag (Days)": "lag",
    "GDD Floor Temp (C)": "gddBase",
    "Plant Life Cycle (in days)": "gddLifeCycle",
}

const ALERT_TITLES_EQUIVALENCE = {
    "Heat Stress Risk": "risk_heat_stress",
    "Cold Risk": "risk_cold",
    "Insufficient High Temperature Risk": "risk_insufficient_high_temp",
    "Insufficient Chill Temperature Risk": "risk_insufficient_low_temp",
    "Precipitation Risk": "risk_flood",
    "Cumulative Precipitation Risk": "risk_cumulative_flood",
    "Drought Risk": "risk_drought",
    "Cumulative Drought Risk": "risk_cumulative_drought",
    "Dry Soil Risk": "risk_dry_soil",
    "Wet Soil Risk": "risk_wet_soil",
    "Low Soil Temperature Risk": "risk_low_soil_temperature",
    "High Soil Temperature Risk": "risk_high_soil_temperature",
    "Low Humidity Risk": "risk_low_humidity",
    "High Humidity Risk": "risk_high_humidity",
    "Growing Degree Days": "risk_growing_degree_days",
    "Pest Degree Days": "risk_pest_degree_days",
}

const MUST_HAVE_ALERT_PROPS = [
    "threshold",
    "active",
    "end_date",
    "start_date",
    "probability",
    "recurrent_days",
    "title"
]

// invert key values
const PROPERTY_HEADERS_EQUIVALENCE = Object.keys(TEXT_HEADERS_EQUIVALENCE).reduce((prev, curr) => {
    prev[TEXT_HEADERS_EQUIVALENCE[curr]] = curr
    return prev
}, {})

// invert key values
const PROPERTY_TITLES_EQUIVALENCE = Object.keys(ALERT_TITLES_EQUIVALENCE).reduce((prev, curr) => {
    prev[ALERT_TITLES_EQUIVALENCE[curr]] = curr
    return prev
}, {})

const HIDDEN_VALUES = ["risk_category", "modifier"]

function parseDate(str) {
    return {
        day: new Date(str).getDate(),
        month: new Date(str).getMonth() + 1,
    }
}

function restoreDate(month, day) {
    const date = new Date()
    date.setMonth(month - 1)
    date.setDate(day)
    return `'${date.toLocaleDateString("default", { month: "short", day: "numeric" })}`
}

function parseCell(propName, value, unit) {
    let result = null
    switch (propName) {
        case "threshold":
            result = parseFloat(value)
            break
        case "probability":
            result = parseInt(value) / 100
            break
        case "recurrent_days":
            result = parseInt(value)
            break
        case "start_date":
            result = parseDate(value)
            break
        case "end_date":
            result = parseDate(value)
            break
        case "active":
            result = true
            break
        case "lag":
            result = parseInt(value)
            break
        case "gddBase":
            result = parseFloat(value)
            break
        case "gddLifeCycle":
            result = parseInt(value)
            break
        default:
            result = value
            break
    }
    return result
}

function restoreCellValue(propName, value, unit) {
    let result = null
    switch (propName) {
        case "probability":
            result = value * 100
            break
        case "start_date":
            result = restoreDate(value.month, value.day)
            break
        case "end_date":
            result = restoreDate(value.month, value.day)
            break
        case "active":
            result = value ? "Yes" : "No"
            break
        default:
            result = value
            break
    }
    return result
}

export function parseCSVFile(startValue, file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onload = function () {
            // get csv lines
            const lines = reader.result.split("\n")

            // result obj
            const alertConfigs = {}

            // result's propNames to be mapped from the csv
            const propNames = []
            const tableHeaders = lines[startValue].split(",")

            // get table headers
            for (let tableHeader of tableHeaders) {
                const currentValue = TEXT_HEADERS_EQUIVALENCE[tableHeader]
                propNames.push(currentValue)
            }

            const titleIndex = propNames.indexOf("title")
            if (!titleIndex)
                resolve({
                    error: 'Invalid data, "title" was not found in the alert config table headers.'
                })

            // fill alert configs
            for (let i = startValue + 1; i < lines.length; i++) {
                const cells = lines[i].split(",")
                if (!cells[0]) continue

                const alertTitle = ALERT_TITLES_EQUIVALENCE[cells[0]]

                if (!alertConfigs[alertTitle]) alertConfigs[alertTitle] = {}

                const alertConfig = {}
                for (let j = 1; j < cells.length; j++) {
                    let cell = cells[j]
                    if (MUST_HAVE_ALERT_PROPS.includes(propNames[j]) && !cell) {
                        if (propNames[j] === "recurrent_days") {
                            if (alertTitle.includes("cumulative")) {
                                resolve({
                                    error: `The CSV file provided is missing a value for "${propNames[j]}".`
                                })
                            }
                            cell = 1 // hardcode the empty recurrent_days to 1
                            // then go to the last line of the for
                        } else {
                            resolve({
                                error: `The CSV file provided is missing a value for "${propNames[j]}".`
                            })
                        }
                    }
                    else if (
                        !cell ||
                        !propNames[j] ||
                        (cell && (cell === "NA" || cell === "0" || HIDDEN_VALUES.includes(propNames[j])))
                    )
                        continue
                    alertConfig[propNames[j]] = parseCell(propNames[j], cell)
                }

                alertConfigs[alertTitle][
                    `${alertConfig?.title?.toLowerCase()?.replaceAll(" ", "_")}_${i}`
                ] = alertConfig
            }

            if (Object.keys(alertConfigs).length === 0) {
                resolve({
                    error: "The CSV file provided has no alerts."
                })
            }
            resolve(alertConfigs)
        }
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(file)
    })
}

export function toCSVFile(crop = "", variety = "", alerts) {
    console.log(alerts)

    // const tableHeaders = []
    const tableHeaders = Object.keys(PROPERTY_HEADERS_EQUIVALENCE).map((key) => PROPERTY_HEADERS_EQUIVALENCE[key])
    const tableData = []

    for (let type in alerts) {
        const currentAlertType = alerts[type]
        for (let alertKey in currentAlertType) {
            const currentAlert = currentAlertType[alertKey]
            const rowData = []

            if (!alerts[type][alertKey]["stacked"]) {
                // rowData[0] = currentAlert
                for (let key in PROPERTY_HEADERS_EQUIVALENCE) {
                    switch (key) {
                        case "risk_category":
                            rowData.push(PROPERTY_TITLES_EQUIVALENCE[type])
                            break
                        case "modifier":
                            rowData.push("Put modifier")
                            break
                        default:
                            rowData.push(restoreCellValue(key, currentAlert[key]))
                            break
                    }
                }
            }

            tableData.push(rowData.join(","))
            // tableData[currentAlert.title] = {
            //     ...currentAlert,
            // }
        }
    }

    return `${crop} Alerts ,,,,,,,,,,,,,,
Days to maturity,NA,not editable,,,,,,,,,,,,
Variety:,${variety},editable by customer,,,,,,,,,,,,
Units: ,Metric (Imperial/Metric),Yield  column: to forget for now,,,,,,,,,,,,
,,,,,,,,,,,,,,
${tableHeaders.join(",")}
${tableData.join("\n")}`
}
