import * as React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { token } from '../accessToken';
import './mapbox.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';
import * as d3 from 'd3'
import { Popover } from "../../../../ui/Popover"
import { scaleColor } from '../../../../../src/helpers/chartHelpers'

mapboxgl.accessToken = token;

/**
 * We use class instead of pure functions because we need to
 * be able to handle chart update function.
 * 
 * 
 * Sample Invokation
 * ```javascript
<MapboxFieldHeatmapViewerComponent
   units="metric"
   data={[
       {
           id: 'id1',
           name:"Field 1",
           metadata: [{ name: 'id', value: 'id1' }, { name: 'name', value: 'test1' }],
           polygon: [[40, 43], [41, 41], [46, 41], [40, 43]],
           temp: 30,
           precipitation: 10,
       },
       {
           id: 'id2',
           name:"Field 2",
           metadata: [{ name: 'id', value: 'id2' }, { name: 'name', value: 'test2' }],
           polygon: [[30, 33], [31, 31], [36, 31], [30, 33]],
           temp: 30,
           precipitation: 20,
       }
   ]}
   variables={[
       {
           iconType: 'temperature',
           name: 'Temperature',
           colors: ['Orange', 'Red'],
           value: 'temp',
           unit: "°C",
       },
       {
           iconType: 'precipitation',
           name: 'Precipitation',
           colors: ['LightBlue', 'Blue'],
           value: 'precipitation',
           unit: "mm",
       }
   ]}
   width='100%'
   onSelectedVariableChange={d=>d}
   height="370px">
</MapboxFieldHeatmapViewerComponent>
 * ```
 * @typedef {MapboxFieldHeatmapViewerComponent} Props
 * @prop {HeatmapData} data - Necessary data to display polygon and some information in tip
 * @prop {VariableData} variables - Necessary data for drawing variable buttons
 * @prop {string} units - Units for component (`'metric'` or `'imperial'`)
 * @prop {string} height - Overall map height
 * @prop {string} width - Overall map width
 * @prop {Function} onSelectedVariableChange - Event handler to listen and output variable changes
 * @extends {Component<Props>}width
 */
export class MapboxFieldHeatmapViewerComponent extends React.Component {
    /**
    * @private
    * @hideconstructor
    */
    constructor(props) {
        super(props);

        // Define initial state
        this.state = {
            markers: [],
            viewport: {
                longitude: 42,
                latitude: 42,
                zoom: 0,
            }
        };

        // Save mapbox instance reference
        this._map = React.createRef();
    }

    // Viewport update func - Mostly needed for dragging functionality
    _updateViewport = viewport => {
        this.setState({ viewport });
    };

    // Calculate right top end coordinates (lat,lon) of passed polygon
    maxRightTop = (polygonCoords) => {
        const maxX = Math.max(...polygonCoords.map(d => d[0]));
        const maxY = Math.max(...polygonCoords.map(d => d[1]));
        return [maxX, maxY];
    }

    // Calculate left bottom end coordinates (lat,lon) of passed polygon
    minLeftBottom = (polygonCoords) => {
        const minX = Math.min(...polygonCoords.map(d => d[0]));
        const minY = Math.min(...polygonCoords.map(d => d[1]));
        return [minX, minY];
    }


    // Calculate right top end coordinates (lat,lon) of passed multi polygon figures
    maxRightTopMulti = (polygonCoords) => {
        const maxes = polygonCoords.map(d => this.maxRightTop(d));
        const maxX = Math.max(...maxes.map(d => d[0]));
        const maxY = Math.max(...maxes.map(d => d[1]));
        return [maxX, maxY];
    }

    // Calculate left bottom end coordinates (lat,lon) of passed multi polygon figures
    minLeftBottomMulti = (polygonCoords) => {
        const mins = polygonCoords.map(d => this.minLeftBottom(d));
        const minX = Math.min(...mins.map(d => d[0]));
        const minY = Math.min(...mins.map(d => d[1]));
        return [minX, minY];
    }

    // Handle component mounting event
    componentDidMount() {

        if (!this.props.variables) return;
        // Save initial variable
        this.setState({
            variable: this.props.variables[0]
        })

        // Define map instance
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            //style: 'mapbox://styles/mapbox/dark-v10',   // Dark Style
            style: 'mapbox://styles/mapbox/satellite-v9', // Satelite Style
            // style: 'mapbox://styles/mapbox/light-v10', // Light Style
            center: [this.state.viewport.longitude, this.state.viewport.latitude],
            zoom: this.state.viewport.zoom
        });

        // Set centers
        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });

        this.map = map;

        // Listen for map load and save it into state
        map.on('load', d => {
            this.mapLoaded = true;
        })

        // Create a popup, but don't add it to the map yet.
        var popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        // Listen for map mouseenter event and display tips
        map.on('mouseenter', 'polygon-layer', (e) => {
            // Disable marker events
            if (e.originalEvent.srcElement.tagName != 'CANVAS') return;

            // Retrieve name and metadata array for tip 
            let name = '';
            let metadata = [];
            let properties = {};
            if (e.features) {
                let feature = e.features[0];
                if (feature.properties) {
                    properties = feature.properties;
                    let stringifiedMetadata = feature.properties.metadata;
                    metadata = JSON.parse(stringifiedMetadata);
                    name = feature.properties.name;
                }
            }

            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            // Retrieve coordinates of one of polygon point 
            var coordinates = e.features[0].geometry.coordinates.slice();

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Retrieve final coordiants
            const coords = d3.polygonCentroid(coordinates[0]);

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coords).setHTML(`<div>
                <div style="margin:5px;font-size:20px">${name}</div>
                <table class="tip-table">
                  <tr> <th> ${this.state.variable.name}:  </th>  <td> &nbsp;  ${properties[this.state.variable.value]?.toFixed(2)} ${this.state.variable.unit}</td> </tr>
                  ${metadata.map(m => `<tr> <th>${m.name}:  </th>  <td> &nbsp;  ${m.value} </td> </tr>`).join('')}
                </table>
                </div>`).addTo(map);
        });

        // Listen for mouseleave events and hide tip
        map.on('mouseleave', 'polygon-layer', function () {
            map.getCanvas().style.cursor = '';
            popup.remove();
        });

        // Listen for click events and redirect polygon to weather dashboard
        map.on('click', 'polygon-layer', function (e) {
            let feature = e.features[0];
            if (feature) {
                window.location.href = "/weather/" + feature.properties.id;
            }
        });
    }

    // Handle component update event
    componentDidUpdate(prevProps, prevState) {
        const that = this;

        // Update saved unit value
        if (this.props.units != prevProps.units) {
            let newVariable = this.props.variables.filter(d => d.value == this.state.variable?.value)[0];
            this.setState({ variable: newVariable });
        }

        // If passed polygon data is the same as in the previous case and
        // Passed variable is the same as in the previous state and
        // Passed layer is the same as in the previous state return        
        if (this.props.data === prevProps.data && this.state.variable === prevState.variable && this.state.layer == prevState.layer && this.props.units == prevProps.units) return;

        // If data is empty return
        if (this.props.data.length == 0) {
            removeMarkers(this);
            removeSourcesAndLayers(this);
            return;
        };

        function removeMarkers({ state }) {
            // Remove old markers
            state.markers.forEach(marker => marker.remove());
        }

        function removeSourcesAndLayers({ map }) {
            // If map layer found, remove it
            if (map.getLayer("polygon-layer")) {
                map.removeLayer("polygon-layer");
            }

            // If map source found, remove it
            if (map.getSource("polygon-shape")) {
                map.removeSource("polygon-shape");
            }
        }

        // Add source of geojson layers
        function addSource({ map, props, state, maxRightTopMulti, minLeftBottomMulti }) {

            // Remove sources and layers
            removeSourcesAndLayers({ map })

            // Build geojson feature from passed data
            const features = props.data.map(d => {
                return {
                    "type": "Feature",
                    "properties": d,
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [d.polygon]
                    }
                }
            });

            // Adding polygon shape layer
            map.addSource('polygon-shape', {
                'type': 'geojson',
                'data': {
                    type: "FeatureCollection",
                    features: features
                }
            });

            // Retrieve min and maximum current variable values 
            const min = Math.min(...features.map(d => d.properties[state.variable.value]).filter(d => !isNaN(d)))
            const max = Math.max(...features.map(d => d.properties[state.variable.value]).filter(d => !isNaN(d)))

            that.setState({ min, max })

            let firstColor = state.variable.colors[0];
            let secondColor = state.variable.colors[1];

            // if min and max equals to each others, make colors similar
            if (min === max) {
                secondColor = firstColor;
            }
            that.setState({
                colors: [secondColor, firstColor]
            })

            // Data driven styling of polygon layers
            map.addLayer({
                'id': 'polygon-layer',
                'type': 'fill',
                'source': 'polygon-shape',
                'layout': {},
                'paint': {
                    'fill-color': [                     // Passing data drive fill color property
                        'interpolate',                  // Colors will beinterpolated between values
                        ['linear'],                     // Linearly
                        ['get', state.variable.value],  // Dinamically retrieve value       
                        min - 0.0000001,                // Scale from min value
                        firstColor,       // From min color
                        max + 0.0000001,                // To max value
                        secondColor

                    ],
                    'fill-opacity': 0.8
                }
            });

            const colorScale = scaleColor(d3)
                .colors([state.variable.colors[0], state.variable.colors[1]])
                .values([min, max])
                .gradient(true)

            // Remove all markers first
            removeMarkers({ state })

            // Add new markers at the center of polygons
            const markers = props.data.map(d => {
                const marker = new mapboxgl.Marker({ "color": min === max ? firstColor : colorScale(d[state.variable.value]) })
                    .setPopup(new mapboxgl
                        .Popup()
                        .setHTML(`<div>
                                  <div style="margin:5px;font-size:20px">${d.name}</div>
                                  <table class="tip-table">
                                    <tr> <th> ${that.state.variable.name}:  </th>  <td> &nbsp;  ${d[that.state.variable.value]?.toFixed(2)} ${that.state.variable.unit}</td> </tr>
                                    ${d.metadata.map(m => `<tr> <th>${m.name}:  </th>  <td> &nbsp;  ${m.value} </td> </tr>`).join('')}
                                  </table>
                            </div>`)
                    )
                    .setLngLat(d3.polygonCentroid(d.polygon))
                    .addTo(map);
                const markerDiv = marker.getElement();
                markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
                markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
                markerDiv.addEventListener('click', () => window.location.href = "/weather/" + d.id);
                return marker;
            })

            // Save new markers into state
            that.setState({ markers: markers });

            // Fitting map to polygon bounds
            map.fitBounds([
                maxRightTopMulti(props.data.map(d => d.polygon)),
                minLeftBottomMulti(props.data.map(d => d.polygon)),
            ], { padding: 30 });
        }

        // If layer was changed during redrawing phase
        if (this.state.layer != prevState.layer) {

            // Set corresponding light or satelite layer
            if (this.state.layer) {
                this.map.setStyle('mapbox://styles/mapbox/light-v10');
            } else {
                this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
            }

            // After 1 second, add layers and sources again (because it was removed by styling changes)
            setTimeout(() => {
                addSource(this);
            }, 1000);
        }

        // If map was loaded
        if (this.mapLoaded) {
            // Add source
            addSource(this);
        } else {
            // If map is not yet loaded, wait for it and then add polygon source
            this.map.on('load', d => {
                this.mapLoaded = true;
                addSource(this)
            })
        }
    }

    // Render chart
    render() {
        // Get variables of properties
        const { variables } = this.props;

        // Define variable icons
        const icons = {
            'temperature': <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 12.99L9 20L11 20L11 12.99L14 12.99L10 9L6 12.99L9 12.99ZM8 7.01L5 7.01L5 -2.18557e-07L3 -1.31134e-07L3 7.01L-5.67811e-07 7.01L4 11L8 7.01Z" fill="#1C9690" />
            </svg>,
            'precipitation': <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04Z" fill="#1C9690" />
            </svg>

        }

        // Render map , variable and layer controls
        return <div style={{ position: 'relative' }}>
            <div className="layer-switch" onClick={d => { this.setState({ layer: !this.state.layer }) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z" /></svg>
            </div>
            <div className="mapbox-field-legend">
                <div className="field-legend-gradient-wrapper">
                    <div className="gradient-value"> <b>{this.state.variable?.name} </b></div>
                    <div className="gradient-value">{this.state.max?.toFixed(2)} {this.state.variable?.unit}</div>
                    <div className="gradient-bar" style={{ backgroundImage: `linear-gradient(${[this.state.colors]})` }}>
                    </div>
                    <div className="gradient-value">{this.state.min?.toFixed(2)} {this.state.variable?.unit}</div>
                </div>
            </div>
            <div className="variable-controls" style={{ position: 'absolute', height: this.props.height || "200px", zIndex: 10 }}>
                {(variables ?? []).map((d, i) => {
                    return <div key={i}
                        title={d.name}
                        className={`${d.value == this.state.variable?.value ? 'active' : ''} variable-button`}
                        onClick={() => { this.setState({ variable: d }); this.props.onSelectedVariableChange && this.props.onSelectedVariableChange(d) }}
                        style={{ zIndex: 10 }}
                    >
                        {icons[d.iconType]}
                    </div>
                })}
            </div>
            <div style={{
                width: this.props.width || "200px",
                borderRadius: 5,
                height: this.props.height || "200px"
            }} ref={el => { this.mapContainer = el }} className="mapContainer" />
        </div >
    }
}

/**
* Sample:
* ```javascript
*[
*        {
*            id: 'id1',
*            name:"Field 1",
*            metadata: [{ name: 'id', value: 'id1' }, { name: 'name', value: 'test1' }],
*            polygon: [[40, 43], [41, 41], [46, 41], [40, 43]],
*            temp: 30,
*            precipitation: 10,
*        },
*        {
*            id: 'id2',
*            name:"Field 2",
*            metadata: [{ name: 'id', value: 'id2' }, { name: 'name', value: 'test2' }],
*            polygon: [[30, 33], [31, 31], [36, 31], [30, 33]],
*            temp: 30,
*            precipitation: 20,
*        }
*]
* ```
* @typedef {Array<HeatmapDataItem>} HeatmapData - Field Heatmap Main Data
*/


/**
* Sample:
* ```javascript
* {
*     id: 'id1',
*     name:"Field 1",
*     metadata: [{ name: 'id', value: 'id1' }, { name: 'name', value: 'test1' }],
*     polygon: [[40, 43], [41, 41], [46, 41], [40, 43]],
*     temp: 30,
*     precipitation: 10,
* }
* ```
* @typedef {Object} HeatmapDataItem - Field heatmap single piece data item
* @property {string} id - id of polygon
* @property {Array<MetadataObject>} metadata - Tooltip values
* @property {Array<Array<number>>} polygon - Coordinate (Latitude, Longitude) matrix for polygons
* @property {number} key:string - value of any variable (`temp` or `precipitation` e.t.c)
*/



/**
* Metadata will be used in polygon tooltips
* Sample:
* ```javascript
* {
*    name: 'id',
*    value: 'id_value'
* }
* ```
* @typedef {Object} MetadataObject - Single piece tip metadata item
* @property {string | number} name - Name of metadata item
* @property {string | number} value - Value of the metadata item
*/



/**
* Sample:
* ```javascript
[{
        iconType: 'temperature',
        name: 'Temperature',
        colors: ['Orange', 'Red'],
        value: 'temp',
        unit: "°C",
 },
 {
        iconType: 'precipitation',
        name: 'Precipitation',
        colors: ['LightBlue', 'Blue'],
        value: 'precipitation',
        unit: "mm",
}]
* ```
* @typedef {Array<VariableItem>} VariableData - Variable data items array
*/


/**
* Sample:
* ```javascript
{
    iconType: 'precipitation',
    name: 'Precipitation',
    colors: ['LightBlue', 'Blue'],
    value: 'precipitation',
    unit: "mm",
}
* ```
* @typedef {Object} VariableItem - Single piece variable data item
* @property {string} iconType - iconType, based on which svg icon will be assigned
* @property {string} name - This will get displayed in legend (`Precipitation` or `Temperature` for now)
* @property {Array<string>} colors - Two color array, data will wary from those colors
* @property {string} value - Value of variable (This will be used to retrieve relevant number value from HetampData)
* @property {string} unit - `mm` or `C` or `F` - Corresponding `imperial` or `Metric system`
*/