import Firebase from "firebase/app"
import "firebase/auth"
import "firebase/storage"
import "firebase/database"
import "firebase/analytics"
import config from "./config"
const firebaseConfig = config.firebase
// Initialize Firebase
const fire = Firebase.initializeApp(firebaseConfig)
Firebase.analytics()
export default fire
