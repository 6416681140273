import React from "react"

// Alert
export const alertField = {
    propName: "triggered_alerts",
    displayName: "",
    style: { flex: "initial", justifyContent: "flex-end", width: "40px" },
    headerStyle: { flex: "initial", width: "40px" },
    sortable: false,
}

export const checkField = {
    propName: "check",
    displayName: "",
    style: { flex: "initial", justifyContent: "center", width: "60px" },
    headerStyle: { flex: "initial", width: "60px" },
    sortable: false,
    toggler: true,
}

// Name
export const nameField = {
    propName: "name",
    displayName: "Name",
    style: { flex: 2 },
    headerStyle: { flex: 2, justifyContent: "center" },
    toggler: true,
    draggable: true,
    sortable: true,
    form: true,
    required: true,
    type: "string",
}

// Crop
export const cropField = {
    propName: "crop",
    displayName: "Crop",
    draggable: true,
    sortable: true,
    form: true,
    required: true,
    type: "string",
    groupFields: [
        {
            propName: "crop",
            displayName: "Crop",
            toggler: true,
            render(value) {
                return "Crop: " + value
            },
        },
    ],
}

export const varietyField = {
    propName: "variety",
    displayName: "Variety",
    style: { flex: 1.5 },
    headerStyle: { flex: 1.5 },
    draggable: true,
    sortable: true,
    form: true,
    required: true,
    type: "string",
    groupFields: [
        {
            propName: "variety",
            displayName: "Variety",
            toggler: true,
            render(value) {
                return "Variety: " + value
            },
        },
    ],
}

export const avgPlantDateField = {
    propName: "avgPlantDate",
    displayName: "Average Plant Date",
    style: { flex: 2 },
    sortable: true,
    transform(value, rowData) {
        let sum = 0
        for (let field of rowData.data) {
            sum += new Date(field.plantingDateP).getTime()
        }
        const avgPlantDate = new Date(sum / rowData.data.length)
        return `${avgPlantDate.getDate()}/${avgPlantDate.getMonth() + 1}/${avgPlantDate.getFullYear()}`
    },
}

export const medianStageField = {
    propName: "medianStage",
    displayName: "Median Stage",
    style: { flex: 2 },
    sortable: true,
    transform(value, rowData) {
        return "Canopy Closure"
    },
}

export const avgYieldField = {
    propName: "avgYield",
    displayName: "Average Yield",
    style: { flex: 2 },
    sortable: true,
    transform(value, rowData) {
        return "200 tn/ha"
    },
}

// Region
export const regionField = {
    propName: "region",
    displayName: "Region",
    style: { flex: 1 },
    headerStyle: { flex: 1 },
    draggable: true,
    sortable: true,
    form: true,
    required: true,
    type: "string",
}

export const labelField = {
    propName: "labels",
    displayName: "Labels",
    style: { flex: 2 },
    headerStyle: { flex: 2, justifyContent: "center" },
    draggable: false,
    sortable: false,
    form: false,
}

// Weather File
export const weatherFileField = {
    propName: "weatherfile",
    displayName: "Weather File",
    headerStyle: { display: "flex", justifyContent: "center", alignItems: "center" },
    style: { display: "flex", justifyContent: "center", alignItems: "center" },
    render: (value, obj) => {
        return <div className="weather-file">{/* <ErrorOutlined fontSize="inherit" /> */}</div>
    },
}

// Shape File
export const shapeFileField = {
    propName: "shapefile",
    displayName: "Shape File",
    headerStyle: { display: "flex", justifyContent: "center", alignItems: "center" },
    style: { display: "flex", justifyContent: "center", alignItems: "center" },
    form: true,
    required: true,
    type: "file",
}

export const actionField = {
    editField: true,
    deleteField: true,
    displayName: "Actions",
    headerStyle: { flex: "initial", display: "flex", justifyContent: "center", alignItems: "center", width: "100px" },
    style: { flex: "initial", display: "flex", justifyContent: "center", alignItems: "center", width: "100px" },
}

export const deleteField = {
    deleteField: true,
    displayName: "",
    headerStyle: { display: "flex", justifyContent: "center", alignItems: "center" },
    style: { display: "flex", justifyContent: "space-around", alignItems: "center" },
}

export function tomorrowTempField(unitsString) {
    let style = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontWeight: "bold",
    }
    return {
        displayName: (
            <>
                <div>Temp.</div>
                <div>({unitsString}°)</div>
            </>
        ),
        propName: "weatherVar_tomorrowTemp",
        sortable: false,
        headerStyle: style,
        style: style,
    }
}

export function tomorrowPrecipField(unitsString) {
    let style = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
    }

    return {
        displayName: (
            <>
                <div>Precip.</div>
                <div>({unitsString})</div>
            </>
        ),

        propName: "weatherVar_tomorrowPrecip",
        sortable: false,
        headerStyle: style,
        style: style,
    }
}

export const yieldField = {
    displayName: (
        <>
            <div>Yield</div>
            <div>(Kg/Ha)</div>
        </>
    ),
    propName: "weatherVar_yield",
    sortable: false,
    headerStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
    },
    style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
    },
}

export const yieldVsPlanField = {
    displayName: (
        <>
            <div>Yield</div>
            <div>(+/- vs. plan)</div>
        </>
    ),
    propName: "weatherVar_yieldVsPlan",
    sortable: false,
    headerStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
    },
    style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
    },
}
