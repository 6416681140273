import React from "react"

const VarietyTab = (props) => {
    const { name } = props

    return (
        <button id={name} className="alert-settings__alerts__variety-button">
            <div className="alert-settings__alerts__variety-title">{name} Variety</div>
        </button>
    )
}

export default VarietyTab
