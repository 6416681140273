import React from "react"

import "./Popover.css"

function Popover(props) {
    const { children, text, className = "" } = props
    return (
        <>
            <span className={"popover " + className} data-value={text}>
                {children}
            </span>
        </>
    )
}

export default Popover
