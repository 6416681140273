import React, { useState } from "react"
// Modules
// Components
// Views
// Context providers / Utils
// Hooks
// Material-UI *
import { Typography } from "@material-ui/core"

// Styles
import "../WeatherVariableTabs.css"

const DailyMonthlyOptions = (props) => {
    const { monthlyAvailable = false, monthlySelected = false, onChange = () => null } = props

    return (
        <div className="blocks-container">
            {
                <div
                    className="textBlockContainer"
                    style={{
                        marginBottom: 15,
                        boxShadow: !monthlySelected && "0px 4px 10px #C0D2E4",
                        backgroundColor: !monthlySelected ? "#1C9690" : "",
                    }}
                    onClick={() => {
                        onChange(false)
                    }}
                >
                    <Typography
                        className="textBlock"
                        style={{
                            color: !monthlySelected ? "#fff" : "",
                        }}
                    >
                        Daily
                    </Typography>
                </div>
            }
            {monthlyAvailable ? (
                <div
                    className="textBlockContainer"
                    style={{
                        marginBottom: 22,
                        boxShadow: monthlySelected && "0px 4px 10px #C0D2E4",
                        backgroundColor: monthlySelected ? "#1C9690" : "",
                    }}
                    onClick={() => {
                        onChange(true)
                    }}
                >
                    <Typography
                        className="textBlock"
                        style={{
                            marginBottom: 22,
                            boxShadow: monthlySelected && "0px 4px 10px #C0D2E4",
                            backgroundColor: monthlySelected ? "#1C9690" : "",
                        }}
                    ></Typography>
                    <Typography
                        className="textBlock"
                        style={{
                            boxShadow: "none",
                            color: monthlySelected ? "#fff" : "",
                        }}
                    >
                        Monthly
                    </Typography>
                </div>
            ) : (
                <div
                    className="textBlockContainer disabled"
                    style={{
                        marginBottom: 22,
                        boxShadow: "none",
                        cursor: "no-drop",
                    }}
                >
                    <Typography style={{ color: "gray" }} className="textBlock">
                        Monthly
                    </Typography>
                </div>
            )}
        </div>
    )
}

export default DailyMonthlyOptions
