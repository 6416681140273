import React from "react"
import { useHistory } from "react-router-dom"
import UserProfile from "../../components/UserProfile"
import ChevronLeft from "@material-ui/icons/ChevronLeft"

import "./SettingsTopbar.css"

const SettingsTopbar = (props) => {
    const { settingsName } = props
    const history = useHistory()

    return (
        <div className="settings__menu-header">
            <ChevronLeft
                onClick={() => {
                    history.goBack()
                }}
                className="main__header__title-return-icon clickable"
            />
            <div className="settings__header-title">{settingsName} Settings</div>

            <div className="settings__user-profile">
                <UserProfile />
            </div>
        </div>
    )
}

export default SettingsTopbar
