import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { withRouter } from "react-router"
import Copyright from "../ui/Copyright/Copyright"
import "./PasswordCode.css"

const PasswordCode = () => {
    return (
        <div className="password-code">
            <div className="password-code__container">
                <img alt="climate ai color logo" className="password-code__logo" src="/Climate-AI-Color-Logo.png" />
                <div className="password-code__content">
                    <div className="password-code__title">Password recovery</div>
                    <div className="password-code__text">
                        For your security, we need to verify your identity. We have sent a code to your email.
                    </div>
                    <div className="password-code__form">
                        <input
                            className="password-code__text-input"
                            placeholder="Enter Code"
                            type="text"
                            id="code"
                            name="code"
                        ></input>
                        <div className="password-code__button-container">
                            <RouterLink to="/">
                                <button className="password-code__cancel-button">Cancel</button>
                            </RouterLink>
                            <RouterLink to="/create-password">
                                <button className="password-code__confirm-button">Confirm</button>
                            </RouterLink>
                        </div>
                    </div>

                    <div className="password-code__resend-code">Resend Code</div>
                </div>
                <div className="password-code__copyright">
                    <Copyright />
                </div>
            </div>
        </div>
    )
}

export default withRouter(PasswordCode)
