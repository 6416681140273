import React, { useRef } from "react"
// Modules
import { Link as RouterLink } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { useHistory } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"

// Components
import CropTab from "./components/CropTab"
import CropAlert from "./components/CropAlert"
import SettingsNavbar from "../../../ui/Navbar/Navbar"
import SettingsTopbar from "../../../ui/Navbar/SettingsTopbar"

// Views

// Context providers / Utils
import { AuthContext } from "../../../Auth/Auth"
import { UIAlertContext } from "../../../ui/UIAlert/UIAlert"
import {
    getUserAlerts,
    updateNotifyVia,
    getCropVarietyConfigs,
    updateCropVarietyConfigs,
} from "../../../Util/AlertsMiddleware"
import { parseCSVFile, toCSVFile } from "../../../Util/CSVParser"
// Hooks

// Material-UI *

// Styles
import "react-toastify/dist/ReactToastify.css"
import "./AlertSettings.css"
import "./components/Alert.css"

const AlertSettings = (props) => {
    const { stacked = false } = props

    const [crops, setCrops] = useState({
        crops: {
            name: "Loading",
        },
    })
    const [loading, setLoading] = useState(false)

    const { currentUser, featurePermissions } = useContext(AuthContext)
    const { confirmationModal } = useContext(UIAlertContext)
    const [notifyVia, setNotifyVia] = useState({ table: false, email: false })

    async function handleVarietyFileDownload(crop, variety) {
        setLoading(true)
        const userToken = await currentUser.getIdToken()
        const { data } = await getCropVarietyConfigs(userToken, crop, variety)
        setLoading(false)
        // download json, directly from stack overflow lol

        const result = toCSVFile(crop, variety, data)

        const dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(result)
        const downloadAnchorNode = document.createElement("a")
        downloadAnchorNode.setAttribute("href", dataStr)
        downloadAnchorNode.setAttribute("download", crop + "-" + variety + ".csv")
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
    }

    async function handleVarietyFileUpload(crop, variety, file) {
        // let uploadedFile = e.target.files[0]

        const success = await confirmationModal("Are you sure?", "This action will replace all your alerts.", [])

        if (!success) return

        setLoading(true)
        let result = null

        result = await parseCSVFile(5, file)

        if (result.error) {
            setLoading(false)
            toast.error(result.error)
            return
        }

        const userToken = await currentUser.getIdToken()
        await updateCropVarietyConfigs(userToken, crop, variety, result)

        setTimeout(async () => {
            await fetchAlerts()
            setLoading(false)
            toast.success("Alert configurations successfully updated!")
        }, 1000)
    }

    async function fetchAlerts() {
        try {
            let userToken = await currentUser.getIdToken()
            getUserAlerts(userToken).then((res) => {
                let { notify_via_table, notify_via_email, ...crops } = res.data
                setNotifyVia({
                    table: notify_via_table || false,
                    email: notify_via_email || false,
                })
                setCrops(crops)
            })
        } catch (err) {
            console.log("Error getting user alerts ", err)
        }
    }

    useEffect(() => {
        fetchAlerts()
    }, [])

    async function onChangeNotify(via) {
        if (!featurePermissions.alerts.edit) {
            return
        }
        let newNotifyVia = { ...notifyVia, [via]: !notifyVia[via] }
        setNotifyVia(newNotifyVia)
        try {
            let userToken = await currentUser.getIdToken()
            updateNotifyVia(userToken, newNotifyVia)
        } catch (err) {
            console.log("Error updating channel of notification ", err)
        }
    }

    return (
        <>
            <div className="alert-settings">
                <div className="alert-settings__container">
                    {/* <div className="alert-settings__container__navbar">
                    <SettingsNavbar />
                </div> */}

                    <div className="alert-settings__menu">
                        {loading && <div className="alert-settings__menu__loader" />}
                        {/* <SettingsTopbar settingsName={"Alerts"} /> */}
                        <div style={{ display: "none" }}>
                            <div className="alert-settings__title">
                                <div className="alert-settings__title-dot">&middot;</div> Select if you prefer to be
                                notified via email and/or see notifications on your field's table
                            </div>
                            <div className="alert-settings__text"></div>
                            <input
                                type="checkbox"
                                id="notify_email"
                                name="notify_email"
                                checked={notifyVia.email}
                                onChange={() => {
                                    onChangeNotify("email")
                                }}
                            />
                            <label htmlFor="notify_email">Email</label>

                            <input
                                type="checkbox"
                                id="notify_dashboard"
                                name="notify_dashboard"
                                checked={notifyVia.table}
                                onChange={() => {
                                    onChangeNotify("table")
                                }}
                            />
                            <label htmlFor="notify_dashboard">Field's table</label>

                            {/* <div className="alert-settings__checkbox-container">
                              <span className="alert-settings__checkmark"></span>
                              <input
                                  className="alert-settings__checkbox"
                                  type="checkbox"
                                  id="notify_dashboard"
                                  name="notify_dashboard"
                                  checked={notifyVia.dashboard}
                                  onChange={() => {
                                      onChangeNotify("dashboard")
                                  }}
                              />
                          </div> */}

                            {/* <Checkbox /> */}
                        </div>

                        <div className="alert-settings__alerts-menu-container">
                            <Tabs>
                                <TabList className="alert-settings__alerts__crop-menu-container">
                                    {Object.keys(crops).map((crop) => {
                                        let name = crops[crop].name || crop
                                        return (
                                            <Tab key={"tab-" + crop}>
                                                <CropTab name={name} />
                                            </Tab>
                                        )
                                    })}
                                </TabList>
                                <div className="alert-settings__alerts-container">
                                    {Object.keys(crops).map((crop) => {
                                        let cropObj = crops[crop]
                                        return (
                                            <TabPanel key={"tabpanel-" + crop}>
                                                <CropAlert
                                                    cropObj={{ ...cropObj }}
                                                    path={{ crop: crop }}
                                                    onVarietyFileDownload={handleVarietyFileDownload}
                                                    onVarietyFileUpload={handleVarietyFileUpload}
                                                    isDownloadEnabled={featurePermissions?.alerts?.view}
                                                    isUploadEnabled={featurePermissions?.alerts?.add}
                                                    stackedAlertsView={stacked}
                                                />
                                            </TabPanel>
                                        )
                                    })}
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default AlertSettings
