import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={15} height={10} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M0 10h15V8.571H0V10zm0-4.286h15V4.286H0v1.428zM0 0v1.429h15V0H0z" fill="#828282" />
        </svg>
    )
}

export default SvgComponent
