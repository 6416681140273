import React, { useRef, useEffect, useState } from "react"
import Navbar from "../Navbar"
import UserProfile from "../../components/UserProfile"
import MenuIcon from "../Icons/MenuIcon"
import "./MobileHeader.css"

// TODO: Change to a smoother transition between pages with Navbar navigation

function MobileHeader(props) {
    const { navbarContext, view, pages, permissions } = props
    // TODO: Add toggle animations
    const [openMenu, toggleMenu] = useState(false)

    const handleMenuClick = (e) => {
        toggleMenu(true)
    }

    const wrapperRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target) && openMenu) {
                toggleMenu(false)
            }
        }

        document.addEventListener("touchstart", handleClickOutside)
        return () => {
            document.removeEventListener("touchstart", handleClickOutside)
        }
    }, [wrapperRef, openMenu])

    return (
        <>
            {openMenu && (
                <div className="mobile-header__navbar" ref={wrapperRef}>
                    <Navbar navbarContext={navbarContext} view=
                    {view} pages={pages} permissions={permissions} />
                </div>
            )}
            <div className="mobile-header">
                <MenuIcon onClick={handleMenuClick} className="mobile-header__icon" />

                <UserProfile />
            </div>
        </>
    )
}

export default MobileHeader
