import React, { useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
// Material UI
import { makeStyles, useTheme } from "@material-ui/styles"
import { useMediaQuery, Grid } from "@material-ui/core"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
// Components
import { UserProfile } from "../../components"
import { Sidebar } from "./components"
import MobileHeader from "../../ui/Header/MobileHeader"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Popover } from "../../ui/Popover"

// Auth
import { AuthContext } from "../../Auth/Auth"
// Util
import networking from "../../Util/Networking"
// React Router
import { useParams, Link as RouterLink } from "react-router-dom"
// Styles
import "../../styles/chart.css"
import "./Main.css"

const Main = (props) => {
    const { children, name } = props
    let { id } = useParams()
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    })
    const [openSidebar, setOpenSidebar] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [polygonData, setPolygonData] = useState({})
    const [fieldData, setFieldData] = useState({})
    const [fieldName, setFieldName] = useState("")
    const [header, setHeader] = useState("")

    const { currentUser } = useContext(AuthContext)
    const history = useHistory()

    // Load data
    useEffect(() => {
        currentUser.getIdToken().then((userToken) => {
            // Load polygon data
            networking
                .get(`/api/v1/maps/ndvimap?uuid=${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setPolygonData(res.data)
                })
                .catch((err) => {
                    toast.warn("Could not load polygon data.")
                })

            // Load fields data
            networking
                .get(`/api/v1/fields/${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setFieldData(res.data.data)
                })
                .catch((err) => {
                    toast.error("Could not load field data")
                })
        })
    }, [currentUser, id])

    const handleSidebarOpen = () => {
        setOpenSidebar(true)
    }

    const handleSidebarClose = () => {
        setOpenSidebar(false)
    }

    // Handle Header Text
    const getFieldName = () => {
        setFieldName("")
        currentUser.getIdToken().then((userToken) => {
            networking
                .get("/api/v1/fields/" + id, { extraHeaders: { "User-Token": userToken } })
                .then((res) => res.data)
                .then((result) => {
                    let f = result.data.name
                    setFieldName(f)
                })
        })
    }

    const getHeader = () => {
        switch (name) {
            case "general":
                setHeader("General Model")
                break
            case "weather":
                setHeader("Weather Model")
                break
            case "satellite":
                setHeader("Satellite Model")
                break
            case "planting_tool":
                setHeader("Planting Tool")
                break
            default:
                setHeader("View")
                break
        }
    }

    useEffect(() => {
        if (currentUser !== null) {
            getFieldName()
            getHeader()
        }
    }, [currentUser, id, name])

    // Closes menu on click outside
    // TODO: Refactor as an util
    const handleMenuClick = (e) => {
        setOpenMenu(true)
    }

    const [tabletMedia, setTabletMedia] = useState()

    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)")

    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    const shouldOpenSidebar = isDesktop ? true : openSidebar

    return (
        <div className="main">
            <div className="main__container">
                <div className="main__header__navbar">
                    <Sidebar
                        onClose={handleSidebarClose}
                        view={name}
                        open={shouldOpenSidebar}
                        variant={isDesktop ? "persistent" : "temporary"}
                        fieldName={id}
                    />
                </div>
                <div className="main__content">
                    <div className="main__header">
                        <div className="main__header__title-container">
                            <Popover
                                text="Go back"
                                className="plain-popover">
                                <ChevronLeft
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                    className="main__header__title-return-icon clickable"
                                />
                            </Popover>

                            <div className="main__header__title-model">{header}</div>
                            <ChevronRight className="main__header__title-icon" />

                            <div className="main__header__title-field">{fieldName}</div>
                        </div>

                        {!tabletMedia && <UserProfile />}
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

Main.propTypes = {
    children: PropTypes.node,
}

export default Main
