import React, { useState } from "react"

import ShareIcon from "../Icons/ShareIcon"
import ShareLinkIcon from "../Icons/ShareLinkIcon"
import PlusIconAlt from "../Icons/PlusIconAlt"
import Checkbox from "../Checkbox/Checkbox"
import SimpleButton from "../Button/SimpleButton"

import EmailItem from "./EmailItem"

import "./ShareReportForm.css"

function ShareReportForm(props) {
    const { onContinue, onCancel } = props

    const [state, setState] = useState({ items: [], value: "", error: null })

    function validate() {
        var value = state.value.trim()

        if (value && isValid(value)) {
            setState({
                ...state,
                items: [...state.items, state.value],
                value: "",
            })
        }
    }

    function handleKeyDown(e) {
        if (["Enter", "Tab", ","].includes(e.key)) {
            e.preventDefault()
            validate()
        }
    }
    function handleAddButtonClick(e) {
        e.preventDefault()
        validate()
    }

    function handleChange(evt) {
        setState({
            ...state,
            value: evt.target.value,
            error: null,
        })
    }

    function handlePaste(evt) {
        evt.preventDefault()

        var paste = evt.clipboardData.getData("text")
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)

        if (emails) {
            var toBeAdded = emails.filter((email) => !isInList(email))

            setState({
                ...state,
                items: [...state.items, ...toBeAdded],
            })
        }
    }

    function isValid(email) {
        let error = null

        if (isInList(email)) {
            error = `${email} has already been added.`
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`
        }

        if (error) {
            setState({ ...state, error })

            return false
        }

        return true
    }

    function handleItemDelete(item) {
        let itemIndex = state.items.indexOf(item)

        if (itemIndex > -1) {
            const _items = [...state.items]
            _items.splice(itemIndex, 1)
            setState({ ...state, items: _items })
        }
    }

    function isInList(email) {
        return state.items.includes(email)
    }

    function isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)
    }

    return (
        <>
            <div className="share-report-form">
                <div className="share-report-form__emails">
                    <p className="share-report-form__emails__text">
                        We’ll send it to your ClimateAI associated email when it’s ready.
                    </p>
                    <div className="share-report-form__emails__title">
                        <ShareIcon />
                        <h3 className="share-report-form__emails__title__text">
                            Share your report with others (optional)
                        </h3>
                    </div>
                    <div className="share-report-form__email__input-container">
                        <input
                            type="text"
                            placeholder="Add People to Share With"
                            className={"share-report-form__email__input" + (state.error ? " error" : "")}
                            value={state.value}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            onPaste={handlePaste}
                        />
                        <button className="share-report-form__email__input-button" onClick={handleAddButtonClick}>
                            <PlusIconAlt width={12} height={12} />
                        </button>
                    </div>
                    <div className="share-report-form__email__error">{state.error}</div>
                    <div className="share-report-form__email__list">
                        {state.items &&
                            state.items.map((email, index) => {
                                return (
                                    <EmailItem
                                        onDelete={() => handleItemDelete(email)}
                                        name={"Friend " + (index + 1)}
                                        email={email}
                                    />
                                )
                            })}
                    </div>
                </div>
                <div className="share-report-form__share">
                    <div className="share-report-form__share__title">
                        <ShareLinkIcon />
                        <h3 className="share-report-form__share__title__text">Get Link to Share</h3>
                        {/* <p className="share-report-form__share__title__soon">(available soon)</p> */}
                        {/* <button className="share-report-form__share__button">Copy Link</button> */}
                    </div>
                    <div className="share-report-form__share__checkbox">
                        {/* <Checkbox /> */}
                        <p className="share-report-form__share__checkbox__text">
                            <strong>Available soon,</strong> only people you added can see this report.
                        </p>
                    </div>
                    <div className="share-report-form__share__checkbox__button">
                        <SimpleButton
                            onClick={onCancel}
                            style={{ backgroundColor: "#838383", fontWeight: "400", marginRight: "10px" }}
                        >
                            Cancel
                        </SimpleButton>
                        <SimpleButton
                            onClick={() => onContinue(state.items)}
                            style={{ backgroundColor: "#1C9690", fontWeight: "400" }}
                        >
                            Send Report
                        </SimpleButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ShareReportForm)
