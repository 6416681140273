import * as React from "react"

function PlusIconAlt(props) {
    return (
        <svg width={15} height={15} viewBox="0 0 15 15" fill="none" {...props}>
            <path
                d="M13.929 8.571H8.57v5.358C8.571 14.518 8.09 15 7.5 15c-.59 0-1.071-.482-1.071-1.071V8.57H1.07C.482 8.571 0 8.09 0 7.5c0-.59.482-1.071 1.071-1.071H6.43V1.07C6.429.482 6.91 0 7.5 0c.59 0 1.071.482 1.071 1.071V6.43h5.358C14.518 6.429 15 6.91 15 7.5c0 .59-.482 1.071-1.071 1.071z"
                fill="#1F9992"
            />
        </svg>
    )
}

export default PlusIconAlt
