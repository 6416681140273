import * as React from "react"

import "./Styles/Icons.css"

function ThermometerUpIcon(props) {
    return (
        <div className="thermometer-up-icon">
            <svg width={5} height={12} viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M4.228 7.974V2.217c0-.81-.544-1.531-1.328-1.696A1.689 1.689 0 00.863 2.166v5.808c0 .05-.026.1-.064.126a2.542 2.542 0 00-.607 2.822c.291.721.924 1.29 1.683 1.48a2.549 2.549 0 003.213-2.454c0-.709-.29-1.38-.797-1.848-.05-.025-.063-.076-.063-.126zm-2.24 3.833a1.873 1.873 0 01-1.15-.949 1.909 1.909 0 01.569-2.441.175.175 0 00.076-.14V2.23c0-.556.404-1.075.961-1.126a1.075 1.075 0 011.177 1.063v6.099c0 .063.025.114.076.139.48.354.784.936.784 1.544-.012 1.252-1.202 2.226-2.492 1.86z"
                    fill="white"
                />
                <path
                    d="M2.989 8.733V3.38a.454.454 0 00-.456-.456.454.454 0 00-.455.456v5.352c-.506.19-.86.67-.86 1.227a1.315 1.315 0 102.631 0c0-.57-.354-1.05-.86-1.227z"
                    fill="white"
                />
            </svg>

            <svg width={5} height={7} viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M.24 2.648h1.5v3.797h1V2.648h1.5l-2-2.161-2 2.161z" fill="#fff" />
            </svg>
        </div>
    )
}

export default ThermometerUpIcon
