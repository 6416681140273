import * as React from "react"

function DownloadFileIcon(props) {
    return (
        <svg width={12} height={10} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.313 6.188a.312.312 0 01.312.312v1.563a.625.625 0 00.625.625h7.5a.625.625 0 00.625-.625V6.5a.313.313 0 11.625 0v1.563a1.25 1.25 0 01-1.25 1.25h-7.5A1.25 1.25 0 011 8.063V6.5a.312.312 0 01.313-.312z"
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.5}
            />
            <path
                d="M6.222.717a.312.312 0 00-.443 0L3.904 2.592a.313.313 0 00.443.442l1.341-1.342v5.496a.313.313 0 00.625 0V1.692l1.341 1.342a.313.313 0 10.443-.442L6.222.717z"
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.5}
            />
        </svg>
    )
}

export default DownloadFileIcon
