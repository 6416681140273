import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={9} height={10} viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#prefix__clip0)">
                <path
                    d="M2.535 2.302l-.675-.671-.529.528.671.672.533-.529zM1.5 4.424H.375v.75H1.5v-.75zM4.875.694h-.75v1.105h.75V.693zm2.794 1.465l-.529-.528-.671.671.528.529.672-.672zM6.465 7.297l.671.675.529-.529-.675-.671-.525.525zM7.5 4.424v.75h1.125v-.75H7.5zm-3-1.875A2.252 2.252 0 002.25 4.8 2.252 2.252 0 004.5 7.05 2.252 2.252 0 006.75 4.8 2.252 2.252 0 004.5 2.55zm-.375 6.357h.75V7.799h-.75v1.107zM1.331 7.439l.529.53.671-.676-.529-.529-.67.675z"
                    fill="#fff"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" transform="translate(0 .487)" d="M0 0h9v9H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgComponent
