import React, { useState } from "react"

import "./NavbarFields.css"

import ListBullet from "../Icons/ListBullet"
import ArrowRight from "../Icons/ArrowRight"
import SearchIcon from "../Icons/SearchIcon"

import { Link as RouterLink, useParams } from "react-router-dom"
import NavbarFieldsItem from "./NavbarFieldsItem"

import { filterDataByInput } from "../../helpers/table"

function NavbarFields(props) {
    //
    const [open, toggle] = useState(false)
    const [inputValue, setInputValue] = useState("")

    //
    const { view, fields, permissions } = props
    const { id } = useParams()

    const currentRegion = fields.find((field) => field.uuid === id)?.region
    const currentRegionFields = fields.filter((field) => field.region === currentRegion && field.uuid !== id)

    function handleInputChange(e) {
        setInputValue(e.target.value)
    }

    return (
        <>
            <div className={open ? "navbar-fields--highlight" : "navbar-fields"}>
                <div className="navbar-fields__menu">
                    <div>
                        <RouterLink to="/dashboard">
                            <ListBullet className="navbar-fields__menu__return-button" />
                        </RouterLink>
                    </div>
                    <div className={"navbar-fields__menu__text"}>My Fields</div>

                    <ArrowRight
                        onClick={() => toggle(!open)}
                        className={
                            open ? "navbar-fields__menu__deploy-button rotate" : "navbar-fields__menu__deploy-button"
                        }
                    />
                </div>

                <div
                    className={
                        open
                            ? "navbar-fields__menu__deployable-fields--deployed"
                            : "navbar-fields__menu__deployable-fields"
                    }
                >
                    <div className="navbar-fields__search-bar">
                        <SearchIcon className="navbar-fields__search-bar__icon" />
                        <input
                            className="navbar-fields__search-bar__input"
                            placeholder="Search"
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="navbar-fields__fields-list">
                        <div className="navbar-fields__fields-list__info">
                            {!inputValue && <div className="navbar-fields__fields-list__region">Current Region:</div>}
                            <div className="navbar-fields__fields-list__items-container">
                                {(
                                    (!inputValue && currentRegionFields && currentRegionFields) ||
                                    (inputValue &&
                                        filterDataByInput(
                                            fields.filter((field) => field.uuid !== id),
                                            inputValue
                                        ))
                                ).map((field, i) => {
                                    return (
                                        <RouterLink key={i} to={"/" + view + "/" + field.uuid}>
                                            <NavbarFieldsItem lot={field.name} crop={field.crop} />
                                        </RouterLink>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavbarFields
