import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={7} height={8} viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.767 7.404c.245-.166.325-.524.178-.8-.42-.788-.42-1.53 0-2.317.617-1.157.617-2.36 0-3.517-.148-.276-.466-.366-.712-.2-.245.166-.325.524-.178.8.42.788.42 1.53 0 2.317-.617 1.157-.617 2.36 0 3.517.148.276.466.365.712.2zM1.174 7.404c.246-.166.325-.524.178-.8-.42-.788-.42-1.53 0-2.317.617-1.157.617-2.36 0-3.517C1.205.494.886.404.641.57c-.246.166-.326.524-.178.8.42.788.42 1.53 0 2.317-.617 1.157-.617 2.36 0 3.517.147.276.466.365.711.2zM6.36 7.404c.245-.166.325-.524.177-.8-.42-.788-.42-1.53 0-2.317.617-1.157.617-2.36 0-3.517-.147-.276-.466-.366-.711-.2-.246.166-.325.524-.178.8.42.788.42 1.53 0 2.317-.617 1.157-.617 2.36 0 3.517.147.276.466.365.711.2z"
                fill="#fff"
            ></path>
        </svg>
    )
}

export default SvgComponent
