import React, { useState, useContext, useEffect } from "react"

// Modules
import { ToastContainer, toast } from "react-toastify"

// Components
import Navbar from "../../../ui/Navbar/Navbar"
import TableColumns from "./components/TableColumns"
import SettingsTopbar from "../../../ui/Navbar/SettingsTopbar"

// Views

// Context providers / Utils
import { AuthContext } from "../../../Auth/Auth"
import networking from "../../../Util/Networking"

// Hooks

// Material-UI *

// Styles
import "react-toastify/dist/ReactToastify.css"
import "./TableSettings.css"

const TableSettings = () => {
    const { currentUser, featurePermissions } = useContext(AuthContext)
    const [modal, setModal] = useState("")
    const [tableColumns, setTableColumns] = useState({})
    const [columnsSaving, setColumnsSaving] = useState(false)

    async function fetchTableColumnsSettings() {
        try {
            let userToken = await currentUser.getIdToken()
            networking
                .get("/api/v1/tablesettings/columns", {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setTableColumns(res.data)
                })
        } catch (err) {
            toast.warning("Error fetching column settings")
            console.log("Error getting user alerts ", err)
        }
    }

    async function updateTableColumnsSettings() {
        setColumnsSaving(true)
        let userToken = await currentUser.getIdToken()
        networking
            .post("/api/v1/tablesettings/columns", tableColumns, {
                extraHeaders: { "User-Token": userToken },
            })
            .then((res) => {
                toast.success("Successfully updated column settings.")
                setColumnsSaving(false)
            })
            .catch((err) => {
                toast.error("Error updating column settings.")
                setColumnsSaving(false)
            })
    }

    function handleCheckboxChange(propName) {
        setTableColumns({ ...tableColumns, [propName]: !tableColumns[propName] })
    }

    useEffect(() => {
        fetchTableColumnsSettings()
    }, [])

    return (
        <>
            <div className="table-settings__menu">
                <div className="table-settings__card small">
                    <div className="table-settings__card-title">Weather Variables</div>
                    <div className="table-settings__card-subtitle">
                        Select which weather variables you want to see for each field on your main table.
                    </div>
                    <div className="columns-checkbox-container">
                        <TableColumns
                            columns={tableColumns}
                            onCheckboxChange={handleCheckboxChange}
                            isAllowedToEdit={featurePermissions.table.edit}
                        />
                    </div>
                    {featurePermissions.table.edit && (
                        <div className="table-settings__save-button__container">
                            <button
                                className="table-settings__save-button"
                                onClick={updateTableColumnsSettings}
                                disabled={columnsSaving}
                            >
                                {!columnsSaving ? "Save" : "Saving..."}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <ToastContainer />
        </>
    )
}

export default TableSettings
