import React, { useState, useContext, useEffect } from "react"

// Modules
import { Link as RouterLink } from "react-router-dom"
import firebase from "firebase/app"

// Components
import ConfirmationModal from "../../../ui/Modal/ConfirmationModal"
import ChangeEmailModal from "../../../ui/Modal/ChangeEmailModal"
import ChangePasswordModal from "../../../ui/Modal/ChangePasswordModal"
import NewPasswordModal from "../../../ui/Modal/NewPasswordModal"
import ChangePictureModal from "../../../ui/Modal/ChangePictureModal"
import EditIcon from "../../../ui/Icons/EditIcon"
import CheckIcon from "../../../ui/Icons/CheckIcon"
import CancelIcon from "../../../ui/Icons/CancelIcon"
import Navbar from "../../../ui/Navbar/Navbar"
import SettingsTopbar from "../../../ui/Navbar/SettingsTopbar"
import UnitSystem from "./components/UnitSystem"
import MobileHeader from "../../../ui/Header/MobileHeader"

// Views

// Context providers / Utils
import { AuthContext } from "../../../Auth/Auth"
import { SettingsContext } from "../../../Util/SettingsContext"
import validateEmail from "../../../Util/Validate"
import app from "../../../Util/Fire"

// Hooks

// Material-UI *
import useMediaQuery from "@material-ui/core/useMediaQuery"

// Styles
import "./ProfileSettings.css"

const ProfileSettings = () => {
    const [modal, setModal] = useState("")

    const { currentUser } = useContext(AuthContext)

    const { currentSettings, handleUpdateDBUserSettings } = useContext(SettingsContext)

    const [needRecentLogin, setNeedRecentLogin] = useState(false)

    const [formDisplayName, setFormDisplayName] = useState(currentUser.displayName || "")
    const [displayNameError, setDisplayNameError] = useState({})
    const [isDisplayNameDisabled, setIsDisplayNameDisabled] = useState(formDisplayName !== null)

    const [formEmail, setFormEmail] = useState(currentUser.email || "")
    const [emailError, setEmailError] = useState({})
    const [isEmailDisabled, setIsEmailDisabled] = useState(formEmail !== null)

    // Media Handler
    const [tabletMedia, setTabletMedia] = useState(null)

    const mediaBreakpoint = useMediaQuery("(min-width: 900px)")

    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    const canUpdateEmail = () => {
        if (formEmail === "" || formEmail === null || formEmail === undefined) {
            setEmailError(() => {
                return {
                    flag: "error",
                    message: "Email cannot be empty.",
                }
            })
            return false
        }

        if (!validateEmail(formEmail)) {
            setEmailError(() => {
                return {
                    flag: "error",
                    message: "Invalid email. Valid e-mail can contain only latin letters, numbers, '@' and '.'",
                }
            })
            return false
        }
        return true
    }

    const handleEmailEdit = async () => {
        if (isEmailDisabled) {
            setIsEmailDisabled(false)
        } else {
            if (!canUpdateEmail()) {
                return
            }

            if (formEmail !== currentUser.email) {
                try {
                    await app.auth().currentUser.updateEmail(formEmail)
                } catch (err) {
                    let message = err.message
                    if (err.code === "auth/requires-recent-login") {
                        setNeedRecentLogin(true)
                        message = "Please try again"
                    }
                    setEmailError(() => {
                        return {
                            flag: "error",
                            message,
                        }
                    })
                    return
                }
            }
            setEmailError(() => {
                return { flag: "", message: "" }
            })
            setIsEmailDisabled(true)
        }
    }

    const handleDisplayNameEdit = async () => {
        if (isDisplayNameDisabled) {
            setIsDisplayNameDisabled(false)
        } else {
            if (formDisplayName === "" || formDisplayName === null || formDisplayName === undefined) {
                setDisplayNameError(() => {
                    return {
                        flag: "error",
                        message: "User Name cannot be empty.",
                    }
                })
                return
            } else if (formDisplayName !== currentUser.displayName) {
                try {
                    await app.auth().currentUser.updateProfile({
                        displayName: formDisplayName,
                    })
                } catch (err) {
                    setDisplayNameError(() => {
                        return {
                            flag: "error",
                            message: err.message,
                        }
                    })
                    return
                }
            }
            setDisplayNameError(() => {
                return { flag: "", message: "" }
            })
            setIsDisplayNameDisabled(true)
        }
    }

    const handleCancelDisplayNameEdit = () => {
        setFormDisplayName(currentUser.displayName)
        setIsDisplayNameDisabled(true)
        setDisplayNameError(() => {
            return { flag: "", message: "" }
        })
    }

    const handleCancelEmailEdit = () => {
        setFormEmail(currentUser.email)
        setIsEmailDisabled(true)
        setEmailError(() => {
            return { flag: "", message: "" }
        })
    }

    const reauthUser = (e) => {
        e.preventDefault()
        const userPassword = e.target.password.value
        const cred = firebase.auth.EmailAuthProvider.credential(currentUser.email, userPassword)

        currentUser
            .reauthenticateWithCredential(cred)
            .then(function () {
                setNeedRecentLogin(false)
            })
            .catch(function (error) {
                alert(error)
            })
    }

    return (
        <div className="profile-settings">
            <div className="profile-settings__grid">
                <div className="profile-settings__image-column">
                    <div className="profile-settings__user-profile__icon">
                        {currentUser.displayName ? currentUser.displayName[0] : currentUser.email[0]}
                    </div>
                    {/*<div
                                    className="profile-seetings__image-column__edit-icon-container"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal("changePicture")
                                    }}
                                >
                                    <EditIcon fill="white" />
                                </div>
                                <img
                                    className="profile-settings__image-column__image"
                                    src="/images/user_placeholder.png"
                                    alt="profile"
                                />
                                <div className="profile-seetings__image-column__edit-icon-container">
                                    <EditIcon fill="white" />
                                </div>*/}
                </div>

                <div className="profile-settings__info-column">
                    <div className="profile-settings__info-column__title-text">Full Name</div>
                    <div className={"profile-settings__info-column__info-container " + displayNameError.flag}>
                        <input
                            className="profile-settings__info-column__info-text"
                            disabled={isDisplayNameDisabled}
                            value={formDisplayName}
                            onChange={(e) => setFormDisplayName(e.target.value)}
                        />
                        <div className="profile-settings__info-column__edit-icon-container">
                            <div className={!isDisplayNameDisabled ? "hidden" : ""}>
                                <div onClick={handleDisplayNameEdit}>
                                    <EditIcon />
                                </div>
                            </div>
                            <div
                                className={
                                    !isDisplayNameDisabled
                                        ? "profile-settings__info-column__edit-confirmation-container"
                                        : "hidden"
                                }
                            >
                                <div
                                    className="profile-settings__info-column__edit-confirmation-container__confirm"
                                    onClick={handleDisplayNameEdit}
                                >
                                    <CheckIcon fill="#505050" />
                                </div>
                                <div
                                    className="profile-settings__info-column__edit-confirmation-container__cancel"
                                    onClick={handleCancelDisplayNameEdit}
                                >
                                    <CancelIcon fill="#505050" />
                                </div>
                            </div>
                        </div>
                        {displayNameError.flag && (
                            <div className="profile-settings__info-column__info-container__error-message">
                                {" "}
                                {displayNameError.message}{" "}
                            </div>
                        )}
                    </div>

                    <div className="profile-settings__info-column__title-text">Email Address</div>
                    <div className={"profile-settings__info-column__info-container " + emailError.flag}>
                        <input
                            className="profile-settings__info-column__info-text"
                            disabled={isEmailDisabled}
                            value={formEmail}
                            type="email"
                            onChange={(e) => setFormEmail(e.target.value)}
                        />
                        <div className="profile-settings__info-column__edit-icon-container">
                            <div className="hidden">
                                {/*!isEmailDisabled ? "hidden" : ""*/}
                                <div onClick={handleEmailEdit}>
                                    <EditIcon />
                                </div>
                            </div>
                            <div className={!isEmailDisabled ? "" : "hidden"}>
                                <div onClick={handleEmailEdit}>
                                    <CheckIcon />
                                </div>
                                <div onClick={handleCancelEmailEdit}>
                                    <CancelIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                    {emailError.flag && <div> {emailError.message} </div>}

                    <div className="profile-settings__info-column__title-text">Password</div>
                    <div className="profile-settings__info-column__info-container">
                        <div type="password" className="profile-settings__info-column__info-password">
                            ***************
                        </div>
                    </div>
                    <RouterLink
                        className="profile-settings__info-column__change-password-option"
                        to="/restore-password"
                    >
                        Change Password
                    </RouterLink>
                </div>

                <UnitSystem
                    currentSettings={currentSettings}
                    currentUser={currentUser}
                    onUserSettingsChange={handleUpdateDBUserSettings}
                />
            </div>

            {/* Modals */}
            {needRecentLogin && (
                // <SimpleModal title="Please enter your password">
                //     <p>Changing your email requires recent authentication.</p>
                //     <form onSubmit={reauthUser}>
                //         <input type="password" id="password" name="password" placeholder="*******" />
                //         <button type="submit">
                //             <CheckIcon />
                //         </button>
                //         <div
                //             onClick={() => {
                //                 setNeedRecentLogin(false)
                //             }}
                //         >
                //             <CancelIcon />
                //         </div>
                //     </form>
                // </SimpleModal>
                <ChangeEmailModal
                    onCancel={(e) => {
                        e.preventDefault()
                        setNeedRecentLogin(false)
                    }}
                    onConfirm={(e) => {
                        e.preventDefault()
                        setNeedRecentLogin(false)
                        setModal("confirmationEmail")
                    }}
                />
            )}

            {modal === "confirmationEmail" && (
                <ConfirmationModal
                    title="Verify your new email"
                    text="We have sent you an email, please verify your inbox and click on the link we've sent you."
                    onContinue={(e) => {
                        e.preventDefault()
                        setModal("")
                    }}
                />
            )}

            {modal === "changePassword" && (
                <ChangePasswordModal
                    onCancel={(e) => {
                        e.preventDefault()
                        setModal("")
                    }}
                    onConfirm={(e) => {
                        e.preventDefault()
                        setModal("confirmationChangePassword")
                    }}
                />
            )}

            {modal === "confirmationChangePassword" && (
                <ConfirmationModal
                    title="Check your email"
                    text="We have sent you a link, please verify your inbox and click on the link we've sent you"
                    onContinue={(e) => {
                        e.preventDefault()
                        setModal("newPassword")
                    }}
                />
            )}

            {modal === "newPassword" && (
                <NewPasswordModal
                    onCancel={(e) => {
                        e.preventDefault()
                        setModal("")
                    }}
                    onConfirm={(e) => {
                        e.preventDefault()
                        setModal("confirmationNewPassword")
                    }}
                />
            )}

            {modal === "confirmationNewPassword" && (
                <ConfirmationModal
                    title="Your new password has been set!"
                    onContinue={(e) => {
                        e.preventDefault()
                        setModal("")
                    }}
                />
            )}

            {modal === "changePicture" && (
                <ChangePictureModal
                    onCancel={(e) => {
                        e.preventDefault()
                        setModal("")
                    }}
                    onConfirm={(e) => {
                        e.preventDefault()
                        setModal("")
                    }}
                />
            )}
        </div>
    )
}

export default ProfileSettings
