import React from "react"
// Modules
import { Link as RouterLink } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

// Components
import UserProfile from "../../components/UserProfile"
import SettingsTopbar from "../../ui/Navbar/SettingsTopbar"

// Views

// Context providers / Utils
import { AuthContext } from "../../Auth/Auth"
import { getUserAlerts, updateNotifyVia } from "../../Util/AlertsMiddleware"

// Hooks

// Material-UI *
import ChevronLeft from "@material-ui/icons/ChevronLeft"

// Styles
import "./AlertSettings.css"
import "./components/Alert.css"
import { DemoContext } from "../../Util/DemoContext"
import { Redirect } from "react-router"

const AlertSettings = () => {

    const {mode,updateDemoMode} = useContext(DemoContext)

    const { currentUser, featurePermissions } = useContext(AuthContext)
    const [notifyVia, setNotifyVia] = useState(mode)
    async function onChangeNotify() {
        updateDemoMode(!notifyVia)
        setNotifyVia(!notifyVia)
    }

    updateDemoMode(true)
    return <Redirect to="/dashboard" />


    return (
        <div className="alert-settings">
            <div className="alert-settings__container">
                <div className="alert-settings__container__navbar">
                    <SettingsTopbar />
                </div>

                <div className="alert-settings__menu">
                    <div className="alert-settings__menu-header">
                        <RouterLink to="/dashboard">
                            <ChevronLeft className="alert-settings__return-icon" />
                        </RouterLink>
                        <div className="alert-settings__header-title">Alert Settings</div>

                        <div className="alert-settings__user-profile">
                            <UserProfile />
                        </div>
                    </div>

                    <div>
                        <div className="alert-settings__title">
                            <div className="alert-settings__title-dot">&middot;</div> Enable demo mode
                        </div>
                        <div className="alert-settings__text"></div>
                        <input
                            type="checkbox"
                            id="notify_email"
                            name="notify_email"
                            checked={notifyVia}
                            onChange={() => {
                                onChangeNotify()
                            }}
                        />

                        <label htmlFor="notify_dashboard">Demo</label>

                        {/* <div className="alert-settings__checkbox-container">
                              <span className="alert-settings__checkmark"></span>
                              <input
                                  className="alert-settings__checkbox"
                                  type="checkbox"
                                  id="notify_dashboard"
                                  name="notify_dashboard"
                                  checked={notifyVia.dashboard}
                                  onChange={() => {
                                      onChangeNotify("dashboard")
                                  }}
                              />
                          </div> */}

                        {/* <Checkbox /> */}
                    </div>


                </div>
            </div>
        </div>
    )
}

export default AlertSettings
