import * as React from "react"

function SvgComponent(props) {
    return (
        <div className="heat-soil-icon">
            <svg
                className="heat-soil-icon__sun"
                width={6}
                height={8}
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M2.253 1.613l-.6-.596-.47.47.597.596.473-.47zM1.333 3.5h-1v.667h1V3.5zm3-3.317h-.666v.984h.667V.183zm2.484 1.304l-.47-.47-.597.596.47.47.597-.596zm-1.07 4.566l.596.6.47-.47-.6-.596-.466.466zm.92-2.553v.667h1V3.5h-1zM4 1.833c-1.103 0-2 .897-2 2 0 1.104.897 2 2 2 1.104 0 2-.896 2-2 0-1.103-.896-2-2-2zm-.333 5.65h.667V6.5h-.667v.983zM1.184 6.18l.47.47.596-.6-.47-.47-.597.6z"
                    fill="#fff"
                />
            </svg>
            <svg
                className="heat-soil-icon__soil"
                width={10}
                height={6}
                viewBox="0 0 20 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M16.533 2.018h-1.367a.586.586 0 01-.575-.598c0-.33.258-.597.575-.597h1.722c.217 0 .416.128.513.33l2.297 4.782c.19.397-.087.865-.514.865H.814C.387 6.8.109 6.332.3 5.935l2.296-4.782a.572.572 0 01.514-.33h1.722c.317 0 .574.267.574.597 0 .33-.257.598-.574.598H3.465L1.742 5.604h16.513l-1.722-3.586z"
                    fill="#fff"
                />
            </svg>
        </div>
    )
}

export default SvgComponent
