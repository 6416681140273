import React from "react"
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import Copyright from "../ui/Copyright/Copyright"
import "./PasswordConfirmation.css"

const SignUpConfirmation = () => {
    return (
        <div className="password-confirmation">
            <div className="password-confirmation__container">
                <img
                    alt="climate ai color logo"
                    className="password-confirmation__logo"
                    src="/Climate-AI-Color-Logo.png"
                />

                <div className="password-confirmation__content">
                    <div className="password-confirmation__title">Your password has been changed succesfully!</div>

                    <RouterLink to="/">
                        <button className="password-confirmation__button">Continue and Login</button>
                    </RouterLink>
                </div>
            </div>

            <div className="sign-up__copyright-confirmation">
                <Copyright />
            </div>
        </div>
    )
}

export default withRouter(SignUpConfirmation)
