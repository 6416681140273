import React, { useEffect, useState } from "react"
// Modules
import PropTypes from "prop-types"

// Components
import SavedAlerts from "./SavedAlerts"
import NewAlert from "./NewAlert"
import { Popover } from "../../../../ui/Popover"
import LoadingBar from "../../../../components/LoadingBar"

// Views
import PlusIcon from "../../../../ui/Icons/PlusIcon"

// Context providers / Utils
import AlertsContext from "./AlertsContext"

// Hooks

// Material-UI *

// Styles
import "../AlertSettings.css"

const RiskAlert = (props) => {
    const {
        riskData,
        icon,
        alertsObj = {},
        path,
        units,
        currentUser,
        featurePermissions,
        onAlertAdd = () => null,
        onStackedAlertAdd = () => null,
        onAlertActiveChange = () => null,
        onUpdateAlert = () => null,
        onUpdateStackedAlert = () => null,
        onDeleteAlert = () => null,
    } = props

    const [openNewAlert, toggleNewAlert] = useState(false)
    const [alerts, setAlerts] = useState({ ...alertsObj })
    const [alertCount, setAlertCount] = useState(Object.keys(alerts).length)
    const [loading, toggleLoading] = useState(false)

    // control is back
    useEffect(() => {
        setAlerts({ ...alertsObj })
    }, [alertsObj])

    const handleToggleNewAlert = () => {
        if (featurePermissions.alerts.add) toggleNewAlert(!openNewAlert)
    }

    function handleAlertCountUpdate(unit) {
        let newCount = alertCount + unit
        if (newCount >= 0) {
            setAlertCount(newCount)
        }
    }

    function handleNewAlertSaved(alertId, newAlert) {
        handleAlertCountUpdate(1)
        let newAlertsObj = { ...alerts }
        newAlertsObj[alertId] = newAlert
        setAlerts(newAlertsObj)
    }

    function handleAlertDeleted(idAlert) {
        handleAlertCountUpdate(-1)
        let newAlertsObj = { ...alerts }
        delete newAlertsObj[idAlert]
        setAlerts(newAlertsObj)
    }

    return (
        <>
            <AlertsContext.Provider
                value={{
                    onAlertAdd,
                    onStackedAlertAdd,
                    onAlertActiveChange,
                    onUpdateAlert,
                    onUpdateStackedAlert,
                    onDeleteAlert,
                    toggleLoading
                }}
            >
                <div className="alert">
                    <div className="alert__container">
                        <div className="alert__header__loading-bar">
                            <LoadingBar loading={loading} />
                        </div>
                        <div className="alert__header">
                            <div
                                onClick={handleToggleNewAlert}
                                className={`alert__header__plus-icon ${openNewAlert ? "opacity" : ""} ${
                                    featurePermissions.alerts.add ? "" : "disabled"
                                }`}
                            >
                                <Popover className="plain-popover" text="Add new alert.">
                                    <PlusIcon />
                                </Popover>
                            </div>

                            <div className="alert__header__title">{riskData.title}</div>

                            <div className="alert__header__custom-icon">{icon}</div>

                            {alertCount > 0 && <div className="alert__header__alert-count">[{alertCount}]</div>}
                        </div>

                        <div className={openNewAlert ? "alert__content-container" : "hidden"}>
                            <NewAlert
                                riskData={riskData}
                                path={path}
                                onSave={handleNewAlertSaved}
                                onCancel={() => {
                                    toggleNewAlert(false)
                                }}
                                units={units}
                                currentUser={currentUser}
                                featurePermissions={featurePermissions}
                                toggleNewAlert={toggleNewAlert}
                            />
                        </div>

                        {openNewAlert && Object.keys(alerts).length > 0 && (
                            <div className="alert__content__divider"></div>
                        )}

                        <div className={"alert__content-container"}>
                            <SavedAlerts
                                riskData={riskData}
                                alerts={alerts}
                                path={path}
                                onDeleteAlert={handleAlertDeleted}
                                units={units}
                                currentUser={currentUser}
                                featurePermissions={featurePermissions}
                            />
                        </div>
                    </div>
                </div>
            </AlertsContext.Provider>
        </>
    )
}

RiskAlert.propTypes = {
    children: PropTypes.node,
}

export default RiskAlert
