import { toast } from "react-toastify"
import * as d3 from "d3"
import area from "@turf/area"
import { convertTemperatureValueOnly, convertWaterLengthValueOnly } from "../Util//UnitConversion"
import { endOfMonth } from "date-fns"

/**
 * Merge historical and forecast data mostly for hourly data exports
 * @param {Object} data - historical and forecast data and value accessor
 * @param {{time:[]}} data.historical -
 * @param {'t2m'|'tp'|'stl1'|'e'|'rh'} data.prop -
 * @param {{time:[]}} data.forecast -
 */
export function mergeHistoricalAndForecastData({ historical, forecast, prop = "t2m" }) {
    // Assemble Historical
    const historicalMerged = historical.time.map((d, i) => [d, historical[prop][i], [""]])

    // Assemble Forecast
    const forecastMerged = forecast.time.map((d, i) => [d, [""], forecast[prop][i]])

    // Merge and return result
    const result = historicalMerged.concat(forecastMerged)
    return result
}

// Reusable function to export forecast data in area chart supported format
export function getForecastConfidenceData(
    forecast,
    lastHistoricalPoint,
    forecastDataObj,
    confidenceProportion,
    historicalData
) {
    const maxConfidenceProportion = confidenceProportion
    let minConfidenceProportion = "0.25"
    if (maxConfidenceProportion === "0.95") {
        minConfidenceProportion = "0.05"
    }
    if (!lastHistoricalPoint) return []
    const result = forecast.time.map((item, index) => {
        if (!index) {
            return {
                x: new Date(item).getTime(),
                y: lastHistoricalPoint.y - 0.000005,
                y0: lastHistoricalPoint.y + 0.00005,
            }
        } else {
            return {
                x: new Date(item).getTime(),
                y: forecastDataObj[maxConfidenceProportion][index],
                y0: forecastDataObj[minConfidenceProportion][index],
            }
        }
    })

    // If historical data was passed, add last 3 item in order to area to be smoothly transitioned
    if (historicalData) {
        const filtered = historicalData
            .slice()
            .reverse()
            .filter((d, i) => i < 4 && i > 0)
            .reverse()
        return filtered.map((d) => ({ x: d.x, y: d.y - 0.000005, y0: d.y + 0.00005 })).concat(result)
    }
    return result
}

// Reusable function to add and substract months to the passed date
export function addMonths(date, months) {
    date.setMonth(date.getMonth() + months)
    return date
}

// Reusable function to add and substract years to the passed date
export function addYears(date, years) {
    const dt = new Date(date)
    dt.setFullYear(dt.getFullYear() + years)
    return dt
}

export function addDays(date, days) {
    const dt = new Date(date)
    dt.setDate(dt.getDate() + days)
    return dt
}

// Reusable function to duplicate historical yearly data for future 6 month date values
export function duplicateMonthlyHistoricalDataForFutureSixMonths(oneYearAreasData) {
    // Concatenate passed yearly data
    const concatenatedYears = oneYearAreasData.concat(
        oneYearAreasData.map((d) => Object.assign({}, d, { x: addYears(d.x, 1) }))
    )

    // Get next 6 month data
    const sixMonthsAhead = addMonths(new Date(), 6)

    // Calculate last day
    var lastDay = new Date(2008, sixMonthsAhead.getMonth() + 1, 0).getDate()

    // Set last day
    sixMonthsAhead.setDate(lastDay)

    // Filter out of range records
    const yearsFiltered = concatenatedYears.filter((d) => d.x < sixMonthsAhead)

    // Return result
    return yearsFiltered
}

// Reusable function to hide climatology data starting two weeks from now
export function trimSeasonalDateClimate(oneYearAreasData) {
    const twoWeeksLater = addDays(new Date(), 14)
    const filteredClimData = oneYearAreasData.filter((d) => d.x < twoWeeksLater)
    return filteredClimData
}

/**
 *  Reusable function for data validation
 * @param historic - Historical data
 * @param forecast - Forecast data
 * @param message -  Message which will be displayed in case validity fails
 * @param accessorKey - key, to access historic data value
 */
export function validateData({ historic, forecast, message, accessorKey, diffToAlert }) {
    // Declare minimum viable diff unit value
    const maxDiffForAlert = diffToAlert || 10

    // If passed data is empty, return
    if (!historic || historic.length === 0) return

    // Get forecast dates
    const forecastKeys = forecast.map((d) => d.x)

    // Get matching historical max values
    const filtereHistoric = historic.filter((d) => forecastKeys.includes(d.x))

    // Calculate unit differences between forecast and historical data, and filter out invalids
    const diffs = forecast
        .map((f, i) => {
            const maxHistoric = filtereHistoric[i]?.y
            const minHistoric = filtereHistoric[i]?.y0
            let diff = 0
            if (f.y < minHistoric) diff = minHistoric - f.y
            if (f.y > maxHistoric) diff = f.y - maxHistoric
            return diff
        })
        .filter((diff) => diff > maxDiffForAlert)

    // Calculate proportions
    const proportion = diffs.length / forecast.length

    // If invalid points proportions is more than 80%, display warning
    if (proportion >= 0.8) {
        toast.warning(message)
    }
}

// Trim data based 12 and future 14 days
export function trimmData(data) {
    const result = data.filter((item) => {
        const minX = new Date().getTime() - 12 * 24 * 60 * 60 * 1000
        const maxX = new Date().getTime() + 14 * 24 * 60 * 60 * 1000
        return minX <= item.x && item.x <= maxX
    })
    return result
}

// Interpolates number between two passed number
function interpolateNumber(start, end, current) {
    var i = d3.interpolateNumber(start, end)
    return i(current)
}

/**
 * Assembles sevaral area datas into one
 * @param  {bool} isMonthly  Whether we should take monthly view into account
 * @param  {} areaData Original area data
 * @param  {} seasonal  Seasonal data
 * @param  {} climatology  Climatology data for rounding
 * @param  {boolean} isCumulative  Flag which checks if we should acumulate data or not
 * @param  {string} cumulativeType  sum_per_month (sum up values on monthly basis), cumulative_sum_per_month - (accumulate monthly basis sums)
 * @return {{x,y,y0}[]} Assembled area data
 */
export function assembleAreaData({ lineDataBaseValue, isMonthly, areaData, seasonal, climatology, isCumulative, cumulativeType }) {
    if (isMonthly) {
        if (areaData && areaData.length && seasonal && seasonal.length) {
            // Get next 6 month data
            const sixMonthsAhead = addMonths(new Date(), 6)

            // Filter seasonal data to stay within next six months range
            const filteredSeasonalData = seasonal
                .filter((d) => +d.x > +areaData[areaData.length - 1].x)
                .filter((d) => d.x <= sixMonthsAhead)
            if (filteredSeasonalData.length > 7) {
                if (climatology) {
                    const climatologyObj = {}
                    climatology.forEach((c) => (climatologyObj[c.x] = c))
                    filteredSeasonalData.forEach((f) => {
                        if (climatologyObj[f.x]) {
                            f.y1 = Math.max(climatologyObj[f.x].y, f.y)
                            f.y0 = Math.min(climatologyObj[f.x].y0, f.y0)
                            f.min = f.y0
                            f.max = f.y1
                        }
                    })
                }

                // Interpolate intemediate values
                filteredSeasonalData
                    .filter((d, i) => i < 7)
                    .forEach((d, i) => {
                        d.y0 = interpolateNumber(areaData[areaData.length - 1].y0, filteredSeasonalData[7].y0, i / 7)
                        d.y1 = interpolateNumber(areaData[areaData.length - 1].y, filteredSeasonalData[7].y1, i / 7)
                        d.min = d.y0
                        d.max = d.y1
                    })
            }
            // Acumulate data if there is such option passed
            return isCumulative
                ? accumulateArea({ areaData: areaData.concat(filteredSeasonalData), cumulativeType: cumulativeType, lineDataBaseValue })
                : areaData.concat(filteredSeasonalData)
        }
        // Acumulate data if there is such option passed
        return isCumulative ? accumulateArea({ areaData: areaData, cumulativeType: cumulativeType, lineDataBaseValue }) : areaData
    } else {
        // Trim to daily view data
        const trimmed = trimmData(areaData)
        return trimmed
    }
}

/**
 * Accumulation of area data
 * @param  {{x,y,y0}[]} areaData
 * @param  {string} cumulativeType  sum_per_month (sum up values on monthly basis)
 * @param  {Array} lineDataBaseValue  Argument to normalize forecast area data
 * @return {{x,y,y0}[]} Acumulated area data
 */
function accumulateArea({ areaData, cumulativeType, lineDataBaseValue = [] }) {
    let result = areaData

    // If acumulation type was sum per month, sum all values or monthly basis
    if (cumulativeType === "sum_per_month") {
        result = [
            // Group all data based on the year and month
            ...d3.group(areaData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ]
            // Map grouped data to area data format
            .map(([key, entries]) => {
                const [year, month] = key.split("-")
                return {
                    x: new Date(year, month, 15).getTime(), // Use middle of month as main value
                    y: +d3.sum(entries, (d) => d.y ?? d.y1).toFixed(2), // Sum up maximum y values
                    y0: +d3.sum(entries, (d) => d.y0).toFixed(2), // sum Up minimum y values
                }
            })
    }

    if (cumulativeType === 'cumulative_sum_per_month') {
        // Filter out first element
        const fitleredAreaData = areaData.filter((d, i) => i);

        // Retrieve only necessary climd ata
        const lineDataClim = lineDataBaseValue
            .filter(d => d.x <= fitleredAreaData[0].x);
        //.filter((d, i, arr) => i !== arr.length - 1)
        let sumPerMonth = [
            // Group all data based on the year and month
            ...d3.group(fitleredAreaData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ]
            // Map grouped data to area data format
            .map(([key, entries]) => {
                const [year, month] = key.split("-")
                return {
                    x: new Date(year, month, 15).getTime(), // Use middle of month as main value
                    y: +d3.sum(entries, (d) => d.y ?? d.y1).toFixed(4), // Sum up maximum y values
                    y0: +d3.sum(entries, (d) => d.y0).toFixed(4), // sum Up minimum y values
                }
            });


        // Take missing line data (historical) into account if passed
        let baseValueAccumulated = lineDataClim.map(d => ({
            x: d.x,
            y: d.y,
            y0: d.y
        })).concat(sumPerMonth);

        result = [];

        // Cummulative sum  values
        baseValueAccumulated.forEach(d => {
            if (!result.length) {
                result.push(d);
            } else {
                const last = result[result.length - 1];
                result.push({ x: d.x, y: last.y + d.y, y0: last.y0 + d.y0 })
            }
        });

        result = result
            .filter((d => d.x > lineDataBaseValue[lineDataBaseValue.length - 1].x))
    }
    return result
}

// Assemble lines data
export function assembleLineData({ isMonthly, historical, forecast = [], seasonal, isCumulative, cumulativeType }) {
    // If we have monthly view
    if (isMonthly) {
        // Assemple monthly view data
        const monthlyData = historical.concat(forecast.map((d) => Object.assign(d, { dashed: true })))
        let result = monthlyData

        // If seasonal data was passed, include it into foracasting
        if (monthlyData && monthlyData.length && seasonal && seasonal.length) {
            // Get next 6 month data
            const sixMonthsAhead = addMonths(new Date(), 6)

            // Filter seasonal data to stay within next six months range
            const filteredSeasonalData = seasonal
                .filter((d) => d.x > monthlyData[monthlyData.length - 1].x)
                .filter((d) => d.x < sixMonthsAhead)

            const dashedSeasonal = filteredSeasonalData.map((d) => Object.assign(d, { dashed: true }))

            if (dashedSeasonal.length > 7) {
                // Interpolate intemediate values
                dashedSeasonal
                    .filter((d, i) => i < 7)
                    .forEach((d, i) => {
                        d.y = interpolateNumber(monthlyData[monthlyData.length - 1].y, dashedSeasonal[7].y, i / 7)
                    })
            }

            // Concat results (Accumulate if such options are passed)
            return isCumulative
                ? accumulateLine({ lineData: monthlyData.concat(dashedSeasonal), cumulativeType: cumulativeType })
                : monthlyData.concat(dashedSeasonal)
        }
        // Concat results (Accumulate if such options are passed)
        return isCumulative ? accumulateLine({ lineData: result, cumulativeType: cumulativeType }) : result
    } else {
        // Trim to daily view data
        const trimmed = trimmData(historical)

        // Trim and map forecasted daily view data
        const forecastedLines = trimmData(forecast).map((d) => Object.assign(d, { dashed: true }))

        // Assemble daily view lines (and filter out similar date records)
        const assembledLines = trimmed.concat(forecastedLines.filter(d => d.x > trimmed[trimmed.length - 1].x))
            .filter((d, i, arr) => {
                if (i && +d.x == +arr[i - 1].x) return false;
                return true;
            })
        return assembledLines
    }
}

/**
 * Accumulation of line data
 * @param  {{x,y}[]} lineData
 * @param  {string} cumulativeType  sum_per_month (sum up values on monthly basis)
 * @return {{x,y}[]} Acumulated area data
 */
function accumulateLine({ lineData, cumulativeType }) {
    // Get first day of month
    let firstDayOfMonth = new Date().setDate(31)
    // Save result
    let result = lineData
    if (cumulativeType == "sum_per_month") {
        // If cumulative type was sum_per_month (group data and map to line format)
        result = [
            ...d3.group(lineData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ].map(([key, entries]) => {
            const [year, month] = key.split("-")
            let x = new Date(year, month, 15).getTime()
            return {
                x,
                y: +d3.sum(entries, (d) => d.y ?? d.y).toFixed(2),
                dashed: firstDayOfMonth <= x,
            }
        })
    }

    if (cumulativeType === 'cumulative_sum_per_month') {
        const sumPerMonth = [
            // Group all data based on the year and month
            ...d3.group(lineData, (d) => {
                let dt = new Date(d.x)
                return dt.getFullYear() + "-" + dt.getMonth()
            }),
        ]
            // Map grouped data to area data format
            .map(([key, entries]) => {
                const [year, month] = key.split("-")
                let x = new Date(year, month, 15).getTime()
                return {
                    x,
                    y: +d3.sum(entries, (d) => d.y ?? d.y).toFixed(2),
                    dashed: firstDayOfMonth <= x,
                }
            })

        result = [];

        // Cummulative sum  values
        sumPerMonth.forEach(d => {
            if (!result.length) {
                result.push(d);
            } else {
                const last = result[result.length - 1];
                result.push({ x: d.x, y: last.y + d.y, dashed: d.dashed })
            }
        })
    }

    return result
}

// Function to transform shaded ranges data
export function convertToShadedRangesFormat(
    sampleAlertsData,
    keys = ["t2m_max", "t2m_min"],
    iconString = `<svg width="12" height="12" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 12.99L9 20L11 20L11 12.99L14 12.99L10 9L6 12.99L9 12.99ZM8 7.01L5 7.01L5 -2.18557e-07L3 -1.31134e-07L3 7.01L-5.67811e-07 7.01L4 11L8 7.01Z" fill="#5D6772"/>
  </svg>`
) {
    return keys
        .map((key) => {
            return sampleAlertsData[key] // Retrieve alert objects
        })
        .map((alerts) => {
            return Object.keys(alerts).map((key) => alerts[key]) // Get alert objecy values
        })
        .flat() // flat out array
        .map((alert) => {
            // Create new alert for each values object
            return alert.values
                .map((value) => {
                    return {
                        start: new Date(value.start_time),
                        end: new Date(value.end_time),
                        label: `${iconString} ${alert.title}`,
                        metadata: alert.metadata || "",
                    }
                })
                .map((d) => {
                    // Intercept one day alers and make them compatible to shaded ranges view
                    if (+d.start == +d.end) {
                        d.end.setTime(d.end.getTime() + 12 * 60 * 60 * 1000)
                        d.start.setTime(d.start.getTime() - 12 * 60 * 60 * 1000)
                    }
                    return d
                })
        })
        .flat() // Flat out again
}

// Function to process polygon data and selected fields data
export function processPolygonData({ selectedFields, polygons }, units) {
    const data = selectedFields
        .filter((sf) => polygons[sf.uuid]) // Filter out fields, which do not have corresponding polygons
        .filter((sf) => polygons[sf.uuid].data) // Filter out fields, which have associated data
        .map((sf) => {
            // Map to map field data
            return {
                id: sf.uuid,
                name: sf.name,
                metadata: [
                    { name: "Crop", value: sf.crop },
                    { name: "Region", value: sf.region },
                    { name: "Variety", value: sf.variety },
                    { name: "Notes", value: sf.notes },
                    {
                        name: "Ha (Extension)",
                        value: Math.round(
                            area({
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [polygons[sf.uuid].data.field_polygon],
                                },
                            }) / 10000
                        ).toLocaleString(),
                    },
                ],
                polygon: polygons[sf.uuid]?.data?.field_polygon,
                temp: +convertTemperatureValueOnly("metric", units, sf.weather_variables.t2m.data.t2m_max),
                precipitation: +convertWaterLengthValueOnly("metric", units, sf.weather_variables.tp.data.tp_sum),
            }
        })

    // Prepare and return result
    const result = {
        data: data,
        units: units,
        variables: [
            {
                iconType: "temperature",
                name: "Temperature",
                colors: ["#FAC3A8", "#FC2024"],
                value: "temp",
                unit: units === "metric" ? "°C" : "°F",
            },
            {
                iconType: "precipitation",
                name: "Precipitation",
                colors: ["#D1E8F0", "#3B77B5"],
                value: "precipitation",
                unit: units === "metric" ? "mm" : "in",
            },
        ],
    }
    return result
}

// Define custom varying gradient color scale
export function scaleColor(d3) {
    let colors,
        values,
        gradient = false
    function scale(value) {
        if (value >= values[values.length - 1]) {
            return colors[colors.length - 1]
        }
        if (value < values[0]) {
            return colors[0]
        }
        let out
        for (let i = 0, l = values.length - 1; i < l; i++) {
            const nextIndex = i === values.length - 1 ? i : i + 1
            const curr = values[i]
            const next = values[nextIndex]
            if (value >= curr && value < next) {
                const range = next - curr
                if (gradient) {
                    const distance = value - curr
                    out = d3.interpolate(colors[i], colors[nextIndex])(distance / range)
                } else {
                    out = colors[i]
                }
                break
            }
        }
        return out
    }
    scale.colors = function (_) {
        return arguments.length ? ((colors = _), scale) : colors
    }

    scale.values = function (_) {
        return arguments.length ? ((values = _), scale) : values
    }

    scale.gradient = function (_) {
        return arguments.length ? ((gradient = _), scale) : gradient
    }
    return scale
}



/**
 * Creates cumulative forecast data in area or line chart format - depending on confidence level passed
 */
export function assembleCumulativeData({ forecastData, confidenceLevel, variable, climCumulativeData }) {
    try {
        let result = null;

        // Transform 0.95 confidence data to area graph format
        if (+confidenceLevel == 0.05 || confidenceLevel == 0.95) {
            result = forecastData[variable]['0.05'].map((d, i) => {
                return {
                    x: addDays(new Date(forecastData.time[i]), 15),
                    y: +forecastData[variable]['0.95'][i],
                    y0: +forecastData[variable]['0.05'][i]
                }
            })
        } else if (+confidenceLevel == 0.75 || confidenceLevel == 0.25) {  // Transform 0.95 confidence data to area graph format
            result = forecastData[variable]['0.25'].map((d, i) => {
                return {
                    x: addDays(new Date(forecastData.time[i]), 15),
                    y: +forecastData[variable]['0.75'][i],
                    y0: +forecastData[variable]['0.25'][i]
                }
            })
        } else {

            // Concat cumulative clim data to cumulative forecast
            const climCumulativeLineData = climCumulativeData
                .filter(d => !d.dashed)
                .filter((d, i, arr) => i !== arr.length - 1)
            const forecastLineData = forecastData.time.map((strDate, i) => {
                return {
                    x: addDays(new Date(strDate), 15),
                    y: forecastData[variable]['0.5'][i],
                    dashed: true,
                }
            })

            // Add intermediate point to divide forecast & climatology points, so it's easier for user to understand
            const result = climCumulativeLineData
                .concat([
                    {
                        x: endOfMonth(climCumulativeLineData[climCumulativeLineData.length - 1].x),
                        y: (climCumulativeLineData[climCumulativeLineData.length - 1].y + forecastLineData[0].y) / 2,
                        invisible: true,
                    }
                ])
                .concat(
                    forecastLineData
                )
            return result;
        }
        return result;
    } catch (err) {
        console.log(err)
        return []
    }
}

// Function to load current unit symbol and value if necessary
export function getUnit({
    system = "metric",
    value = 0
}) {
    // Define initial metric values
    let tempUnit = '°C';
    let temp = value;
    let precipUnit = 'mm'
    let precip = value;

    // If imperial system was passed, convert values accordingly
    if (system === 'imperial') {
        tempUnit = '°F';
        temp = +convertTemperatureValueOnly('metric', 'imperial', temp);
        precipUnit = 'in';
        precip = +convertWaterLengthValueOnly('metric', 'imperial', precip);
    }

    // Return corresponding values
    return {
        tempUnit,
        temp,
        precipUnit,
        precip
    }
}

// Function to map area or line values according to current variable type and metric vs imperial system
export function processUnitSystem(d, { system = "metric", type = "temp" }) {
    const item = Object.assign({}, d);
    if (system === 'imperial') {
        if (item.y !== undefined) {
            if (type === 'temp') {
                item.y = +convertTemperatureValueOnly('metric', 'imperial', item.y)
            }
            if (type === 'precip') {
                item.y = +convertWaterLengthValueOnly('metric', 'imperial', item.y);
            }
        }
        if (item.y0 !== undefined) {
            if (type === 'temp') {
                item.y0 = +convertTemperatureValueOnly('metric', 'imperial', item.y0)
            }
            if (type === 'precip') {
                item.y0 = +convertWaterLengthValueOnly('metric', 'imperial', item.y0);
            }
        }
    }
    return item;
}