import React, { useState } from "react"

import UploadIcon from "../Icons/UploadIcon"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import { toast } from "react-toastify"

import "./FileInput.css"

function FileInput(props) {
    const { multiple, onChange = () => null, accept = "" } = props
    const [file, setFile] = useState(null)

    function handleInputChange(...args) {
        const [e] = args
        if (e.target.files[0]) {
            setFile(e.target.files[0])
        }
        onChange(...args)
    }

    return (
        <>
            <span className="file-input">
                {file ? (
                    <div className="file-input__file-icon-container">
                        <DescriptionOutlinedIcon
                            className="file-input__file-icon"
                            style={{ color: "var(--color-climate-green)" }}
                        />
                    </div>
                ) : (
                    <div className="file-input__upload-icon">
                        <UploadIcon />
                    </div>
                )}
                <input
                    type="file"
                    multiple={multiple}
                    onChange={handleInputChange}
                    accept={accept}
                    className="file-input__input"
                />
            </span>
        </>
    )
}

export default FileInput
