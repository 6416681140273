import React from "react"

import "./NavbarFieldsItem.css"

export default function NavbarFieldsItem(props) {
    const { image, lot, crop } = props
    return (
        <>
            <div className="navbar-fields-item">
                {/* <img className="navbar-fields-item__image" src={image} alt="" /> */}
                <div className="navbar-fields-item__lot">{lot}</div>
                <div className="navbar-fields-item__crop">{crop}</div>
            </div>
        </>
    )
}
