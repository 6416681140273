import React, { useState, useEffect } from "react"
// Modules
import Dropdown from "rc-dropdown"

// Components
import ActionBlock from "./components/ActionBlock"
import { FieldProperties } from "../../../../components"
import DailyMonthlyOptions from "./components/DailyMonthlyOptions"
import { Popover } from "../../../../ui/Popover"

// Views
// Icons - The Active versions are actually used on the unselected Tabs
import minMaxIconActive from "../../../../assets/Chart/min-max-active.svg"
import minMaxIcon from "../../../../assets/Chart/min-max.svg"
import CloudIconActive from "../../../../assets/Chart/cloud-active.svg"
import CloudIcon from "../../../../assets/Chart/cloud.svg"
import SunIconActive from "../../../../assets/Chart/sun-active.svg"
import SunIcon from "../../../../assets/Chart/sun.svg"
import TrendingUp from "../../../../assets/Chart/trending-up.svg"
import TrendingUpActive from "../../../../assets/Chart/trending-up-active.svg"
import WaterBudgetIconActive from "../../../../assets/Chart/water-budget-active.svg"
import WaterBudgetIcon from "../../../../assets/Chart/water-budget.svg"
import HumidityIcon from "../../../../assets/Chart/humidity.svg"
import HumidityIconActive from "../../../../assets/Chart/humidity-active.svg"
import EvapotranspirationIcon from "../../../../assets/Chart/evapotranspiration.svg"
import EvapotranspirationIconActive from "../../../../assets/Chart/evapotranspiration-active.svg"
import DotIcon from "../../../../assets/Chart/dots.svg"
import DotIconWhite from "../../../../assets/Chart/dots-white.svg"

// Context providers / Utils

// Hooks

// Material-UI *
import useMediaQuery from "@material-ui/core/useMediaQuery"

// Styles
import "./WeatherVariableTabs.css"

const REMEMBERED_WEATHER_VARIABLE_KEY = "WeatherModelSelectedVariable"
const REMEMBERED_VARIABLE_PERIOD = "WeatherModelIsMonthly"

const WeatherVariableTabs = (props) => {
    const { initialState, onStateChange, allowedWeatherVariables } = props
    const [state, setState] = useState(initialState)
    const [current, setCurrent] = useState("minmax")
    const [extraTab, setExtraTab] = useState("waterBudget")

    useEffect(() => {
        onStateChange(state)
    }, [state, onStateChange])

    const [shownVariables, setShownVariables] = useState([])
    const [dropdownVariables, setDropdownVariables] = useState([])
    const [selectedVariable, setSelectedVariable] = useState("")
    const [amountOfShownVariables, setAmountOfShownVariables] = useState(4)
    const [monthlySelected, setMonthlySelected] = useState(false)
    const desktopMedia = useMediaQuery("(min-width: 1101px)")

    useEffect(() => {
        if (desktopMedia) {
            setAmountOfShownVariables(4)
        } else {
            setAmountOfShownVariables(3)
        }
    }, [desktopMedia])

    useEffect(() => {
        fillAllowedVariablesState()
    }, [amountOfShownVariables])

    function fillAllowedVariablesState() {
        const vars = Object.keys(allowedWeatherVariables)
        let variablesToShow = []
        let dropDownVariablesToShow = []
        const selectedVar = localStorage.getItem(REMEMBERED_WEATHER_VARIABLE_KEY) || null
        for (var i = 0; i < vars.length; i++) {
            let key = vars[i]
            if (allowedWeatherVariables[key]) {
                if (variablesToShow.length < amountOfShownVariables) {
                    variablesToShow.push(key)
                } else {
                    if (selectedVar === key) {
                        const varToDropDown = variablesToShow.pop()
                        variablesToShow.push(key)
                        dropDownVariablesToShow.push(varToDropDown)
                    } else {
                        dropDownVariablesToShow.push(key)
                    }
                }
            }
        }
        setShownVariables(variablesToShow)
        setDropdownVariables(dropDownVariablesToShow)
        onSelect(selectedVar || variablesToShow[0] || "")
    }

    const VARIABLES_META = {
        temperature_t2m: {
            monthly: true,
            title: "Min & Max Temp.",
            icons: { active: minMaxIcon, default: minMaxIconActive },
            key: "temperature_t2m",
        },
        precipitation: {
            monthly: true,
            title: "Precipitation",
            icons: { active: CloudIcon, default: CloudIconActive },
            key: "precipitation",
        },
        soil_temperature: {
            monthly: false,
            title: "Soil Temperature",
            icons: { active: SunIcon, default: SunIconActive },
            key: "soil_temperature",
        },
        water_budget: {
            monthly: false,
            title: "Water Budget",
            icons: { active: WaterBudgetIcon, default: WaterBudgetIconActive },
            key: "water_budget",
        },
        relative_humidity: {
            monthly: false,
            title: "Relative Humidity",
            icons: { active: HumidityIcon, default: HumidityIconActive },
            key: "relative_humidity",
        },
        evapotranspiration: {
            monthly: false,
            title: "Evapotranspiration",
            icons: { active: EvapotranspirationIcon, default: EvapotranspirationIconActive },
            key: "evapotranspiration",
        },
        solar_radiation: {
            monthly: true,
            title: "Solar Radiation",
            icons: { active: SunIcon, default: SunIconActive },
            key: "solar_radiation",
        },
        gdd: {
            monthly: true,
            title: "Growing Degree Days",
            icons: { active: TrendingUp, default: TrendingUpActive },
            key: "gdd",
        },
        soil_moisture: {
            monthly: false,
            title: "Soil Moisture",
            icons: { active: TrendingUp, default: TrendingUpActive },
            key: "soil_moisture",
        },
        wind: {
            monthly: false,
            title: "Wind",
            icons: { active: TrendingUp, default: TrendingUpActive },
            key: "wind",
        },
    }

    function getSelectedAvailablePeriod(variable) {
        let isMonthly = false
        if (localStorage.getItem(REMEMBERED_VARIABLE_PERIOD) === "true") {
            isMonthly = true
        }
        return VARIABLES_META[variable].monthly && isMonthly
    }

    function onSelect(variable) {
        localStorage.setItem(REMEMBERED_WEATHER_VARIABLE_KEY, variable)
        setSelectedVariable(variable)
        const isMonthly = getSelectedAvailablePeriod(variable)
        setMonthlySelected(isMonthly)
        onStateChange({
            currentTab: variable,
            isMonthly
        })
    }

    function handleDailyMonthlyChange(isMonthly) {
        localStorage.setItem(REMEMBERED_VARIABLE_PERIOD, isMonthly)
        setMonthlySelected(isMonthly)
        onStateChange({
            currentTab: selectedVariable,
            isMonthly: isMonthly,
        })
    }

    function onSelectFromDropdown(variable) {
        // Copy arrays
        let shownVariablesCopy = shownVariables
        let dropdownVariablesCopy = dropdownVariables

        // Get the last shown variable to add it to the dropdown and remove it
        // from shown
        let lastShownVariable = shownVariablesCopy.pop()

        // Add last shown variable to dropdown
        dropdownVariablesCopy.push(lastShownVariable)

        // Remove the selected dropdown variable
        dropdownVariablesCopy = dropdownVariablesCopy.filter(function (value) {
            return value != variable
        })

        // Add the selected dropdown to the shown variables
        shownVariablesCopy.push(variable)

        setShownVariables(shownVariablesCopy)
        setDropdownVariables(dropdownVariablesCopy)
        onSelect(variable)
    }

    return (
        <>
            <div className="headerBlocksContainer">
                <DailyMonthlyOptions
                    monthlyAvailable={VARIABLES_META[selectedVariable]?.monthly}
                    monthlySelected={monthlySelected}
                    onChange={handleDailyMonthlyChange}
                />

                <div className="chart-actions__options-container">
                    {shownVariables.map((variable) => {
                        return (
                            <ActionBlock
                                key={variable}
                                select={onSelect}
                                current={selectedVariable}
                                title={VARIABLES_META[variable]?.title}
                                currentTab={VARIABLES_META[variable]?.key}
                                chartIcon={VARIABLES_META[variable]?.icons}
                            />
                        )
                    })}
                </div>

                {dropdownVariables.length > 0 ? (
                    <Popover text="See more variables" className="plain-popover">
                        <Dropdown
                            className="chart-actions__dropdown-button"
                            overlayClassName="more-dropdown-container"
                            trigger="click"
                            overlay={
                                <div className="more-content-dropdown">
                                    {dropdownVariables.map((variable) => {
                                        return (
                                            <div
                                                key={"dropdown-" + variable}
                                                className="more-item-dropdown"
                                                onClick={() => {
                                                    onSelectFromDropdown(variable)
                                                }}
                                            >
                                                {VARIABLES_META[variable]?.title}
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            animation="slide-up"
                        >
                            <div
                                className="headerBlockContainer headerBlockContainer-hover dotIcon"
                                style={{
                                    backgroundColor: state.extraDropdown ? "#1C9690" : "",
                                }}
                            >
                                {!state.extraDropdown ? <img alt="" src={DotIcon} /> : <img alt="" src={DotIconWhite} />}
                            </div>
                        </Dropdown>
                    </Popover>
                ) : (
                    <div></div>
                )}
                {desktopMedia && <FieldProperties className="chart-actions__field-properties" />}
            </div>
        </>
    )
}

export default WeatherVariableTabs
