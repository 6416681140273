import React from "react"

// Modules
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

// components
import SignUp from "./components/SignUp"
import SignUpConfirmation from "./components/SignUpConfirmation"
import RestorePassword from "./components/RestorePassword"
import PasswordCode from "./components/PasswordCode"
import CreatePassword from "./components/CreatePassword"
import PasswordConfirmation from "./components/PasswordConfirmation"
import Dashboard from "./components/Dashboard"
import ReceiveTest from "./components/ReceiveTest"
import SignInSideBar from "./components/SignInSideBar"
import ProfileSettings from "./views/Settings/ProfileSettings"
import AlertSettings from "./views/Settings/AlertSettings"
import TableSettings from "./views/Settings/TableSettings/TableSettings"
import { RouteWithLayout } from "./components"
import { Main as MainLayout, SettingsLayout } from "./layouts"

// Views

import {default as PrivateView} from "./views/Private/AlertSettings"
import {
    Dashboard as DashboardView,
    Weather as WeatherView,
    Satellite as SatelliteView,
    Sustainability as SustainabilityView,
    LongTerm as LongTermView,
    Soil as SoilView,
    RegionAlerts as RegionView,
    PlantingTool as PlantingToolView,
} from "./views"

// Context providers / Utils
import { SettingsProvider } from "./Util/SettingsContext"
import { AuthProvider } from "./Auth/Auth" // TODO move Auth to Util collection
import { DemoProvider } from "./Util/DemoContext"
import DataProvider from "./Util/Data"
import UIAlertProvider from "./ui/UIAlert"
import PrivateRoute from "./Util/PrivateRoute"

// Material-UI
import { ThemeProvider } from "@material-ui/styles"
import theme from "./theme"

// styles
import "./App.css"
import "./styles/chart.css"
import "react-toastify/dist/ReactToastify.css"

function App() {
    return (
        <AuthProvider>
            <DataProvider>
                <DemoProvider>
                    <ThemeProvider theme={theme}>
                        <UIAlertProvider>
                            <Router>
                                <Switch>
                                    <SettingsProvider>
                                        <Route exact path="/">
                                            <SignInSideBar />
                                        </Route>
                                        <Route exact path="/signup">
                                            <SignUp />
                                        </Route>
                                        <Route exact path="/signup/confirmation">
                                            <SignUpConfirmation />
                                        </Route>
                                        <Route exact path="/restore-password">
                                            <RestorePassword />
                                        </Route>
                                        <Route exact path="/password-code">
                                            <PasswordCode />
                                        </Route>
                                        <Route exact path="/create-password">
                                            <CreatePassword />
                                        </Route>
                                        <Route exact path="/password-confirmation">
                                            <PasswordConfirmation />
                                        </Route>
                                        <RouteWithLayout
                                            requiresPermission={false}
                                            component={ProfileSettings}
                                            exact
                                            layout={SettingsLayout}
                                            path="/profile-settings"
                                            name="Profile"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={false}
                                            component={TableSettings}
                                            exact
                                            layout={SettingsLayout}
                                            path="/table-settings"
                                            name="Table"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={false}
                                            component={AlertSettings}
                                            exact
                                            layout={SettingsLayout}
                                            path="/alerts-settings-individual"
                                            name="Individual Alerts"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={false}
                                            component={AlertSettings}
                                            exact
                                            layout={SettingsLayout}
                                            path="/alerts-settings-stacked"
                                            stacked={true}
                                            name="Stacked Alerts"
                                        />
                                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                        <PrivateRoute exact path="/dashboard/private" component={PrivateView } />
                                        <Route path="/dashboard/:id">
                                            <ReceiveTest />
                                        </Route>
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            name="regions"
                                            component={RegionView}
                                            exact
                                            layout={MainLayout}
                                            path="/regionAlerts/:id"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            component={DashboardView}
                                            name={"general"}
                                            exact
                                            layout={MainLayout}
                                            path="/general/:id"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            component={WeatherView}
                                            name={"weather"}
                                            exact
                                            layout={MainLayout}
                                            path="/weather/:id"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            component={SatelliteView}
                                            name={"satellite"}
                                            exact
                                            layout={MainLayout}
                                            path="/satellite/:id"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            component={SustainabilityView}
                                            name={"sustainability"}
                                            exact
                                            layout={MainLayout}
                                            path="/sustainability/:id"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            component={PlantingToolView}
                                            name={"planting_tool"}
                                            exact
                                            layout={MainLayout}
                                            path="/planting_tool/:id"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            component={LongTermView}
                                            exact
                                            layout={MainLayout}
                                            path="/longterm/:id"
                                        />
                                        <RouteWithLayout
                                            requiresPermission={true}
                                            component={SoilView}
                                            exact
                                            layout={MainLayout}
                                            path="/soil/:id"
                                        />
                                    </SettingsProvider>
                                </Switch>
                            </Router>
                        </UIAlertProvider>
                    </ThemeProvider>
                </DemoProvider>
            </DataProvider>
        </AuthProvider>
    )
}

export default App
