import * as React from "react"

function SvgComponent(props) {
    return (
        <div className="cold-soil-icon">
            <svg
                className="cold-soil-icon__snow"
                width={14}
                height={16}
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M13.406 10.906l-1.062-.614c.192-.197.412-.366.654-.5a.687.687 0 00-.665-1.203c-.461.255-.87.596-1.205 1.003L8.375 8l2.753-1.59a4.17 4.17 0 001.204 1.004.688.688 0 10.665-1.204 2.807 2.807 0 01-.654-.5l1.063-.613a.688.688 0 10-.687-1.19l-1.063.613a2.804 2.804 0 01-.107-.817.688.688 0 00-.674-.703h-.013a.688.688 0 00-.687.675c-.01.527.08 1.05.265 1.544l-2.753 1.59V3.631c.52-.086 1.02-.27 1.471-.542a.688.688 0 10-.71-1.178 2.799 2.799 0 01-.76.316v-1.04a.688.688 0 00-1.376 0v1.04a2.799 2.799 0 01-.76-.316.688.688 0 00-.71 1.178c.451.272.95.456 1.47.542v3.178L3.56 5.22a4.175 4.175 0 00.266-1.545A.687.687 0 003.138 3h-.013a.687.687 0 00-.675.7c.005.277-.03.553-.106.82L1.28 3.905a.688.688 0 10-.687 1.19l1.062.615a2.805 2.805 0 01-.654.5.687.687 0 10.665 1.203c.461-.255.87-.596 1.204-1.003L5.625 8 2.872 9.59a4.176 4.176 0 00-1.205-1.004.688.688 0 10-.665 1.204c.242.133.462.302.655.5l-1.063.616a.689.689 0 10.687 1.19l1.063-.613c.075.266.111.541.107.818a.687.687 0 00.674.699h.013a.688.688 0 00.687-.675c.01-.526-.08-1.05-.265-1.544l2.752-1.59v3.178c-.52.086-1.019.27-1.47.542a.687.687 0 10.71 1.178 2.8 2.8 0 01.76-.316v1.04a.687.687 0 001.375 0v-1.04a2.8 2.8 0 01.762.316.687.687 0 10.71-1.178 4.171 4.171 0 00-1.472-.542V9.191l2.753 1.59a4.174 4.174 0 00-.266 1.545.687.687 0 00.688.675h.013a.686.686 0 00.675-.7c-.005-.277.03-.553.106-.82l1.063.614a.688.688 0 10.687-1.189z"
                    fill="#fff"
                />
            </svg>
            <svg
                className="cold-soil-icon__soil"
                width={10}
                height={6}
                viewBox="0 0 20 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M16.533 2.018h-1.367a.586.586 0 01-.575-.598c0-.33.258-.597.575-.597h1.722c.217 0 .416.128.513.33l2.297 4.782c.19.397-.087.865-.514.865H.814C.387 6.8.109 6.332.3 5.935l2.296-4.782a.572.572 0 01.514-.33h1.722c.317 0 .574.267.574.597 0 .33-.257.598-.574.598H3.465L1.742 5.604h16.513l-1.722-3.586z"
                    fill="#fff"
                />
            </svg>
        </div>
    )
}

export default SvgComponent
