import React from "react"

import TableLabelItem from "./TableLabelItem"

import "./TableLabels.css"

function TableItemLabels(props) {
    const { labels = [], onRemoveLabelFromField } = props

    const items = []
    const lastItems = []
    for (let i = 0; i < labels.length; i++) (i < 2 ? items : lastItems).push(labels[i])

    return (
        <>
            <div className="table-labels">
                {items.map((label) => {
                    return <TableLabelItem label={label} onDelete={onRemoveLabelFromField} />
                })}
                {(lastItems && lastItems.length && (
                    <div className="table-labels__last-items">
                        <div className="table-labels__last-items__value">+{lastItems.length}</div>
                        <div className="table-labels__last-items__container">
                            {lastItems.map((label) => {
                                return <TableLabelItem label={label} onDelete={onRemoveLabelFromField} />
                            })}
                        </div>
                    </div>
                )) ||
                    null}
            </div>
        </>
    )
}

export default TableItemLabels
