import axios from "axios"
import config from "../Util/config"

import app from "../Util/Fire"

const hostDomain = config.backend_server

export async function getAllLabels() {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const {
            data: { data },
        } = await axios.get(hostDomain + "/api/v1/labels/", { headers: { "User-Token": userToken } })
        return data
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function addLabelToFields(label, fields) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const response = await axios.post(
            hostDomain + "/api/v1/labels/",
            {
                label,
                fields: fields.map((field) => ({ uuid: field.uuid })),
            },
            { headers: { "User-Token": userToken } }
        )
        return response
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function removeLabel(label) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const response = await axios.delete(hostDomain + "/api/v1/labels/", {
            headers: { "User-Token": userToken },
            data: { label },
        })
        return response
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function removeLabelFromField(label, field) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const response = axios.delete(hostDomain + "/api/v1/labels/field", {
            headers: { "User-Token": userToken },
            data: { label, field },
        })
        return response
    } catch (e) {
        throw new Error(e?.response?.data?.message || "There was a problem, try again later.")
    }
}
