import React, { useState, useEffect } from "react"

import "./AlertSelect.css"

import ArrowRight from "../../../../ui/Icons/ArrowRight"

const AlertSelect = (props) => {
    const { options, selectedIndex = 1, maxNumber = Infinity, disabled, onChange = () => null } = props

    const [selected, setSelected] = useState()
    const [open, toggle] = useState(false)

    const selectOption = (index) => {
        setSelected(index)
        onChange(index + 1)
        toggle(false)
    }

    const openDropdown = () => {
        toggle(!open)
    }

    useEffect(() => {
        if (selectedIndex > maxNumber) {
            let index = maxNumber - 1
            setSelected(index)
            selectOption(index)
        }
    }, [maxNumber])

    useEffect(() => {
        setSelected(selectedIndex - 1)
    }, [selectedIndex])

    return (
        <div className="alert-select">
            <div className="alert-select__control-container" onClick={openDropdown}>
                <div className="alert-select__control-selected">{options[selected]}</div>
                {!disabled && (
                    <ArrowRight
                        className={open ? "alert-select__control-arrow rotate" : "alert-select__control-arrow"}
                    />
                )}
            </div>
            <div className={open && !disabled ? "alert-select__options-dropdown" : "hidden"}>
                {options.map((option, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            selectOption(i)
                        }}
                        className="alert-select__option"
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AlertSelect
