import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={11} height={7} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.181 4.192h-.77a.33.33 0 01-.324-.337.33.33 0 01.324-.337h.97c.123 0 .235.072.29.187l1.294 2.694c.107.224-.05.488-.29.488H.322c-.24 0-.396-.264-.289-.488l1.294-2.694a.322.322 0 01.29-.187h.97a.33.33 0 01.324.337.33.33 0 01-.324.337h-.77L.846 6.213h9.306l-.97-2.02zM5.938 4.02a.345.345 0 01-.11.462.316.316 0 01-.445-.116c-.385-.668-.385-1.362 0-2.03.262-.455.262-.883 0-1.338a.345.345 0 01.111-.462.316.316 0 01.444.115c.385.668.385 1.363 0 2.031-.262.455-.262.883 0 1.338zm-1.617 0a.345.345 0 01-.111.462.316.316 0 01-.444-.116c-.385-.668-.385-1.362 0-2.03.262-.455.262-.883 0-1.338a.345.345 0 01.11-.462.316.316 0 01.445.115c.385.668.385 1.363 0 2.031-.263.455-.263.883 0 1.338zm3.235 0a.345.345 0 01-.111.462A.316.316 0 017 4.365c-.385-.668-.385-1.362 0-2.03.262-.455.262-.883 0-1.338a.345.345 0 01.11-.462.316.316 0 01.445.115c.385.668.385 1.363 0 2.031-.262.455-.262.883 0 1.338z"
                fill="#fff"
            />
        </svg>
    )
}

export default SvgComponent
