import React from "react"
import { CircularProgress } from "@material-ui/core"

import "./LoaderWithMessages.css"

class LoaderWithMessages extends React.Component {
    static timeOut

    constructor(props) {
        super(props)
        this.state = { messageIndex: -1, startAnimation: false }
    }

    loop(i) {
        this.timeOut = setTimeout(() => {
            this.setState({ messageIndex: i })
            if (i == 0) this.setState({ startAnimation: true })
            if (this.state.messageIndex < this.props.messages.length - 1) {
                this.loop(i + 1)
            }
        }, 2500)
    }

    componentDidMount() {
        this.loop(0)
    }

    componentWillUpdate() {}

    componentWillUnmount() {
        clearTimeout(this.timeOut)
    }

    render() {
        return (
            <>
                <CircularProgress />
                <div className="message">
                    {this.state.messageIndex === -1 ? "Loading..." : this.props.messages[this.state.messageIndex]}
                </div>
            </>
        )
    }
}

export default LoaderWithMessages
