import React, { useContext, useState } from "react"

import ArrowRight from "../Icons/ArrowRight"
import DecisionModal from "../Modal/DecisionModal"

import { convertDistance } from "../../Util/UnitConversion"

import "./DataSourceDropdown.css"

function DataSourceDropdown(props) {
    const { datasourcesData = {}, units, onConfirmChange = () => null, fieldId, showDataSourceDropdown } = props
    const [selected, setSelected] = useState(datasourcesData.selected)
    const [open, toggle] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmModalTitle, setConfirmModalTitle] = useState("Change datasource for field")
    const [confirmModalText, setConfirmModalText] = useState("Explanation of what changing the datasource means.")

    function handleSelected(option) {
        setConfirmModalTitle("Changing...")
        onConfirmChange(fieldId, { selected: option })
            .then((res) => {
                setSelected(option)
                handleToggle()
                setConfirmModal(false)
                resetModal()
            })
            .catch((err) => {
                console.log("Error = ", err)
                setModalError()
            })
    }

    function resetModal() {
        setConfirmModalTitle("Change datasource for field")
        setConfirmModalText("Explanation of what changing the datasource means.")
    }

    function setModalError() {
        setConfirmModalTitle("Something went wrong")
        setConfirmModalText("Please try again later.")
    }

    function handleToggle() {
        if (showDataSourceDropdown) {
            toggle(!open)
        }
    }

    function getName(data) {
        if (data === null || data === undefined || !data || (Object.keys(data).length = 0)) return
        let name = data["climateai-id"] ? data["climateai-id"] : "ERA5"
        return name + " - " + convertDistance("metric", units, data["distance"], "")
    }

    function isEmpty(data) {
        return data && Object.keys(data).length === 0 && data.constructor === Object
    }

    return (
        <>
            <div className="data-source-drop">
                <div className="data-source-drop__selection" onClick={handleToggle}>
                    <div className="data-source-drop__text-container">
                        <div className="data-source-drop__title">Data Source</div>
                        <div className="data-source-drop__selected">{getName(datasourcesData[selected])}</div>
                    </div>
                    <div className={open ? "data-source-drop__arrow-icon--open" : "data-source-drop__arrow-icon"}>
                        {showDataSourceDropdown && <ArrowRight fill={open ? "#1C9690" : "#505050"} />}
                    </div>
                </div>
                <div
                    className={
                        open ? "data-source-drop__options-container--open" : "data-source-drop__options-container"
                    }
                >
                    {datasourcesData
                        ? Object.keys(datasourcesData).map((key) => {
                              if ((key !== "era" && key !== "ghcn") || isEmpty(datasourcesData[key])) return

                              return (
                                  <div
                                      key={key}
                                      //   Could this set problems with renders???
                                      onClick={() => {
                                          if (key !== selected) {
                                              resetModal()
                                              setConfirmModal(key)
                                          } else {
                                              handleToggle()
                                          }
                                      }}
                                      className={`data-source-drop__option ${
                                          datasourcesData.suggested === key ? "suggested" : ""
                                      } ${selected === key ? "selected" : ""}`}
                                  >
                                      {getName(datasourcesData[key])}
                                  </div>
                              )
                          })
                        : null}
                </div>
            </div>

            {(confirmModal === "era" || confirmModal === "ghcn") && (
                <DecisionModal
                    title={confirmModalTitle}
                    text={confirmModalText}
                    disabled={confirmModalTitle === "Changing..."}
                    errorBool={confirmModalTitle === "Something went wrong"}
                    onDecision={() => {
                        handleSelected(confirmModal)
                    }}
                    onCancel={() => {
                        setConfirmModal(false)
                    }}
                />
            )}
        </>
    )
}

export default DataSourceDropdown
