import React from "react"

import "./DeleteModal.css"

const DeleteModal = (props) => {
    const { deleteItem, onDelete, onCancel, context } = props

    return (
        <div className="delete-modal">
            <div className="delete-modal__container">
                <div className="delete-modal__title">{`Are you sure you want to delete ${deleteItem}?`}</div>
                <div className="delete-modal__text">This action cannot be undone</div>
                <div className="delete-modal__button-container">
                    <button className="delete-modal__delete-button" onClick={onDelete}>
                        Delete {context}
                    </button>
                    <button className="delete-modal__cancel-button" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal
