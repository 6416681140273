import React, { useRef, useState, useEffect } from "react"

import LabelItem from "./LabelItem"

import LabelIcon from "../Icons/LabelIcon"

import "./LabelSelect.css"

import ColorPicker from "./ColorPicker"

function LabelSelect(props) {
    const [colorPickerActive, toggleColorPicker] = useState(false)

    const {
        labels,
        onSelectLabel,
        onDeleteLabel,
        __onClose__: onClose = () => null, // property from parent
    } = props

    const inputRef = useRef(null)
    const labelSelectRef = useRef(null)

    useEffect(() => {
        function handleDocumentClick(e) {
            if (!labelSelectRef.current.contains(e.target)) {
                onClose()
            }
        }

        document.addEventListener("click", handleDocumentClick)
        return () => document.removeEventListener("click", handleDocumentClick)
    }, [])

    function handleKeyDown(e) {
        if (["Enter", "Tab"].includes(e.key)) {
            e.preventDefault()
            if (e.target.value) toggleColorPicker(!colorPickerActive)
        }
    }

    function handleLabelIconPress() {
        if (inputRef.current.value) toggleColorPicker(!colorPickerActive)
    }

    function handleColorSelect(color) {
        if (inputRef.current.value) onSelectLabel({ name: inputRef.current.value, color })
        toggleColorPicker(false)
        inputRef.current.value = ""
    }

    return (
        <>
            <div className="label-select" ref={labelSelectRef}>
                <p className="label-select__text">Label as:</p>
                <div className="label-select__input-container">
                    <input
                        type="text"
                        placeholder="New Label"
                        className="label-select__input-container__input"
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        autoFocus
                    />
                    <button className="label-select__input-container__button" onClick={handleLabelIconPress}>
                        <LabelIcon fill="#4E4E4E" />
                    </button>
                    {colorPickerActive && <ColorPicker onColorSelect={handleColorSelect} />}
                </div>
                {labels && labels.length > 0 && (
                    <div className="label-select__list">
                        {labels.map((label) => {
                            const { name, color } = label
                            return (
                                <LabelItem
                                    name={name}
                                    color={color}
                                    onDeleteLabel={onDeleteLabel}
                                    onSelectLabel={onSelectLabel}
                                />
                            )
                        })}
                    </div>
                )}
            </div>
        </>
    )
}

export default LabelSelect
