import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="8.23879" cy="7.98682" rx="7.48977" ry="7.5" fill="#1D2934" />
            <g clipPath="url(#clip0)">
                <path
                    d="M12.0003 8.51406C11.9928 8.78641 11.7625 9 11.4901 9H10.6255V9.25C10.6255 9.59163 10.5492 9.91537 10.413 10.2054L11.354 11.1465C11.5493 11.3417 11.5493 11.6583 11.354 11.8536C11.1588 12.0488 10.8422 12.0488 10.6469 11.8536L9.79167 10.9983C9.40495 11.312 8.91221 11.5 8.37548 11.5V7.6875C8.37548 7.58395 8.29153 7.5 8.18798 7.5H7.81298C7.70943 7.5 7.62548 7.58395 7.62548 7.6875V11.5C7.08874 11.5 6.59601 11.312 6.20929 10.9983L5.35404 11.8536C5.15876 12.0488 4.84218 12.0488 4.64692 11.8536C4.45167 11.6583 4.45167 11.3417 4.64692 11.1465L5.58798 10.2054C5.45176 9.91537 5.37548 9.59163 5.37548 9.25V9H4.51089C4.23845 9 4.00815 8.78641 4.00068 8.51406C3.99292 8.2315 4.21967 8 4.50048 8H5.37548V7.08211L4.64692 6.35355C4.45167 6.15828 4.45167 5.8417 4.64692 5.64644C4.8422 5.45117 5.15876 5.45117 5.35404 5.64644L6.20759 6.5H9.79335L10.6469 5.64645C10.8422 5.45119 11.1587 5.45119 11.354 5.64645C11.5493 5.84172 11.5493 6.1583 11.354 6.35356L10.6255 7.08211V8H11.5005C11.7813 8 12.008 8.2315 12.0003 8.51406ZM8.0161 4C7.0496 4 6.2661 4.7835 6.2661 5.75H9.7661C9.7661 4.7835 8.9826 4 8.0161 4Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgComponent
