import { makeStyles } from "@material-ui/styles"

export const climateOverviewStyles = makeStyles((theme) => ({
    root: {
        width: "inherit",
        display: "flex",
        justifyContent: "center",
        boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
        borderRadius: "20px",
        position: "relative",
    },
    alert: {
        position: "absolute",
        backgroundColor: theme.palette.colorStyles.positiveAlert,
        width: "inherit",
        color: "white",
        padding: "8px 0",
    },
    cardContent: {
        margin: "32px 0",
        textAlign: "end",
        width: "inherit",
    },
    minTemperature: {
        fontWeight: "200",
    },
    divider: {
        margin: "8px 0 16px",
    },
    icons: {
        height: "12px",
        width: "12px",
        margin: "0 4px 0 0",
    },
    windIcon: {
        transform: "rotate(104deg)",
        margin: "0 4px 4px 0",
    },
}))

export const dashboardStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1),
        },
        padding: theme.spacing(4),
    },
    leftContainer: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: "60px !important",
        },
    },
    climateOverviewContainer: {
        [theme.breakpoints.down("md")]: {
            width: "150px",
        },
    },
    cardsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "20px",
    },
    card: {
        width: "150px",
    },
    growthStageContainer: {
        paddingRight: "15px",
    },
}))
